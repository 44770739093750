<template>
  <div class="col-4 sidebar">
    <nav class="main-left-nav" role="navigation">
      <hr role="separator" aria-orientation="horizontal" class="d-none divider" />

      <!-- Visualizzazioni -->
      <ul class="unstyled nav-parent-item">
        <li>
          <div
            class="btn-group-vertical d-block"
            v-for="(visualizzazione, index) in tipologiaViste"
            :key="index"
          >
            <input
              :id="'filter_' + visualizzazione.value"
              :value="visualizzazione"
              v-model="checkedViews"
              @change="onChangeViewType(visualizzazione.value)"
              type="radio"
              class="btn-check-filter"
              :checked="getViewsTypeAttivita == visualizzazione.value"
            />
            <label class="btn btn-filter" :for="'filter_' + visualizzazione.value">{{
              visualizzazione.text
            }}</label>
          </div>
        </li>
      </ul>

      <hr role="separator" aria-orientation="horizontal" class="divider" />

      <!-- Menù modi attività -->
      <ul class="unstyled nav-parent-item">
        <li v-for="item in menuModiAttivita" :key="item.text">
          <template v-if="isProfiloDipendente || isSuperAdmin">
            <router-link
              @click.prevent="clearAllAttributesFilters()"
              :to="{ name: item.value }"
              exact
              :class="{ active: $route.name === item.value }"
              >{{ item.text }}
            </router-link>
          </template>
          <template v-else-if="item.value == 'route-attivita-riepilogo'">
            <router-link
              @click.prevent="clearAllAttributesFilters()"
              :to="{ name: item.value }"
              exact
              :class="{ active: $route.name === item.value }"
              >{{ item.text }}
            </router-link>
          </template>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { UtilityMixins } from "@/mixins/UtilityMixins.js";

export default {
  mixins: [UtilityMixins],
  components: {},
  data() {
    return {
      userAuthenticated: null,
      checkedViews: "",
      tipologiaViste: [
        // {
        //   text: "Giornaliero",
        //   value: "giornaliera",
        // },
        // {
        //   text: "Settimanale",
        //   value: "settimanale",
        // },
        {
          text: "Mensile",
          value: "mensile",
        },
        // {
        //   text: "Annuale",
        //   value: "annuale",
        // },
      ],
      menuModiAttivita: [
        {
          text: "Riepilogo",
          value: "route-attivita-riepilogo",
        },
        {
          text: "Ferie",
          value: "route-attivita-ferie",
        },
        {
          text: "Assenze",
          value: "route-attivita-assenza",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["getViewsTypeAttivita"]),
    isProfiloDipendente() {
      // Restituisce se è un profilo di tipo "Dipendente"
      if (this.userAuthenticated && this.userAuthenticated.profili) {
        return this.userAuthenticated.profili.some(
          (item) => item.descrizione.toLowerCase() === "dipendente"
        );
      }
      return false;
    },
    isSuperAdmin() {
      // Restituisce se è un Super Admin"
      if (this.userAuthenticated && this.userAuthenticated.isSuperAdmin) {
        return this.userAuthenticated.isSuperAdmin;
      }
      return false;
    },
  },
  watch: {},
  methods: {
    ...mapActions(["setViewsTypeAttivita"]),
    clearAllAttributesFilters() {
      // Resetto eventuali filtri
    },
    onChangeViewType(view) {
      console.log("** change view in", view);
      this.setViewsTypeAttivita(view);
    },
  },
  created() {
    this.checkedViews = this.getViewsTypeAttivita;
  },
  mounted() {
    // Oggetto user da local storage
    this.userAuthenticated = this.mixin_getUserDataLS();
  },
};
</script>
