<template>
  <overlay-loading v-model:isLoading="isLoading"></overlay-loading>
  <div
    class="d-flex justify-content-center m-0 pt-4 row"
    :class="{
      'element-disabled':
        isMe && !isAuthenticatedSuperAdmin && !isAuthenticatedGestionePersonale,
    }"
  >
    <!-- Details Rapporto -->
    <div class="col-lg-9 p-0">
      <div class="row mb-1">
        <label
          class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
          ><span>SOCIETÀ</span>
        </label>
        <div class="align-items-center col-lg-10 d-flex justify-content-center">
          <MultiSelect
            v-model="selectedLocalModels.societa"
            :options="societaOptions"
            :searchable="true"
            placeholder="- SELEZIONA -"
            label="label"
            track-by="value"
            :show-no-results="true"
            selectLabel="Seleziona"
            deselectLabel="Rimuovi"
            selectedLabel="Selezionato"
            :openDirection="'below'"
            tagPlaceholder="Premi enter per creare un tag"
            :multiple="false"
            :taggable="false"
            :showLabels="false"
            @update:modelValue="onSocietaUpdate"
            :maxHeight="210"
          >
            <template #noOptions>
              <span>L'elenco è vuoto</span>
            </template>
            <template #noResult>
              <span>Nessun elemento trovato</span>
            </template>
          </MultiSelect>
        </div>
      </div>
      <div class="row mb-1">
        <label
          class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
          ><span>PROFILI</span>
        </label>
        <div class="align-items-center col-lg-10 d-flex justify-content-center">
          <MultiSelect
            v-model="selectedLocalModels.profili"
            :options="profiliOptions"
            :searchable="true"
            placeholder="- SELEZIONA -"
            label="label"
            track-by="value"
            :show-no-results="true"
            selectLabel="Seleziona"
            deselectLabel="Rimuovi"
            selectedLabel="Selezionato"
            :openDirection="'below'"
            tagPlaceholder="Premi enter per creare un tag"
            :multiple="true"
            :taggable="false"
            :showLabels="false"
            @update:modelValue="onProfiliUpdate"
            :maxHeight="210"
          >
            <template #clear>
              <div
                v-if="selectedLocalModels.profili && selectedLocalModels.profili.length"
                class="multiselect__clear"
                @mousedown.prevent.stop="clearProfili"
              ></div>
            </template>
            <template #noOptions>
              <span>L'elenco è vuoto</span>
            </template>
            <template #noResult>
              <span>Nessun elemento trovato</span>
            </template>
          </MultiSelect>
        </div>
      </div>
      <div class="d-none row mb-3">
        <label
          class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
          ><span>RUOLI</span>
        </label>
        <div class="align-items-center col-lg-10 d-flex justify-content-center">
          <MultiSelect
            v-model="selectedLocalModels.ruoli"
            :options="ruoliOptions"
            :searchable="true"
            placeholder="- SELEZIONA -"
            label="label"
            track-by="value"
            :show-no-results="true"
            selectLabel="Seleziona"
            deselectLabel="Rimuovi"
            selectedLabel="Selezionato"
            :openDirection="'below'"
            tagPlaceholder="Premi enter per creare un tag"
            :multiple="true"
            :taggable="false"
            :showLabels="false"
            @update:modelValue="onRuoliUpdate"
            :maxHeight="210"
          >
            <template #clear>
              <div
                v-if="selectedLocalModels.ruoli && selectedLocalModels.ruoli.length"
                class="multiselect__clear"
                @mousedown.prevent.stop="clearRuoli"
              ></div>
            </template>
            <template #noOptions>
              <span>L'elenco è vuoto</span>
            </template>
            <template #noResult>
              <span>Nessun elemento trovato</span>
            </template>
          </MultiSelect>
        </div>
      </div>
      <div class="row mb-1">
        <label
          class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
          ><span>MATRICOLA</span>
        </label>
        <div class="align-items-center col-lg-10 d-flex justify-content-center">
          <input
            type="text"
            class="form-control"
            v-model.trim="localRapporto.matricola"
            @input="onInputChange($event, 'matricola')"
            @paste="onPaste($event, 'matricola')"
            v-input-textarea
            spellcheck="false"
            autocomplete="off"
            autocapitalize="none"
            placeholder="MATRICOLA"
          />
        </div>
      </div>
      <div class="row mb-3">
        <label
          class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
          ><span>CODICE PACO</span>
        </label>
        <div class="align-items-center col-lg-10 d-flex justify-content-center">
          <input
            type="text"
            class="form-control"
            v-model.trim="localRapporto.codicepaco"
            @input="onInputChange($event, 'codicepaco')"
            @paste="onPaste($event, 'codicepaco')"
            v-input-textarea
            spellcheck="false"
            autocomplete="off"
            autocapitalize="none"
            placeholder="CODICE PACO"
          />
        </div>
      </div>
      <div class="row mb-3">
        <label
          class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
          ><span
            >AZIENDA TERZA PARTE<br />(caricate: {{ aziendaterzaparteOptions.length }} /
            totali: {{ totalItemsFornitori }})</span
          >
        </label>
        <div class="align-items-center col-lg-10 d-flex justify-content-center">
          <MultiSelect
            ref="fornitoriContainer"
            v-model="selectedLocalModels.aziendaterzaparte"
            :options="aziendaterzaparteOptions"
            :searchable="true"
            placeholder="- SELEZIONA -"
            label="label"
            track-by="value"
            :show-no-results="true"
            selectLabel="Seleziona"
            deselectLabel="Rimuovi"
            selectedLabel="Selezionato"
            :openDirection="'below'"
            tagPlaceholder="Premi enter per creare un tag"
            :multiple="false"
            :taggable="false"
            :showLabels="false"
            :loading="isLoadingFornitori"
            :limit="limit"
            :maxHeight="210"
            @search-change="onSearchFornitoriChange"
            @open="onOpenFornitori"
            @close="onCloseFornitori"
            @update:modelValue="onAziendaterzaparteUpdate"
          >
            <template #noOptions>
              <span>L'elenco è vuoto</span>
            </template>
            <template #noResult>
              <span>Nessun elemento trovato</span>
            </template>
          </MultiSelect>
        </div>
      </div>
      <div class="row mb-1">
        <label
          class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
          ><span>DATA INIZIO RAPPORTO</span>
        </label>
        <div class="align-items-center col-lg-4 d-flex justify-content-center">
          <VueDatePicker
            v-model="selectedLocalModels.datainiziorapporto"
            dark
            locale="it"
            format="dd/MM/yyyy"
            cancelText="Annulla"
            selectText="Conferma"
            :enable-time-picker="false"
            @update:modelValue="onDataIniziorapportoUpdate"
            @cleared="onDataIniziorapportoClear"
            placeholder="DATA INIZIO RAPPORTO"
          >
            <template #input-icon>
              <i class="input-slot-image2 zmdi zmdi-calendar"></i>
            </template>
            <template #clear-icon="{ clear }">
              <i @click="clear" class="input-slot-image zmdi zmdi-close zmdi-hc-lg"></i>
            </template>
          </VueDatePicker>
          <button
            class="timepicker-button"
            alt="OGGI"
            title="OGGI"
            @click="setToday('datainiziorapporto')"
          >
            <i class="zmdi zmdi-calendar zmdi-hc-lg"></i>
          </button>
        </div>
        <label
          class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
          ><span>DATA FINE RAPPORTO</span>
        </label>
        <div class="align-items-center col-lg-4 d-flex justify-content-center">
          <VueDatePicker
            v-model="selectedLocalModels.datafinerapporto"
            dark
            locale="it"
            format="dd/MM/yyyy"
            cancelText="Annulla"
            selectText="Conferma"
            :enable-time-picker="false"
            @update:modelValue="onDataFinerapportoUpdate"
            @cleared="onDataFinerapportoClear"
            placeholder="DATA FINE RAPPORTO"
          >
            <template #input-icon>
              <i class="input-slot-image2 zmdi zmdi-calendar"></i>
            </template>
            <template #clear-icon="{ clear }">
              <i @click="clear" class="input-slot-image zmdi zmdi-close zmdi-hc-lg"></i>
            </template>
          </VueDatePicker>
          <button
            class="timepicker-button"
            alt="OGGI"
            title="OGGI"
            @click="setToday('datafinerapporto')"
          >
            <i class="zmdi zmdi-calendar zmdi-hc-lg"></i>
          </button>
        </div>
      </div>
      <div class="row mb-1">
        <label
          class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
          ><span>SEDE</span>
        </label>
        <div class="align-items-center col-lg-10 d-flex justify-content-center">
          <MultiSelect
            :searchable="false"
            v-model="selectedLocalModels.sede"
            :options="sedeOptions"
            placeholder="- SELEZIONA -"
            label="label"
            track-by="value"
            :openDirection="'below'"
            :showLabels="false"
            @update:modelValue="onSedeUpdate"
            :maxHeight="210"
          >
            <template #noOptions>
              <span>L'elenco è vuoto</span>
            </template>
            <template #noResult>
              <span>Nessun elemento trovato</span>
            </template>
          </MultiSelect>
        </div>
      </div>
      <div class="row mb-1">
        <label
          class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
          ><span>REFERENTE/DTC</span>
        </label>
        <div class="align-items-center col-lg-10 d-flex justify-content-center">
          <MultiSelect
            v-model="selectedLocalModels.referente"
            :options="referentiOptions"
            :searchable="true"
            placeholder="- SELEZIONA -"
            label="label"
            track-by="value"
            :show-no-results="true"
            selectLabel="Seleziona"
            deselectLabel="Rimuovi"
            selectedLabel="Selezionato"
            :openDirection="'below'"
            tagPlaceholder="Premi enter per creare un tag"
            :multiple="false"
            :taggable="false"
            :showLabels="false"
            @update:modelValue="onReferenteUpdate"
            :loading="isSearchingReferente"
            @search-change="asyncFetchReferenti"
            :maxHeight="210"
          >
            <template #noOptions>
              <span>L'elenco è vuoto</span>
            </template>
            <template #noResult>
              <span>Nessun elemento trovato</span>
            </template>
          </MultiSelect>
        </div>
      </div>
      <div class="row mb-3">
        <label
          class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
          ><span>ORE CONTRATTUALI</span>
        </label>
        <div class="align-items-center col-lg-6 d-flex justify-content-center">
          <MultiSelect
            :searchable="false"
            v-model="selectedLocalModels.orecontrattuali"
            :options="orecontrattualiOptions"
            placeholder="- SELEZIONA -"
            label="label"
            track-by="value"
            :openDirection="'below'"
            :showLabels="false"
            @update:modelValue="onOrecontrattualiUpdate"
            :maxHeight="210"
          >
            <template #noOptions>
              <span>L'elenco è vuoto</span>
            </template>
            <template #noResult>
              <span>Nessun elemento trovato</span>
            </template>
          </MultiSelect>
        </div>
        <div class="align-items-center col-lg-4 d-flex justify-content-lg-end">
          <label class="col-form-label form-label d-inline-flex me-2">
            <span>TURNISTA</span>
          </label>
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              :checked="localRapporto.turnista === 1"
              @change="toggleTurnista"
            />
            <label class="content-form text-end label-yes-no">
              {{ localRapporto.turnista === 1 ? "SI" : "NO" }}
            </label>
          </div>
        </div>
      </div>
      <div class="row mb-1">
        <label
          class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
          ><span>RAL</span>
        </label>
        <div class="align-items-center col-lg-4 d-flex justify-content-start">
          <input
            type="number"
            inputmode="numeric"
            pattern="[0-9]*"
            class="form-control no-spin"
            style="width: 200px"
            v-model="localRapporto.ral"
            @input="onInputChange($event, 'ral')"
            @paste="onPaste($event, 'ral')"
            placeholder="RAL"
          />
        </div>
      </div>
      <div class="row mb-1">
        <label
          class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
          ><span>DIARIA</span>
        </label>
        <div class="align-items-center col-lg-4 d-flex justify-content-start">
          <input
            type="number"
            inputmode="numeric"
            pattern="[0-9]*"
            class="form-control no-spin me-2"
            style="width: 200px"
            v-model="localRapporto.diaria"
            @input="onInputChange($event, 'diaria')"
            @paste="onPaste($event, 'diaria')"
            placeholder="DIARIA"
          />
          <div class="col-form-label form-label d-inline-flex">
            <span>/&nbsp;Mese</span>
          </div>
        </div>
      </div>
      <div class="row mb-1">
        <label
          class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
          ><span>BENEFIT</span>
        </label>
        <div class="align-items-center col-lg-4 d-flex justify-content-start">
          <input
            type="number"
            inputmode="numeric"
            pattern="[0-9]*"
            class="form-control no-spin me-2"
            style="width: 200px"
            v-model="localRapporto.benefit"
            @input="onInputChange($event, 'benefit')"
            @paste="onPaste($event, 'benefit')"
            placeholder="BENEFIT"
          />
          <div class="col-form-label form-label d-inline-flex">
            <span>/&nbsp;Mese</span>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <label
          class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
          ><span>TICKET</span>
        </label>
        <div class="align-items-center col-lg-4 d-flex justify-content-start">
          <input
            type="number"
            inputmode="numeric"
            pattern="[0-9]*"
            class="form-control no-spin me-2"
            style="width: 200px"
            v-model="localRapporto.ticket"
            @input="onInputChange($event, 'ticket')"
            @paste="onPaste($event, 'ticket')"
            placeholder="TICKET"
          />
          <div class="col-form-label form-label d-inline-flex">
            <span>/&nbsp;Giorno</span>
          </div>
        </div>
      </div>
      <!-- Errors model -->
      <div v-if="errorsModel" class="mb-4">
        <div
          v-for="(errorModel, key) in errorsModel"
          :key="key"
          class="text-danger small mt-1"
        >
          {{ errorModel }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Rapporto, RapportoSchema } from "@/models/TeamJoiModel";
import { fetchUserRapporto, fetchFornitori } from "@/services/api";
import { UtilityMixins } from "@/mixins/UtilityMixins.js";
import OverlayLoading from "@/components/ui/OverlayLoading.vue";
import { formatISO, parseISO } from "date-fns";
import "date-fns/locale/it";
import ProfiliPermissions from "@/mixins/ProfiliPermissions.js";
import { Fornitore } from "@/models/FornitoreJoiModel";

export default {
  mixins: [UtilityMixins, ProfiliPermissions],
  components: {
    OverlayLoading,
  },
  props: {
    isEditMode: {
      type: Boolean,
      default: false,
    },
    userDataRapporto: {
      type: Object,
      required: true,
    },
  },
  emits: [
    "section-modified",
    "section-verified",
    "fetch-data",
    "data-updated",
    "update:userDataRapporto",
  ],
  data() {
    return {
      localRapporto: new Rapporto(), // Creo un oggetto per gestire i dati locali
      initialRapporto: null, // Memorizzo lo stato iniziale per il confronto
      isLoading: false, // Gestione del loader per indicare il caricamento
      errorsModel: null, // Oggetto per memorizzare gli errori di validazione
      societaOptions: [], // Array delle opzioni per la societa
      profiliOptions: [], // Array delle opzioni per i profili
      ruoliOptions: [], // Array delle opzioni per i ruoli
      referentiOptions: [], // Array delle opzioni per i referenti DTC
      sedeOptions: [], // Array delle sedi dell'azienda selezionata
      orecontrattualiOptions: [], // Array dell'ora contrattuale selezionata
      selectedLocalModels: {
        // Raccolgo i modelli locali in un unico oggetto
        societa: [],
        profili: [],
        ruoli: [],
        referente: [],
        aziendaterzaparte: [],
        sede: [],
        datainiziorapporto: null,
        datafinerapporto: null,
      },
      isSearchingReferente: false,
      isSearchingAziendaterzaparte: false,
      paramsId: this.$route.params.id || null,
      userAuthenticated: null,
      // multiselect Fornitori
      aziendaterzaparteOptions: [], // Array delle opzioni per le aziende terza parte
      isLoadingFornitori: false, // Indicatore di caricamento
      currentPageFornitori: 1, // Pagina corrente per il caricamento asincrono
      totalItemsFornitori: 0, // Totale degli elementi nel server
      textToSearchFornitori: "", // Query di ricerca
      limit: 50, // limite max degli items restituiti dalla API
    };
  },
  computed: {
    //
    // Authenticated
    //
    isAuthenticatedSuperAdmin() {
      return this.permissions_isAuthenticatedSuperAdmin(this.userAuthenticated);
    },
    isAuthenticatedGestionePersonale() {
      return this.permissions_isAuthenticatedGestionePersonale(this.userAuthenticated);
    },
    isMe() {
      return this.userAuthenticated?.providerUserId == this.paramsId;
    },
  },
  watch: {
    // Osservo il cambiamento di userDataRapporto per aggiornare i dati locali
    userDataRapporto: {
      immediate: true, // Eseguo l'handler al montaggio
      handler(newVal) {
        // Aggiorno localRapporto e i modelli locali al cambio dei dati
        this.localRapporto = new Rapporto(newVal);
        this.initialRapporto = JSON.parse(JSON.stringify(this.localRapporto));
      },
    },
  },
  methods: {
    //
    // ** Fetch Data **
    //
    async fetchData() {
      if (!this.isEditMode) return; // Non faccio nulla se non sono in modalità modifica

      // Mostro il loader subito dopo il montaggio del componente
      this.$nextTick(() => {
        this.isLoading = true;
      });
      try {
        const response = await fetchUserRapporto(this.$route.params.id);
        if (response) {
          // Se l'oggetto mandato dal server è vuoto,  non visualizzo la scheda
          if (this.mixins_isEmptyObject(response)) {
            // this.$parent.errorMessage = "L'elemento 'rapporto' non esiste";
            // return;
            this.localRapporto = new Rapporto();
          } else {
            this.localRapporto = new Rapporto(response.rapporto);
          }

          // Aggiorno i dati locali con la risposta
          this.initialRapporto = JSON.parse(JSON.stringify(this.localRapporto));
          console.log("** dati localRapporto", this.localRapporto);
          console.log("** dati initialRapporto", this.initialRapporto);

          // Emetto eventi per aggiornare il v-model e segnalare il fetch completato
          this.$emit("update:userDataRapporto", this.localRapporto);
          this.$emit("fetch-data", "rapporto");
          this.$parent.errorMessage = "";
        } else {
          this.$parent.errorMessage = "L'elemento 'rapporto' non esiste";
        }
      } catch (error) {
        // console.error("Errore durante il caricamento dei dati", error);
        this.$parent.errorMessage = "Errore durante il caricamento dei dati";
      } finally {
        // Nascondo il loader al termine del caricamento
        this.$nextTick(() => {
          this.isLoading = false;
        });
      }
    },
    async asyncFetchAziendaterzaparte(query) {
      // rimuovo i caratteri *^? insieme ai tag HTML/XML per le query
      query = this.mixins_cleanTextForQuery(query);
      // Carico le opzioni dei fornitori trovati
      this.isSearchingAziendaterzaparte = true;
      const tabAziendaterzaparte = await this.mixins_getFornitori(query);
      this.aziendaterzaparteOptions = tabAziendaterzaparte.map((fornitore) => ({
        value: fornitore._id,
        label: fornitore.ragionesociale,
      }));
      this.isSearchingAziendaterzaparte = false;
      console.log("** aziendaterzaparteOptions", this.aziendaterzaparteOptions);
    },
    async asyncFetchReferenti(query) {
      // rimuovo i caratteri *^? insieme ai tag HTML/XML per le query
      query = this.mixins_cleanTextForQuery(query);
      // Carico le opzioni dei referenti DTC trovati
      this.isSearchingReferente = true;
      const tabReferentiDTC = await this.mixins_getReferentiDTC(query, "rapporto");
      this.referentiOptions = tabReferentiDTC.map((referente) => ({
        value: referente.providerUserId,
        // label: `${referente.metadata.anagrafica.nome} ${referente.metadata.anagrafica.cognome}`,
        label: referente.metadata.fullname,
      }));
      this.isSearchingReferente = false;
      console.log("** referentiOptions", this.referentiOptions);
    },
    async fetchDataAndUpdateFields() {
      if (this.isEditMode) {
        // Eseguo il fetch dei dati del rapporto solo se in editMode
        await this.fetchData();
      } else {
        // Altrimenti genero un nuovo rapporto
        this.localRapporto = new Rapporto();
        this.initialRapporto = JSON.parse(JSON.stringify(this.localRapporto));
        console.log("** dati localRapporto", this.localRapporto);
        console.log("** dati initialRapporto", this.initialRapporto);

        this.validateImmediate();
        this.updateParent();

        // Emetto eventi per aggiornare il v-model e segnalare il fetch completato
        this.$emit("update:userDataRapporto", this.localRapporto);
        this.$emit("fetch-data", "rapporto");
        this.$parent.errorMessage = "";
      }

      this.$nextTick(() => {
        this.isLoading = true;
      });

      // Carico le opzioni delle società
      const tabAziende = await this.mixins_getTabAziende();
      this.societaOptions =
        tabAziende?.map((societa) => ({
          value: societa._id,
          label: societa.ragionesociale,
          giorniFerie: societa.giorniFerie,
          sedi: societa.sedi,
        })) || [];
      console.log("** societaOptions", this.societaOptions);

      // Carico le opzioni delle sedi per società
      this.sedeOptions =
        this.societaOptions[0]?.sedi?.map((sede) => ({
          value: sede.id,
          label: sede.citta,
        })) || [];
      console.log("** sedeOptions", this.sedeOptions);

      // Carico le opzioni dei profili
      const tabProfili = await this.mixins_getTabProfili();
      this.profiliOptions =
        tabProfili?.map((profilo) => ({
          value: profilo.id,
          label: profilo.nome,
        })) || [];
      console.log("** profiliOptions", this.profiliOptions);

      // Carico le opzioni dei ruoli
      const tabRuoli = await this.mixins_getTabRuoli();
      this.ruoliOptions =
        tabRuoli?.map((ruolo) => ({
          value: ruolo.id,
          label: ruolo.nome,
        })) || [];
      console.log("** ruoliOptions", this.ruoliOptions);

      // Carico le opzioni delle aziende terze parti (fornitori)
      // if (this.localRapporto.aziendaterzaparte) {
      //   const tabAziendaterzaparte = await this.mixins_getFornitori(
      //     this.localRapporto.aziendaterzaparte.descrizione
      //   );
      //   this.aziendaterzaparteOptions =
      //     tabAziendaterzaparte?.map((fornitore) => ({
      //       value: fornitore._id,
      //       label: fornitore.ragionesociale,
      //     })) || [];
      // }
      // console.log("** aziendaterzaparteOptions", this.aziendaterzaparteOptions);

      // Carico le opzioni dei referenti DTC
      if (this.localRapporto.referente) {
        const tabReferentiDTC = await this.mixins_getReferentiDTC("", "rapporto");
        this.referentiOptions =
          tabReferentiDTC?.map((referente) => ({
            value: referente.providerUserId,
            label: referente.metadata.fullname,
          })) || [];
      }
      console.log("** referentiOptions", this.referentiOptions);

      // Carico le opzioni delle ore contrattuali
      const tabOrecontrattuali = await this.mixins_getTabOreContrattuali();
      this.orecontrattualiOptions =
        tabOrecontrattuali?.map((ora) => ({
          value: ora.value,
          label: ora.label,
        })) || [];
      console.log("** orecontrattualiOptions", this.orecontrattualiOptions);

      // Sincronizzo i modelli locali
      this.updateLocalModels();

      this.$nextTick(() => {
        this.isLoading = false;
      });
    },

    //
    // ** Gestione aggiornamenti**
    //
    onInputChange() {
      this.$nextTick(() => {
        this.validateImmediate();
        this.updateParent();
      });
    },
    onPaste(event, field) {
      event.preventDefault();
      const pastedText = (event.clipboardData || window.Clipboard).getData("text");
      const trimmedText = pastedText.trim();
      this.localRapporto[field] = trimmedText;
      this.$nextTick(() => {
        this.validateImmediate();
        this.updateParent();
      });
    },
    toggleTurnista(event) {
      this.localRapporto.turnista = event.target.checked ? 1 : 0;
      this.validateImmediate();
      this.updateParent();
    },
    clearProfili() {
      this.localRapporto.profili = null;
      this.selectedLocalModels.profili = null;
      this.validateImmediate();
      this.updateParent();
    },
    clearRuoli() {
      this.localRapporto.ruoli = null;
      this.selectedLocalModels.ruoli = null;
      this.validateImmediate();
      this.updateParent();
    },
    updateParent() {
      if (JSON.stringify(this.localRapporto) !== JSON.stringify(this.initialRapporto)) {
        this.markAsModified("rapporto");

        if (this.validateImmediate()) {
          this.$emit("update:userDataRapporto", this.localRapporto);
          this.$emit("data-updated", this.localRapporto);
        }
      }
    },
    //
    // ** Gestione delle date **
    //
    onDataIniziorapportoUpdate(newDate) {
      this.localRapporto.datainiziorapporto = newDate ? formatISO(newDate) : null;
      this.validateImmediate();
      this.updateParent();
    },
    onDataIniziorapportoClear() {
      this.localRapporto.datainiziorapporto = null;
      this.selectedLocalModels.datainiziorapporto = null;
      this.validateImmediate();
      this.updateParent();
    },
    onDataFinerapportoUpdate(newDate) {
      this.localRapporto.datafinerapporto = newDate ? formatISO(newDate) : null;
      this.validateImmediate();
      this.updateParent();
    },
    onDataFinerapportoClear() {
      this.localRapporto.datafinerapporto = null;
      this.selectedLocalModels.datafinerapporto = null;
      this.validateImmediate();
      this.updateParent();
    },
    setToday(fieldName) {
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Imposta l'orario a 00:00:00:000

      this.selectedLocalModels[fieldName] = today;
      this.localRapporto[fieldName] = formatISO(today);
      this.validateImmediate();
      this.updateParent();
    },
    //
    // ** Gestione Multiselect **
    //
    //
    // Societa
    //
    onSocietaUpdate(selected) {
      if (selected) {
        // Aggiorno i dettagli della società selezionata
        this.localRapporto.societa = {
          id: selected.value,
          descrizione: selected.label,
          giorniFerie: selected.giorniFerie,
        };

        // Popolo sedeOptions con le sedi corrispondenti alla società selezionata
        if (selected.sedi && selected.sedi.length > 0) {
          this.sedeOptions = selected.sedi.map((sede) => ({
            value: sede.id,
            label: sede.citta,
          }));
        } else {
          this.sedeOptions = [];
        }

        // Reset del modello sede se le sedi vengono aggiornate
        this.selectedLocalModels.sede = null;
      } else {
        this.localRapporto.societa = null;
        this.sedeOptions = [];
        this.selectedLocalModels.sede = null;
      }

      this.validateImmediate();
      this.updateParent();
    },
    //
    // Profili
    //
    onProfiliUpdate(selected) {
      if (Array.isArray(selected) && selected.length > 0) {
        this.localRapporto.profili = selected.map((profilo) => ({
          id: profilo.value,
          descrizione: profilo.label,
        }));
      } else {
        this.localRapporto.profili = null;
      }

      this.validateImmediate();
      this.updateParent();
    },
    //
    // Ruoli
    //
    onRuoliUpdate(selected) {
      if (Array.isArray(selected) && selected.length > 0) {
        this.localRapporto.ruoli = selected.map((ruolo) => ({
          id: ruolo.value,
          descrizione: ruolo.label,
        }));
      } else {
        this.localRapporto.ruoli = null;
      }

      this.validateImmediate();
      this.updateParent();
    },
    //
    // Referente
    //
    onReferenteUpdate(selected) {
      if (selected) {
        this.localRapporto.referente = {
          id: selected.value,
          descrizione: selected.label,
        };
      } else {
        this.localRapporto.referente = null;
      }
      this.validateImmediate();
      this.updateParent();
    },
    //
    // Azienda terza parte
    //
    onAziendaterzaparteUpdate(selected) {
      if (selected) {
        this.localRapporto.aziendaterzaparte = {
          id: selected.value,
          descrizione: selected.label,
        };
      } else {
        this.localRapporto.aziendaterzaparte = null;
      }

      this.validateImmediate();
      this.updateParent();
    },
    //
    // Sede
    //
    onSedeUpdate(selected) {
      if (selected) {
        this.localRapporto.sede = {
          id: selected.value,
          descrizione: selected.label,
        };
      } else {
        this.localRapporto.sede = null;
      }
      this.validateImmediate();
      this.updateParent();
    },
    //
    // Ore contrattuali
    //
    onOrecontrattualiUpdate(selected) {
      if (selected) {
        this.localRapporto.orecontrattuali = selected.value;
        this.selectedLocalModels.orecontrattuali = {
          value: selected.value,
          label: selected.label,
        };
      } else {
        // Se non è selezionato nulla, imposta il valore minimo
        this.localRapporto.orecontrattuali = 24;

        // Cerca l'opzione predefinita con valore 1
        const defaultOption = this.orecontrattualiOptions.find(
          (option) => option.value === 24
        );

        this.selectedLocalModels.orecontrattuali = {
          value: defaultOption.value,
          label: defaultOption.label,
        };
      }
      this.validateImmediate();
      this.updateParent();
    },
    updateLocalModels() {
      // Sincronizzo i dati ricevuti

      // societa e sede
      if (this.localRapporto.societa) {
        if (
          this.localRapporto.societa.id !== "" &&
          this.localRapporto.societa.descrizione !== ""
        ) {
          const selectedSocieta = this.societaOptions.find(
            (societa) => societa.value === this.localRapporto.societa.id
          );

          this.selectedLocalModels.societa = {
            value: this.localRapporto.societa.id,
            label: this.localRapporto.societa.descrizione,
          };

          // Aggiorna sedeOptions con le sedi della società selezionata
          if (selectedSocieta && selectedSocieta.sedi.length > 0) {
            this.sedeOptions = selectedSocieta.sedi.map((sede) => ({
              value: sede.id,
              label: sede.citta,
            }));

            // Sincronizza la sede selezionata
            if (this.localRapporto.sede) {
              this.selectedLocalModels.sede = {
                value: this.localRapporto.sede.id,
                label: this.localRapporto.sede.descrizione,
              };
            } else {
              this.selectedLocalModels.sede = null;
            }
          } else {
            this.sedeOptions = [];
            this.selectedLocalModels.sede = null;
          }
        } else {
          this.selectedLocalModels.societa = null;
          this.sedeOptions = [];
          this.selectedLocalModels.sede = null;
        }
      } else {
        this.selectedLocalModels.societa = null;
        this.sedeOptions = [];
        this.selectedLocalModels.sede = null;
      }

      // profili
      if (
        Array.isArray(this.localRapporto.profili) &&
        this.localRapporto.profili.length > 0
      ) {
        this.selectedLocalModels.profili = this.localRapporto.profili.map((profilo) => ({
          value: profilo.id,
          label: profilo.descrizione,
        }));
      } else {
        this.selectedLocalModels.profili = [];
      }

      // ruoli
      if (
        Array.isArray(this.localRapporto.ruoli) &&
        this.localRapporto.ruoli.length > 0
      ) {
        this.selectedLocalModels.ruoli = this.localRapporto.ruoli.map((ruolo) => ({
          value: ruolo.id,
          label: ruolo.descrizione,
        }));
      } else {
        this.selectedLocalModels.ruoli = [];
      }

      // datainiziorapporto
      this.selectedLocalModels.datainiziorapporto = this.localRapporto.datainiziorapporto
        ? parseISO(this.localRapporto.datainiziorapporto)
        : null;

      // datafinerapporto
      this.selectedLocalModels.datafinerapporto = this.localRapporto.datafinerapporto
        ? parseISO(this.localRapporto.datafinerapporto)
        : null;

      // referente
      if (this.localRapporto.referente) {
        if (
          this.localRapporto.referente.id !== "" &&
          this.localRapporto.referente.descrizione !== ""
        ) {
          this.selectedLocalModels.referente = {
            value: this.localRapporto.referente.id,
            label: this.localRapporto.referente.descrizione,
          };
        } else {
          this.selectedLocalModels.referente = null;
        }
      } else {
        this.selectedLocalModels.referente = null;
      }

      // aziendaterzaparte
      if (this.localRapporto.aziendaterzaparte) {
        this.selectedLocalModels.aziendaterzaparte = {
          value: this.localRapporto.aziendaterzaparte.id,
          label: this.localRapporto.aziendaterzaparte.descrizione,
        };
      } else {
        this.selectedLocalModels.aziendaterzaparte = null;
      }

      // orecontrattuali
      // Predispongo un fallback di default
      const defaultOreOptions = () => {
        this.localRapporto.orecontrattuali = 24;
        const defaultOption = this.orecontrattualiOptions.find(
          (option) => option.value === 24
        );

        if (defaultOption) {
          this.selectedLocalModels.orecontrattuali = {
            value: defaultOption.value,
            label: defaultOption.label,
          };
        }
      };
      // Controllo il valore ricevuto
      if (this.localRapporto.orecontrattuali) {
        const selectedOre = this.orecontrattualiOptions.find(
          (option) => option.value == this.localRapporto.orecontrattuali
        );
        if (selectedOre) {
          this.selectedLocalModels.orecontrattuali = {
            value: selectedOre.value,
            label: selectedOre.label,
          };
        } else {
          defaultOreOptions();
        }
      } else {
        defaultOreOptions();
      }
    },
    //
    // ** Gestione Validazioni **
    //
    validateImmediate() {
      const result = RapportoSchema.validate(this.localRapporto, {
        abortEarly: false,
      });
      if (result.error) {
        this.errorsModel = result.error.details.reduce((acc, curr) => {
          acc[curr.path[0]] = curr.message;
          return acc;
        }, {});
        this.markAsVerified("rapporto", false);
        return false;
      } else {
        this.errorsModel = null;
        this.markAsVerified("rapporto", true);
        return true;
      }
    },
    markAsModified(section) {
      // Segnalo che una specifica sezione è stata modificata
      this.$emit("section-modified", section);
    },
    markAsVerified(section, value) {
      // Segnalo che una specifica sezione è stata verificata con successo o meno
      this.$emit("section-verified", section, value);
    },
    //
    // Gestione multiselect Fornitori
    //
    resetFornitori() {
      this.aziendaterzaparteOptions = [];
      this.isLoadingFornitori = false;
      this.currentPageFornitori = 1;
      this.totalItemsFornitori = 0;
      this.textToSearchFornitori = "";
    },
    async onSearchFornitoriChange(query) {
      this.textToSearchFornitori = query; // Salva la query corrente
      this.currentPageFornitori = 1; // Resetta la paginazione
      this.aziendaterzaparteOptions = []; // Resetta le opzioni
      this.asyncLoadOptionsFornitori(); // Carica le nuove opzioni in base alla ricerca
    },
    async asyncLoadOptionsFornitori() {
      if (this.isLoadingFornitori) return; // Evita richieste multiple
      this.isLoadingFornitori = true;

      try {
        // Eseguo la chiamata API con i parametri appropriati
        const response = await fetchFornitori(
          this.currentPageFornitori,
          this.limit,
          2,
          this.textToSearchFornitori
        );

        // Mappo i fornitori in oggetti Fornitore e crea opzioni con `value` e `label`
        const data =
          response.fornitori?.map((item) => {
            const fornitore = new Fornitore(item); // Crea un nuovo oggetto Fornitore
            return {
              value: fornitore._id,
              label: fornitore.ragionesociale,
            };
          }) ?? [];

        console.log("** data fornitori", data);

        // Aggiungi i nuovi elementi all'array delle opzioni
        this.aziendaterzaparteOptions = [...this.aziendaterzaparteOptions, ...data];
        this.totalItemsFornitori = response?.totalCount ?? 0; // Imposta il totale degli elementi
        this.isLoadingFornitori = false;
      } catch (error) {
        console.error("Errore durante il caricamento dei dati:", error);
        this.isLoadingFornitori = false;
      }
    },
    onOpenFornitori() {
      this.$nextTick(() => {
        const dropdownContent = this.$refs.fornitoriContainer.$el.querySelector(
          ".multiselect__content-wrapper"
        );
        if (dropdownContent) {
          dropdownContent.addEventListener("scroll", this.onScrollFornitori);
        }
      });
    },
    onCloseFornitori() {
      const dropdownContent = this.$refs.fornitoriContainer.$el.querySelector(
        ".multiselect__content-wrapper"
      );
      if (dropdownContent) {
        dropdownContent.removeEventListener("scroll", this.onScrollFornitori);
      }
    },
    onScrollFornitori(event) {
      const scrollPosition = event.target.scrollTop + event.target.clientHeight;
      const scrollHeight = event.target.scrollHeight;

      if (
        scrollPosition >= scrollHeight &&
        this.aziendaterzaparteOptions.length < this.totalItemsFornitori
      ) {
        this.currentPageFornitori += 1;
        this.asyncLoadOptionsFornitori();
      } else {
        console.log("Tutti i fornitori sono stati caricati");
      }
    },
  },
  created() {
    // Oggetto user da local storage
    this.userAuthenticated = this.mixin_getUserDataLS();

    this.asyncLoadOptionsFornitori();
  },
};
</script>
