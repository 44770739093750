import { toJpeg } from 'html-to-image';

export default {
    methods: {
        exportToJpeg(fullname, mese, anno) {
            try {
                this.isLoading = true;

                // Aggiungo una leggera pausa per permettere al browser di aggiornare l'interfaccia
                setTimeout(() => {
                    const element = document.getElementById('dettaglioMensile');
                    toJpeg(element, {
                        quality: 0.95,
                        backgroundColor: '#eef7fc'
                    })
                        .then(dataUrl => {
                            const fileName = `unigate-rapportino-${fullname.toLowerCase().replace(/\s+/g, '-')}-${mese.toLowerCase()}-${anno}.jpg`;
                            const link = document.createElement('a');
                            link.href = dataUrl;
                            link.download = fileName;
                            link.click();

                            this.mixins_showMessage(
                                "Esporta Immagine",
                                "Esportazione completata con successo",
                                "success"
                            );
                            this.isLoading = false;
                        })
                        .catch(error => {
                            console.error("Errore durante l'esportazione:", error);
                            this.mixins_showMessage(
                                "Esporta Immagine",
                                "Si è verificato un errore durante l'esportazione dei dati",
                                "error"
                            );
                            this.isLoading = false;
                        });
                }, 500); // Ritardo di 500ms per permettere l'aggiornamento dell'interfaccia
            } catch (error) {
                console.error("Errore durante l'esportazione:", error);
                this.isLoading = false;
            }
        }


    }
};