<template>
  <overlay-loading v-model:isLoading="isLoading"></overlay-loading>
  <div class="tab-pane fade show active" id="contentAnagrafica">
    <div class="row m-0 pt-4">
      <!-- Image Logo -->
      <div class="col-lg-3 mb-3 p-0 text-center">
        <img
          :src="logoCliente || require('@/assets/images/logo-placeholder.jpg')"
          class="img-fluid w-100 mb-3"
        />
      </div>
      <!-- Details Anagrafica -->
      <div class="col-lg-9 p-0 ps-4">
        <div class="row mb-3">
          <label
            class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
          >
            <span>CLIENTE DIRETTO</span>
          </label>
          <div class="align-items-center col-lg-10 d-flex justify-content-center">
            <MultiSelect
              v-model="selectedLocalModels.cliente"
              :options="clienteOptions"
              :searchable="true"
              placeholder="- CERCA... -"
              label="label"
              track-by="value"
              :show-no-results="true"
              selectLabel="Seleziona"
              deselectLabel="Rimuovi"
              selectedLabel="Selezionato"
              :openDirection="'below'"
              tagPlaceholder="Premi enter per creare un tag"
              :multiple="false"
              :taggable="false"
              :showLabels="false"
              @update:modelValue="onClienteUpdate"
              :loading="isSearchingCliente"
              @search-change="asyncFetchCliente"
              :maxHeight="210"
            >
              <template #noOptions>
                <span>L'elenco è vuoto</span>
              </template>
              <template #noResult>
                <span>Nessun elemento trovato</span>
              </template>
            </MultiSelect>
          </div>
        </div>
        <div class="row mb-3">
          <label
            class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
          >
            <span>TITOLO COMMESSA</span>
          </label>
          <div class="align-items-center col-lg-10 d-flex justify-content-center">
            <input
              type="text"
              class="form-control"
              v-model.trim="localAnagrafica.titolo"
              @input="onInputChange($event, 'titolo')"
              @paste="onPaste($event, 'titolo')"
              v-input-textarea
              spellcheck="false"
              autocomplete="off"
              autocapitalize="none"
              placeholder="TITOLO COMMESSA"
            />
          </div>
        </div>
        <div class="row mb-3">
          <label
            class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
          >
            <span>CLIENTE FINALE</span>
          </label>
          <div class="align-items-center col-lg-10 d-flex justify-content-center">
            <input
              type="text"
              class="form-control"
              v-model.trim="localAnagrafica.clientefinale.descrizione"
              @input="onInputChange($event, 'clientefinale')"
              @paste="onPaste($event, 'clientefinale')"
              v-input-textarea
              spellcheck="false"
              autocomplete="off"
              autocapitalize="none"
              placeholder="CLIENTE FINALE"
            />
          </div>
        </div>
        <div class="row">
          <label
            class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
          >
            <span>TIPOLOGIA</span>
          </label>
          <div class="align-items-center col-lg-10 d-flex justify-content-center">
            <MultiSelect
              :searchable="false"
              v-model="selectedLocalModels.tipologia"
              :options="tipologiaOptions"
              placeholder="- SELEZIONA -"
              label="label"
              track-by="value"
              :openDirection="'below'"
              :showLabels="false"
              @update:modelValue="onTipologiaUpdate"
              :maxHeight="210"
            >
              <template #noOptions>
                <span>L'elenco è vuoto</span>
              </template>
              <template #noResult>
                <span>Nessun elemento trovato</span>
              </template>
            </MultiSelect>
          </div>
        </div>
      </div>
    </div>
    <hr class="sepGray" />
    <div class="row m-0 pt-4">
      <!-- Image Logo -->
      <div class="col-lg-3 mb-3 p-0 text-center">
        <img
          :src="logoSocieta || require('@/assets/images/logo-placeholder.jpg')"
          class="img-fluid w-100 mb-3"
        />
      </div>
      <!-- Details Anagrafica -->
      <div class="col-lg-9 p-0 ps-4">
        <div class="row mb-3">
          <label
            class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
          >
            <span>SOCIETÀ INTERNA</span>
          </label>
          <div class="align-items-center col-lg-10 d-flex justify-content-center">
            <MultiSelect
              v-model="selectedLocalModels.societainterna"
              :options="societainternaOptions"
              :searchable="true"
              placeholder="- SELEZIONA -"
              label="label"
              track-by="value"
              :show-no-results="true"
              selectLabel="Seleziona"
              deselectLabel="Rimuovi"
              selectedLabel="Selezionato"
              :openDirection="'below'"
              tagPlaceholder="Premi enter per creare un tag"
              :multiple="false"
              :taggable="false"
              :showLabels="false"
              @update:modelValue="onSocietaUpdate"
              :maxHeight="210"
            >
              <template #noOptions>
                <span>L'elenco è vuoto</span>
              </template>
              <template #noResult>
                <span>Nessun elemento trovato</span>
              </template>
            </MultiSelect>
          </div>
        </div>
        <div class="row mb-3">
          <label
            class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
          >
            <span>DATA INIZIO</span>
          </label>
          <div class="align-items-center col-lg-4 d-flex justify-content-center">
            <VueDatePicker
              v-model="selectedLocalModels.datainizio"
              dark
              locale="it"
              format="dd/MM/yyyy"
              cancelText="Annulla"
              selectText="Conferma"
              :enable-time-picker="false"
              @update:modelValue="onDatainizioUpdate"
              @cleared="onDatainizioClear"
              placeholder="DATA INIZIO"
            >
              <template #input-icon>
                <i class="input-slot-image2 zmdi zmdi-calendar"></i>
              </template>
              <template #clear-icon="{ clear }">
                <i @click="clear" class="input-slot-image zmdi zmdi-close zmdi-hc-lg"></i>
              </template>
            </VueDatePicker>
            <button
              class="timepicker-button"
              alt="OGGI"
              title="OGGI"
              @click="setToday('datainizio')"
            >
              <i class="zmdi zmdi-calendar zmdi-hc-lg"></i>
            </button>
          </div>
          <label
            class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
          >
            <span>DATA FINE</span>
          </label>
          <div class="align-items-center col-lg-4 d-flex justify-content-center">
            <VueDatePicker
              v-model="selectedLocalModels.datafine"
              dark
              locale="it"
              format="dd/MM/yyyy"
              cancelText="Annulla"
              selectText="Conferma"
              :enable-time-picker="false"
              @update:modelValue="onDatafineUpdate"
              @cleared="onDatafineClear"
              placeholder="DATA FINE"
            >
              <template #input-icon>
                <i class="input-slot-image2 zmdi zmdi-calendar"></i>
              </template>
              <template #clear-icon="{ clear }">
                <i @click="clear" class="input-slot-image zmdi zmdi-close zmdi-hc-lg"></i>
              </template>
            </VueDatePicker>
            <button
              class="timepicker-button"
              alt="OGGI"
              title="OGGI"
              @click="setToday('datafine')"
            >
              <i class="zmdi zmdi-calendar zmdi-hc-lg"></i>
            </button>
          </div>
        </div>
        <div class="row mb-3">
          <label
            class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
          >
            <span>COMMERCIALE</span>
          </label>
          <div class="align-items-center col-lg-10 d-flex justify-content-center">
            <MultiSelect
              v-model="selectedLocalModels.commerciale"
              :options="commercialeOptions"
              :searchable="true"
              placeholder="- SELEZIONA -"
              label="label"
              track-by="value"
              :show-no-results="true"
              selectLabel="Seleziona"
              deselectLabel="Rimuovi"
              selectedLabel="Selezionato"
              :openDirection="'below'"
              tagPlaceholder="Premi enter per creare un tag"
              :multiple="false"
              :taggable="false"
              :showLabels="false"
              @update:modelValue="onCommercialeUpdate"
              :loading="isSearchingCommerciale"
              @search-change="asyncFetchCommerciale"
              :maxHeight="210"
            >
              <template #noOptions>
                <span>L'elenco è vuoto</span>
              </template>
              <template #noResult>
                <span>Nessun elemento trovato</span>
              </template>
            </MultiSelect>
          </div>
        </div>
        <div class="row mb-3">
          <label
            class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
          >
            <span>CODICE NAV</span>
          </label>
          <div class="align-items-center col-lg-10 d-flex justify-content-center">
            <input
              type="text"
              class="form-control"
              v-model.trim="localAnagrafica.identificativonav"
              @input="onInputChange($event, 'identificativonav')"
              @paste="onPaste($event, 'identificativonav')"
              v-input-textarea
              spellcheck="false"
              autocomplete="off"
              autocapitalize="none"
              placeholder="CODICE NAV"
            />
          </div>
        </div>
        <div class="row mb-3">
          <label
            class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
          >
            <span>CENTRO COSTO</span>
          </label>
          <div class="align-items-center col-lg-10 d-flex justify-content-center">
            <MultiSelect
              :searchable="false"
              v-model="selectedLocalModels.centrocosto"
              :options="centrocostoOptions"
              placeholder="- SELEZIONA -"
              label="label"
              track-by="value"
              :openDirection="'below'"
              :showLabels="false"
              @update:modelValue="onCentrocostoUpdate"
              :maxHeight="210"
            >
              <template #noOptions>
                <span>L'elenco è vuoto</span>
              </template>
              <template #noResult>
                <span>Nessun elemento trovato</span>
              </template>
            </MultiSelect>
          </div>
        </div>
      </div>
    </div>
    <!-- Errors model -->
    <div class="row m-0 pt-2">
      <div class="col-lg-3 p-0 text-center"></div>
      <div class="col-lg-9 p-0 ps-4">
        <div v-if="errorsModel" class="mb-4">
          <div
            v-for="(errorModel, key) in errorsModel"
            :key="key"
            class="text-danger small mt-1"
          >
            {{ errorModel }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchCommessaById, fetchClienteById } from "@/services/api";
import OverlayLoading from "@/components/ui/OverlayLoading.vue";
import { UtilityMixins } from "@/mixins/UtilityMixins.js";
import { Commessa, CommessaSchema } from "@/models/CommessaJoiModel";
import { formatISO, parseISO } from "date-fns";
import "date-fns/locale/it";

export default {
  mixins: [UtilityMixins],
  components: {
    OverlayLoading,
  },
  props: {
    isEditMode: {
      type: Boolean,
      default: false,
    },
    commessaDataAnagrafica: {
      type: Object,
      required: true,
    },
  },
  emits: [
    "section-modified",
    "section-verified",
    "fetch-data",
    "data-updated",
    "update:commessaDataAnagrafica",
  ],
  data() {
    return {
      localAnagrafica: new Commessa(), // Creo un oggetto per gestire i dati locali
      initialAnagrafica: null, // Memorizzo lo stato iniziale per il confronto
      isLoading: false, // Gestione del loader per indicare il caricamento
      errorsModel: null, // Oggetto per memorizzare gli errori di validazione
      tabellaTipologia: [], // Array della tipologia commessa
      tabellaSocieta: [], // Array delle societa
      tabellaCentrocosto: [], // Array del centrocosto
      societainternaOptions: [], // Array delle opzioni per la societainterna
      clienteOptions: [], // Array delle opzioni per il cliente
      tipologiaOptions: [], // Array delle opzioni per la tipologia
      centrocostoOptions: [], // Array delle opzioni per il centrocosto
      commercialeOptions: [], // Array delle opzioni per il commerciale
      selectedLocalModels: {
        // Raccolgo i modelli locali in un unico oggetto
        societainterna: [],
        cliente: [],
        tipologia: [],
        centrocosto: [],
        commerciale: [],
        datainizio: null,
        datafine: null,
      },
      logoCliente: "",
      logoSocieta: "",
      isSearchingCliente: false,
      isSearchingCommerciale: false,
      userAuthenticated: null,
    };
  },
  computed: {
    isProfiloDtc() {
      // Restituisce se è un profilo di tipo "DTC"
      if (this.userAuthenticated && this.userAuthenticated.profili) {
        return this.userAuthenticated.profili.some(
          (item) => item.descrizione.toLowerCase() === "dtc"
        );
      }
      return false;
    },
    isProfiloCommerciale() {
      // Restituisce se è un profilo di tipo "Commerciale"
      if (this.userAuthenticated && this.userAuthenticated.profili) {
        return this.userAuthenticated.profili.some(
          (item) => item.descrizione.toLowerCase() === "commerciale"
        );
      }
      return false;
    },
    isSuperAdmin() {
      // Restituisce se è un Super Admin"
      if (this.userAuthenticated && this.userAuthenticated.isSuperAdmin) {
        return this.userAuthenticated.isSuperAdmin;
      }
      return false;
    },
  },
  watch: {
    // Osservo il cambiamento di commessaDataAnagrafica per aggiornare i dati locali
    commessaDataAnagrafica: {
      immediate: true, // Eseguo l'handler al montaggio
      handler(newVal) {
        // Aggiorno localAnagrafica e i modelli locali al cambio dei dati
        this.localAnagrafica = new Commessa(newVal);
        this.initialAnagrafica = JSON.parse(JSON.stringify(this.localAnagrafica));
      },
    },
  },
  methods: {
    //
    // ** Fetch Data **
    //
    async fetchData() {
      if (!this.isEditMode) return; // Non faccio nulla se non sono in modalità modifica

      // Mostro il loader subito dopo il montaggio del componente
      this.$nextTick(() => {
        this.isLoading = true;
      });
      try {
        const response = await fetchCommessaById(this.$route.params.id);
        if (response) {
          // Se l'oggetto mandato dal server è vuoto,  non visualizzo la scheda
          if (this.mixins_isEmptyObject(response)) {
            this.$parent.errorMessage = "L'elemento non esiste";
            return;
          }

          // Aggiorno i dati locali con la risposta
          this.localAnagrafica = new Commessa(response);
          this.initialAnagrafica = JSON.parse(JSON.stringify(this.localAnagrafica));
          console.log("** dati localAnagrafica", this.localAnagrafica);
          console.log("** dati initialAnagrafica", this.initialAnagrafica);

          // Emetto eventi per aggiornare il v-model e segnalare il fetch completato
          this.$emit("update:commessaDataAnagrafica", this.localAnagrafica);
          this.$emit("fetch-data", "anagrafica");
          this.$parent.errorMessage = "";
        } else {
          this.$parent.errorMessage = "L'elemento non esiste";
        }
      } catch (error) {
        // console.error("Errore durante il caricamento dei dati", error);
        this.$parent.errorMessage = "Errore durante il caricamento dei dati";
      } finally {
        // Nascondo il loader al termine del caricamento
        this.$nextTick(() => {
          this.isLoading = false;
        });
      }
    },
    async fetchDataAndUpdateFields() {
      if (this.isEditMode) {
        // Eseguo il fetch dei dati dell'anagrafica
        await this.fetchData();
      } else {
        // Altrimenti genero un nuova commessa in locale
        this.localAnagrafica = new Commessa();
        this.initialAnagrafica = JSON.parse(JSON.stringify(this.localAnagrafica));
        console.log("** dati localAnagrafica", this.localAnagrafica);
        console.log("** dati initialAnagrafica", this.initialAnagrafica);

        this.validateImmediate();
        this.updateParent();

        // Emetto eventi per aggiornare il v-model e segnalare il fetch completato
        this.$emit("update:commessaDataAnagrafica", this.localAnagrafica);
        this.$emit("fetch-data", "anagrafica");
        this.$parent.errorMessage = "";
      }

      this.$nextTick(() => {
        this.isLoading = true;
      });

      // Carico le opzioni delle tipologie
      this.tabellaTipologia = await this.mixins_fetchTipologiaCommesse();
      console.log("** tab tipologia commessa", this.tabellaTipologia);
      this.tipologiaOptions = this.tabellaTipologia.map((tipo) => ({
        value: tipo.id,
        label: tipo.nome,
      }));

      // Carico le opzioni del centrocosto
      this.tabellaCentrocosto = await this.mixins_fetchCentrocostoCommesse();
      console.log("** tab centrocosto commessa", this.tabellaCentrocosto);
      this.centrocostoOptions = this.tabellaCentrocosto.map((costo) => ({
        value: costo.id,
        label: costo.nome,
      }));

      // Carico le opzioni della societainterna
      this.tabellaSocieta = await this.mixins_getTabAziende();
      console.log("** tab società", this.tabellaSocieta);
      this.societainternaOptions = this.tabellaSocieta.map((societa) => ({
        value: societa._id,
        label: societa.ragionesociale,
        logo: societa.logo,
      }));

      // Carico le opzioni dei clienti cercando il cliente nella ragionesociale
      if (this.localAnagrafica.cliente) {
        const tabellaClienti = await this.mixins_getClienti(
          this.localAnagrafica.cliente.descrizione
        );
        this.clienteOptions = tabellaClienti.map((cliente) => ({
          value: cliente._id,
          label: cliente.ragionesociale,
          logo: cliente.logo,
        }));
      }
      console.log("** clienteOptions", this.clienteOptions);

      if (this.localAnagrafica.commerciale) {
        const tabellaCommerciale = await this.mixins_getCommerciale();
        // problema: sono stati memorizzati nella commessa con "cognome nome" e non "nome cognome"
        //this.localAnagrafica.commerciale.fullname
        console.log(
          "** tabellaCommerciale",
          tabellaCommerciale,
          this.localAnagrafica.commerciale
        );
        this.commercialeOptions = tabellaCommerciale.map((commerciale) => ({
          value: commerciale.providerUserId,
          // label: `${commerciale.metadata.anagrafica.nome} ${commerciale.metadata.anagrafica.cognome}`,
          label: commerciale.metadata.fullname,
        }));
      }
      console.log("** commercialeOptions", this.commercialeOptions);

      // Carico le opzioni dei commerciali cercando il commerciale nel fullname
      // if (this.localAnagrafica.commerciale) {
      //   if (
      //     this.localAnagrafica.commerciale.fullname !== "" &&
      //     this.localAnagrafica.commerciale.idcommerciale !== ""
      //   ) {
      //     const tabellaCommerciale = await this.mixins_getCommerciale();
      //     // problema: sono stati memorizzati nella commessa con "cognome nome" e non "nome cognome"
      //     //this.localAnagrafica.commerciale.fullname
      //     console.log(
      //       "** tabellaCommerciale",
      //       tabellaCommerciale,
      //       this.localAnagrafica.commerciale
      //     );
      //     this.commercialeOptions = tabellaCommerciale.map((commerciale) => ({
      //       value: commerciale.providerUserId,
      //       // label: `${commerciale.metadata.anagrafica.nome} ${commerciale.metadata.anagrafica.cognome}`,
      //       label: commerciale.metadata.fullname,
      //     }));
      //   } else {
      //     //
      //   }
      // }

      // Sincronizzo i modelli locali
      await this.updateLocalModels();

      this.$nextTick(() => {
        this.isLoading = false;
      });
    },
    async asyncFetchCliente(query) {
      // rimuovo i caratteri *^? insieme ai tag HTML/XML per le query
      query = this.mixins_cleanTextForQuery(query);
      // Carico le opzioni dei fornitori trovati
      this.isSearchingCliente = true;
      const tabCliente = await this.mixins_getClienti(query);
      this.clienteOptions = tabCliente.map((cliente) => ({
        value: cliente._id,
        label: cliente.ragionesociale,
        logo: cliente.logo,
      }));
      this.isSearchingCliente = false;
      console.log("** clienteOptions", this.clienteOptions);
    },
    async asyncFetchCommerciale(query) {
      // rimuovo i caratteri *^? insieme ai tag HTML/XML per le query
      query = this.mixins_cleanTextForQuery(query);
      // Carico le opzioni dei commerciali trovati
      this.isSearchingCommerciale = true;
      const tabCommerciale = await this.mixins_getCommerciale(query);
      this.commercialeOptions = tabCommerciale.map((commerciale) => ({
        value: commerciale.providerUserId,
        // label: commerciale.metadata.fullname,
        label: `${commerciale.metadata.anagrafica.cognome} ${commerciale.metadata.anagrafica.nome}`,
      }));
      this.isSearchingCommerciale = false;
      console.log("** commercialeOptions", this.commercialeOptions);
    },

    //
    // ** Gestione Multiselect **
    //
    //
    // Societa
    //
    onSocietaUpdate(selected) {
      if (selected) {
        // Aggiorno i dettagli della società selezionata
        this.localAnagrafica.societainterna = {
          idsocieta: selected.value,
          descrizione: selected.label,
        };
      } else {
        this.localAnagrafica.societainterna = {
          idsocieta: "",
          descrizione: "",
        };
      }

      // Aggiorno url immagine
      this.logoSocieta = selected ? selected.logo : "";

      this.validateImmediate();
      this.updateParent();
    },
    //
    // Cliente
    //
    onClienteUpdate(selected) {
      if (selected) {
        // Aggiorno i dettagli della società selezionata
        this.localAnagrafica.cliente = {
          idcliente: selected.value,
          descrizione: selected.label,
        };
      } else {
        this.localAnagrafica.cliente = {
          idcliente: "",
          descrizione: "",
        };
      }

      // Aggiorno url immagine
      this.logoCliente = selected ? selected.logo : "";

      this.validateImmediate();
      this.updateParent();
    },
    //
    // Commerciale
    //
    onCommercialeUpdate(selected) {
      if (selected) {
        // Aggiorno i dettagli del commerciale selezionato
        this.localAnagrafica.commerciale = {
          idcommerciale: selected.value,
          fullname: selected.label,
        };
      } else {
        this.localAnagrafica.commerciale = {
          idcommerciale: "",
          fullname: "",
        };
      }

      this.validateImmediate();
      this.updateParent();
    },
    //
    // Tipologia
    //
    onTipologiaUpdate(selected) {
      // Aggiorno i dettagli della tipologia selezionata
      this.localAnagrafica.tipologia = selected?.label ?? "";
      this.validateImmediate();
      this.updateParent();
    },
    //
    // Centrocosto
    //
    onCentrocostoUpdate(selected) {
      // Aggiorno i dettagli del centrocosto selezionato
      this.localAnagrafica.centrocosto = selected?.label ?? "";
      this.validateImmediate();
      this.updateParent();
    },
    //
    // ** Gestione Sincronizzazioni **
    //
    async updateLocalModels() {
      // Sincronizzo i dati ricevuti

      // societainterna
      if (this.localAnagrafica.societainterna) {
        if (
          this.localAnagrafica.societainterna.idsocieta !== "" &&
          this.localAnagrafica.societainterna.descrizione !== ""
        ) {
          this.selectedLocalModels.societainterna = {
            value: this.localAnagrafica.societainterna.idsocieta,
            label: this.localAnagrafica.societainterna.descrizione,
          };
        } else {
          this.selectedLocalModels.societainterna = null;
        }
      } else {
        this.selectedLocalModels.societainterna = null;
      }
      // Cerco la società selezionata
      const societa = this.tabellaSocieta.find(
        (societa) => societa._id === this.localAnagrafica.societainterna.idsocieta
      );
      // Aggiorno url immagine
      this.logoSocieta = societa ? societa.logo : "";

      // cliente
      let cliente = null;
      if (this.localAnagrafica.cliente) {
        if (
          this.localAnagrafica.cliente.idcliente !== "" &&
          this.localAnagrafica.cliente.descrizione !== ""
        ) {
          this.selectedLocalModels.cliente = {
            value: this.localAnagrafica.cliente.idcliente,
            label: this.localAnagrafica.cliente.descrizione,
          };
          // Ottengo il cliente per ID
          cliente = await fetchClienteById(this.localAnagrafica.cliente.idcliente);
        } else {
          this.selectedLocalModels.cliente = null;
        }
      } else {
        this.selectedLocalModels.cliente = null;
      }
      // Cerco il cliente selezionato
      // non posso farlo con la tabella in memoria perché la richiesta al server può essere fatta con un limite massimo di 50 elementi
      // const cliente = this.tabellaClienti.find(
      //   (cliente) => cliente._id === this.localAnagrafica.cliente.idcliente
      // );
      // Aggiorno url immagine
      this.logoCliente = cliente ? cliente.logo : "";

      // commerciale
      if (!this.isEditMode && (this.isProfiloCommerciale || this.isProfiloDtc)) {
        this.localAnagrafica.commerciale = {
          idcommerciale: this.userAuthenticated.providerUserId,
          fullname: this.userAuthenticated.fullname,
        };
      }

      if (this.localAnagrafica.commerciale) {
        if (
          this.localAnagrafica.commerciale.idcommerciale !== "" &&
          this.localAnagrafica.commerciale.fullname !== ""
        ) {
          this.selectedLocalModels.commerciale = {
            value: this.localAnagrafica.commerciale.idcommerciale,
            label: this.localAnagrafica.commerciale.fullname,
          };
        } else {
          this.selectedLocalModels.commerciale = null;
        }
      } else {
        this.selectedLocalModels.commerciale = null;
      }

      // tipologia
      this.selectedLocalModels.tipologia = this.localAnagrafica.tipologia
        ? this.tipologiaOptions.find(
            (item) => item.label === this.localAnagrafica.tipologia
          )
        : [];

      // centrocosto
      this.selectedLocalModels.centrocosto = this.localAnagrafica.centrocosto
        ? this.centrocostoOptions.find(
            (item) => item.label === this.localAnagrafica.centrocosto
          )
        : [];

      // datainizio
      this.selectedLocalModels.datainizio = this.localAnagrafica.datainizio
        ? parseISO(this.localAnagrafica.datainizio)
        : null;

      // datafine
      this.selectedLocalModels.datafine = this.localAnagrafica.datafine
        ? parseISO(this.localAnagrafica.datafine)
        : null;
    },
    //
    // ** Gestione Validazioni **
    //
    validateImmediate() {
      const result = CommessaSchema.validate(this.localAnagrafica, {
        abortEarly: false,
      });
      if (result.error) {
        this.errorsModel = result.error.details.reduce((acc, curr) => {
          acc[curr.path[0]] = curr.message;
          return acc;
        }, {});
        this.markAsVerified("anagrafica", false);
        return false;
      } else {
        this.errorsModel = null;
        this.markAsVerified("anagrafica", true);
        return true;
      }
    },
    markAsModified(section) {
      // Segnalo che una specifica sezione è stata modificata
      this.$emit("section-modified", section);
    },
    markAsVerified(section, value) {
      // Segnalo che una specifica sezione è stata verificata con successo o meno
      this.$emit("section-verified", section, value);
    },
    //
    // ** Gestione aggiornamenti**
    //
    onInputChange() {
      this.$nextTick(() => {
        this.validateImmediate();
        this.updateParent();
      });
    },
    onPaste(event, field) {
      event.preventDefault();
      const pastedText = (event.clipboardData || window.Clipboard).getData("text");
      const trimmedText = pastedText.trim();
      this.localAnagrafica[field] = trimmedText;
      this.$nextTick(() => {
        this.validateImmediate();
        this.updateParent();
      });
    },
    updateParent() {
      if (
        JSON.stringify(this.localAnagrafica) !== JSON.stringify(this.initialAnagrafica)
      ) {
        this.markAsModified("anagrafica");

        if (this.validateImmediate()) {
          this.$emit("update:commessaDataAnagrafica", this.localAnagrafica);
          this.$emit("data-updated", this.localAnagrafica);
        }
      }
    },
    //
    // ** Gestione delle date **
    //
    onDatainizioUpdate(newDate) {
      this.localAnagrafica.datainizio = newDate ? formatISO(newDate) : null;
      this.validateImmediate();
      this.updateParent();
    },
    onDatainizioClear() {
      this.localAnagrafica.datainizio = null;
      this.selectedLocalModels.datainizio = null;
      this.validateImmediate();
      this.updateParent();
    },
    onDatafineUpdate(newDate) {
      this.localAnagrafica.datafine = newDate ? formatISO(newDate) : null;
      this.validateImmediate();
      this.updateParent();
    },
    onDatafineClear() {
      this.localAnagrafica.datafine = null;
      this.selectedLocalModels.datafine = null;
      this.validateImmediate();
      this.updateParent();
    },
    setToday(fieldName) {
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Imposta l'orario a 00:00:00:000

      this.selectedLocalModels[fieldName] = today;
      this.localAnagrafica[fieldName] = formatISO(today);

      // Mappo i campi ai rispettivi metodi di aggiornamento
      const updateMethods = {
        datainizio: this.onDatainizioUpdate,
        datafine: this.onDatafineUpdate,
      };

      // Invoco il metodo corretto in modo dinamico
      if (updateMethods[fieldName]) {
        updateMethods[fieldName](today);
      }

      this.validateImmediate();
      this.updateParent();
    },
  },
  async mounted() {
    // Oggetto user da local storage
    this.userAuthenticated = this.mixin_getUserDataLS();

    await this.fetchDataAndUpdateFields();
  },
  async created() {
    //******* DEBUG ******//
    //Debug: carico tutte le tipologie di skill raggruppati per tipologie
    // const skillsData = await this.mixins_getTabSkillsData(); // carico gli skills data
    // const groupedSkillsData = await this.mixins_groupSottoclassiByType(skillsData);
    // console.log("** tab skills data raggruppati per tipologia", groupedSkillsData);
  },
};
</script>
