<template>
  <overlay-loading v-model:isLoading="isLoading"></overlay-loading>
  <div
    class="container-fluid align-items-center d-flex flex-row justify-content-between m-0 p-0 py-4"
  >
    <!-- Intestazione -->
    <div class="d-flex m-0 p-0">
      <h4 class="color-mainblue m-0">
        Piano ferie dell'anno <span class="text-black">{{ selectedYear }}</span>
      </h4>
    </div>
    <!-- Filtri -->
    <div class="d-flex m-0 p-0">
      <div class="align-items-center d-flex flex-row justify-content-center">
        <span class="text-black me-2">Ferie:</span>
        <!-- Filtro Ferie -->
        <div class="btn-group d-block" role="group">
          <input
            type="radio"
            class="btn-check"
            name="filterHolidaies"
            id="filter_all"
            value="all"
            v-model="selectedFilter"
          />
          <label
            class="btn btn-outline-primary default"
            :class="{ checked: selectedFilter === 'all' }"
            for="filter_all"
            >TUTTE <span>({{ calcolaTotaleStato("all") }})</span></label
          >
          <input
            type="radio"
            class="btn-check"
            name="filterHolidaies"
            id="filter_approved"
            value="Approved"
            v-model="selectedFilter"
          />
          <label
            class="btn btn-outline-primary approved"
            :class="{ checked: selectedFilter === 'Approved' }"
            for="filter_approved"
            >APPROVATE <span>({{ calcolaTotaleStato("Approved") }})</span></label
          >

          <input
            type="radio"
            class="btn-check"
            name="filterHolidaies"
            id="filter_pending"
            value="Pending"
            v-model="selectedFilter"
          />
          <label
            class="btn btn-outline-primary pending"
            :class="{ checked: selectedFilter === 'Pending' }"
            for="filter_pending"
            >IN ATTESA <span>({{ calcolaTotaleStato("Pending") }})</span></label
          >

          <input
            type="radio"
            class="btn-check"
            name="filterHolidaies"
            id="filter_rejected"
            value="Rejected"
            v-model="selectedFilter"
          />
          <label
            class="btn btn-outline-primary rejected"
            :class="{ checked: selectedFilter === 'Rejected' }"
            for="filter_rejected"
            >RESPINTE <span>({{ calcolaTotaleStato("Rejected") }})</span></label
          >
        </div>
      </div>
    </div>

    <!-- Date -->
    <div class="d-flex">
      <!-- Bottone Anno in corso -->
      <button
        @click="selectYear(new Date().getFullYear())"
        class="btn button-outline-1 small me-2"
        type="button"
      >
        <i class="bi bi-calendar-check-fill"></i> Anno in corso
      </button>
      <!-- Selezione dell'anno -->
      <div class="dropdown">
        <button
          class="btn button-outline-1 small dropdown-toggle"
          type="button"
          id="dropdown_anno"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <span>{{ selectedYear }}</span>
        </button>
        <ul class="dropdown-menu dropdown-menu-end">
          <li v-for="year in availableYears" :key="year">
            <button
              @click="selectYear(year)"
              class="dropdown-item"
              :class="{ active: selectedYear == year }"
              type="button"
            >
              {{ year }}
            </button>
          </li>
        </ul>
      </div>
      <button
        @click="fetchCalendarData"
        id="but_vai"
        type="button"
        class="d-none btn button-outline-1 small"
      >
        <i class="zmdi zmdi-long-arrow-return zmdi-hc-lg"></i> VAI
      </button>
    </div>
  </div>
  <!-- Calendario -->
  <div class="calendar-wrapper">
    <div class="calendar">
      <div v-for="(month, monthIndex) in months" :key="monthIndex" class="month-table">
        <div class="month-header">{{ month }}</div>
        <div class="days-row">
          <div
            v-for="(day, index) in daysOfWeek"
            :key="day"
            class="day-cell day-header"
            :class="{ weekend: isWeekend(index) }"
          >
            {{ day }}
          </div>
        </div>
        <div v-for="week in getWeeksForMonth()" :key="week" class="week-row">
          <div
            v-for="(date, dateIndex) in getDatesForWeek(week, monthIndex)"
            :key="date.day"
            class="day-cell date-cell"
            :class="{
              greyed: !date.isCurrentMonth,
              rejected: date.isCurrentMonth && getCellState(date) === this.rejectedStr,
              approved: date.isCurrentMonth && getCellState(date) === this.approvedStr,
              pending: date.isCurrentMonth && getCellState(date) === this.pendingStr,
              'default-state': date.isCurrentMonth && !getCellState(date),
              'past-date': date.isCurrentMonth && isPastDate(date),
              'last-week-first-cell': week === 5 && dateIndex === 0,
              'last-week-last-cell': week === 5 && dateIndex === 6,
              today: date.isCurrentMonth && isToday(date),
              holiday: date.isCurrentMonth && isHoliday(date),
              // Rimuovi la classe 'weekend' da qui
            }"
          >
            {{ date.day }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { UtilityMixins } from "@/mixins/UtilityMixins.js";
import OverlayLoading from "@/components/ui/OverlayLoading.vue";
import { fetchUserAttivitaAssenzeAggregato } from "@/services/api";

export default {
  mixins: [UtilityMixins],
  components: {
    OverlayLoading,
  },
  data() {
    return {
      isLoading: false,
      months: [
        "Gennaio",
        "Febbraio",
        "Marzo",
        "Aprile",
        "Maggio",
        "Giugno",
        "Luglio",
        "Agosto",
        "Settembre",
        "Ottobre",
        "Novembre",
        "Dicembre",
      ],
      daysOfWeek: ["Lun", "Mar", "Mer", "Gio", "Ven", "Sab", "Dom"],
      calendarData: [],
      availableYears: [],
      selectedYear: new Date().getFullYear(),
      today: new Date(),
      isInitialized: false,
      userAuthenticated: null,
      tabellaFestivita: [],
      approvedStr: "Approved",
      pendingStr: "Pending",
      rejectedStr: "Rejected",
      selectedFilter: "all",
    };
  },
  computed: {
    isMe() {
      return this.userAuthenticated?.providerUserId == this.$route.params.id || null;
    },
  },
  watch: {
    selectedYear: {
      handler(newYear) {
        if (newYear) {
          this.fetchCalendarData();
        }
      },
      immediate: true,
    },
    selectedFilter() {
      // Forza un re-render del calendario
      this.$forceUpdate();
    },
  },
  methods: {
    calcolaTotaleStato(stato) {
      if (stato === "all") {
        return this.calendarData.length;
      } else {
        return this.calendarData.filter((item) => item.state === stato).length;
      }
    },
    async fetchCalendarData() {
      const { start: dateFrom, end: dateTo } = {
        start: `${this.selectedYear}-01-01`,
        end: `${this.selectedYear}-12-31`,
      };
      const tipoAssenza = 0; // ferie
      let currentUser = null;
      let userId = null;
      let idUser = null;
      if (this.isMe) {
        currentUser = "Y";
        userId = this.userAuthenticated.providerUserId;
        idUser = "";
      } else {
        currentUser = "N";
        userId = this.$route.params.id;
        idUser = this.$route.params.id;
      }

      const response = await this.fetchAssenzeAggregato(
        dateFrom,
        dateTo,
        tipoAssenza,
        currentUser,
        idUser
      );

      console.log("** user Id", userId);
      console.log(`** ferie aggregate dal ${dateFrom} al ${dateTo}`, response);

      // Elabora i dati e aggiorna calendarData
      this.calendarData = this.processCalendarData(response, userId);

      console.log("Calendar data after fetch:", this.calendarData);
      this.isInitialized = true;
    },

    async fetchAssenzeAggregato(dateFrom, dateTo, tipoAssenza, currentUser, idUser) {
      this.isLoading = true;
      try {
        const response = await fetchUserAttivitaAssenzeAggregato(
          dateFrom,
          dateTo,
          tipoAssenza,
          currentUser,
          idUser
        );
        if (response) {
          return response?.data ?? [];
        }
      } catch (error) {
        this.mixins_showMessage(
          "Caricamento dati",
          `Errore durante il caricamento dei dati: ${error.message}`,
          "error"
        );
      } finally {
        this.isLoading = false;
      }
    },

    isPastDate(date) {
      if (!date.isCurrentMonth) return false;
      const cellDate = new Date(date.year, date.month, date.day);
      return cellDate < this.today;
    },

    isToday(date) {
      if (!date.isCurrentMonth) return false;
      return (
        date.day === this.today.getDate() &&
        date.month === this.today.getMonth() &&
        date.year === this.today.getFullYear()
      );
    },

    isWeekend(dayIndex) {
      // In questo array, 0 è Lunedì e 6 è Domenica
      return dayIndex === 5 || dayIndex === 6;
    },

    isHoliday(date) {
      // Se non è del mese corrente, non è considerato festivo
      if (!date.isCurrentMonth) return false;

      const cellDate = new Date(date.year, date.month, date.day);
      const dayOfWeek = cellDate.getDay();

      // Controlla se è sabato (6) o domenica (0)
      if (dayOfWeek === 0 || dayOfWeek === 6) return true;

      // Controlla se è una festività nazionale
      return this.tabellaFestivita.some((festivity) => {
        const festivityDate = new Date(festivity.data);
        return (
          festivityDate.getFullYear() === date.year &&
          festivityDate.getMonth() === date.month &&
          festivityDate.getDate() === date.day &&
          festivity.locale === false // Aggiungo questa condizione per considerare solo le festività nazionali
        );
      });
    },

    getDaysInMonth(month, year) {
      return new Date(year, month + 1, 0).getDate();
    },

    getStartDayOfMonth(month, year) {
      return new Date(year, month, 1).getDay();
    },

    getWeeksForMonth() {
      return Array.from({ length: 6 }, (_, i) => i);
    },

    getDatesForWeek(weekIndex, monthIndex) {
      const dates = [];
      const daysInMonth = this.getDaysInMonth(monthIndex, this.selectedYear);
      const startDay = this.getStartDayOfMonth(monthIndex, this.selectedYear);
      const adjustedStartDay = startDay === 0 ? 6 : startDay - 1;

      const prevMonthIndex = monthIndex === 0 ? 11 : monthIndex - 1;
      const prevMonthYear = monthIndex === 0 ? this.selectedYear - 1 : this.selectedYear;
      const prevMonthDays = this.getDaysInMonth(prevMonthIndex, prevMonthYear);

      for (let i = 0; i < 7; i++) {
        const dayNumber = weekIndex * 7 + i + 1 - adjustedStartDay;
        let date = {
          day: 0,
          month: monthIndex,
          year: this.selectedYear,
          isCurrentMonth: true,
        };

        if (dayNumber <= 0) {
          date.day = prevMonthDays + dayNumber;
          date.month = prevMonthIndex;
          date.year = prevMonthYear;
          date.isCurrentMonth = false;
        } else if (dayNumber > daysInMonth) {
          date.day = dayNumber - daysInMonth;
          date.month = monthIndex === 11 ? 0 : monthIndex + 1;
          date.year = monthIndex === 11 ? this.selectedYear + 1 : this.selectedYear;
          date.isCurrentMonth = false;
        } else {
          date.day = dayNumber;
        }

        dates.push(date);
      }

      return dates;
    },

    getCellState(date) {
      if (!date.isCurrentMonth) return null;

      const dateString = `${date.year}-${(date.month + 1)
        .toString()
        .padStart(2, "0")}-${date.day.toString().padStart(2, "0")}`;
      const dateData = this.calendarData.find((d) => d.date === dateString);

      if (!dateData) return null;

      if (this.selectedFilter === "all" || dateData.state === this.selectedFilter) {
        return dateData.state;
      }

      return null;
    },

    generateAvailableYears() {
      const currentYear = new Date().getFullYear();
      this.availableYears = Array.from(
        { length: currentYear - 2009 },
        (_, index) => currentYear + 1 - index
      );
    },
    selectYear(year) {
      this.selectedYear = year;
    },
    processCalendarData(response, userId) {
      // Filtra i dati per l'utente specifico
      const userData = response.filter((item) => item.iduser === userId);

      // Inizializza l'array risultante
      let processedData = [];

      userData.forEach((item) => {
        const startDate = new Date(item.datainizio);
        const endDate = new Date(item.datafine);
        const state = item.status;

        // Itera attraverso tutti i giorni tra datainizio e datafine
        for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
          // Verifica se la data non è una festività nazionale, un sabato o una domenica
          if (!this.isHolidayOrWeekend(d)) {
            processedData.push({
              date: this.formatDate(d),
              state: state,
            });
          }
        }
      });

      // Ordina l'array per data
      processedData.sort((a, b) => new Date(a.date) - new Date(b.date));

      // Rimuovi eventuali duplicati, mantenendo l'ultimo stato per ogni data
      processedData = processedData.reduce((acc, current) => {
        const x = acc.find((item) => item.date === current.date);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc.map((item) => (item.date === current.date ? current : item));
        }
      }, []);

      console.log("** processedData", processedData);
      return processedData;
    },

    formatDate(date) {
      // Metodo helper per formattare la data
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
        2,
        "0"
      )}-${String(date.getDate()).padStart(2, "0")}`;
    },

    isHolidayOrWeekend(date) {
      // Metodo helper per verificare se una data è una festività nazionale o un weekend
      // Controlla se è sabato (6) o domenica (0)
      if (date.getDay() === 0 || date.getDay() === 6) {
        return true;
      }

      // Controlla se è una festività nazionale
      return this.tabellaFestivita.some(
        (festivity) =>
          festivity.data.getFullYear() === date.getFullYear() &&
          festivity.data.getMonth() === date.getMonth() &&
          festivity.data.getDate() === date.getDate() &&
          !festivity.locale
      );
    },
  },
  created() {
    this.today.setHours(0, 0, 0, 0);
    this.generateAvailableYears();
  },
  async mounted() {
    // Oggetto user da local storage
    this.userAuthenticated = this.mixin_getUserDataLS();

    // Ottengo tabella festività
    this.tabellaFestivita = await this.mixins_getTabFestivita();
    console.log("** assenze tabella festività", this.tabellaFestivita);

    // Verifica che this.tabellaFestivita sia un array
    if (Array.isArray(this.tabellaFestivita)) {
      // Assicuriamoci che le date siano oggetti Date
      this.tabellaFestivita = this.tabellaFestivita.map((festivity) => {
        let parsedDate = new Date(festivity.data);

        // Verifica che la data sia valida
        if (isNaN(parsedDate.getTime())) {
          console.error(`Data non valida per la festività: ${festivity.data}`);
          parsedDate = null; // Imposta a null se la data non è valida
        }

        return {
          ...festivity,
          data: parsedDate,
        };
      });
    } else {
      console.error("La tabella festività non è un array valido:", this.tabellaFestivita);
      this.tabellaFestivita = [];
    }
  },
};
</script>
