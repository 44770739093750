<template>
  <div class="col-sm-12 main-col">
    <div class="row p-0">
      <div class="align-items-center d-flex flex-row justify-content-between m-0 p-0 row">
        <div class="col-sm-10 p-0">
          <div
            class="align-items-center bg-light d-flex header-text justify-content-start p-0 w-100"
          >
            <h1>
              GESTIONE FORNITORI
              <span class="text-black small">
                <span class="mx-3 single-list">
                  <i class="fa-solid fa-building fa-fw"></i>
                </span>
                <span>{{ headerTitle }}</span>
              </span>
            </h1>
          </div>
        </div>
      </div>
      <div
        class="container-fluid m-0 p-2 background-gray overflow-auto container-contents"
      >
        <!-- Loading -->
        <div v-if="loading" class="linear-loader"><span></span></div>
        <!-- Error message -->
        <div v-if="errorMessage" class="alert alert-danger">{{ errorMessage }}</div>

        <div v-if="fornitore && !errorMessage && !loading">
          <div class="row m-0 padding-x-4px">
            <div class="col-md-12 p-0 mt-4">
              <h4 class="text-black m-0 p-0">
                <span v-html="fornitore.ragionesociale || blank"></span>
              </h4>
            </div>
            <hr class="sepGray" />
          </div>
          <div class="row m-0 padding-x-4px" v-if="fornitore">
            <ul class="nav nav-tabs video-tabs" ref="tabs">
              <li class="nav-item">
                <a
                  href="#contentAnagrafica"
                  class="nav-link d-inline-flex active"
                  data-bs-toggle="tab"
                >
                  ANAGRAFICA
                  <span class="ms-2 change-data" v-if="isModified.anagrafica"></span>
                </a>
              </li>
            </ul>
            <div class="tab-content p-0">
              <div class="tab-pane fade show active" id="contentAnagrafica">
                <div class="row m-0 pt-4">
                  <div class="col-lg-3 mb-3 p-0 text-center">
                    <img
                      style="border: 1px solid var(--main-blue)"
                      :src="
                        fornitore.logo
                          ? fornitore.logo
                          : require('@/assets/images/logo-placeholder.jpg')
                      "
                      @error="
                        $event.target.src = require('@/assets/images/logo-placeholder.jpg')
                      "
                      class="img-fluid w-100 mb-3"
                    />
                    <div>
                      <button
                        type="button"
                        class="btn icon-button me-2"
                        alt="CARICA IMMAGINE"
                        title="CARICA IMMAGINE"
                        @click="uploadImage"
                        :disabled="!isEditMode"
                      >
                        <i class="bi bi-image"></i>
                      </button>
                      <button
                        type="button"
                        class="btn icon-button me-2"
                        alt="RIMUOVI IMMAGINE"
                        title="RIMUOVI IMMAGINE"
                        @click="removeImage"
                        :disabled="!isEditMode || fornitore.logo === ''"
                      >
                        <i class="bi bi-trash"></i>
                      </button>
                      <input
                        type="file"
                        ref="fileInput"
                        @change="onFileChange"
                        accept="image/*"
                        style="display: none"
                      />
                    </div>
                  </div>
                  <div class="col-lg-9 p-0 ps-lg-4">
                    <div class="row mb-3">
                      <label
                        class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
                      >
                        <span>RAGIONE SOCIALE</span>
                      </label>
                      <div
                        class="align-items-center col-lg-10 d-flex justify-content-center"
                      >
                        <input
                          type="text"
                          v-input-textarea
                          class="form-control"
                          id="inputRagioneSociale"
                          v-model.trim="fornitore.ragionesociale"
                          @input="markAsModified('anagrafica')"
                          spellcheck="false"
                          autocomplete="off"
                          autocapitalize="none"
                          placeholder="RAGIONE SOCIALE"
                        />
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label
                        class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
                      >
                        <span>INFO</span>
                      </label>
                      <div
                        class="align-items-center col-lg-10 d-flex justify-content-center"
                      >
                        <input
                          type="text"
                          v-input-textarea
                          class="form-control"
                          id="inputInfo"
                          v-model.trim="fornitore.info"
                          @input="markAsModified('anagrafica')"
                          spellcheck="false"
                          autocomplete="off"
                          autocapitalize="none"
                          placeholder="INFO"
                        />
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label
                        class="col-form-label form-label col-lg-2 d-flex align-items-start justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
                      >
                        <span>RIFERIMENTI</span>
                      </label>
                      <div
                        class="align-items-center col-lg-10 d-flex justify-content-center"
                      >
                        <textarea
                          rows="5"
                          class="form-control"
                          id="inputRiferimenti"
                          v-model.trim="fornitore.riferimenti"
                          @input="markAsModified('anagrafica')"
                          spellcheck="false"
                          autocomplete="off"
                          autocapitalize="none"
                          placeholder="RIFERIMENTI"
                        />
                      </div>
                    </div>
                    <!-- Errors model -->
                    <div v-if="errorsModel" class="mb-4">
                      <div
                        v-for="(errorModel, key) in errorsModel"
                        :key="key"
                        class="text-danger small mt-1"
                      >
                        {{ errorModel }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr class="sepGray" />
            <div class="container-fluid p-0 my-3">
              <button
                @click="backToList()"
                type="button"
                class="btn button-outline-1 float-start"
              >
                <span><i class="zmdi zmdi-arrow-left"></i>Torna alla lista</span>
              </button>
              <button
                type="button"
                class="btn button-outline-1 float-end"
                :class="{ notSaved: isModified.anagrafica }"
                @click="saveFornitore"
              >
                <span
                  ><i class="zmdi zmdi-floppy"></i
                  >{{ isEditMode ? "Aggiorna i dati" : "Salva i dati" }}</span
                >
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//views/menu_impostazioni/fornitori/FornitoriAddEdit.vue

import { FornitoreSchema, Fornitore } from "@/models/FornitoreJoiModel";
import {
  fetchFornitoreById,
  createFornitore,
  updateFornitore,
  uploadLogoFornitore,
  deleteLogoFornitore,
} from "@/services/api";
import { mapGetters } from "vuex";
import { UtilityMixins } from "@/mixins/UtilityMixins.js";

export default {
  mixins: [UtilityMixins],
  components: {},
  data() {
    return {
      fornitore: new Fornitore(),
      loading: false,
      errorsModel: null,
      errorMessage: null,
      isEditMode: false,
      isModified: {
        anagrafica: false,
      },
      headerTitle: "",
      isDialogVisible: false,
      dialogTitle: "",
      selectedIndex: null,
      blank: `&nbsp;`,
    };
  },
  computed: {
    ...mapGetters(["getCurrentPageFornitori"]),
  },
  methods: {
    async fetchFornitore() {
      this.loading = true;
      try {
        const response = await fetchFornitoreById(this.$route.params.id);
        console.log("Risposta fetchFornitore:", response); // Debug
        if (response) {
          this.fornitore = new Fornitore(response);
          console.log("Fornitore dopo fetchFornitore:", this.fornitore); // Debug
          this.isEditMode = true;
          this.headerTitle = "Modifica";
        } else {
          this.errorMessage = "L'elemento non esiste";
        }
      } catch (error) {
        console.error("Errore durante il caricamento dei dati", error);
        this.errorMessage = "Errore durante il caricamento dei dati";
      } finally {
        this.loading = false;
      }
    },

    async saveFornitore() {
      // controllo che ci siano dati da salvare
      if (!this.hasUnsavedChanges()) return;

      // valido i campi
      const { error, value } = FornitoreSchema.validate(this.fornitore, {
        abortEarly: false,
      });
      if (error) {
        this.errorsModel = error.details.reduce((acc, err) => {
          acc[err.path.join(".")] = err.message;
          return acc;
        }, {});
        this.showAnagraficaTab();
        return;
      }

      // salvo i dati
      this.loading = true;
      try {
        if (this.isEditMode) {
          await updateFornitore(this.fornitore._id, value);
          this.mixins_showMessage(
            "Aggiornamento dati",
            "Dati salvati correttamente",
            "info"
          );
        } else {
          const newFornitore = await createFornitore(value);
          this.fornitore = newFornitore;
          this.isEditMode = true;
          this.headerTitle = "Modifica";
          this.$router.replace({
            name: "route-fornitori-edit",
            params: { id: newFornitore._id },
          });
        }
        this.isModified.anagrafica = false;
        this.errorsModel = null;
      } catch (error) {
        console.error("Errore durante il salvataggio dei dati", error);
        this.errorMessage = "Errore durante il salvataggio dei dati";
      } finally {
        this.loading = false;
      }
    },
    showAnagraficaTab() {
      this.$refs.tabs.querySelector('a[href="#contentAnagrafica"]').click();
    },
    markAsModified(section) {
      this.isModified[section] = true;
    },
    backToList() {
      this.$router.push({
        name: "route-fornitori",
        query: { page: this.getCurrentPageFornitori },
      });
    },

    closeModal() {
      this.isDialogVisible = false;
    },
    async uploadImage() {
      this.$refs.fileInput.click();
    },
    async onFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.loading = true;
        try {
          const response = await uploadLogoFornitore(this.fornitore._id, file);
          if (response && response.url) {
            this.fornitore.logo = response.url;
          }
        } catch (error) {
          console.error("Errore durante il caricamento del logo", error);
          this.errorMessage = "Errore durante il caricamento del logo";
        } finally {
          this.loading = false;
        }
      }
    },
    async removeImage() {
      if (!this.fornitore.logo) return;
      this.loading = true;
      try {
        const response = await deleteLogoFornitore(this.fornitore._id);
        if (response) {
          this.fornitore.logo = "";
        }
      } catch (error) {
        console.error("Errore durante la cancellazione del logo", error);
        this.errorMessage = "Errore durante la cancellazione del logo";
      } finally {
        this.loading = false;
      }
    },
    //
    // Metodi controllo modifiche non salvate
    //
    hasUnsavedChanges() {
      // Verifico se ci sono modifiche non salvate
      return this.isModified.anagrafica;
    },
    handleBeforeUnload(event) {
      // Gestore dell'evento beforeunload
      if (this.hasUnsavedChanges()) {
        event.preventDefault();
        event.returnValue = ""; // Questo è necessario per far funzionare il messaggio in alcuni browser
      }
    },
    handleBeforeRouteLeave(next) {
      // Gestione del beforeRouteLeave
      if (this.hasUnsavedChanges()) {
        this.$swal
          .fire({
            title: "Dati non salvati",
            html:
              "Ci sono dati non salvati. Sei sicuro di voler abbandonare le modifiche?",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: '<i class="fa fa-check"></i> Conferma',
            cancelButtonText: '<i class="fa fa-xmark"></i> Annulla',
          })
          .then((result) => {
            if (result.isConfirmed) {
              next();
            } else {
              next(false);
            }
          });
      } else {
        next();
      }
    },
  },
  //
  // Hooks
  //
  async created() {
    if (this.$route.params.id) {
      await this.fetchFornitore();
    } else {
      this.fornitore = new Fornitore();
      this.isEditMode = false;
      this.headerTitle = "Crea Nuovo";
    }
    console.log("Fornitore dopo created:", this.fornitore); // Debug
  },
  mounted() {
    // Aggiungo l'evento beforeunload per intercettare la chiusura della pagina
    window.addEventListener("beforeunload", this.handleBeforeUnload);
  },
  beforeUnmount() {
    // Rimuovo l'evento beforeunload quando il componente viene distrutto
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  },
  beforeRouteLeave(to, from, next) {
    this.handleBeforeRouteLeave(next);
  },
};
</script>
