<template>
  <main>
    <overlay-loading v-model:isLoading="isLoading"></overlay-loading>
    <div class="container" style="margin-top: 33px !important">
      <div
        class="align-items-center bg-light d-flex header-text justify-content-start p-0 w-100"
      >
        <h1>
          DETTAGLIO PRESENZE<span class="text-black small ms-3"
            ><i class="bi bi-grid-3x3"></i
          ></span>
        </h1>
      </div>
      <div class="container m-0 p-2 background-gray container-contents">
        <div class="row my-5">
          <!-- Colonna 1 sinistra -->
          <div class="col-lg-4">
            <div class="w-100">
              <div class="row g-2 align-items-center">
                <div class="col-lg-4 text-lg-end text-start">
                  <label class="form-label">DAL</label>
                </div>
                <div class="align-items-center col-lg-8 d-flex justify-content-center">
                  <VueDatePicker
                    v-model="dataDal"
                    dark
                    locale="it"
                    format="dd/MM/yyyy"
                    cancelText="Annulla"
                    selectText="Conferma"
                    :enable-time-picker="false"
                    @update:modelValue="onDataDalUpdate"
                    @cleared="onDataDalClear"
                    placeholder="DAL"
                    :teleport="true"
                  >
                    <template #input-icon>
                      <i class="input-slot-image2 zmdi zmdi-calendar"></i>
                    </template>
                    <template #clear-icon="{ clear }">
                      <i
                        @click="clear"
                        class="input-slot-image zmdi zmdi-close zmdi-hc-lg"
                      ></i>
                    </template>
                  </VueDatePicker>
                  <button
                    class="timepicker-button"
                    alt="OGGI"
                    title="OGGI"
                    @click="setToday('dataDal')"
                  >
                    <i class="zmdi zmdi-calendar zmdi-hc-lg"></i>
                  </button>
                </div>
              </div>
              <div class="row g-2 align-items-center mt-2">
                <div class="col-lg-4 text-lg-end text-start">
                  <label class="form-label">AL</label>
                </div>
                <div class="align-items-center col-lg-8 d-flex justify-content-center">
                  <VueDatePicker
                    v-model="dataAl"
                    dark
                    locale="it"
                    format="dd/MM/yyyy"
                    cancelText="Annulla"
                    selectText="Conferma"
                    :enable-time-picker="false"
                    @update:modelValue="onDataAlUpdate"
                    @cleared="onDataAlClear"
                    placeholder="AL"
                    :teleport="true"
                  >
                    <template #input-icon>
                      <i class="input-slot-image2 zmdi zmdi-calendar"></i>
                    </template>
                    <template #clear-icon="{ clear }">
                      <i
                        @click="clear"
                        class="input-slot-image zmdi zmdi-close zmdi-hc-lg"
                      ></i>
                    </template>
                  </VueDatePicker>
                  <button
                    class="timepicker-button"
                    alt="OGGI"
                    title="OGGI"
                    @click="setToday('dataAl')"
                  >
                    <i class="zmdi zmdi-calendar zmdi-hc-lg"></i>
                  </button>
                </div>
              </div>
            </div>
            <!-- Tipo -->
            <div class="row g-2 align-items-center">
              <div class="col-lg-4 text-lg-end text-start">
                <label class="form-label"></label>
              </div>
              <div class="col-lg-8">
                <div class="dropdown my-5 w-100">
                  <button
                    class="btn button-outline-1 small dropdown-toggle w-100"
                    type="button"
                    id="dropdown_query"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span
                      >Tipo <span class="text-black">{{ selectedTypeLabel }}</span></span
                    >
                  </button>
                  <ul
                    class="dropdown-menu dropdown-menu-end w-100"
                    aria-labelledby="dropdown_query"
                  >
                    <li v-for="type in types" :key="type.value">
                      <button
                        class="dropdown-item"
                        :class="{ active: selectedTypeLabel == type.label }"
                        type="button"
                        @click="selectType(type)"
                      >
                        {{ type.label }}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- Colonna 2 destra -->
          <div class="col-lg-8">
            <!-- DTC -->
            <div v-if="selectedType === 'dtc'">
              <div class="row g-2 align-items-center mb-3">
                <div class="col-lg-4 text-lg-end text-start">
                  <label class="form-label"
                    >DTC<br />(caricati: {{ referentiDTCOptions.length }} / totali:
                    {{ referentiDTCOptions.length }})</label
                  >
                </div>
                <div class="col-lg-8">
                  <MultiSelect
                    v-model="selectedReferentiDTC"
                    :options="referentiDTCOptions"
                    :searchable="true"
                    placeholder="- SELEZIONA -"
                    label="label"
                    track-by="value"
                    :show-no-results="true"
                    selectLabel="Seleziona"
                    deselectLabel="Rimuovi"
                    selectedLabel="Selezionato"
                    :openDirection="'below'"
                    tagPlaceholder="Premi enter per creare un tag"
                    :multiple="false"
                    :taggable="false"
                    :showLabels="false"
                    :loading="isLoadingReferentiDTC"
                    @search-change="asyncFetchReferentiDTC"
                    :maxHeight="400"
                  >
                    <template #noOptions>
                      <span>L'elenco è vuoto</span>
                    </template>
                    <template #noResult>
                      <span>Nessun elemento trovato</span>
                    </template>
                  </MultiSelect>
                </div>
              </div>
            </div>
            <!-- SOCIETÀ -->
            <div v-if="selectedType === 'societa'">
              <div class="row g-2 align-items-center mb-3">
                <div class="col-lg-4 text-lg-end text-start">
                  <label class="form-label"
                    >SOCIETÀ<br />(caricati: {{ societaOptions.length }} / totali:
                    {{ societaOptions.length }})</label
                  >
                </div>
                <div class="col-lg-8">
                  <MultiSelect
                    v-model="selectedSocieta"
                    :options="societaOptions"
                    :searchable="true"
                    placeholder="- SELEZIONA -"
                    label="label"
                    track-by="value"
                    :show-no-results="true"
                    selectLabel="Seleziona"
                    deselectLabel="Rimuovi"
                    selectedLabel="Selezionato"
                    :openDirection="'below'"
                    tagPlaceholder="Premi enter per creare un tag"
                    :multiple="false"
                    :taggable="false"
                    :showLabels="false"
                    :loading="isLoadingSocieta"
                    :maxHeight="400"
                  >
                    <template #noOptions>
                      <span>L'elenco è vuoto</span>
                    </template>
                    <template #noResult>
                      <span>Nessun elemento trovato</span>
                    </template>
                  </MultiSelect>
                </div>
              </div>
            </div>
            <!-- FORNITORI -->
            <div v-if="selectedType === 'fornitori'">
              <div class="row g-2 align-items-center mb-3">
                <div class="col-lg-4 text-lg-end text-start">
                  <label class="form-label"
                    >FORNITORE<br />(caricati: {{ fornitoriOptions.length }} / totali:
                    {{ totalItemsFornitori }})</label
                  >
                </div>
                <div class="col-lg-8">
                  <MultiSelect
                    ref="fornitoriContainer"
                    v-model="selectedFornitori"
                    :options="fornitoriOptions"
                    :searchable="true"
                    placeholder="- SELEZIONA -"
                    label="label"
                    track-by="value"
                    :show-no-results="true"
                    selectLabel="Seleziona"
                    deselectLabel="Rimuovi"
                    selectedLabel="Selezionato"
                    :openDirection="'below'"
                    tagPlaceholder="Premi enter per creare un tag"
                    :multiple="false"
                    :taggable="false"
                    :showLabels="false"
                    :loading="isLoadingFornitori"
                    :limit="limit"
                    :maxHeight="400"
                    @search-change="onSearchFornitoriChange"
                    @open="onOpenFornitori"
                    @close="onCloseFornitori"
                    @select="onFornitoriSelect"
                  >
                    <template #noOptions>
                      <span>L'elenco è vuoto</span>
                    </template>
                    <template #noResult>
                      <span>Nessun elemento trovato</span>
                    </template>
                  </MultiSelect>
                </div>
              </div>
            </div>
            <!-- COMMESSE -->
            <div v-if="selectedType === 'commesse'">
              <div class="row g-2 align-items-center mb-3">
                <div class="col-lg-4 text-lg-end text-start">
                  <label class="form-label"
                    >COMMESSA<br />(caricate: {{ commesseOptions.length }} / totali:
                    {{ totalItemsCommesse }})</label
                  >
                </div>
                <div class="col-lg-8">
                  <MultiSelect
                    ref="commesseContainer"
                    v-model="selectedCommesse"
                    :options="commesseOptions"
                    :searchable="true"
                    placeholder="- SELEZIONA -"
                    label="label"
                    track-by="value"
                    :show-no-results="true"
                    selectLabel="Seleziona"
                    deselectLabel="Rimuovi"
                    selectedLabel="Selezionato"
                    :openDirection="'below'"
                    tagPlaceholder="Premi enter per creare un tag"
                    :multiple="false"
                    :taggable="false"
                    :showLabels="false"
                    :loading="isLoadingCommesse"
                    :limit="limit"
                    :maxHeight="400"
                    @search-change="onSearchCommesseChange"
                    @open="onOpenCommesse"
                    @close="onCloseCommesse"
                    @select="onCommesseSelect"
                  >
                    <template #noOptions>
                      <span>L'elenco è vuoto</span>
                    </template>
                    <template #noResult>
                      <span>Nessun elemento trovato</span>
                    </template>
                  </MultiSelect>
                </div>
              </div>
            </div>
            <!-- RISORSE -->
            <div v-if="selectedType === 'fornitori' || selectedType === 'commesse'">
              <div class="row g-2 align-items-center mb-3">
                <div class="col-lg-4 text-lg-end text-start">
                  <label class="form-label">TUTTE LE RISORSE</label>
                </div>
                <div class="col-lg-8">
                  <div class="form-check form-switch">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="allRisorse"
                      @change="toggleAllRisorse"
                    />
                    <label class="content-form text-end label-yes-no">
                      {{ allRisorse === true ? "SI" : "NO" }}
                    </label>
                  </div>
                </div>
              </div>
              <div class="row g-2 align-items-center mb-3">
                <div class="col-lg-4 text-lg-end text-start">
                  <label class="form-label"
                    >RISORSE<br />(caricate: {{ risorseOptions.length }} / totali:
                    {{ totalItemsRisorse }})</label
                  >
                </div>
                <div class="col-lg-8">
                  <MultiSelect
                    ref="risorseContainer"
                    v-model="selectedRisorse"
                    :options="risorseOptions"
                    :searchable="true"
                    placeholder="- SELEZIONA -"
                    label="label"
                    track-by="value"
                    :show-no-results="true"
                    selectLabel="Seleziona"
                    deselectLabel="Rimuovi"
                    selectedLabel="Selezionato"
                    :openDirection="'below'"
                    tagPlaceholder="Premi enter per creare un tag"
                    :multiple="true"
                    :taggable="false"
                    :showLabels="false"
                    :loading="isLoadingRisorse"
                    :limit="limit"
                    :maxHeight="400"
                    @search-change="onSearchRisorseChange"
                    @open="onOpenRisorse"
                    @close="onCloseRisorse"
                    @select="onSelectRisorse"
                  >
                    <template #clear>
                      <div
                        v-if="selectedRisorse && selectedRisorse.length"
                        class="multiselect__clear"
                        @mousedown.prevent.stop="clearRisorse"
                      ></div>
                    </template>
                    <template #noOptions>
                      <span>L'elenco è vuoto</span>
                    </template>
                    <template #noResult>
                      <span>Nessun elemento trovato</span>
                    </template>
                  </MultiSelect>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Download -->
        <div
          v-if="urlFileToDownload != ''"
          class="align-items-center d-flex flex-row justify-content-center my-5"
        >
          <button
            type="button"
            @click="downloadFile()"
            class="btn button-outline-1 small"
          >
            <span><i class="bi bi-download"></i>Scarica il file generato</span>
          </button>
        </div>
        <hr class="sepGray" />
        <div class="row">
          <!-- Esporta -->
          <div class="my-3">
            <button
              v-if="urlFileToDownload == ''"
              @click="esportaExcel"
              type="button"
              class="btn button-outline-1 float-end"
            >
              <span><i class="bi bi-file-earmark-excel-fill"></i>Esporta Excel</span>
            </button>
            <button
              v-if="urlFileToDownload == ''"
              @click="esportaExcelHR"
              type="button"
              class="btn button-outline-1 float-end me-2"
            >
              <span
                ><i class="bi bi-file-earmark-excel-fill"></i>Esporta Excel per HR</span
              >
            </button>
            <button
              v-if="urlFileToDownload != ''"
              @click="nuovaEsportazione()"
              type="button"
              class="btn button-outline-1 float-end"
            >
              <span><i class="bi bi-plus-lg"></i>Nuova Esportazione</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import OverlayLoading from "@/components/ui/OverlayLoading.vue";
import { fetchTeam, fetchFornitori, fetchCommesse } from "@/services/api";
import { User } from "@/models/TeamJoiModel";
import { Fornitore } from "@/models/FornitoreJoiModel";
import { Commessa } from "@/models/CommessaJoiModel";
import { UtilityMixins } from "@/mixins/UtilityMixins.js";
import { formatISO } from "date-fns";
import {
  exportDettaglioPresenzeExcel,
  exportDettaglioPresenzeExcelHR,
} from "@/services/api";

export default {
  mixins: [UtilityMixins],
  components: { OverlayLoading },
  data() {
    return {
      selectedType: "dtc", // Valore predefinito (value)
      selectedTypeLabel: "DTC", // Etichetta predefinita (label)
      types: [
        { value: "dtc", label: "DTC" },
        { value: "societa", label: "SOCIETÀ" },
        { value: "fornitori", label: "FORNITORE" },
        { value: "commesse", label: "COMMESSA" },
      ],
      isLoading: false,
      // multiselect Risorse
      risorseOptions: [], // Dati da visualizzare nel multiselect
      selectedRisorse: [], // Valore selezionato
      isLoadingRisorse: false, // Indicatore di caricamento
      currentPageRisorse: 1, // Pagina corrente per il caricamento asincrono
      totalItemsRisorse: 0, // Totale degli elementi nel server
      textToSearchRisorse: "", // Query di ricerca
      idsupplier: "",
      allRisorse: false,
      // multiselect Fornitori
      fornitoriOptions: [], // Dati da visualizzare nel multiselect
      selectedFornitori: [], // Valore selezionato
      isLoadingFornitori: false, // Indicatore di caricamento
      currentPageFornitori: 1, // Pagina corrente per il caricamento asincrono
      totalItemsFornitori: 0, // Totale degli elementi nel server
      textToSearchFornitori: "", // Query di ricerca
      // multiselect Commesse
      commesseOptions: [], // Dati da visualizzare nel multiselect
      selectedCommesse: [], // Valore selezionato
      isLoadingCommesse: false, // Indicatore di caricamento
      currentPageCommesse: 1, // Pagina corrente per il caricamento asincrono
      totalItemsCommesse: 0, // Totale degli elementi nel server
      textToSearchCommesse: "", // Query di ricerca
      risorseAssociate: [],
      // multiselect Referenti DTC
      selectedReferentiDTC: [],
      referentiDTCOptions: [],
      isLoadingReferentiDTC: false,
      // multiselect Società
      selectedSocieta: [],
      societaOptions: [],
      isLoadingSocieta: false,
      // date
      dataDal: null,
      dataAl: null,
      // parametri generali multiselect
      limit: 50, // limite max degli items restituiti dalla API
      urlFileToDownload: "",
    };
  },
  methods: {
    downloadFile() {
      if (this.urlFileToDownload) {
        const link = document.createElement("a");
        link.href = this.urlFileToDownload;
        const fileName = this.urlFileToDownload.split("/").pop();
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    esportaExcel() {
      this.exportData("excel");
    },
    esportaExcelHR() {
      this.exportData("excel-hr");
    },
    async exportData(exportType) {
      const result = this.validateAndPrepareBody();
      if (!result.isValid) return false;
      if (!result.body) return false;

      console.log("** exportData body", result.body);

      // Effettuo l'esportazione...
      this.isLoading = true;

      try {
        let response = null;

        if (exportType === "excel") {
          response = await exportDettaglioPresenzeExcel(result.body);
        } else if (exportType === "excel-hr") {
          response = await exportDettaglioPresenzeExcelHR(result.body);
        }

        console.log("** response", response);

        if (response && response.url) {
          console.log("** dettaglio presenze export excel url", response.url);
          this.mixins_showMessage(
            "Dettaglio Presenze",
            "Esportazione terminata con successo",
            "info"
          );

          this.urlFileToDownload = response.url;
        } else {
          this.mixins_showMessage(
            "Dettaglio Presenze",
            `Errore durante l'esportazione dei dati`,
            "error"
          );
        }
      } catch (error) {
        this.mixins_showMessage(
          "Dettaglio Presenze",
          `Errore durante l'esportazione dei dati`,
          "error"
        );
      } finally {
        this.isLoading = false;
      }
    },
    validateAndPrepareBody() {
      let body = null;
      let dtcId = "";
      let companyId = "";
      let supplierId = "";
      let commessaId = "";
      let resources = [];
      let resourcesIds = [];

      // Verifico se le date del periodo sono valide
      const result = this.mixins_isValidDateRange(this.dataDal, this.dataAl);
      if (!result.isValid) {
        this.mixins_showMessage("Dettaglio Presenze", result.message, "warning");
        return { isValid: false, body: body };
      }

      // Converto le date nel formato stringa "YYYY-MM-DD"
      const dataDal = this.mixins_convertDateToYYYYMMDD(this.dataDal);
      const dataAl = this.mixins_convertDateToYYYYMMDD(this.dataAl);

      switch (this.selectedType) {
        case "dtc":
          dtcId = this.selectedReferentiDTC ? this.selectedReferentiDTC.value : "";
          if (!dtcId) {
            this.mixins_showMessage(
              "Dettaglio Presenze",
              "Selezionare un DTC",
              "warning"
            );
            return { isValid: false, body: body };
          }
          body = {
            exportExcel: {
              datefrom: dataDal,
              dateto: dataAl,
              dtc: dtcId,
              company: "",
              supplier: "",
              commessa: "",
              resources: [],
            },
          };
          break;
        case "societa":
          companyId = this.selectedSocieta ? this.selectedSocieta.value : "";
          if (!companyId) {
            this.mixins_showMessage(
              "Dettaglio Presenze",
              "Selezionare una SOCIETÀ",
              "warning"
            );
            return { isValid: false, body: body };
          }
          body = {
            exportExcel: {
              datefrom: dataDal,
              dateto: dataAl,
              dtc: "",
              company: companyId,
              supplier: "",
              commessa: "",
              resources: [],
            },
          };
          break;
        case "fornitori":
          supplierId = this.selectedFornitori ? this.selectedFornitori.value : "";
          if (!supplierId) {
            this.mixins_showMessage(
              "Dettaglio Presenze",
              "Selezionare un FORNITORE",
              "warning"
            );
            return { isValid: false, body: body };
          }
          if (this.totalItemsRisorse === 0) {
            this.mixins_showMessage(
              "Dettaglio Presenze",
              "Non ci sono risorse per il fornitore selezionato",
              "warning"
            );
            return { isValid: false, body: body };
          }
          resources = this.selectedRisorse ? this.selectedRisorse : [];
          if (resources.length === 0 && !this.allRisorse) {
            this.mixins_showMessage(
              "Dettaglio Presenze",
              'Selezionare almeno una risorsa o "TUTTE LE RISORSE"',
              "warning"
            );
            return { isValid: false, body: body };
          }
          // Se è selezionato "TUTTE LE RISORSE"
          if (this.allRisorse) {
            resourcesIds = ["all"];
          } else {
            resourcesIds = resources.map((item) => item.value);
          }
          body = {
            exportExcel: {
              datefrom: dataDal,
              dateto: dataAl,
              dtc: "",
              company: "",
              supplier: supplierId,
              commessa: "",
              resources: resourcesIds,
            },
          };
          break;
        case "commesse":
          commessaId = this.selectedCommesse ? this.selectedCommesse.value : "";
          if (!commessaId) {
            this.mixins_showMessage(
              "Dettaglio Presenze",
              "Selezionare una COMMESSA",
              "warning"
            );
            return { isValid: false, body: body };
          }
          if (this.totalItemsRisorse === 0) {
            this.mixins_showMessage(
              "Dettaglio Presenze",
              "Non ci sono risorse per la commessa selezionata",
              "warning"
            );
            return { isValid: false, body: body };
          }
          resources = this.selectedRisorse ? this.selectedRisorse : [];
          if (resources.length === 0 && !this.allRisorse) {
            this.mixins_showMessage(
              "Dettaglio Presenze",
              'Selezionare almeno una risorsa o "TUTTE LE RISORSE"',
              "warning"
            );
            return { isValid: false, body: body };
          }
          // Se è selezionato "TUTTE LE RISORSE"
          if (this.allRisorse) {
            resourcesIds = ["all"];
          } else {
            resourcesIds = resources.map((item) => item.value);
          }
          body = {
            exportExcel: {
              datefrom: dataDal,
              dateto: dataAl,
              dtc: "",
              company: "",
              supplier: "",
              commessa: commessaId,
              resources: resourcesIds,
            },
          };
          break;
      }
      return { isValid: true, body: body };
    },
    selectType(type) {
      this.selectedType = type.value;
      this.selectedTypeLabel = type.label;

      this.resetReferentiDTC();
      this.resetSocieta();
      this.resetFornitori();
      this.resetRisorse();
      this.resetCommesse();

      switch (type.value) {
        case "dtc":
          this.asyncLoadOptionsDTC();
          break;
        case "societa":
          this.asyncLoadOptionsSocieta();
          break;
        case "fornitori":
          this.asyncLoadOptionsFornitori();
          break;
        case "commesse":
          this.asyncLoadOptionsCommesse();
          break;
      }
    },
    async nuovaEsportazione() {
      this.urlFileToDownload = "";
      this.resetDates();
      this.resetReferentiDTC();
      this.resetSocieta();
      this.resetFornitori();
      this.resetRisorse();
      this.resetCommesse();
      this.selectedType = "dtc";
      this.selectedTypeLabel = "DTC";
      this.isLoading = true;
      await this.asyncLoadOptionsDTC();
      this.isLoading = false;
    },
    //
    // Gestione multiselect DTC
    //
    resetReferentiDTC() {
      this.selectedReferentiDTC = [];
      this.referentiDTCOptions = [];
      this.isLoadingReferentiDTC = false;
    },
    async asyncFetchReferentiDTC(query) {
      // rimuovo i caratteri *^? insieme ai tag HTML/XML per le query
      query = this.mixins_cleanTextForQuery(query);
      // Carico le opzioni dei referenti DTC trovati
      this.isLoadingReferentiDTC = true;
      const tabReferentiDTC = await this.mixins_getReferentiDTC(query, "rapporto");
      this.referentiDTCOptions = tabReferentiDTC.map((referente) => ({
        value: referente.providerUserId,
        label: referente.metadata.fullname,
      }));
      this.isLoadingReferentiDTC = false;
      console.log("** referentiDTCOptions", this.referentiDTCOptions);
    },
    async asyncLoadOptionsDTC() {
      if (this.isLoadingReferentiDTC) return; // Evita richieste multiple
      this.isLoadingReferentiDTC = true;
      try {
        // Carico le opzioni dei DTC
        const tabReferentiDTC = await this.mixins_getReferentiDTC("", "rapporto");
        this.referentiDTCOptions =
          tabReferentiDTC?.map((referente) => ({
            value: referente.providerUserId,
            label: referente.metadata.fullname,
          })) || [];
        this.isLoadingReferentiDTC = false;
      } catch (error) {
        console.error("Errore durante il caricamento dei dati:", error);
        this.isLoadingReferentiDTC = false;
      }
    },
    //
    // Gestione multiselect Società
    //
    resetSocieta() {
      this.selectedSocieta = [];
      this.societaOptions = [];
      this.isLoadingSocieta = false;
    },
    async asyncFetchSocieta() {
      // Carico le opzioni per le società
      this.isLoadingSocieta = true;
      const tabSocieta = await this.mixins_getTabAziende();
      this.societaOptions = tabSocieta.map((societa) => ({
        value: societa._id,
        label: societa.ragionesociale,
      }));
      this.isLoadingSocieta = false;
      console.log("** societaOptions", this.societaOptions);
    },
    async asyncLoadOptionsSocieta() {
      if (this.isLoadingSocieta) return; // Evita richieste multiple
      this.isLoadingSocieta = true;
      try {
        // Carico le opzioni delle società
        const tabSocieta = await this.mixins_getTabAziende();
        this.societaOptions =
          tabSocieta?.map((societa) => ({
            value: societa._id,
            label: societa.ragionesociale,
          })) || [];
        this.isLoadingSocieta = false;
      } catch (error) {
        console.error("Errore durante il caricamento dei dati:", error);
        this.isLoadingSocieta = false;
      }
    },
    //
    // Gestione multiselect Fornitori
    //
    resetFornitori() {
      this.fornitoriOptions = [];
      this.selectedFornitori = [];
      this.isLoadingFornitori = false;
      this.currentPageFornitori = 1;
      this.totalItemsFornitori = 0;
      this.textToSearchFornitori = "";
    },
    async onSearchFornitoriChange(query) {
      this.textToSearchFornitori = query; // Salva la query corrente
      this.currentPageFornitori = 1; // Resetta la paginazione
      this.fornitoriOptions = []; // Resetta le opzioni
      this.asyncLoadOptionsFornitori(); // Carica le nuove opzioni in base alla ricerca
    },
    async asyncLoadOptionsFornitori() {
      if (this.isLoadingFornitori) return; // Evita richieste multiple
      this.isLoadingFornitori = true;

      try {
        // Eseguo la chiamata API con i parametri appropriati
        const response = await fetchFornitori(
          this.currentPageFornitori,
          this.limit,
          2,
          this.textToSearchFornitori
        );

        // Mappo i fornitori in oggetti Fornitore e crea opzioni con `value` e `label`
        const data =
          response.fornitori?.map((item) => {
            const fornitore = new Fornitore(item); // Crea un nuovo oggetto Fornitore
            return {
              value: fornitore._id,
              label: fornitore.ragionesociale,
            };
          }) ?? [];

        console.log("** data fornitori", data);

        // Aggiungi i nuovi elementi all'array delle opzioni
        this.fornitoriOptions = [...this.fornitoriOptions, ...data];
        this.totalItemsFornitori = response?.totalCount ?? 0; // Imposta il totale degli elementi
        this.isLoadingFornitori = false;
      } catch (error) {
        console.error("Errore durante il caricamento dei dati:", error);
        this.isLoadingFornitori = false;
      }
    },
    onOpenFornitori() {
      this.$nextTick(() => {
        const dropdownContent = this.$refs.fornitoriContainer.$el.querySelector(
          ".multiselect__content-wrapper"
        );
        if (dropdownContent) {
          dropdownContent.addEventListener("scroll", this.onScrollFornitori);
        }
      });
    },
    onCloseFornitori() {
      const dropdownContent = this.$refs.fornitoriContainer.$el.querySelector(
        ".multiselect__content-wrapper"
      );
      if (dropdownContent) {
        dropdownContent.removeEventListener("scroll", this.onScrollFornitori);
      }
    },
    onScrollFornitori(event) {
      const scrollPosition = event.target.scrollTop + event.target.clientHeight;
      const scrollHeight = event.target.scrollHeight;

      if (
        scrollPosition >= scrollHeight &&
        this.fornitoriOptions.length < this.totalItemsFornitori
      ) {
        this.currentPageFornitori += 1;
        this.asyncLoadOptionsFornitori();
      } else {
        console.log("Tutti i fornitori sono stati caricati");
      }
    },
    onFornitoriSelect(selected) {
      this.resetRisorse();
      this.idsupplier = selected.value;
      this.asyncLoadOptionsRisorse();
    },
    //
    // Gestione multiselect Commesse
    //
    resetCommesse() {
      this.commesseOptions = [];
      this.selectedCommesse = [];
      this.isLoadingCommesse = false;
      this.currentPageCommesse = 1;
      this.totalItemsCommesse = 0;
      this.textToSearchCommesse = "";
      this.risorseAssociate = [];
    },
    async onSearchCommesseChange(query) {
      this.textToSearchCommesse = query; // Salva la query corrente
      this.currentPageCommesse = 1; // Resetta la paginazione
      this.commesseOptions = []; // Resetta le opzioni
      this.asyncLoadOptionsCommesse(); // Carica le nuove opzioni in base alla ricerca
    },
    async asyncLoadOptionsCommesse() {
      if (this.isLoadingCommesse) return; // Evita richieste multiple
      this.isLoadingCommesse = true;

      try {
        // Eseguo la chiamata API con i parametri appropriati
        const response = await fetchCommesse(
          this.currentPageCommesse,
          this.limit,
          2,
          this.textToSearchCommesse
        );

        // Mappo le commesse in oggetti Commessa e crea opzioni con `value` e `label`
        // Inoltre inserisco le risorse associate
        const data =
          response.commesse?.map((item) => {
            const commessa = new Commessa(item); // Crea un nuovo oggetto Commessa
            let risorseAssociate = commessa.risorseassociate || []; // Se non esiste, default a []

            // Mappo risorseAssociate per ottenere { value, label } per ogni risorsa
            let risorseMappate = risorseAssociate.map((risorsa) => ({
              value: risorsa.idrisorsa, // Usa idrisorsa come value
              label: risorsa.fullname, // Usa fullname come label
            }));

            return {
              value: commessa._id,
              label: commessa.titolo,
              risorse: risorseMappate, // Mappo e assegno l'array delle risorse
            };
          }) ?? [];

        console.log("** data commesse", data);

        // Aggiungi i nuovi elementi all'array delle opzioni
        this.commesseOptions = [...this.commesseOptions, ...data];
        this.totalItemsCommesse = response?.totalCount ?? 0; // Imposta il totale degli elementi
        this.isLoadingCommesse = false;
      } catch (error) {
        console.error("Errore durante il caricamento dei dati:", error);
        this.isLoadingCommesse = false;
      }
    },
    onOpenCommesse() {
      this.$nextTick(() => {
        const dropdownContent = this.$refs.commesseContainer.$el.querySelector(
          ".multiselect__content-wrapper"
        );
        if (dropdownContent) {
          dropdownContent.addEventListener("scroll", this.onScrollCommesse);
        }
      });
    },
    onCloseCommesse() {
      const dropdownContent = this.$refs.commesseContainer.$el.querySelector(
        ".multiselect__content-wrapper"
      );
      if (dropdownContent) {
        dropdownContent.removeEventListener("scroll", this.onScrollCommesse);
      }
    },
    onScrollCommesse(event) {
      const scrollPosition = event.target.scrollTop + event.target.clientHeight;
      const scrollHeight = event.target.scrollHeight;

      if (
        scrollPosition >= scrollHeight &&
        this.commesseOptions.length < this.totalItemsCommesse
      ) {
        this.currentPageCommesse += 1;
        this.asyncLoadOptionsCommesse();
      } else {
        console.log("Tutti i commesse sono stati caricati");
      }
    },
    onCommesseSelect(selected) {
      this.resetRisorse();
      this.risorseAssociate = selected.risorse;
      this.asyncLoadOptionsRisorse();
    },
    //
    // Gestione multiselect Risorse
    //
    resetRisorse() {
      this.risorseOptions = [];
      this.selectedRisorse = [];
      this.isLoadingRisorse = false;
      this.currentPageRisorse = 1;
      this.totalItemsRisorse = 0;
      this.textToSearchRisorse = "";
      this.idsupplier = "";
      this.allRisorse = false;
    },
    clearRisorse() {
      this.selectedRisorse = null;
    },
    async onSearchRisorseChange(query) {
      this.textToSearchRisorse = query; // Salva la query corrente
      this.currentPageRisorse = 1; // Resetta la paginazione
      this.risorseOptions = []; // Resetta le opzioni
      this.asyncLoadOptionsRisorse(); // Carica le nuove opzioni in base alla ricerca
    },
    async asyncLoadOptionsRisorse() {
      switch (this.selectedType) {
        case "fornitori":
          if (this.isLoadingRisorse) return; // Evita richieste multiple
          this.isLoadingRisorse = true;

          try {
            // Eseguo la chiamata API con i parametri appropriati
            const response = await fetchTeam({
              page: this.currentPageRisorse,
              limit: this.limit,
              sortType: 2,
              textsearch: this.textToSearchRisorse,
              idsupplier: this.idsupplier,
            });

            // Mappo gli utenti in oggetti User e crea opzioni con `value` e `label`
            const data =
              response.users?.map((item) => {
                const user = new User(item); // Crea un nuovo oggetto User
                return {
                  value: user.providerUserId, // Usa providerUserId come value
                  label: user.metadata.fullname, // Usa fullname come label
                };
              }) ?? [];

            console.log("** data risorse fornitori", data);

            // Aggiungi i nuovi elementi all'array delle opzioni
            this.risorseOptions = [...this.risorseOptions, ...data];
            this.totalItemsRisorse = response?.totalCount ?? 0; // Imposta il totale degli elementi
            this.isLoadingRisorse = false;

            if (this.totalItemsRisorse === 0) {
              console.log("** Non ci sono risorse");
              this.mixins_showMessage(
                "Dettaglio Presenze",
                "Non ci sono risorse per il fornitore selezionato",
                "warning"
              );
            }
          } catch (error) {
            console.error("Errore durante il caricamento dei dati:", error);
            this.isLoadingRisorse = false;
          }
          break;

        case "commesse":
          if (this.isLoadingRisorse) return; // Evita richieste multiple
          this.isLoadingRisorse = true;

          try {
            const data = this.risorseAssociate;
            console.log("** data risorse commesse", data);

            this.risorseOptions = [...data];
            this.totalItemsRisorse = data?.length || 0; // Imposta il totale degli elementi
            this.isLoadingRisorse = false;

            if (this.totalItemsRisorse === 0) {
              console.log("** Non ci sono risorse");
              this.mixins_showMessage(
                "Dettaglio Presenze",
                "Non ci sono risorse per la commessa selezionata",
                "warning"
              );
            }
          } catch (error) {
            console.error("Errore durante il caricamento dei dati:", error);
            this.isLoadingRisorse = false;
          }
          break;
      }
    },
    onOpenRisorse() {
      this.$nextTick(() => {
        const dropdownContent = this.$refs.risorseContainer.$el.querySelector(
          ".multiselect__content-wrapper"
        );
        if (dropdownContent) {
          dropdownContent.addEventListener("scroll", this.onScrollRisorse);
        }
      });
    },
    onCloseRisorse() {
      const dropdownContent = this.$refs.risorseContainer.$el.querySelector(
        ".multiselect__content-wrapper"
      );
      if (dropdownContent) {
        dropdownContent.removeEventListener("scroll", this.onScrollRisorse);
      }
    },
    onScrollRisorse(event) {
      const scrollPosition = event.target.scrollTop + event.target.clientHeight;
      const scrollHeight = event.target.scrollHeight;

      if (
        scrollPosition >= scrollHeight &&
        this.risorseOptions.length < this.totalItemsRisorse
      ) {
        this.currentPageRisorse += 1;
        this.asyncLoadOptionsRisorse();
      } else {
        console.log("Tutte le risorse sono state caricate");
      }
    },
    onSelectRisorse(selected) {
      console.log("** onSelectRisorse", selected);
      if (this.selectedRisorse?.length > 0) {
        this.allRisorse = false;
      }
    },
    toggleAllRisorse(event) {
      console.log("** toggleAllRisorse", event.target.checked);
      // Imposta allRisorse a false se totalItemsRisorse è === 0
      if (this.totalItemsRisorse === 0) {
        this.allRisorse = false;
      } else if (this.selectedRisorse?.length > 0) {
        this.selectedRisorse = [];
      }
    },
    //
    // Gestione Date
    //
    onDataDalUpdate(newDate) {
      this.dataDal = newDate ? formatISO(newDate) : null;
    },
    onDataDalClear() {
      this.dataDal = null;
    },
    onDataAlUpdate(newDate) {
      this.dataAl = newDate ? formatISO(newDate) : null;
    },
    onDataAlClear() {
      this.dataAl = null;
    },
    resetDates() {
      this.dataDal = null;
      this.dataAl = null;
    },
    setToday(fieldName) {
      // Crea una nuova data e imposta l'orario a mezzanotte (00:00:00)
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Imposta l'orario a 00:00:00:000

      this[fieldName] = formatISO(today); // Usa la data con orario 00:00:00

      // Mappo i campi ai rispettivi metodi di aggiornamento
      const updateMethods = {
        dataDal: this.onDataDalUpdate,
        dataAl: this.onDataAlUpdate,
      };

      // Invoco il metodo corretto in modo dinamico
      if (updateMethods[fieldName]) {
        updateMethods[fieldName](today);
      }
    },
  },
  async mounted() {
    // Carica i DTC
    this.isLoading = true;
    await this.asyncLoadOptionsDTC();
    this.isLoading = false;
  },
};
</script>
