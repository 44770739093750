<template>
  <overlay-loading v-model:isLoading="isLoading"></overlay-loading>
  <div class="col-sm-12 main-col">
    <div class="row p-0">
      <div class="align-items-center d-flex flex-row justify-content-between m-0 p-0 row">
        <div class="col-sm-10 p-0">
          <div
            class="align-items-center bg-light d-flex header-text justify-content-start p-0 w-100"
          >
            <h1>
              {{ isMe ? "AREA PERSONALE" : "GESTIONE TEAM" }}
              <span class="text-black small">
                <span class="mx-3 single-list">
                  <i class="fa-solid fa-user fa-fw"></i>
                </span>
                <span>{{ headerTitle }}</span>
              </span>
            </h1>
          </div>
        </div>
      </div>
      <div
        class="container-fluid m-0 p-2 background-gray overflow-auto container-contents"
      >
        <!-- Error message -->
        <div v-if="errorMessage" class="alert alert-danger">{{ errorMessage }}</div>

        <!-- Main contents -->
        <div v-if="!mixins_isEmptyObject(localDataAnagrafica) && !errorMessage">
          <div class="row m-0 padding-x-4px">
            <div class="col-md-12 p-0 mt-4">
              <h4 class="text-black m-0 p-0">
                <span
                  v-html="
                    localDataAnagrafica.nome + ' ' + localDataAnagrafica.cognome || blank
                  "
                ></span>
              </h4>
            </div>
            <hr class="sepGray" />
          </div>

          <!-- Navigazione dei tab -->
          <div class="row m-0 padding-x-4px">
            <ul class="nav nav-tabs video-tabs" ref="tabs">
              <!-- Tab Anagrafica -->
              <li class="nav-item">
                <a
                  @click.prevent="
                    (activeTab = 'contentAnagrafica'), fetchAnagraficaHandler
                  "
                  href="#contentAnagrafica"
                  class="nav-link d-inline-flex"
                  :class="{ active: activeTab === 'contentAnagrafica' }"
                  :aria-selected="activeTab === 'contentAnagrafica'"
                  data-bs-toggle="tab"
                  >ANAGRAFICA<span
                    class="ms-2 change-data"
                    v-if="isModified.anagrafica"
                  ></span
                ></a>
              </li>
              <!-- Tab Rapporto -->
              <li class="nav-item">
                <a
                  @click.prevent="
                    activeTab = 'contentRapporto';
                    fetchRapportoHandler();
                  "
                  href="#contentRapporto"
                  class="nav-link d-inline-flex"
                  data-bs-toggle="tab"
                  >RAPPORTO<span
                    class="ms-2 change-data"
                    v-if="isModified.rapporto"
                  ></span
                ></a>
              </li>
              <!-- Tab Dotazioni -->
              <li class="d-none nav-item">
                <a
                  href="#contentDotazioni"
                  class="nav-link d-inline-flex"
                  data-bs-toggle="tab"
                  >DOTAZIONI<span
                    class="ms-2 change-data"
                    v-if="isModified.dotazioni"
                  ></span
                ></a>
              </li>
              <!-- Tab Attività -->
              <li v-if="!isMe" class="nav-item">
                <a
                  @click.prevent="
                    activeTab = 'contentAttivita';
                    fetchAttivitaHandler();
                  "
                  href="#contentAttivita"
                  class="nav-link d-inline-flex"
                  :class="{ active: activeTab === 'contentAttivita' }"
                  :aria-selected="activeTab === 'contentAttivita'"
                  data-bs-toggle="tab"
                  >ATTIVITÀ<span
                    class="ms-2 change-data"
                    v-if="isModified.attivita"
                  ></span
                ></a>
              </li>
              <!-- Tab Costi -->
              <li class="d-none nav-item">
                <a
                  href="#contentCosti"
                  class="nav-link d-inline-flex"
                  data-bs-toggle="tab"
                  >COSTI<span class="ms-2 change-data" v-if="isModified.costi"></span
                ></a>
              </li>
              <!-- Tab Skill -->
              <li class="nav-item">
                <a
                  @click.prevent="
                    activeTab = 'contentSkill';
                    fetchSkillHandler();
                  "
                  href="#contentSkill"
                  class="nav-link d-inline-flex"
                  data-bs-toggle="tab"
                  >SKILL<span class="ms-2 change-data" v-if="isModified.skill"></span
                ></a>
              </li>
              <!-- Tab Documenti -->
              <li class="nav-item">
                <a
                  @click.prevent="
                    activeTab = 'contentDocumenti';
                    fetchDocumentoHandler();
                  "
                  href="#contentDocumenti"
                  class="nav-link d-inline-flex"
                  data-bs-toggle="tab"
                  >DOCUMENTI<span
                    class="ms-2 change-data"
                    v-if="isModified.documenti"
                  ></span
                ></a>
              </li>
              <!-- Tab Gruppi -->
              <li class="d-none nav-item">
                <a
                  href="#contentGruppi"
                  class="nav-link d-inline-flex"
                  data-bs-toggle="tab"
                  >GRUPPI<span class="ms-2 change-data" v-if="isModified.gruppi"></span
                ></a>
              </li>
              <!-- Tab Piano Ferie -->
              <li class="nav-item">
                <a
                  @click.prevent="
                    activeTab = 'contentPianoferie';
                    fetchPianoferieHandler();
                  "
                  href="#contentPianoferie"
                  class="nav-link d-inline-flex"
                  data-bs-toggle="tab"
                  >PIANO FERIE</a
                >
              </li>
              <!-- Tab Sicurezza -->
              <li v-if="isMe" class="nav-item">
                <a
                  @click.prevent="activeTab = 'contentSicurezza'"
                  href="#contentSicurezza"
                  class="nav-link d-inline-flex"
                  data-bs-toggle="tab"
                  >SICUREZZA</a
                >
              </li>
            </ul>

            <!-- Contenuto dei tab -->
            <div class="tab-content p-0">
              <!-- Content Anagrafica -->
              <div
                class="tab-pane fade"
                :class="{ 'show active': activeTab === 'contentAnagrafica' }"
                id="contentAnagrafica"
              >
                <TabUserAnagrafica
                  ref="userAnagrafica"
                  :isEditMode="isEditMode"
                  @fetch-data="onFetchData"
                  @data-updated="handleDataAnagraficaUpdate"
                  @section-modified="handleSectionModified"
                  @section-verified="handleSectionVerified"
                  v-model:userDataAnagrafica="localDataAnagrafica"
                />
              </div>
              <!-- Content Rapporto -->
              <div
                class="tab-pane fade"
                :class="{ 'show active': activeTab === 'contentRapporto' }"
                id="contentRapporto"
              >
                <TabUserRapporto
                  ref="userRapporto"
                  :isEditMode="isEditMode"
                  @fetch-data="onFetchData"
                  @data-updated="handleDataRapportoUpdate"
                  @section-modified="handleSectionModified"
                  @section-verified="handleSectionVerified"
                  v-model:userDataRapporto="localDataRapporto"
                />
              </div>
              <!-- Content Dotazioni -->
              <div
                class="tab-pane fade"
                :class="{ 'show active': activeTab === 'contentDotazioni' }"
                id="contentDotazioni"
              >
                <TabUserDotazioni ref="userDotazioni" />
              </div>
              <!-- Content Attività -->
              <div
                class="tab-pane fade"
                :class="{ 'show active': activeTab === 'contentAttivita' }"
                id="contentAttivita"
              >
                <TabUserAttivita ref="userAttivita" :isEditMode="isEditMode" />
              </div>
              <!-- Content Costi -->
              <div
                class="tab-pane fade"
                :class="{ 'show active': activeTab === 'contentCosti' }"
                id="contentCosti"
              >
                <TabUserCosti ref="userCosti" />
              </div>
              <!-- Content Skill -->
              <div
                class="tab-pane fade"
                :class="{ 'show active': activeTab === 'contentSkill' }"
                id="contentSkill"
              >
                <TabUserSkill
                  ref="userSkill"
                  :isEditMode="isEditMode"
                  @fetch-data="onFetchData"
                  @data-updated="handleDataSkillUpdate"
                  @section-modified="handleSectionModified"
                  @section-verified="handleSectionVerified"
                  v-model:userDataSkill="localDataSkill"
                />
              </div>
              <!-- Content Documenti -->
              <div
                class="tab-pane fade"
                :class="{ 'show active': activeTab === 'contentDocumenti' }"
                id="contentDocumenti"
              >
                <TabUserDocumenti
                  ref="userDocumenti"
                  :isEditMode="isEditMode"
                  @fetch-data="onFetchData"
                  @data-updated="handleDataDocumentoUpdate"
                  @section-modified="handleSectionModified"
                  @section-verified="handleSectionVerified"
                  v-model:userDataDocumento="localDataDocumento"
                />
              </div>
              <!-- Content Gruppi -->
              <div
                class="tab-pane fade"
                :class="{ 'show active': activeTab === 'contentGruppi' }"
                id="contentGruppi"
              >
                <TabUserGruppi ref="userGruppi" />
              </div>
              <!-- Content Piano Ferie -->
              <div
                class="tab-pane fade"
                :class="{ 'show active': activeTab === 'contentPianoferie' }"
                id="contentPianoferie"
              >
                <TabUserPianoferie ref="userPianoferie" />
              </div>
              <!-- Content Sicurezza -->
              <div
                v-if="isMe"
                class="tab-pane fade"
                :class="{ 'show active': activeTab === 'contentSicurezza' }"
                id="contentSicurezza"
              >
                <TabUserSicurezza ref="userSicurezza" />
              </div>
            </div>
            <hr class="sepGray" />
            <div class="container-fluid p-0 my-3">
              <button
                v-if="!isMe"
                @click="backToList()"
                type="button"
                class="btn button-outline-1 float-start"
              >
                <span><i class="zmdi zmdi-arrow-left"></i>Torna alla lista</span>
              </button>
              <button
                v-if="!shouldHideUpdateButton"
                type="button"
                class="btn button-outline-1 float-end"
                :class="{
                  notSaved:
                    isModified.anagrafica ||
                    isModified.rapporto ||
                    isModified.dotazioni ||
                    isModified.attivita ||
                    isModified.costi ||
                    isModified.skill ||
                    isModified.documenti ||
                    isModified.gruppi,
                }"
                @click="updateAllData"
                :disabled="!isMe && isAuthenticatedConsultazione"
              >
                <span
                  ><i class="zmdi zmdi-floppy"></i
                  >{{ isEditMode ? "Aggiorna i dati" : "Salva i dati" }}</span
                >
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { UtilityMixins } from "@/mixins/UtilityMixins.js";
import { mapGetters } from "vuex";
import { Anagrafica, Rapporto, SkillSet, DocumentoSet } from "@/models/TeamJoiModel";
import {
  updateUserAnagrafica,
  updateUserRapporto,
  updateUserSkill,
  updateUserDocumento,
} from "@/services/api";
import TabUserAnagrafica from "@/views/menu_team/TabUserAnagrafica.vue";
import TabUserRapporto from "@/views/menu_team/TabUserRapporto.vue";
import TabUserDotazioni from "@/views/menu_team/TabUserDotazioni.vue";
import TabUserAttivita from "@/views/menu_team/TabUserAttivita.vue";
import TabUserCosti from "@/views/menu_team/TabUserCosti.vue";
import TabUserSkill from "@/views/menu_team/TabUserSkill.vue";
import TabUserDocumenti from "@/views/menu_team/TabUserDocumenti.vue";
import TabUserGruppi from "@/views/menu_team/TabUserGruppi.vue";
import TabUserPianoferie from "@/views/menu_team/TabUserPianoferie.vue";
import TabUserSicurezza from "@/views/menu_team/TabUserSicurezza.vue";
import ProfiliPermissions from "@/mixins/ProfiliPermissions.js";
import EventBus from "@/eventBus"; // Importa l'EventBus
import OverlayLoading from "@/components/ui/OverlayLoading.vue";

export default {
  mixins: [UtilityMixins, ProfiliPermissions],
  components: {
    OverlayLoading,
    TabUserAnagrafica,
    TabUserRapporto,
    TabUserDotazioni,
    TabUserAttivita,
    TabUserCosti,
    TabUserSkill,
    TabUserDocumenti,
    TabUserGruppi,
    TabUserPianoferie,
    TabUserSicurezza,
  },
  data() {
    return {
      isLoading: false,
      errorMessage: null,
      isEditMode: true,
      isModified: {
        anagrafica: false,
        rapporto: false,
        dotazioni: false,
        attivita: false,
        costi: false,
        skill: false,
        documenti: false,
        gruppi: false,
      },
      isVerified: {
        anagrafica: false,
        rapporto: false,
        dotazioni: false,
        attivita: false,
        costi: false,
        skill: false,
        documenti: false,
        gruppi: false,
      },
      isDataFetched: {
        anagrafica: false,
        rapporto: false,
        dotazioni: false,
        attivita: false,
        costi: false,
        skill: false,
        documenti: false,
        gruppi: false,
      },
      headerTitle: "",

      activeTab: "contentAnagrafica", // Tab di default

      paramsId: this.$route.params.id || null,
      // gestione tab data
      localDataAnagrafica: new Anagrafica(),
      localDataRapporto: new Rapporto(),
      localDataSkill: new SkillSet(),
      localDataDocumento: new DocumentoSet(),
      blank: `&nbsp;`,
      userAuthenticated: null,
    };
  },
  computed: {
    ...mapGetters(["getCurrentPageTeams"]),
    shouldHideUpdateButton() {
      // Verifica se il tab attivo è DOCUMENTI, PIANO FERIE o SICUREZZA
      return (
        this.activeTab === "contentDocumenti" ||
        this.activeTab === "contentPianoferie" ||
        this.activeTab === "contentSicurezza"
      );
    },
    //
    // Authenticated
    //
    isAuthenticatedSuperAdmin() {
      return this.permissions_isAuthenticatedSuperAdmin(this.userAuthenticated);
    },
    isAuthenticatedGestionePersonale() {
      return this.permissions_isAuthenticatedGestionePersonale(this.userAuthenticated);
    },
    isAuthenticatedConsultazione() {
      return this.permissions_isAuthenticatedConsultazione(this.userAuthenticated);
    },
    isMe() {
      return this.userAuthenticated?.providerUserId == this.paramsId;
    },
  },
  methods: {
    //
    // ** Fetch Data
    //
    fetchAnagraficaHandler() {
      if (this.isModified.anagrafica || this.isDataFetched.anagrafica) {
        console.log(
          "** Anagrafica: modifiche rilevate o dati già caricati, fetchData non eseguito."
        );
        return;
      }
      if (
        this.$refs.userAnagrafica &&
        this.$refs.userAnagrafica.fetchDataAndUpdateFields
      ) {
        this.$refs.userAnagrafica.fetchDataAndUpdateFields();
      } else {
        console.error(
          "** Il metodo $refs.userAnagrafica.fetchDataAndUpdateFields() non è disponibile nel componente child."
        );
      }
    },
    fetchRapportoHandler() {
      if (this.isModified.rapporto || this.isDataFetched.rapporto) {
        console.log(
          "** Rapporto: modifiche rilevate o dati già caricati, fetchDataAndUpdateFields non eseguito."
        );
        return;
      }
      if (this.$refs.userRapporto && this.$refs.userRapporto.fetchDataAndUpdateFields) {
        this.$refs.userRapporto.fetchDataAndUpdateFields();
      } else {
        console.error(
          "** Il metodo $refs.userRapporto.fetchDataAndUpdateFields() non è disponibile nel componente child."
        );
      }
    },
    fetchSkillHandler() {
      // Mi posiziono sulla videata iniziale skill matrix (tutte le multiselect)
      if (this.$refs.userSkill && this.$refs.userSkill.showMatrixSkill) {
        this.$refs.userSkill.showMatrixSkill();
      }

      if (this.isModified.skill || this.isDataFetched.skill) {
        console.log(
          "** Skill: modifiche rilevate o dati già caricati, fetchDataAndUpdateFields non eseguito."
        );
        return;
      }
      if (this.$refs.userSkill && this.$refs.userSkill.fetchDataAndUpdateFields) {
        this.$refs.userSkill.fetchDataAndUpdateFields();
      } else {
        console.error(
          "** Il metodo $refs.userSkill.fetchDataAndUpdateFields() non è disponibile nel componente child."
        );
      }
    },
    fetchDocumentoHandler() {
      if (this.$refs.userDocumenti && this.$refs.userDocumenti.fetchDataAndUpdateFields) {
        this.$refs.userDocumenti.fetchDataAndUpdateFields();
      } else {
        console.error(
          "** Il metodo $refs.userDocumenti.fetchDataAndUpdateFields() non è disponibile nel componente child."
        );
      }
    },
    fetchAttivitaHandler() {
      if (this.$refs.userAttivita && this.$refs.userAttivita.fetchDataAndUpdateFields) {
        this.$refs.userAttivita.fetchDataAndUpdateFields();
      } else {
        console.error(
          "** Il metodo $refs.userAttivita.fetchDataAndUpdateFields() non è disponibile nel componente child."
        );
      }
    },
    fetchPianoferieHandler() {
      if (this.$refs.userPianoferie && this.$refs.userPianoferie.fetchCalendarData) {
        this.$refs.userPianoferie.fetchCalendarData();
      } else {
        console.error(
          "** Il metodo $refs.userPianoferie.fetchCalendarData() non è disponibile nel componente child."
        );
      }
    },
    onFetchData(section) {
      this.isDataFetched[section] = true;
      console.log(`** Dati sezione ${section} recuperati dal server`);
    },
    //
    // ** Gestione aggiornamenti e validazioni
    //
    //
    // Anagrafica
    //
    handleDataAnagraficaUpdate(updatedData) {
      this.localDataAnagrafica = new Anagrafica(updatedData);
      console.log(
        "** Dati aggiornati ricevuti dal child Anagrafica:",
        this.localDataAnagrafica
      );
    },
    //
    // Rapporto
    //
    handleDataRapportoUpdate(updatedData) {
      this.localDataRapporto = new Rapporto(updatedData);
      console.log(
        "** Dati aggiornati ricevuti dal child Rapporto:",
        this.localDataRapporto
      );
    },
    //
    // Skill
    //
    handleDataSkillUpdate(updatedData) {
      this.localDataSkill = new SkillSet(updatedData);
      console.log("** Dati aggiornati ricevuti dal child Skill:", this.localDataSkill);
    },
    //
    // Documento
    //
    handleDataDocumentoUpdate(updatedData) {
      this.localDataDocumento = new DocumentoSet(updatedData);
      console.log(
        "** Dati aggiornati ricevuti dal child Documento:",
        this.localDataDocumento
      );
    },

    //
    // Handle per sezioni modificate e verificate
    //
    handleSectionModified(section) {
      this.isModified[section] = true;
      console.log(`** Sezione ${section} modificata`);
    },
    handleSectionVerified(section, value) {
      this.isVerified[section] = value;
      console.log(`** Sezione ${section} verificata`, value);
    },
    //
    // ** Aggiornamento Dati
    //
    async updateAllData() {
      if (!this.isMe && this.isAuthenticatedConsultazione) {
        this.mixins_showMessage(
          "Permessi Insufficienti",
          "Non hai i permessi per eseguire questa azione",
          "error"
        );
        return;
      }

      // Array per tracciare le sezioni aggiornate e quelle con errori
      const isUpdated = [];
      const hasErrors = [];

      // Definisco le sezioni e le rispettive funzioni di aggiornamento
      const sections = [
        { label: "ANAGRAFICA", name: "anagrafica", updateFn: this.updateDataAnagrafica },
        { label: "RAPPORTO", name: "rapporto", updateFn: this.updateDataRapporto },
        { label: "SKILL", name: "skill", updateFn: this.updateDataSkill },
        { label: "DOCUMENTI", name: "documenti", updateFn: this.updateDataDocumento },
      ];

      // Ciclo attraverso ogni sezione e chiamo la funzione di aggiornamento corrispondente
      for (const section of sections) {
        if (section && section.updateFn) {
          const updated = await section.updateFn.call(this);
          if (updated) {
            isUpdated.push(section.name);
          } else if (this.isModified[section.name]) {
            hasErrors.push(section.label);
          }
        }
      }

      // Se ci sono stati aggiornamenti
      if (isUpdated.length > 0) {
        this.resetSectionModified(isUpdated);
        this.resetSectionVerified(isUpdated);
        this.resetDataFetched(isUpdated);
        // this.mixins_showMessage(
        //   "Aggiornamento dati",
        //   `Le seguenti sezioni sono state aggiornate correttamente: ${isUpdated.join(
        //     ", "
        //   )}`,
        //   "success"
        // );
        console.log(
          `** Le seguenti sezioni sono state aggiornate correttamente: ${isUpdated.join(
            ", "
          )}`
        );
      }

      // Se ci sono errori, mostro un messaggio specifico
      if (hasErrors.length > 0) {
        let msg;
        if (hasErrors.length == 1) {
          msg = "Ci sono dati non validi nella seguente sezione:";
        } else if (hasErrors.length > 1) {
          msg = "Ci sono dati non validi nelle seguenti sezioni:";
        }
        this.mixins_showMessage("Attenzione", `${msg} ${hasErrors.join(", ")}`, "error");
      }
    },

    async updateDataAnagrafica() {
      let isUpdated = false;
      if (this.isModified.anagrafica && this.isVerified.anagrafica) {
        this.isLoading = true;
        console.log("** Aggiornamento sezione Anagrafica", this.localDataAnagrafica);
        const data = { anagrafica: this.localDataAnagrafica };
        const response = await updateUserAnagrafica(this.paramsId, data);
        if (typeof response == "string" && response == "") {
          this.mixins_showMessage("Anagrafica", "Dati salvati correttamente", "info");
          isUpdated = true;
        }
        this.isLoading = false;
        return isUpdated;
      }
      // Se non verificato o modificato correttamente, restituisco false
      return isUpdated;
    },

    async updateDataRapporto() {
      let isUpdated = false;
      if (this.isModified.rapporto && this.isVerified.rapporto) {
        this.isLoading = true;
        console.log("** Aggiornamento sezione Rapporto", this.localDataRapporto);
        const data = { rapporto: this.localDataRapporto };
        const response = await updateUserRapporto(this.paramsId, data);
        if (typeof response == "string" && response == "") {
          this.mixins_showMessage("Rapporto", "Dati salvati correttamente", "info");
          isUpdated = true;
        }
        this.isLoading = false;
        return isUpdated;
      }
      // Se non verificato o modificato correttamente, restituisco false
      return isUpdated;
    },

    async updateDataSkill() {
      let isUpdated = false;
      if (this.isModified.skill && this.isVerified.skill) {
        this.isLoading = true;
        console.log("** Aggiornamento sezione Skill", this.localDataSkill);
        const data = this.localDataSkill;
        const response = await updateUserSkill(this.paramsId, data);
        if (typeof response == "object") {
          // verifico se l'oggetto ha una chiave "error"
          if (Object.hasOwn(response, "error")) {
            console.error("** errore durante il salvataggio dello skill", response.error);
          } else {
            this.mixins_showMessage("Skill", "Dati salvati correttamente", "info");
            isUpdated = true;
          }
        }
        this.isLoading = false;
        return isUpdated;
      }
      // Se non verificato o modificato correttamente, restituisco false
      return isUpdated;
    },

    async updateDataDocumento() {
      let isUpdated = false;
      if (this.isModified.documenti && this.isVerified.documenti) {
        this.isLoading = true;
        console.log("** Aggiornamento sezione Documento", this.localDataDocumento);
        const data = this.localDataDocumento;
        const response = await updateUserDocumento(this.paramsId, data);
        if (typeof response == "string" && response == "") {
          this.mixins_showMessage("Documento", "Dati salvati correttamente", "info");
          isUpdated = true;
        }
        this.isLoading = false;
        return isUpdated;
      }
      // Se non verificato o modificato correttamente, restituisco false
      return isUpdated;
    },

    //
    // ** Gestione click tabs
    //
    showAnagraficaTab() {
      this.$refs.tabs.querySelector('a[href="#contentAnagrafica"]').click();
    },
    showRapportoTab() {
      this.$refs.tabs.querySelector('a[href="#contentRapporto"]').click();
    },
    showDotazioniTab() {
      this.$refs.tabs.querySelector('a[href="#contentDotazioni"]').click();
    },
    showAttivitaTab() {
      this.$refs.tabs.querySelector('a[href="#contentAttivita"]').click();
    },
    showCostTab() {
      this.$refs.tabs.querySelector('a[href="#contentCosti"]').click();
    },
    showSkillTab() {
      this.$refs.tabs.querySelector('a[href="#contentSkill"]').click();
    },
    showDocumentiTab() {
      this.$refs.tabs.querySelector('a[href="#contentDocumenti]').click();
    },
    showGruppiTab() {
      this.$refs.tabs.querySelector('a[href="#contentGruppi]').click();
    },
    showSicurezzaTab() {
      this.$refs.tabs.querySelector('a[href="#contentSicurezza]').click();
    },
    //
    // ** Altri metodi
    //
    backToList() {
      console.error(this.getCurrentPageTeams);
      this.$router.push({
        name: "route-teams",
        query: {
          ...this.$route.query, // Mantiene i filtri attuali
          page: this.getCurrentPageTeams,
        },
      });
    },
    // resetto le sezioni modificate
    resetSectionModified(sections = []) {
      sections.forEach((section) => {
        this.isModified[section] = false;
      });
    },

    // resetto le sezioni verificate
    resetSectionVerified(sections = []) {
      sections.forEach((section) => {
        this.isVerified[section] = false;
      });
    },

    // resetto i dati fetchati
    resetDataFetched(sections = []) {
      sections.forEach((section) => {
        this.isDataFetched[section] = false;
      });
    },
    switchToAttivita() {
      // Attiva il tab Attività
      this.activeTab = "contentAttivita";

      // Chiama il metodo per fetchare i dati, se disponibile
      if (this.$refs.userAttivita?.fetchDataAndUpdateFields) {
        this.$refs.userAttivita.fetchDataAndUpdateFields();
      }
    },
    //
    // Metodi controllo modifiche non salvate
    //
    hasUnsavedChanges() {
      // Verifico se ci sono modifiche non salvate
      return (
        this.isEditMode &&
        (this.isModified.anagrafica ||
          this.isModified.rapporto ||
          this.isModified.dotazioni ||
          this.isModified.attivita ||
          this.isModified.costi ||
          this.isModified.skill ||
          this.isModified.documenti ||
          this.isModified.gruppi)
      );
    },
    handleBeforeUnload(event) {
      // Gestore dell'evento beforeunload
      if (this.hasUnsavedChanges()) {
        event.preventDefault();
        event.returnValue = ""; // Questo è necessario per far funzionare il messaggio in alcuni browser
      }
    },
    handleBeforeRouteLeave(next) {
      // Gestione del beforeRouteLeave
      if (this.hasUnsavedChanges()) {
        this.$swal
          .fire({
            title: "Dati non salvati",
            html:
              "Ci sono dati non salvati. Sei sicuro di voler abbandonare le modifiche?",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: '<i class="fa fa-check"></i> Conferma',
            cancelButtonText: '<i class="fa fa-xmark"></i> Annulla',
          })
          .then((result) => {
            if (result.isConfirmed) {
              next();
            } else {
              next(false);
            }
          });
      } else {
        next();
      }
    },
  },
  //
  // Hooks
  //
  mounted() {
    console.log("** TeamsEdit montato");

    // Oggetto user da local storage
    this.userAuthenticated = this.mixin_getUserDataLS();

    this.resetSectionModified();
    this.resetSectionVerified();

    this.headerTitle = "Modifica";

    // Aggiungo l'evento beforeunload per intercettare la chiusura della pagina
    window.addEventListener("beforeunload", this.handleBeforeUnload);

    EventBus.on("switchToAttivita", this.switchToAttivita);
  },
  beforeUnmount() {
    // Rimuovo l'evento beforeunload quando il componente viene distrutto
    window.removeEventListener("beforeunload", this.handleBeforeUnload);

    EventBus.off("switchToAttivita", this.switchToAttivita);
  },
  beforeRouteLeave(to, from, next) {
    this.handleBeforeRouteLeave(next);
  },
};
</script>
