<template>
  <overlay-loading v-model:isLoading="isLoading"></overlay-loading>

  <!-- Seconda colonna -->
  <hr v-if="isRapportiniVisible" class="sepGray" />
  <!-- Filtri e Periodo da selezionare -->
  <div
    v-if="isRapportiniVisible"
    class="container-fluid align-items-center d-flex flex-row justify-content-between padding-x-4px m-0 p-0 mt-4"
  >
    <!-- Filtri -->
    <div class="align-items-start d-flex flex-column justify-content-center"></div>
    <div class="align-items-end d-flex flex-column justify-content-center">
      <!-- Selezione periodo -->
      <div class="d-flex py-2 m-0">
        <!-- Mese, visibile in vista giornaliera e mensile -->
        <div
          class="dropdown me-2"
          v-if="['giornaliera', 'mensile'].includes(visualizzazione)"
        >
          <button
            class="btn button-outline-1 small dropdown-toggle"
            type="button"
            id="dropdown_mese"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span>{{ selectedMese }}</span>
          </button>
          <ul class="dropdown-menu dropdown-menu-end">
            <li v-for="mese in mesi" :key="mese">
              <button
                class="dropdown-item"
                :class="{ active: selectedMese == mese }"
                type="button"
                @click="setMese(mese)"
              >
                {{ mese }}
              </button>
            </li>
          </ul>
        </div>

        <!-- Anno, visibile in tutte le viste tranne la settimanale -->
        <div class="dropdown me-2" v-if="visualizzazione !== 'settimanale'">
          <button
            class="btn button-outline-1 small dropdown-toggle"
            type="button"
            id="dropdown_anno"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span>{{ selectedAnno }}</span>
          </button>
          <ul class="dropdown-menu dropdown-menu-end">
            <li v-for="anno in anni" :key="anno">
              <button
                class="dropdown-item"
                :class="{ active: selectedAnno == anno }"
                type="button"
                @click="setAnno(anno)"
              >
                {{ anno }}
              </button>
            </li>
          </ul>
        </div>

        <!-- Bottone Vai, visibile in tutte le viste -->
        <button
          id="but_vai"
          ref="but_vai"
          type="button"
          class="btn button-outline-1 small"
          @click="onVai"
        >
          <i class="zmdi zmdi-long-arrow-return zmdi-hc-lg"></i> VAI
        </button>
      </div>
      <!-- DTC -->
      <div class="d-flex py-2 m-0">
        <div class="row g-2 align-items-center" style="width: 450px">
          <div class="col-lg-5 text-lg-end text-start">
            <label class="form-label"
              >DTC<br />(caricati: {{ referentiDTCOptions.length }} / totali:
              {{ referentiDTCOptions.length }})</label
            >
          </div>
          <div class="col-lg-7">
            <MultiSelect
              v-model="selectedReferentiDTC"
              :options="referentiDTCOptions"
              :searchable="true"
              placeholder="- SELEZIONA -"
              label="label"
              track-by="value"
              :show-no-results="true"
              selectLabel="Seleziona"
              deselectLabel="Rimuovi"
              selectedLabel="Selezionato"
              :openDirection="'below'"
              tagPlaceholder="Premi enter per creare un tag"
              :multiple="false"
              :taggable="false"
              :showLabels="false"
              :loading="isLoadingReferentiDTC"
              @search-change="asyncFetchReferentiDTC"
              @update:modelValue="onReferenteUpdate"
              :maxHeight="400"
            >
              <template #noOptions>
                <span>L'elenco è vuoto</span>
              </template>
              <template #noResult>
                <span>Nessun elemento trovato</span>
              </template>
            </MultiSelect>
          </div>
        </div>
        <div>
          <button
            type="button"
            class="btn button-outline-1 small"
            alt="AZZERA FILTRO DTC"
            title="AZZERA FILTRO DTC"
            @click="resetReferentiDTC()"
          >
            <i class="bi bi-x-lg m-0 p-0"></i>
          </button>
        </div>
      </div>

      <!-- Search container -->
      <div class="d-flex py-2 m-0">
        <!-- Search -->
        <div class="d-flex input-group" style="width: 250px">
          <input
            class="form-control"
            placeholder="CERCA..."
            type="search"
            v-model.trim="model_searchText"
            spellcheck="false"
            autocomplete="off"
            autocapitalize="none"
            @input="handleClear($event)"
            @keydown.enter="submitSearch()"
          />
          <button
            @click.prevent="submitSearch()"
            type="button"
            class="btn button-outline-1 small px-3 m-0"
          >
            <i class="bi bi-search p-0 m-0"></i>
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Table -->
  <div v-if="isRapportiniVisible" class="pt-1">
    <div class="container-fluid m-0 p-0 padding-x-4px">
      <h4 class="color-mainblue">
        Riepilogo Rapportini
        <span style="font-size: smaller"
          >({{ mixins_formatNumberWithLocale(totalRecordsRapportini) }}&nbsp;{{
            totalRecordsRapportini == 1 ? "elemento" : "elementi"
          }})</span
        >
      </h4>
    </div>
    <div v-if="rapportiniHasData" class="container-fluid m-0 p-0 padding-x-4px">
      <div class="row m-0 mb-3">
        <div class="scroll-x-container p-0">
          <table class="table-bordered table-responsive-md table-setup mb-2">
            <thead class="table-header-font">
              <tr class="td-center">
                <th>COGNOME E NOME</th>
                <th style="min-width: 400px !important; width: 370px !important">
                  STATO
                </th>
                <th style="min-width: 130px !important; width: 130px !important">
                  AZIONI
                </th>
              </tr>
            </thead>
            <tbody class="table-body-font td-vertical-center">
              <tr v-for="(rapportino, index) in localRapportini" :key="index">
                <td>{{ rapportino.user.fullname }}</td>
                <td class="td-center">
                  <ul class="list-tags" style="--type-display: flex; --justify: start">
                    <!-- Nessun rapportino -->
                    <li v-if="rapportino._id == ''">
                      <span
                        class="status-rapporto-warning"
                        title="NESSUN RAPPORTINO"
                        alt="NESSUN RAPPORTINO"
                        ><span>NESSUN RAPPORTINO</span></span
                      >
                    </li>
                    <!-- Rapportino aperto -->
                    <li v-else-if="!isRapportinoChiuso(rapportino)">
                      <span
                        class="status-rapporto-alert"
                        title="RAPPORTINO APERTO"
                        alt="RAPPORTINO APERTO"
                        ><span>RAPPORTINO APERTO</span></span
                      >
                    </li>
                    <!-- Rapportino chiuso e non validato -->
                    <template
                      v-else-if="
                        isRapportinoChiuso(rapportino) &&
                        !isRapportinoValidato(rapportino)
                      "
                    >
                      <li>
                        <span
                          class="status-rapporto-success"
                          title="RAPPORTINO CHIUSO"
                          alt="RAPPORTINO CHIUSO"
                          ><span>RAPPORTINO CHIUSO</span></span
                        >
                      </li>
                      <li>
                        <span
                          class="status-rapporto-alert"
                          title="RAPPORTINO NON VALIDATO"
                          alt="RAPPORTINO NON VALIDATO"
                          ><span>RAPPORTINO NON VALIDATO</span></span
                        >
                      </li>
                    </template>
                    <!-- Rapportino chiuso e  validato -->
                    <template
                      v-else-if="
                        isRapportinoChiuso(rapportino) && isRapportinoValidato(rapportino)
                      "
                    >
                      <li>
                        <span
                          class="status-rapporto-success"
                          title="RAPPORTINO CHIUSO"
                          alt="RAPPORTINO CHIUSO"
                          ><span>RAPPORTINO CHIUSO</span></span
                        >
                      </li>
                      <li>
                        <span
                          class="status-rapporto-success"
                          title="RAPPORTINO VALIDATO"
                          alt="RAPPORTINO VALIDATO"
                          ><span>RAPPORTINO VALIDATO</span></span
                        >
                      </li>
                    </template>
                  </ul>
                </td>
                <!-- Actions -->
                <td class="td-actions td-left">
                  <button
                    type="button"
                    class="btn icon-button"
                    alt="VAI AL RAPPORTINO DELL'UTENTE"
                    title="VAI AL RAPPORTINO DELL'UTENTE"
                    @click="routeUserTeam(rapportino)"
                    :disabled="isMe(rapportino)"
                  >
                    <i class="bi bi-person-fill"></i>
                  </button>
                  <button
                    v-if="
                      isRapportinoChiuso(rapportino) &&
                      (!isRapportinoValidato(rapportino) ||
                        isRapportinoValidato(rapportino))
                    "
                    type="button"
                    class="btn icon-button"
                    alt="RIAPRI RAPPORTINO"
                    title="RIAPRI RAPPORTINO"
                    @click="askRiapriRapportino(rapportino)"
                    :disabled="isAuthenticatedConsultazione"
                  >
                    <i class="bi bi-door-open-fill"></i>
                  </button>
                  <button
                    v-if="
                      isRapportinoChiuso(rapportino) && !isRapportinoValidato(rapportino)
                    "
                    type="button"
                    class="btn icon-button"
                    alt="VALIDA RAPPORTINO"
                    title="VALIDA RAPPORTINO"
                    @click="askValidaRapportino(rapportino)"
                    :disabled="isAuthenticatedConsultazione"
                  >
                    <i class="bi bi-check-circle-fill"></i>
                  </button>
                  <button
                    v-if="rapportino._id == '' || !isRapportinoChiuso(rapportino)"
                    type="button"
                    class="btn icon-button"
                    alt="SOLLECITA CHIUSURA"
                    title="SOLLECITA CHIUSURA"
                    @click="askSollecitaChiusuraRapportino(rapportino)"
                    :disabled="isAuthenticatedConsultazione"
                  >
                    <i class="bi bi-send-fill"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- Pagination tabella elenco rapportini -->
        <div v-if="localRapportini.length && totalPagesRapportini > 1">
          <div class="d-flex justify-content-end small mt-3">
            Pagina {{ pageRapportini }} / {{ totalPagesRapportini }}
          </div>
          <nav>
            <ul class="mt-3 pagination pagination-sm justify-content-end">
              <li class="page-item" :class="{ disabled: pageRapportini === 1 }">
                <a
                  class="page-link"
                  href="#"
                  tabindex="-1"
                  @click.prevent="prevPageRapportini"
                  >Precedente</a
                >
              </li>

              <li class="page-item" v-if="getVisiblePagesRapportini()[0] > 1">
                <a class="page-link" href="#" @click.prevent="goToPageRapportini(1)">1</a>
              </li>
              <li class="page-item" v-if="getVisiblePagesRapportini()[0] > 2">
                <span class="page-link">...</span>
              </li>

              <li
                v-for="page in getVisiblePagesRapportini()"
                :key="page"
                class="page-item"
                :class="{ active: page === pageRapportini }"
              >
                <a class="page-link" href="#" @click.prevent="goToPageRapportini(page)">{{
                  page
                }}</a>
              </li>

              <li
                class="page-item"
                v-if="getVisiblePagesRapportini().slice(-1)[0] < totalPagesRapportini - 1"
              >
                <span class="page-link">...</span>
              </li>
              <li
                class="page-item"
                v-if="getVisiblePagesRapportini().slice(-1)[0] < totalPagesRapportini"
              >
                <a
                  class="page-link"
                  href="#"
                  @click.prevent="goToPageRapportini(totalPagesRapportini)"
                  >{{ totalPagesRapportini }}</a
                >
              </li>

              <li
                class="page-item"
                :class="{ disabled: pageRapportini === totalPagesRapportini }"
              >
                <a class="page-link" href="#" @click.prevent="nextPageRapportini"
                  >Successiva</a
                >
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <div v-else class="container-fluid m-0 mt-3 p-0 padding-x-4px">
      <h5>Nessun dato da visualizzare</h5>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { UtilityMixins } from "@/mixins/UtilityMixins.js";
import {
  fetchAllRapportini,
  sendSollecitoChiusuraRapportino,
  validaRapportino,
  reopenRapportino,
} from "@/services/api";
import OverlayLoading from "@/components/ui/OverlayLoading.vue";
import ProfiliPermissions from "@/mixins/ProfiliPermissions.js";
import { ConstructorRapportinoTeam } from "@/models/ConstructorRapportinoTeam";

export default {
  mixins: [UtilityMixins, ProfiliPermissions],
  components: {
    OverlayLoading,
  },
  data() {
    return {
      isLoading: false, // Gestione del loader per indicare il caricamento
      visualizzazione: "mensile", // Vista di default, può essere 'giornaliera', 'settimanale', 'mensile', 'annuale'
      selectedGiorno: "", // Giorno selezionato
      selectedMese: "", // Mese selezionato
      selectedAnno: "", // Anno selezionato
      selectedSettimana: { display: "", start: "", end: "" }, // Settimana corrente selezionata
      giorni: [], // Lista dei giorni per il mese selezionato
      settimane: [], // Lista di settimane popolate dinamicamente
      mesi: [
        "Gennaio",
        "Febbraio",
        "Marzo",
        "Aprile",
        "Maggio",
        "Giugno",
        "Luglio",
        "Agosto",
        "Settembre",
        "Ottobre",
        "Novembre",
        "Dicembre",
      ],
      anni: [], // Lista degli anni
      intestazione: "", // Variabile intestazione da aggiornare
      userAuthenticated: null,
      model_searchText: "",
      isRapportiniVisible: false,
      // pagination rapportini
      pageRapportini: 1,
      limitRapportini: 50,
      totalPagesRapportini: 0,
      totalRecordsRapportini: 0,
      localRapportini: [],
      // multiselect Referenti DTC
      selectedReferentiDTC: [],
      referentiDTCOptions: [],
      isLoadingReferentiDTC: false,
      dtcId: "",
    };
  },
  computed: {
    ...mapGetters(["getViewsTypeAttivita"]),
    rapportiniHasData() {
      return this.localRapportini.length > 0;
    },
    //
    // Dialog
    //
    getUserData() {
      return this.userData;
    },
    getIsFilterFerie() {
      return this.isFilterFerie;
    },
    getDialogTitle() {
      return this.dialogTitle;
    },
    getIsEditMode() {
      return this.isEditMode;
    },
    //
    // Authenticated
    //
    isAuthenticatedSuperAdmin() {
      return this.permissions_isAuthenticatedSuperAdmin(this.userAuthenticated);
    },
    isAuthenticatedDTC() {
      return this.permissions_isAuthenticatedDTC(this.userAuthenticated);
    },
    isAuthenticatedGestionePersonale() {
      return this.permissions_isAuthenticatedGestionePersonale(this.userAuthenticated);
    },
    isAuthenticatedConsultazione() {
      return this.permissions_isAuthenticatedConsultazione(this.userAuthenticated);
    },
  },
  watch: {
    // Watch per il getter di Vuex che osserva il cambio del valore
    getViewsTypeAttivita(newValue) {
      console.log("** getViewsTypeAttivita", newValue);
      // this.visualizzazione = newValue; // Aggiorna la variabile locale
      this.visualizzazione = "mensile"; // Aggiorna la variabile locale

      // Aggiorno l'intestazione iniziale
      this.aggiornaIntestazione();

      // fetch api
      this.fetchDataRapportini();
    },
    // Watcher per il mese, anno e giorno selezionati
    selectedGiorno() {
      this.updateSettimane(); // Ripopola le settimane quando cambia il giorno
    },
    selectedMese() {
      this.updateSettimane(); // Ripopola le settimane quando cambia il mese
    },
    selectedAnno() {
      this.updateSettimane(); // Ripopola le settimane quando cambia l'anno
    },
  },

  methods: {
    //
    // Gestione impostazione periodo
    //
    // Funzione per impostare il giorno selezionato
    setGiorno(giorno) {
      this.selectedGiorno = giorno;
      // Aggiorno l'intestazione iniziale
      this.aggiornaIntestazione();
    },

    // Funzione per selezionare la settimana
    setSettimana(settimana) {
      this.selectedSettimana = settimana;

      // Verifica se il giorno corrente cade nell'intervallo della settimana selezionata
      const startDate = new Date(settimana.start);
      const endDate = new Date(settimana.end);
      const selectedDate = new Date(
        this.selectedAnno,
        this.mesi.indexOf(this.selectedMese),
        this.selectedGiorno
      );

      // Se il giorno selezionato non è nell'intervallo della settimana, aggiorna giorno e mese
      if (selectedDate < startDate || selectedDate > endDate) {
        this.selectedGiorno = startDate.getDate(); // Imposta il giorno al primo giorno della settimana
        this.selectedMese = this.mesi[startDate.getMonth()]; // Imposta il mese corretto
      }

      // Aggiorno l'intestazione iniziale
      this.aggiornaIntestazione();
    },

    // Funzione per impostare il mese selezionato e ripopolare i giorni
    setMese(mese) {
      this.selectedMese = mese;
      const meseIndex = this.mesi.indexOf(mese) + 1;
      this.populateGiorni(this.selectedAnno, meseIndex);

      if (this.selectedGiorno > this.giorni.length) {
        this.selectedGiorno = 1; // Se il giorno selezionato non esiste nel nuovo mese, seleziona il primo giorno
      }
      // Aggiorno l'intestazione iniziale
      this.aggiornaIntestazione();
    },

    // Funzione per impostare l'anno selezionato e ripopolare i giorni
    setAnno(anno) {
      this.selectedAnno = anno;
      const meseIndex = this.mesi.indexOf(this.selectedMese) + 1;
      this.populateGiorni(anno, meseIndex);

      if (this.selectedGiorno > this.giorni.length) {
        this.selectedGiorno = 1; // Se il giorno selezionato non esiste nel nuovo mese, seleziona il primo giorno
      }

      // Aggiorno l'intestazione iniziale
      this.aggiornaIntestazione();
    },

    // Popola gli anni dinamicamente dall'anno corrente al 2010
    populateAnni() {
      this.anni = this.mixins_getArrayYearsUpToCurrentYear();
    },

    // Popola i giorni del mese selezionato
    populateGiorni(anno, mese) {
      const daysInMonth = new Date(anno, mese, 0).getDate();
      this.giorni = Array.from({ length: daysInMonth }, (v, i) => i + 1);
    },

    // Funzione per ripopolare le settimane in base al giorno, mese e anno selezionati
    updateSettimane() {
      const date = new Date(
        this.selectedAnno,
        this.mesi.indexOf(this.selectedMese),
        this.selectedGiorno
      );
      const currentWeek = this.getStartOfWeek(date);
      this.settimane = this.calculateSurroundingWeeks(currentWeek, 5);
      this.selectedSettimana = this.settimane[5]; // Seleziona la settimana corrente
    },

    getStartOfWeek(date) {
      const day = date.getDay();
      const diff = date.getDate() - (day === 0 ? 6 : day - 1); // Ottieni il lunedì
      return new Date(date.setDate(diff));
    },
    // Calcolo settimane, 5 precedenti e 5 successive
    calculateSurroundingWeeks(startDate, range) {
      const weeks = [];
      const monday = new Date(startDate);

      // 5 settimane precedenti
      for (let i = range; i > 0; i--) {
        const previousMonday = new Date(monday);
        previousMonday.setDate(monday.getDate() - 7 * i);
        const sunday = new Date(previousMonday);
        sunday.setDate(previousMonday.getDate() + 6);
        weeks.push({
          display: `${this.formatDateForDisplay(
            previousMonday
          )} - ${this.formatDateForDisplay(sunday)}`,
          start: this.formatDate(previousMonday),
          end: this.formatDate(sunday),
        });
      }

      // Settimana corrente
      const currentMonday = new Date(monday);
      const currentSunday = new Date(currentMonday);
      currentSunday.setDate(currentMonday.getDate() + 6);
      weeks.push({
        display: `${this.formatDateForDisplay(
          currentMonday
        )} - ${this.formatDateForDisplay(currentSunday)}`,
        start: this.formatDate(currentMonday),
        end: this.formatDate(currentSunday),
      });

      // 5 settimane successive
      for (let i = 1; i <= range; i++) {
        const nextMonday = new Date(monday);
        nextMonday.setDate(monday.getDate() + 7 * i);
        const nextSunday = new Date(nextMonday);
        nextSunday.setDate(nextMonday.getDate() + 6);
        weeks.push({
          display: `${this.formatDateForDisplay(
            nextMonday
          )} - ${this.formatDateForDisplay(nextSunday)}`,
          start: this.formatDate(nextMonday),
          end: this.formatDate(nextSunday),
        });
      }

      return weeks;
    },
    composeDateRange() {
      let result;

      if (this.visualizzazione === "giornaliera") {
        const anno = this.selectedAnno;
        const mese = (this.mesi.indexOf(this.selectedMese) + 1)
          .toString()
          .padStart(2, "0");
        const giorno = this.selectedGiorno.toString().padStart(2, "0");
        result = {
          start: `${anno}-${mese}-${giorno}`,
          end: `${anno}-${mese}-${giorno}`,
        };
      } else if (this.visualizzazione === "settimanale") {
        result = {
          start: this.selectedSettimana.start,
          end: this.selectedSettimana.end,
        };
      } else if (this.visualizzazione === "mensile") {
        const anno = this.selectedAnno;
        const mese = (this.mesi.indexOf(this.selectedMese) + 1)
          .toString()
          .padStart(2, "0");
        const firstDay = `${anno}-${mese}-01`;
        const lastDay = `${anno}-${mese}-${new Date(anno, parseInt(mese), 0)
          .getDate()
          .toString()
          .padStart(2, "0")}`;
        result = {
          start: firstDay,
          end: lastDay,
        };
      } else if (this.visualizzazione === "annuale") {
        const anno = this.selectedAnno;
        result = {
          start: `${anno}-01-01`,
          end: `${anno}-12-31`,
        };
      }
      console.log("** composeDateRange", result);
      return result;
    },
    formatDateForDisplay(date) {
      const day = date.getDate().toString().padStart(2, "0");
      const month = this.mesi[date.getMonth()].substring(0, 3);
      return `${day} ${month}`;
    },

    formatDate(date) {
      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        date.getDate().toString().padStart(2, "0")
      );
    },

    aggiornaIntestazione() {
      if (this.visualizzazione === "giornaliera") {
        // Formato: "Giornaliero del 4 settembre 2024"
        this.intestazione = `Giornaliero del ${
          this.selectedGiorno
        } ${this.selectedMese.toLowerCase()} ${this.selectedAnno}`;
      } else if (this.visualizzazione === "settimanale") {
        // Formato: "Settimanale - 2 settembre 8 settembre 2024"
        const start = new Date(this.selectedSettimana.start);
        const end = new Date(this.selectedSettimana.end);
        this.intestazione = `Settimanale - ${start.getDate()} ${this.mesi[
          start.getMonth()
        ].toLowerCase()}, ${end.getDate()} ${this.mesi[end.getMonth()].toLowerCase()} ${
          this.selectedAnno
        }`;
      } else if (this.visualizzazione === "mensile") {
        // Formato: "Mensile - Settembre 2024"
        this.intestazione = `Mensile - ${this.selectedMese} ${this.selectedAnno}`;
      } else if (this.visualizzazione === "annuale") {
        // Formato: "Annuale - 2024"
        this.intestazione = `Annuale - ${this.selectedAnno}`;
      }
    },
    async onVai() {
      await this.fetchDataRapportini();
    },
    //
    // Fetch data
    //
    async fetchDataRapportini() {
      this.isRapportiniVisible = true;
      this.totalRecordsRapportini = 0;
      // Eseguo il fetch dei dati
      await this.fetchAllData();
    },
    async fetchAllData() {
      const { start: dateFrom, end: dateTo } = this.composeDateRange();
      console.log(dateFrom, dateTo);

      this.isLoading = true;

      this.localRapportini = [];

      try {
        const response = await fetchAllRapportini(
          dateFrom,
          dateTo,
          this.pageRapportini,
          this.limitRapportini,
          this.model_searchText,
          this.dtcId
        );
        if (response) {
          const data = response.data ? response.data : [];
          this.localRapportini = data.map((item) => new ConstructorRapportinoTeam(item));
          console.log("** localRapportini", this.localRapportini);
          const totalCount = response.totalCount ? response.totalCount : 0;
          this.totalRecordsRapportini = totalCount ? totalCount : 0;
          this.totalPagesRapportini = Math.ceil(totalCount / this.limitRapportini);
        }
      } catch (error) {
        this.mixins_showMessage(
          "Caricamento dati",
          `Errore durante il caricamento dei dati: ${error.message}`,
          "error"
        );
      } finally {
        this.isLoading = false;
      }
    },
    hideRapportini() {
      this.isRapportiniVisible = false;
    },
    //
    // Paginazione elenco rapportini
    //
    prevPageRapportini() {
      if (this.pageRapportini > 1) {
        this.pageRapportini--;
        this.fetchDataRapportini();
      }
    },
    nextPageRapportini() {
      if (this.pageRapportini < this.totalPagesRapportini) {
        this.pageRapportini++;
        this.fetchDataRapportini();
      }
    },
    goToPageRapportini(page) {
      if (page >= 1 && page <= this.totalPagesRapportini) {
        this.pageRapportini = page;
        this.fetchDataRapportini();
      }
    },
    getVisiblePagesRapportini() {
      const totalPages = this.totalPagesRapportini;
      const currentPage = this.pageRapportini;
      const maxPagesToShow = 6;
      let startPage, endPage;

      if (totalPages <= maxPagesToShow) {
        // Mostro tutte le pagine se sono 6 o meno
        startPage = 1;
        endPage = totalPages;
      } else {
        if (currentPage <= 3) {
          // Mostro le prime 6 pagine
          startPage = 1;
          endPage = maxPagesToShow;
        } else if (currentPage + 2 >= totalPages) {
          // Mostro le ultime 6 pagine
          startPage = totalPages - maxPagesToShow + 1;
          endPage = totalPages;
        } else {
          // Mostro la pagina corrente + le 2 precedenti e le 2 successive
          startPage = currentPage - 2;
          endPage = currentPage + 3;
        }
      }
      return [...Array(endPage + 1 - startPage).keys()].map((i) => startPage + i);
    },
    //
    // Search
    //
    handleClear(event) {
      if (!event.target.value.length) {
        console.log("** clearSearchResult");
        this.clearSearchResult();
      }
    },
    async clearSearchResult() {
      this.model_searchText = "";
      this.pageRapportini = 1;
      this.totalPagesRapportini = 0;
      this.totalRecordsRapportini = 0;
      await this.fetchAllData();
    },
    async submitSearch() {
      // rimuovo i caratteri *^? insieme ai tag HTML/XML per le query
      this.model_searchText = this.mixins_cleanTextForQuery(this.model_searchText);
      if (this.model_searchText.length < 2) {
        this.mixins_showMessage("Ricerca", "Digitare minimo 2 caratteri", "info");
        return;
      }
      this.pageRapportini = 1;
      await this.fetchAllData();
    },
    //
    // Status rapportini
    //
    isRapportinoChiuso(rapportino) {
      return rapportino.closed;
    },
    isRapportinoValidato(rapportino) {
      return rapportino.validated;
    },
    //
    // Actions rapportini
    //
    async askSollecitaChiusuraRapportino(rapportino) {
      if (this.isAuthenticatedConsultazione) {
        this.mixins_showMessage(
          "Permessi Insufficienti",
          "Non hai i permessi per eseguire questa azione",
          "error"
        );
        return;
      }
      this.$swal
        .fire({
          title: "Sollecito Chiusura Rapportino",
          html: `Confermi di voler inviare una email di sollecito a ${rapportino?.user?.fullname}?`,
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: '<i class="fa fa-check"></i> Conferma',
          cancelButtonText: '<i class="fa fa-xmark"></i> Annulla',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await this.sollecitaChiusuraRapportino(rapportino);
          }
        });
    },
    async sollecitaChiusuraRapportino(rapportino) {
      console.log("** sollecita chiusura rapportino");
      this.isLoading = true;

      try {
        const response = await sendSollecitoChiusuraRapportino(
          rapportino?.user?.id,
          this.mesi.indexOf(this.selectedMese) + 1,
          this.selectedAnno
        );

        console.log("** response sollecito chiusura", response);

        if (response && this.mixins_isEmptyObject(response)) {
          console.log("** rapportino sollecito inviato", response);
          this.mixins_showMessage(
            "Sollecito Chiusura Rapportino",
            "Email di sollecito inviata correttamente",
            "info"
          );
        }
      } catch (error) {
        this.mixins_showMessage(
          "Sollecito Chiusura Rapportino",
          "Errore durante l'invio della email di sollecito",
          "error"
        );
      } finally {
        this.isLoading = false;
      }
    },
    async askValidaRapportino(rapportino) {
      if (this.isAuthenticatedConsultazione) {
        this.mixins_showMessage(
          "Permessi Insufficienti",
          "Non hai i permessi per eseguire questa azione",
          "error"
        );
        return;
      }
      const { start: dateFrom, end: dateTo } = this.composeDateRange();
      const periodo = this.formatDateRange(dateFrom, dateTo);

      this.$swal
        .fire({
          title: "Validazione Rapportino",
          html: `<div style="color:#fb1212">Periodo ${periodo}</div><br>Confermi la validazione per questo periodo?`,
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: '<i class="fa fa-check"></i> Conferma',
          cancelButtonText: '<i class="fa fa-xmark"></i> Annulla',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await this.validaRapportino(rapportino, dateFrom, dateTo);
          }
        });
    },
    async validaRapportino(rapportino, dateFrom, dateTo) {
      console.log("** validazione rapportino", rapportino, dateFrom, dateTo);

      this.isLoading = true;

      try {
        const response = await validaRapportino(rapportino?.user?.id, dateFrom, dateTo);

        if (response && this.mixins_isEmptyObject(response)) {
          console.log("** rapportino validato", response);
          this.mixins_showMessage(
            "Validazione Rapportino",
            "Rapportino validato correttamente",
            "info"
          );
          await this.fetchAllData();
        }
      } catch (error) {
        this.mixins_showMessage(
          "Validazione Rapportino",
          `Errore durante la validazione del rapportino: ${error.message}`,
          "error"
        );
      } finally {
        this.isLoading = false;
      }
    },
    async askRiapriRapportino(rapportino) {
      if (this.isAuthenticatedConsultazione) {
        this.mixins_showMessage(
          "Permessi Insufficienti",
          "Non hai i permessi per eseguire questa azione",
          "error"
        );
        return;
      }
      const { start: dateFrom, end: dateTo } = this.composeDateRange();
      const periodo = this.formatDateRange(dateFrom, dateTo);

      this.$swal
        .fire({
          title: "Riapertura Rapportino",
          html: `<div style="color:#fb1212">Periodo ${periodo}</div><br>Confermi la riapertura per questo periodo?`,
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: '<i class="fa fa-check"></i> Conferma',
          cancelButtonText: '<i class="fa fa-xmark"></i> Annulla',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await this.apriRapportino(rapportino, dateFrom, dateTo);
          }
        });
    },
    async apriRapportino(rapportino, dateFrom, dateTo) {
      console.log("** riapertura rapportino", rapportino, dateFrom, dateTo);

      this.isLoading = true;

      try {
        const response = await reopenRapportino(rapportino?.user?.id, dateFrom, dateTo);

        if (response && this.mixins_isEmptyObject(response)) {
          console.log("** rapportino aperto", response);
          this.mixins_showMessage(
            "Riapertura Rapportino",
            "Rapportino riaperto correttamente",
            "info"
          );
          this.fetchAllData();
        }
      } catch (error) {
        this.mixins_showMessage(
          "Riapertura Rapportino",
          `Errore durante la riapertura del rapportino: ${error.message}`,
          "error"
        );
      } finally {
        this.isLoading = false;
      }
    },
    formatDateRange(dataFrom, dataTo) {
      const options = { month: "long", year: "numeric" };
      const fromDate = new Date(dataFrom);
      const toDate = new Date(dataTo);

      const dayFrom = fromDate.getDate();
      const dayTo = toDate.getDate();
      const monthYear = toDate.toLocaleDateString("it-IT", options);

      const formattedMonthYear = monthYear.charAt(0).toUpperCase() + monthYear.slice(1);

      return `dal ${dayFrom} al ${dayTo} ${formattedMonthYear}`;
    },
    routeUserTeam(rapportino) {
      if (rapportino?.user?.id) {
        const mese = (this.mesi.indexOf(this.selectedMese) + 1)
          .toString()
          .padStart(2, "0");
        const anno = this.selectedAnno;
        const routeData = this.$router.resolve({
          name: "route-teams-edit",
          params: { id: rapportino?.user?.id },
          query: { targetTab: "attivita", mese, anno },
        });
        window.open(routeData.href, "_blank");
      } else {
        this.mixins_showMessage(
          "Errore",
          `ID mancante per '${rapportino?.user?.fullname}'`,
          "error"
        );
      }
    },
    isMe(rapportino) {
      return this.userAuthenticated?.providerUserId == rapportino?.user?.id;
    },
    //
    // Gestione multiselect DTC
    //
    async resetReferentiDTC() {
      // if (
      //   this.selectedReferentiDTC &&
      //   Object.keys(this.selectedReferentiDTC).length === 0
      // ) {
      //   console.log("L'oggetto è vuoto");
      // } else if (this.selectedReferentiDTC) {
      //   console.log("L'oggetto NON è vuoto");
      // } else {
      //   console.log("L'oggetto è null o undefined");
      // }
      if (
        this.selectedReferentiDTC &&
        Object.keys(this.selectedReferentiDTC).length !== 0
      ) {
        this.selectedReferentiDTC = [];
        this.dtcId = "";
        await this.fetchAllData();
      }
    },
    async onReferenteUpdate(selected) {
      if (selected) {
        console.log(selected);
        this.dtcId = selected.value;
        await this.fetchAllData();
      } else {
        console.log(selected);
        this.dtcId = "";
        await this.fetchAllData();
      }
    },
    async asyncFetchReferentiDTC(query) {
      // rimuovo i caratteri *^? insieme ai tag HTML/XML per le query
      query = this.mixins_cleanTextForQuery(query);
      // Carico le opzioni dei referenti DTC trovati
      this.isLoadingReferentiDTC = true;
      const tabReferentiDTC = await this.mixins_getReferentiDTC(query, "rapporto");
      this.referentiDTCOptions = tabReferentiDTC.map((referente) => ({
        value: referente.providerUserId,
        label: referente.metadata.fullname,
      }));
      this.isLoadingReferentiDTC = false;
      console.log("** referentiDTCOptions", this.referentiDTCOptions);
    },
    async asyncLoadOptionsDTC() {
      if (this.isLoadingReferentiDTC) return; // Evita richieste multiple
      this.isLoadingReferentiDTC = true;
      try {
        // Carico le opzioni dei DTC
        const tabReferentiDTC = await this.mixins_getReferentiDTC("", "rapporto");
        this.referentiDTCOptions =
          tabReferentiDTC?.map((referente) => ({
            value: referente.providerUserId,
            label: referente.metadata.fullname,
          })) || [];
        this.isLoadingReferentiDTC = false;
      } catch (error) {
        console.error("Errore durante il caricamento dei dati:", error);
        this.isLoadingReferentiDTC = false;
      }
    },
  },
  async created() {
    // Oggetto user da local storage
    this.userAuthenticated = this.mixin_getUserDataLS();

    // Imposta variabili date
    const today = new Date();
    this.selectedGiorno = today.getDate();
    this.selectedMese = this.mesi[today.getMonth()];
    this.selectedAnno = today.getFullYear();

    // Popola giorni e settimane per la vista giornaliera e settimanale
    this.populateGiorni(today.getFullYear(), today.getMonth() + 1);
    this.updateSettimane(); // Popola le settimane inizialmente

    // Popola anni dall'anno corrente fino al 2010
    this.populateAnni();

    // Imposto la viasualizzazione corrente
    if (this.$route.path.includes("team")) {
      //Se mi trovo in /team allora la visualizzazione è solo mensile
      this.visualizzazione = "mensile";
    } else {
      this.visualizzazione = this.getViewsTypeAttivita;
    }

    // Aggiorno l'intestazione iniziale
    this.aggiornaIntestazione();

    // Carica i DTC
    this.isLoading = true;
    await this.asyncLoadOptionsDTC();
    this.isLoading = false;
  },
};
</script>

<style scoped>
.page-item {
  color: var(--main-blue) !important;
  background-color: var(--main-white) !important;
}
.page-item.disabled .page-link {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.6;
}
.page-item.active .page-link,
.page-item .page-link:hover {
  z-index: 3;
  color: white !important;
  background-color: var(--main-blue);
  border-color: var(--main-blue);
}
</style>
