import EventBus from "@/eventBus";
import { fetchTabelle, fetchTabellaById, fetchUtenti, fetchAziende, fetchTeam, fetchFornitori, fetchClienti, fetchFestivita, fetchUserRapporto, fetchCommesse } from "@/services/api";
import { Azienda } from "@/models/AziendaJoiModel";
import config from "@/config";

export const UtilityMixins = {
    methods: {
        //
        // Get Tabelle
        //
        async mixins_fetchAllTabelle() {
            try {
                // fetch di tutte le tabelle
                const response = await fetchTabelle();
                const tabelle = response.tabelle;
                const totalRecordsTabelle = response.totalCount;
                return { tabelle, totalRecordsTabelle }
            } catch (err) {
                console.error = "Si è verificato un errore durante il caricamento delle tabelle";
                return []
            }
        },
        mixins_getTabDotazionibySearch(tabelle) {
            const idTabDotazioni = "66a4eedd4f2fceff5d21ae8b"
            const findTabDotazioni = this.mixins_findClasseById(
                tabelle,
                idTabDotazioni
            );
            if (findTabDotazioni) return findTabDotazioni.sottoclassi;
            return [];
        },
        async mixins_getTabDotazioni() {
            const idTabDotazioni = "66a4eedd4f2fceff5d21ae8b"
            try {
                // fetch tabella dotazioni
                const response = await fetchTabellaById(idTabDotazioni);
                if (response.tabelle) {
                    const tabella = response.tabelle[0].sottoclassi;
                    return tabella
                }
                return []
            } catch (err) {
                console.error = "Si è verificato un errore durante il caricamento della tabella 'Dotazioni'";
                return []
            }
        },
        async mixins_getTabProfili() {
            const idTabProfili = "6683ad353a229567692449a9";
            try {
                // fetch tabella profili
                const response = await fetchTabellaById(idTabProfili);
                if (response.tabelle) {
                    const tabella = response.tabelle[0].sottoclassi;

                    // Ordino la tabella per il campo "nome" con distinzione tra maiuscole e minuscole (case sensitive)
                    tabella.sort((a, b) => a.nome.localeCompare(b.nome, undefined, { sensitivity: 'case' }));

                    return tabella;
                }
                return [];
            } catch (err) {
                console.error("Si è verificato un errore durante il caricamento della tabella 'Profili'");
                return [];
            }
        },
        async mixins_getTabRuoli() {
            const idTabRuoli = "66a253b2f4900c3a921cfc64";
            try {
                // fetch tabella ruoli
                const response = await fetchTabellaById(idTabRuoli);
                if (response.tabelle) {
                    const tabella = response.tabelle[0].sottoclassi;

                    // Ordino la tabella per il campo "nome" con distinzione tra maiuscole e minuscole (case sensitive)
                    tabella.sort((a, b) => a.nome.localeCompare(b.nome, undefined, { sensitivity: 'case' }));

                    return tabella;
                }
                return [];
            } catch (err) {
                console.error("Si è verificato un errore durante il caricamento della tabella 'Ruoli'");
                return [];
            }
        },
        async mixins_getTabTitolidistudio() {
            // Questa è una tabella fatta da me, bisogna prendere quella importata con tipologia = TITOLO_STUDIO
            const idTabTitolidistudio = "66c37339814152c39480f457"
            try {
                // fetch tabella titoli di studio
                const response = await fetchTabellaById(idTabTitolidistudio);
                if (response.tabelle) {
                    const tabella = response.tabelle[0].sottoclassi;
                    return tabella
                }
                return []
            } catch (err) {
                console.error = "Si è verificato un errore durante il caricamento della tabella 'Titoli di studio'";
                return []
            }
        },
        async mixins_getTabTipoDocumenti() {
            const idTabTipoDocumenti = "66cc2c6cde45777ea6e50c11"
            try {
                // fetch tabella tipo documenti
                const response = await fetchTabellaById(idTabTipoDocumenti);
                if (response.tabelle) {
                    const tabella = response.tabelle[0].sottoclassi;
                    // Ordina la tabella per la chiave 'nome'
                    tabella.sort((a, b) => a.nome.localeCompare(b.nome));
                    return tabella
                }
                return []
            } catch (err) {
                console.error = "Si è verificato un errore durante il caricamento della tabella 'Tipo Documenti'";
                return []
            }
        },
        async mixins_getTabSkillsData() {
            const idTabSkillsdata = "66b2521483a48dbb6de17c8d"
            try {
                // fetch tabella tipi di skill
                const response = await fetchTabellaById(idTabSkillsdata);
                if (response.tabelle) {
                    const tabella = response.tabelle[0].sottoclassi;
                    return tabella
                }
                return []
            } catch (err) {
                console.error = "Si è verificato un errore durante il caricamento della tabella degli skill";
                return []
            }
        },
        async mixins_getTabOreContrattuali() {
            const idTabOreContrattuali = "66c77fef1402b05c4c353afe"
            try {
                // fetch tabella ore contrattuali
                const response = await fetchTabellaById(idTabOreContrattuali);
                if (response.tabelle) {
                    const tabella = response.tabelle[0].sottoclassi;
                    return tabella
                }
                return []
            } catch (err) {
                console.error = "Si è verificato un errore durante il caricamento della tabella 'Ore contrattuali'";
                return []
            }
        },
        async mixins_getTabTipoAssenze() {
            const idTabTipoAssenze = "67040b50e0e40a1fe10919d7"
            try {
                // fetch tabella tipo assenze
                const response = await fetchTabellaById(idTabTipoAssenze);
                if (response.tabelle) {
                    const tabella = response.tabelle[0].sottoclassi;
                    return tabella
                }
                return []
            } catch (err) {
                console.error = "Si è verificato un errore durante il caricamento della tabella 'Tipo assenze'";
                return []
            }
        },
        async mixins_getTabAziende() {
            try {
                const response = await fetchAziende(1, 50, 2);

                // Controlla se response.aziende è definito e ha un array
                if (response?.aziende) {
                    const aziende = response.aziende.map((item) => new Azienda(item));
                    return aziende;
                }
                // Se non ci sono aziende, ritorna un array vuoto
                return [];
            } catch (err) {
                console.error("Si è verificato un errore:", err);
                return [];
            }
        },
        async mixins_getTabFestivita() {
            try {
                const response = await fetchFestivita();
                if (response.festivita) {
                    return response.festivita;
                }
                return [];
            } catch (err) {
                console.error = "Si è verificato un errore";
                return [];
            }
        },
        mixins_estraiSediUniche(datiAziende) {
            // Uso un Set per tenere traccia delle sedi uniche
            const sediUniche = new Set();

            // Itero su tutte le aziende
            datiAziende.forEach(azienda => {
                // Se l'azienda ha sedi, itero su di esse
                if (Array.isArray(azienda.sedi)) {
                    azienda.sedi.forEach(sede => {
                        // Aggiungo il nome della città al Set se esiste
                        if (sede.citta) {
                            sediUniche.add(sede.citta);
                        }
                    });
                }
            });

            // Converto il Set in un array di oggetti nel formato richiesto
            const sediUnicheArray = Array.from(sediUniche).map(citta => ({ sede: citta }));

            return sediUnicheArray;
        },

        async mixins_getFornitori(textsearch) {
            const page = 1
            const limit = 50
            const sortType = 2 //alfabetico
            try {
                // fetch fornintori con ricerca 
                const response = await fetchFornitori(page, limit, sortType, textsearch);
                if (response) {
                    return response.fornitori
                }
                return []
            } catch (err) {
                console.error = "Si è verificato un errore durante il caricamento dei fornmitori";
                return []
            }
        },
        async mixins_getClienti(textsearch) {
            const page = 1
            const limit = 50
            const sortType = 2 //alfabetico
            try {
                // fetch clienti con ricerca 
                const response = await fetchClienti(page, limit, sortType, textsearch);
                if (response) {
                    return response.clienti
                }
                return []
            } catch (err) {
                console.error = "Si è verificato un errore durante il caricamento dei clienti";
                return []
            }
        },
        async mixins_getCommesse(textsearch) {
            const page = 1;
            const limit = 50;
            const sortType = 2;
            const filtersIds = {};
            try {
                // fetch commesse con ricerca 
                const response = await fetchCommesse(page, limit, sortType, textsearch, filtersIds);
                if (response) {
                    return response.commesse
                }
                return []
            } catch (err) {
                console.error = "Si è verificato un errore durante il caricamento delle commesse";
                return []
            }
        },
        async mixins_getReferentiDTC(textsearch, rapporto) {
            const idProfiloDTC = "837d70ee-907f-4125-b0e9-89b394fb5971"
            const filtersIds = {
                "profili": [
                    idProfiloDTC
                ],
            }
            const sortType = 2 //alfabetico

            try {
                // fetch team con ricerca e filtro profilo dtc
                // il parametro rapporto se === "rapporto"
                const response = await fetchTeam({
                    filtersIds: filtersIds,
                    sortType: sortType,
                    textsearch: textsearch,
                    rapporto: rapporto,
                })
                if (response) {
                    return response.users
                }
                return []
            } catch (err) {
                console.error = "Si è verificato un errore durante il caricamento del team con profilo referente DTC";
                return []
            }
        },
        async mixins_getCommerciale(textsearch = "", rapporto = "") {
            const idProfiloCommerciale = "35e1dbb2-71c5-4ba6-b870-68c961e95aa6"
            const filtersIds = {
                "profili": [
                    idProfiloCommerciale
                ],
                cessati: "N",
            }
            const sortType = 2 //alfabetico

            try {
                // fetch team con ricerca e filtro profilo commerciale
                const response = await fetchTeam({
                    filtersIds: filtersIds,
                    sortType: sortType,
                    textsearch: textsearch,
                    rapporto: rapporto,
                });
                if (response) {
                    return response.users
                }
                return []
            } catch (err) {
                console.error = "Si è verificato un errore durante il caricamento del team con profilo commerciale";
                return []
            }
        },
        async mixins_getTeamUsers(textsearch, rapporto = "") {
            const filtersIds = {
                "profili": [],
                "aziende": [],
                "sedi": []
            }
            const sortType = 2 //alfabetico

            try {
                // fetch team con ricerca 
                const response = await fetchTeam({
                    filtersIds: filtersIds,
                    sortType: sortType,
                    textsearch: textsearch,
                    rapporto: rapporto,
                });
                if (response) {
                    return response.users
                }
                return []
            } catch (err) {
                console.error = "Si è verificato un errore durante il caricamento del team con profilo referente DTC";
                return []
            }
        },
        async mixins_getQuickRapporto(userid) {
            try {
                const response = await fetchUserRapporto(userid);
                if (response && response.rapporto) {
                    return response.rapporto
                }
                return null
            } catch (err) {
                console.error = "Si è verificato un errore durante fetchUserRapporto by userid";
                return null
            }
        },
        async mixins_getTabMercatoDiRiferimento() {
            const idTabMercatoDiRiferimento = "669e4a7df4900c3a921cfc63";
            try {
                // Fetch tabella mercato di riferimento
                const response = await fetchTabellaById(idTabMercatoDiRiferimento);
                if (response.tabelle) {
                    const tabella = response.tabelle[0].sottoclassi;

                    // Ordina la tabella per la chiave 'descrizione'
                    tabella.sort((a, b) => a.descrizione.localeCompare(b.descrizione));

                    return tabella;
                }
                return [];
            } catch (err) {
                console.error("Si è verificato un errore durante il caricamento della tabella 'SkillsMarketType'");
                return [];
            }
        },
        async mixins_getTabSkillsLevelType() {
            const idTabSkillsLevelType = "66c5a0ca96c0890a87b2b776";
            try {
                // Fetch tabella skills level type
                const response = await fetchTabellaById(idTabSkillsLevelType);
                if (response.tabelle) {
                    const tabella = response.tabelle[0].sottoclassi;
                    return tabella;
                }
                return [];
            } catch (err) {
                console.error("Si è verificato un errore durante il caricamento della tabella 'Skills Level Type'");
                return [];
            }
        },
        async mixins_getTabSkillsDataOneShot(specificType) {
            // Usare questo metodo facendo attenzione che ogni volta che viene invocato, viene caricata la tabella  di tutti gli skill
            // Raggruppo per tipo di skill e filtro per un tipo specifico
            const skillsData = await this.mixins_getTabSkillsData();
            const groupedSkillsDataByType = this.mixins_groupSottoclassiBySpecificType(
                skillsData,
                specificType
            );
            console.log(`** skills data grouped by ${specificType}`, groupedSkillsDataByType);
            return groupedSkillsDataByType.items
        },
        async mixins_getTabSkillsTypeTitoloDiStudio() {
            const specificType = "TITOLO_STUDIO";
            const responseArray = await this.mixins_getTabSkillsDataOneShot(specificType);
            console.log(`** tab skill ${specificType}`, responseArray)
            if (responseArray) {
                return responseArray
            }
            return null
        },
        // Funzione che raggruppa le sottoclassi (di una tabella) per type
        mixins_groupSottoclassiByType(sottoclassi) {
            // Utilizzo reduce per raggruppare le sottoclassi
            const grouped = sottoclassi.reduce((acc, item) => {
                // Trovo l'oggetto nel risultato finale che ha il tipo corrente
                let existingGroup = acc.find(group => group.type === item.type);

                if (!existingGroup) {
                    // Se non esiste, creo un nuovo gruppo e lo aggiungo all'accumulatore
                    existingGroup = { type: item.type, items: [] };
                    acc.push(existingGroup);
                }

                // Aggiungo l'elemento corrente alla lista di items del gruppo trovato o creato
                existingGroup.items.push({
                    id: item.id ?? "",
                    nome: item.nome ?? "",
                    descrizione: item.descrizione ?? "",
                    levelType: item.levelType ?? "",
                    levelCheck: item.levelCheck ?? 0
                })
                return acc;
            }, []);

            return grouped;
        },
        // Funzione che raggruppa le sottoclassi (di una tabella) per type che accetta un prarametro di type specifico
        mixins_groupSottoclassiBySpecificType(sottoclassi, specificType) {
            // Verifico che sottoclassi sia un array
            if (!Array.isArray(sottoclassi)) {
                console.error("Il parametro 'sottoclassi' non è un array", sottoclassi);
                return { type: specificType, items: [] };
            }

            // Filtro le sottoclassi per il tipo specifico
            const filteredSottoclassi = sottoclassi.filter(item => item.type === specificType);

            // Ordino le sottoclassi filtrate per descrizione
            const sortedSottoclassi = filteredSottoclassi.sort((a, b) =>
                a.descrizione.localeCompare(b.descrizione)
            );

            // Costruisco l'oggetto risultante
            return {
                type: specificType,
                items: sortedSottoclassi.map(item => ({
                    id: item.id ?? "",
                    nome: item.nome ?? "",
                    descrizione: item.descrizione ?? "",
                    levelType: item.levelType ?? "",
                    levelCheck: item.levelCheck ?? 0
                }))
            };
        },

        async mixins_getTabSkillsDataBySpecificType(specificType, skillsData = []) {
            // Raggruppo per tipo di skill e filtro per un tipo specifico
            const groupedSkillsDataByType = this.mixins_groupSottoclassiBySpecificType(
                skillsData,
                specificType
            );
            console.log(`** skills data grouped by ${specificType}`, groupedSkillsDataByType);
            return groupedSkillsDataByType.items
        },
        //
        // Fetch data
        //
        async mixins_fetchProvinceComuni() {
            try {
                const response = await fetch('/data/province_comuni_2024.json');
                if (response.ok) {
                    return await response.json();
                } else {
                    console.error('Errore nel caricamento del file JSON /data/province_comuni_2024.json');
                }
            } catch (error) {
                console.error('Errore nella richiesta /data/province_comuni_2024.json:', error);
            }
        },
        async mixins_fetchNazioni() {
            try {
                const response = await fetch('/data/nazioni_2024.json');
                if (response.ok) {
                    return await response.json();
                } else {
                    console.error('Errore nel caricamento del file JSON /data/nazioni_2024.json');
                }
            } catch (error) {
                console.error('Errore nella richiesta /data/nazioni_2024.json:', error);
            }
        },
        async mixins_fetchTipologiaCommesse() {
            try {
                const response = await fetch('/data/tipologia_commesse.json');
                if (response.ok) {
                    return await response.json();
                } else {
                    console.error('Errore nel caricamento del file JSON /data/tipologia_commesse.json');
                }
            } catch (error) {
                console.error('Errore nella richiesta /data/tipologia_commesse.json:', error);
            }
        },
        async mixins_fetchCentrocostoCommesse() {
            try {
                const response = await fetch('/data/centrocosto_commesse.json');
                if (response.ok) {
                    return await response.json();
                } else {
                    console.error('Errore nel caricamento del file JSON /data/centrocosto_commesse.json');
                }
            } catch (error) {
                console.error('Errore nella richiesta /data/centrocosto_commesse.json:', error);
            }
        },
        async mixins_fetchAllUsers(page, limit, textsearch) {
            try {
                // fetch di tutti gli utenti
                const response = await fetchUtenti(page, limit, textsearch);
                const utenti = response.users;
                const totalRecordsUtenti = response.totalCount;
                return { utenti, totalRecordsUtenti }
            } catch (err) {
                console.error = "Si è verificato un errore durante il caricamento degli utenti";
                return null
            }
        },
        //
        // Altri metodi
        //
        mixins_showMessage(title, text, type) {
            EventBus.emit("showMessage", { title, text, type });
        },
        mixins_isValidJSON(data) {
            try {
                JSON.parse(JSON.stringify(data));
                return true;
            } catch (error) {
                console.error("Invalid JSON:", error)
                return false;
            }
        },
        mixins_showLoader(context) {
            if (context && context.$loading) {
                context.$loadingInstance = context.$loading.show();
            } else {
                console.warn("Loader non può essere mostrato perché '$loading' non è definito o non è pronto.");
            }
        },
        mixins_hideLoader(context) {
            if (context && context.$loadingInstance) {
                context.$loadingInstance.hide();
            } else {
                console.warn("Loader non può essere nascosto perché '$loadingInstance' non è definito o non è pronto.");
            }
        },

        mixins_sleep(milliseconds) {
            return new Promise(resolve => setTimeout(resolve, milliseconds));
        },
        mixins_formatCurrency(value) {
            // Assicuriamoci che il valore sia un numero e tronchiamo i decimali a 2 cifre
            let num = parseFloat(value).toFixed(2);
            // Separare la parte intera e la parte decimale
            let parts = num.split(".");
            // Formattare la parte intera con il separatore delle migliaia
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            // Unire le parti e aggiungere il simbolo €
            return "€ " + parts.join(",");
        },
        mixins_findClasseById(tabelleJson, id) {
            return tabelleJson.find(tabella => tabella._id === id);
        },
        mixins_getYearsFrom(fromYear) {
            const currentYear = new Date().getFullYear();
            const years = [];

            for (let year = fromYear; year <= currentYear; year++) {
                years.push(year);
            }

            return years;
        },
        mixins_minYear() {
            return 2010
        },
        // Restituisce un range di anni dall'anno corrente al 2010
        mixins_getArrayYearsUpToCurrentYear() {
            const currentYear = new Date().getFullYear();
            const endYear = currentYear + 1; // Aggiungi un anno in più
            const startYear = this.mixins_minYear();
            return Array.from({ length: endYear - startYear + 1 }, (v, i) => endYear - i);
        },
        // mixins_getArrayYearsUpToCurrentYear() {
        //     const currentYear = new Date().getFullYear();
        //     return Array.from({ length: currentYear - (this.mixins_minYear() - 1) }, (v, i) => currentYear - i);
        // },
        // mixins_getLocalDate(isoDateString) {
        //     if (!isoDateString) return "";

        //     const date = new Date(isoDateString);
        //     if (isNaN(date.getTime())) return "";

        //     const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        //     return date.toLocaleDateString(undefined, options);
        // },
        mixins_getLocalDate(isoDateString, isToday = false) {

            isoDateString = this.mixins_checkDateMinyear(isoDateString)

            if (!isoDateString) return "-";

            const date = new Date(isoDateString);
            if (isNaN(date.getTime())) return "-";

            if (isToday) {
                const currentDate = new Date();
                currentDate.setHours(0, 0, 0, 0); // Imposta l'ora a mezzanotte per la data corrente
                date.setHours(0, 0, 0, 0); // Imposta l'ora a mezzanotte per la data passata

                if (date.getTime() === currentDate.getTime()) {
                    return "Oggi";
                }
            }

            // Restituisco la data nel formato italiano GG/MM/AAAA
            return date.toLocaleDateString("it-IT", {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            });
        },
        mixins_checkDateMinyear(data) {
            const date = new Date(data);
            const year = date.getFullYear();

            // Controlla se l'anno è maggiore o uguale a 2010
            if (year >= this.mixins_minYear()) {
                return data; // Ritorna la data nel formato originale
            } else {
                return null; // Ritorna null se l'anno è inferiore a 2010
            }
        },
        mixins_getLocalDateTime(isoDateString) {
            if (!isoDateString) return "";

            const date = new Date(isoDateString);
            if (isNaN(date.getTime())) return "";

            const dateOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
            const timeOptions = {
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hourCycle: 'h23'  // Configura il ciclo dell'ora basato sul formato del locale
            };

            // Ottieni il formato dell'ora basato sul locale
            const locale = undefined; // Utilizza il locale predefinito dell'utente
            const localDate = date.toLocaleDateString(locale, dateOptions);
            let localTime = date.toLocaleTimeString(locale, timeOptions);

            // Verifica se il locale utilizza il formato a 12 ore
            if (localTime.includes('AM') || localTime.includes('PM')) {
                timeOptions.hourCycle = 'h12';
                localTime = date.toLocaleTimeString(locale, timeOptions);
            }

            return `${localDate} - ${localTime}`;
        },
        // Funzione per verificare se un oggetto è vuoto
        mixins_isEmptyObject(obj) {
            return Object.keys(obj).length === 0;
        },
        // Funzione per verificare se un oggetto contiene una determinata chiave
        mixins_objectHasKey(obj, key) {
            return Object.prototype.hasOwnProperty.call(obj, key);
        },
        // Funzione per rimuovere i caratteri *^? insieme ai tag HTML/XML per le query
        mixins_cleanTextForQuery(inputText) {
            return inputText.replace(/<[^>]*>?|[*^?]/gm, '');
        },
        // Funzione che converte una dimensione in byte in una forma leggibile in KB, MB, GB, ecc., usando sia il sistema di prefissi decimali (KB, MB, GB) sia il sistema di prefissi binari (KiB, MiB, GiB)
        mixins_formatFileSize(bytes, useBinary = false) {
            const thresh = useBinary ? 1024 : 1000;

            if (Math.abs(bytes) < thresh) {
                return bytes + ' Bytes';
            }

            const units = useBinary
                ? ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
                : ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

            let unitIndex = -1;
            const r = 2; // Precisione decimale

            do {
                bytes /= thresh;
                unitIndex++;
            } while (Math.abs(bytes) >= thresh && unitIndex < units.length - 1);

            return bytes.toFixed(r) + ' ' + units[unitIndex];
        },
        mixins_formatNumberWithLocale(value, decimalPrecision = 0, locale = 'it-IT') {
            try {
                // Converto il valore in numero, se possibile
                const numberValue = parseFloat(value);
                // Controllo se il risultato della conversione è un numero valido
                if (isNaN(numberValue)) {
                    throw new Error('Il valore fornito non è un numero valido =', value);
                }
                // Imposto le opzioni di formattazione
                const options = {
                    minimumFractionDigits: decimalPrecision,
                    maximumFractionDigits: decimalPrecision
                };
                // Formatto il numero con il locale specificato e la precisione decimale
                return numberValue.toLocaleString(locale, options);
            } catch (error) {
                // console.error('Errore durante la formattazione del numero =', value);
                // Restituisco un valore di fallback
                return '0';
            }
        },
        mixin_getUserDataLS() {
            // Recupero il valore dal localStorage
            const userJson = localStorage.getItem(config.sessionDataLS);
            // Controllo se esiste il dato nel localStorage
            if (userJson) {
                // Parso il JSON in un oggetto JavaScript
                const userData = JSON.parse(userJson);

                // Restituisco l'oggetto userData
                return userData;
            } else {
                // Se non esiste, restituisco null o un messaggio di errore
                return null;
            }
        },
        mixin_formatBytes(bytes, si = true, dp = 2) {
            // Controllo dei casi limite
            if (isNaN(bytes) || bytes === 0) return "0 Bytes";
            if (bytes === 1) return "1 Byte";

            const thresh = si ? 1000 : 1024;
            const units = si
                ? ["KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
                : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
            let u = -1;

            // Divisione iterativa dei byte fino a trovare l'unità corretta
            while (Math.abs(bytes) >= thresh && u < units.length - 1) {
                bytes /= thresh;
                ++u;
            }

            // Se non ci sono unità corrispondenti, ritorna in Bytes
            if (u === -1) {
                return bytes.toFixed(dp) + " Bytes";
            }

            return bytes.toFixed(dp).toLocaleString() + " " + units[u];
        },
        mixins_generaPassword() {
            const MIN_PASSWORD_LENGTH = 8; // Lunghezza minima della password
            const MAX_PASSWORD_LENGTH = 12; // Lunghezza massima della password

            const lettereMaiuscole = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            const lettereMinuscole = 'abcdefghijklmnopqrstuvwxyz';
            const numeri = '0123456789';
            const caratteriSpeciali = '!@#$%&*'; // Sottoinsieme sicuro di caratteri speciali
            const tuttiICaratteri = lettereMaiuscole + lettereMinuscole + numeri + caratteriSpeciali;

            let password = '';

            // Aggiungo almeno un carattere maiuscolo, minuscolo, numero e un carattere speciale
            password += lettereMaiuscole.charAt(Math.floor(Math.random() * lettereMaiuscole.length));
            password += lettereMinuscole.charAt(Math.floor(Math.random() * lettereMinuscole.length));
            password += numeri.charAt(Math.floor(Math.random() * numeri.length));
            password += caratteriSpeciali.charAt(Math.floor(Math.random() * caratteriSpeciali.length));

            // Determino una lunghezza casuale tra MIN_PASSWORD_LENGTH e MAX_PASSWORD_LENGTH
            const passwordLength = Math.floor(Math.random() * (MAX_PASSWORD_LENGTH - MIN_PASSWORD_LENGTH + 1)) + MIN_PASSWORD_LENGTH;

            // Genero i rimanenti caratteri casuali per raggiungere la lunghezza desiderata
            for (let i = 4; i < passwordLength; i++) {
                password += tuttiICaratteri.charAt(Math.floor(Math.random() * tuttiICaratteri.length));
            }

            // Mischio la password per evitare che i primi quattro caratteri siano sempre maiuscola, minuscola, numero e carattere speciale in quell'ordine
            password = password.split('').sort(() => Math.random() - 0.5).join('');

            return password;
        },
        mixins_controllaPassword(password) {
            // La regex richiede almeno:
            // - una lettera maiuscola
            // - una lettera minuscola
            // - un numero
            // - un carattere speciale da "!@#$%&*"
            // - min 8 caratteri e max 12 caratteri
            const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%&*])[A-Za-z\d!@#$%&*]{8,12}$/;

            return regex.test(password);
        },
        mixins_controllaPasswordMsg(password) {
            let messaggiErrore = [];

            // Controllo della lunghezza (min 8 e max 12 caratteri)
            if (password.length < 8 || password.length > 12) {
                messaggiErrore.push('La "NUOVA PASSWORD" deve essere lunga tra 8 e 12 caratteri');
            }

            // Controllo almeno una lettera maiuscola
            if (!/[A-Z]/.test(password)) {
                messaggiErrore.push('La "NUOVA PASSWORD" deve contenere almeno una lettera maiuscola');
            }

            // Controllo almeno una lettera minuscola
            if (!/[a-z]/.test(password)) {
                messaggiErrore.push('La "NUOVA PASSWORD" deve contenere almeno una lettera minuscola');
            }

            // Controllo almeno un numero
            if (!/\d/.test(password)) {
                messaggiErrore.push('La "NUOVA PASSWORD" deve contenere almeno un numero');
            }

            // Controllo almeno un carattere speciale
            if (!/[!@#$%&*]/.test(password)) {
                messaggiErrore.push('La "NUOVA PASSWORD" deve contenere almeno uno tra questi caratteri speciali !@#$%&*');
            }

            // Se non ci sono messaggi di errore, la password è valida
            if (messaggiErrore.length === 0) {
                return { isValid: true, messages: ['La "NUOVA PASSWORD" è valida'] };
            } else {
                return { isValid: false, messages: messaggiErrore };
            }
        },
        mixins_initTeamsQueryFilters() {
            return {
                profili: '',
                aziende: '',
                sedi: '',
                cessati: '',
                mercato: '',
                tipologia: '',
                tiposkill: '',
                livello: '',
                labels: [],
            }
        },
        mixins_initCommesseQueryFilters() {
            return {
                status: '',
                tipologia: '',
                societa: '',
                commerciale: '',
                cliente: '',
                labels: [],
            }
        },
        mixins_convertDateToYYYYMMDD(isoDateString) {
            // Verifica che la stringa non sia null o undefined
            if (!isoDateString) {
                return null;
            }

            // Verifica che la stringa sia in formato ISO 8601
            const isoDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?(?:Z|[+-]\d{2}:\d{2})$/;

            if (!isoDateRegex.test(isoDateString)) {
                return null;
            }

            // Prova a creare l'oggetto Date
            const date = new Date(isoDateString);

            // Verifica che la data sia valida
            if (isNaN(date.getTime())) {
                return null;
            }

            // Estrae anno, mese e giorno
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // I mesi partono da 0, quindi aggiungi 1
            const day = String(date.getDate()).padStart(2, '0');

            // Restituisce la data nel formato AAAA-MM-GG
            return `${year}-${month}-${day}`;
        },
        mixins_isValidDateRange(from, to) {
            // Verifico che entrambe le date non siano null o undefined
            if (!from || !to) {
                return { isValid: false, message: "Date non valide" };
            }

            // Verifico che entrambe le date siano in formato ISO 8601
            const isoDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?(?:Z|[+-]\d{2}:\d{2})$/;

            if (!isoDateRegex.test(from) || !isoDateRegex.test(to)) {
                return { isValid: false, message: "Date non valide" };
            }

            // Creo gli oggetti Date
            const fromDate = new Date(from);
            const toDate = new Date(to);

            // Verifico che entrambe le date siano valide
            if (isNaN(fromDate.getTime()) || isNaN(toDate.getTime())) {
                return { isValid: false, message: "Date non valide" };
            }

            // Imposto le date alla mezzanotte per ignorare l'orario
            fromDate.setHours(0, 0, 0, 0);
            toDate.setHours(0, 0, 0, 0);

            // Controllo se la data 'to' è antecedente alla data 'from'
            if (toDate < fromDate) {
                return { isValid: false, message: "La data finale non può essere antecedente alla data iniziale" };
            }

            // Calcolo la differenza in millisecondi e la converto in giorni
            const differenceInTime = toDate.getTime() - fromDate.getTime();
            const differenceInDays = differenceInTime / (1000 * 3600 * 24); // Converto millisecondi in giorni

            // Restituisco true se la differenza è >= 0 giorni
            if (differenceInDays >= 0) {
                return { isValid: true, message: "" };
            } else {
                return { isValid: false, message: "Date non valide" };
            }
        }



    }

};