<template>
  <overlay-loading v-model:isLoading="isLoading"></overlay-loading>
  <div class="col-sm-12 main-col">
    <div class="row p-0">
      <div class="align-items-center d-flex flex-row justify-content-between m-0 p-0 row">
        <div class="col-sm-10 p-0">
          <div
            class="align-items-center bg-light d-flex header-text justify-content-start p-0 w-100"
          >
            <h1>
              GESTIONE FESTIVITÀ
              <span class="text-black small ms-2">
                <i class="bi bi-brightness-alt-high-fill"></i
                ><span class="ms-2">{{ `Anno ${selectedYear}` }}</span>
                <span class="small ms-2">
                  ({{ totalElements }} {{ elementsLabel }})
                </span>
              </span>
            </h1>
          </div>
        </div>
        <div class="col-sm-2 p-0">
          <!-- Search -->
          <search-button
            :searchText="searchQuery"
            @txtToSearch="filterTextToSearch"
            @clearTxtToSearch="filterClearTextToSearch"
            v-if="!loading"
          />
        </div>
      </div>
      <!-- Content -->
      <div
        class="container-fluid m-0 p-2 background-gray overflow-auto container-contents"
      >
        <!-- Linear Loader -->
        <div v-if="loading" class="linear-loader">
          <span></span>
        </div>
        <!-- Seleziona anno / Aggiungi nuova festività -->
        <div
          class="w-100 mt-2 padding-x-4px align-items-center d-flex justify-content-between"
        >
          <div class="d-flex">
            <div class="dropdown">
              <button
                class="btn button-outline-1 small dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span>{{ selectedYear }}</span>
              </button>
              <ul class="dropdown-menu dropdown-menu-end">
                <li v-for="year in yearDropdownOptions" :key="year">
                  <button
                    class="dropdown-item"
                    :class="{ active: selectedYear == year }"
                    type="button"
                    @click="selectYear(year)"
                  >
                    {{ year }}
                  </button>
                </li>
              </ul>
            </div>
            <div class="ms-5 align-items-center d-flex justify-content-lg-end">
              <label class="col-form-label form-label d-inline-flex text-end me-2"
                ><span>NASCONDI SABATI E DOMENICHE</span>
              </label>
              <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  type="checkbox"
                  :checked="checkboxHideSabDom === true"
                  @change="toggleHidSabDom"
                />
                <label class="content-form text-end label-yes-no">{{
                  checkboxHideSabDom === true ? "SI" : "NO"
                }}</label>
              </div>
            </div>
          </div>
          <button
            @click="addFestivita()"
            type="button"
            class="btn button-outline-1 small"
          >
            <i class="zmdi zmdi-plus zmdi-hc-lg"></i> Aggiungi Nuova
          </button>
        </div>
        <hr class="sepGray mt-3" />
        <!-- No data -->
        <div v-if="dataFestivitaFiltered.length === 0 && !loading">
          <h5 class="mt-3 padding-x-4px">Nessun dato da visualizzare</h5>
        </div>
        <!-- Data -->
        <div v-if="dataFestivitaFiltered.length > 0 && !loading">
          <!-- Table Data -->
          <div class="mt-3 mb-5">
            <div class="row m-0 padding-x-4px">
              <table class="table-bordered table-responsive-md table-setup mb-2">
                <thead class="table-header-font">
                  <tr class="td-center">
                    <th style="min-width: 200px !important">FESTIVITÀ</th>
                    <th style="min-width: 160px !important; width: 160px !important">
                      DATA
                    </th>
                    <th style="min-width: 160px !important; width: 160px !important">
                      GIORNO DELLA SETTIMANA
                    </th>
                    <th style="min-width: 160px !important; width: 160px !important">
                      LOCALE
                    </th>
                    <th style="min-width: 100px !important; width: 100px !important">
                      AZIONI
                    </th>
                  </tr>
                </thead>
                <tbody class="table-body-font td-vertical-center">
                  <tr v-for="holiday in dataFestivitaFiltered" :key="holiday._id">
                    <td>{{ holiday.descrizione }}</td>
                    <!-- Formattare la data nel formato "Giorno Mese Anno (in stringhe) come l'esempio qui sotto" -->
                    <!-- <td class="td-center">20 Dicembre 2024</td> -->
                    <td class="td-center">{{ formattaData(holiday.data) }}</td>
                    <!-- Calcolare il giorno della settimana -->
                    <td class="td-center">
                      <ul
                        v-if="giornoDellaSettimana(holiday.data) !== ''"
                        class="list-tags"
                      >
                        <li>
                          <span
                            class="badge-colored"
                            :class="
                              !isSabDom(holiday.data) == 0 || !isSabDom(holiday.data) == 6
                                ? 'giorno-sabdom'
                                : 'giorno-feriale'
                            "
                          >
                            {{ giornoDellaSettimana(holiday.data) }}
                          </span>
                        </li>
                      </ul>
                    </td>
                    <td class="td-center">
                      {{
                        holiday.locale && (holiday.citta !== null || holiday.citta !== "")
                          ? holiday.citta
                          : ""
                      }}
                    </td>
                    <!-- Actions -->
                    <td class="td-actions td-center">
                      <button
                        alt="MODIFICA"
                        title="MODIFICA"
                        class="btn icon-button"
                        @click="editFestivita(holiday)"
                      >
                        <i class="zmdi zmdi-edit zmdi-hc-lg"></i>
                      </button>
                      <button
                        class="delete btn icon-button"
                        alt="ELIMINA"
                        title="ELIMINA"
                        @click="askDeleteHoliday(holiday)"
                      >
                        <i class="zmdi zmdi-delete zmdi-hc-lg"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <DialogAddEditFestivita
    :isVisible="isDialogVisible"
    :dialogWidth="'780px'"
    :dialogTitle="getDialogTitle"
    :selectedYear="selectedYear"
    :dataItem="dataHoliday"
    @closeModal="closeModal"
    @saveData="saveData"
    :isEditMode="getIsEditMode"
  />
</template>

<script>
import SearchButton from "@/components/ui/SearchButton.vue";
import { UtilityMixins } from "@/mixins/UtilityMixins.js";
import OverlayLoading from "@/components/ui/OverlayLoading.vue";
import {
  fetchFestivita,
  updateFestivita,
  addFestivita,
  deleteFestivita,
} from "@/services/api";
import DialogAddEditFestivita from "@/components/dialogs/DialogAddEditFestivita.vue";

class HolidayObject {
  constructor(data = {}) {
    this._id = data._id || "";
    this.data = data.data || "";
    this.descrizione = data.descrizione || "";
    this.locale = data.locale || false;
    this.citta = data.citta || null;
  }
}

export default {
  mixins: [UtilityMixins],
  components: {
    OverlayLoading,
    SearchButton,
    DialogAddEditFestivita,
  },
  props: {
    loading: Boolean,
    currentSortType: Number, // Ordinamento passato dalla View
  },
  data() {
    return {
      isEditMode: false,
      isLoading: false,
      festivitaFromServer: [], // Dati caricati dal server
      selectedYear: new Date().getFullYear(), // Anno selezionato
      checkboxHideSabDom: false,
      isSearching: false,
      searchQuery: "",
      // dialog
      isDialogVisible: false,
      dialogTitle: "",
      dataHoliday: new HolidayObject(),
    };
  },
  computed: {
    dataFestivitaFiltered() {
      let dataFestivita = this.festivitaFromServer.filter(
        (holiday) => new Date(holiday.data).getFullYear() === this.selectedYear
      );

      // Filtra per checkbox (nascondi sabati e domeniche)
      if (this.checkboxHideSabDom) {
        dataFestivita = dataFestivita.filter(
          (holiday) => !this.isSabDom(new Date(holiday.data))
        );
      }

      // Filtra per query di ricerca (solo nella descrizione)
      if (this.searchQuery) {
        const query = this.searchQuery.toLowerCase();
        dataFestivita = dataFestivita.filter((holiday) =>
          holiday.descrizione.toLowerCase().includes(query)
        );
      }

      // Ordinamento
      if (this.currentSortType === 1) {
        dataFestivita.sort((a, b) => a.descrizione.localeCompare(b.descrizione));
      } else if (this.currentSortType === 2) {
        dataFestivita.sort((a, b) => b.descrizione.localeCompare(a.descrizione));
      } else if (this.currentSortType === 3) {
        dataFestivita.sort((a, b) => new Date(a.data) - new Date(b.data));
      } else if (this.currentSortType === 4) {
        dataFestivita.sort((a, b) => new Date(b.data) - new Date(a.data));
      }

      return dataFestivita;
    },
    yearDropdownOptions() {
      const currentYear = new Date().getFullYear();
      return [currentYear + 2, currentYear + 1, currentYear, currentYear - 1];
    },
    totalElements() {
      return this.dataFestivitaFiltered.length;
    },
    elementsLabel() {
      return this.totalElements === 1 ? "elemento" : "elementi";
    },
    getDialogTitle() {
      return this.dialogTitle;
    },
    getIsEditMode() {
      return this.isEditMode;
    },
  },
  methods: {
    async getAllFestivita() {
      try {
        this.isLoading = true;
        const response = await fetchFestivita();
        const data = response?.festivita || [];
        this.festivitaFromServer = data.map((item) => ({
          ...item,
          data: new Date(item.data),
        }));
      } catch (error) {
        console.error("Errore nel caricamento delle festività", error);
      } finally {
        this.isLoading = false;
      }
    },
    formattaData(date) {
      const months = [
        "Gennaio",
        "Febbraio",
        "Marzo",
        "Aprile",
        "Maggio",
        "Giugno",
        "Luglio",
        "Agosto",
        "Settembre",
        "Ottobre",
        "Novembre",
        "Dicembre",
      ];
      const d = new Date(date);
      const day = d.getDate();
      const month = months[d.getMonth()];
      const year = d.getFullYear();
      return `${day} ${month} ${year}`;
    },
    toggleHidSabDom(event) {
      this.checkboxHideSabDom = event.target.checked;
    },
    giornoDellaSettimana(date) {
      const giorniSettimana = [
        "Domenica",
        "Lunedì",
        "Martedì",
        "Mercoledì",
        "Giovedì",
        "Venerdì",
        "Sabato",
      ];
      return giorniSettimana[new Date(date).getDay()];
    },
    isSabDom(date) {
      const giorno = new Date(date).getDay();
      return giorno === 0 || giorno === 6; // Domenica (0) o Sabato (6)
    },
    selectYear(year) {
      this.selectedYear = year;
    },
    filterTextToSearch(text) {
      this.searchQuery = text;
    },
    filterClearTextToSearch() {
      this.searchQuery = "";
    },
    //
    // Gestione dialog
    //
    closeModal() {
      this.isDialogVisible = false;
    },
    addFestivita() {
      this.isEditMode = false;
      this.dialogTitle = `NUOVA FESTIVITÀ (Anno ${this.selectedYear})`;
      this.dataHoliday = new HolidayObject();
      this.isDialogVisible = true;
    },
    editFestivita(holiday) {
      console.log("** edit festivita", holiday);
      this.isEditMode = true;
      this.dialogTitle = `MODIFICA FESTIVITÀ (Anno ${this.selectedYear})`;
      this.dataHoliday = new HolidayObject(holiday);
      this.isDialogVisible = true;
    },
    isISODate(date) {
      // RegEx per verificare se una stringa è in formato ISO
      const isoRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?Z$/;
      return typeof date === "string" && isoRegex.test(date);
    },
    generateUniqueId() {
      const generateId = () => {
        // Genera un ID casuale di 24 caratteri esadecimali, simile a "66b2521183a48dbb6de17a27"
        return [...Array(24)]
          .map(() => Math.floor(Math.random() * 16).toString(16))
          .join("");
      };

      let newId;

      do {
        newId = generateId();
      } while (
        // Controlla che l'ID non esista in festivitaFromServer
        this.festivitaFromServer.some((festivita) => festivita._id === newId)
      );

      return newId;
    },
    async saveData(dataRecived) {
      // Controllo e conversione per la proprietà "citta"
      if (dataRecived.citta && dataRecived.citta.value) {
        dataRecived.citta = dataRecived.citta.value; // Sostituisce l'oggetto "citta" con il valore della proprietà "value"
      } else {
        dataRecived.citta = null; // Imposta `citta` su null se è assente o non valida
      }

      // Controllo e conversione per la proprietà "data"
      if (dataRecived.data && !this.isISODate(dataRecived.data)) {
        const parsedDate = new Date(dataRecived.data);
        if (!isNaN(parsedDate)) {
          dataRecived.data = parsedDate.toISOString(); // Converte la data in formato ISO
        } else {
          console.warn("** La data fornita non è valida:", dataRecived.data);
        }
      }

      console.log("** Dati aggiornati ricevuti da DialogAddEditFestivita:", dataRecived);
      this.closeModal();

      // Salvo i dati
      this.isLoading = true;

      if (this.isEditMode) {
        if (this.updateFestivitaLocal(dataRecived)) {
          try {
            const apiResponse = await updateFestivita(dataRecived._id, dataRecived);
            console.log("** update festività", apiResponse);
            const msg = "Festività modificata correttamente";
            if (apiResponse != undefined) {
              this.mixins_showMessage("Gestione Festività", msg, "info");
            }
          } catch (error) {
            console.error("Errore durante il salvataggio dei dati", error);
          } finally {
            this.isLoading = false;
            await this.getAllFestivita();
          }
        }
      } else {
        // dataRecived._id =
        //   dataRecived._id === "" ? this.generateUniqueId() : dataRecived._id;
        // this.dataFestivitaFiltered.push(dataRecived);
        try {
          const apiResponse = await addFestivita(dataRecived);
          console.log("** add festività", apiResponse);
          const msg = "Festività aggiunta correttamente";
          if (apiResponse != undefined) {
            this.mixins_showMessage("Gestione Festività", msg, "info");
          }
        } catch (error) {
          console.error("Errore durante il salvataggio dei dati", error);
        } finally {
          this.isLoading = false;
          await this.getAllFestivita();
        }
      }
    },
    updateFestivitaLocal(dataRecived) {
      const updatedData = dataRecived;

      // Trovo l'indice dell'oggetto con lo stesso _id
      const index = this.dataFestivitaFiltered.findIndex(
        (festivita) => festivita._id === updatedData._id
      );

      // Se l'oggetto è trovato, aggiorno solo i campi esistenti in `updatedData`
      if (index !== -1) {
        this.dataFestivitaFiltered[index] = {
          ...this.dataFestivitaFiltered[index], // Mantengo i campi esistenti
          ...updatedData, // Sovrascrivo solo i campi presenti in updatedData
        };
        return true;
      } else {
        console.warn("ID non trovato in dataFestivitaFiltered");
        return false;
      }
    },
    async askDeleteHoliday(festivita) {
      this.$swal
        .fire({
          title: "Gestione Festività",
          html: "Sei sicuro di voler cancellare lo festività?",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: '<i class="fa fa-check"></i> Conferma',
          cancelButtonText: '<i class="fa fa-xmark"></i> Annulla',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await this.removeFestivita(festivita._id);
          }
        });
    },
    async removeFestivita(id) {
      this.isLoading = true;
      try {
        const apiResponse = await deleteFestivita(id);
        console.log("** delete festivita", apiResponse);
        if (apiResponse != undefined) {
          this.mixins_showMessage(
            "Gestione Festività",
            "Festività cancellata correttamente",
            "info"
          );
        }
      } catch (error) {
        console.error("Errore durante la cancellazione dei dati", error);
      } finally {
        this.isLoading = false;
        await this.getAllFestivita();
      }
    },
  },
  async mounted() {
    await this.getAllFestivita();
  },
};
</script>
