<template>
  <div class="col-4 sidebar">
    <nav class="main-left-nav" role="navigation">
      <!-- Sort -->
      <div>
        <ul class="unstyled nav-parent-item">
          <li>
            <a
              href="#"
              class="collapsible"
              data-bs-toggle="collapse"
              data-bs-target="#menuOrdinamento"
              >Ordinamento</a
            >
            <div id="menuOrdinamento" class="collapse show">
              <ul class="unstyled nav-parent-item">
                <li
                  v-for="item in sortOrderList"
                  :key="item.label"
                  @click="updateSort(item.value)"
                >
                  <a href="#" :class="{ active: activeSortType === item.value }">
                    {{ item.label }}
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  emits: ["update-sort"],
  data() {
    return {
      activeSortType: 3, // Default: Data Crescente
      sortOrderList: [
        { label: "Alfabetico A-Z", value: 1 },
        { label: "Alfabetico Z-A", value: 2 },
        { label: "Data Crescente", value: 3 },
        { label: "Data Decrescente", value: 4 },
      ],
    };
  },
  methods: {
    updateSort(sortType) {
      this.activeSortType = sortType;
      this.$emit("update-sort", sortType);
    },
  },
};
</script>
