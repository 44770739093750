<template>
  <div class="col-sm-12 main-col">
    <div class="row p-0">
      <div class="align-items-center d-flex flex-row justify-content-between m-0 p-0 row">
        <div class="col-sm-10 p-0">
          <div
            class="align-items-center bg-light d-flex header-text justify-content-start p-0 w-100"
          >
            <h1>
              GESTIONE AZIENDE
              <span class="text-black small">
                <span class="mx-3 single-list">
                  <i class="fa-solid fa-city fa-fw"></i>
                </span>
                <span>{{ headerTitle }}</span>
              </span>
            </h1>
          </div>
        </div>
      </div>
      <div
        class="container-fluid m-0 p-2 background-gray overflow-auto container-contents"
      >
        <!-- Loading -->
        <div v-if="loading" class="linear-loader"><span></span></div>
        <!-- Error message -->
        <div v-if="errorMessage" class="alert alert-danger">{{ errorMessage }}</div>

        <div v-if="azienda && !errorMessage && !loading">
          <div class="row m-0 padding-x-4px">
            <div class="col-md-12 p-0 mt-4">
              <h4 class="text-black m-0 p-0">
                <span v-html="azienda.ragionesociale || blank"></span>
              </h4>
            </div>
            <hr class="sepGray" />
          </div>
          <div class="row m-0 padding-x-4px" v-if="azienda">
            <ul class="nav nav-tabs video-tabs" ref="tabs">
              <li class="nav-item">
                <a
                  href="#contentAnagrafica"
                  class="nav-link d-inline-flex active"
                  data-bs-toggle="tab"
                >
                  ANAGRAFICA
                  <span class="ms-2 change-data" v-if="isModified.anagrafica"></span>
                </a>
              </li>
              <li v-if="isEditMode" class="nav-item">
                <a
                  href="#contentSedi"
                  class="nav-link d-inline-flex"
                  data-bs-toggle="tab"
                >
                  SEDI
                  <span class="ms-2 change-data" v-if="isModified.sedi"></span>
                </a>
              </li>
            </ul>
            <div class="tab-content p-0">
              <div class="tab-pane fade show active" id="contentAnagrafica">
                <div class="row m-0 pt-4">
                  <div class="col-lg-3 mb-3 p-0 text-center">
                    <img
                      style="border: 1px solid var(--main-blue)"
                      :src="
                        azienda.logo
                          ? azienda.logo
                          : require('@/assets/images/logo-placeholder.jpg')
                      "
                      @error="
                        $event.target.src = require('@/assets/images/logo-placeholder.jpg')
                      "
                      class="img-fluid w-100 mb-3"
                    />
                    <div>
                      <button
                        type="button"
                        class="btn icon-button me-2"
                        alt="CARICA IMMAGINE"
                        title="CARICA IMMAGINE"
                        @click="uploadImage"
                        :disabled="!isEditMode"
                      >
                        <i class="bi bi-image"></i>
                      </button>
                      <button
                        type="button"
                        class="btn icon-button me-2"
                        alt="RIMUOVI IMMAGINE"
                        title="RIMUOVI IMMAGINE"
                        @click="removeImage"
                        :disabled="!isEditMode || azienda.logo === ''"
                      >
                        <i class="bi bi-trash"></i>
                      </button>
                      <input
                        type="file"
                        ref="fileInput"
                        @change="onFileChange"
                        accept="image/*"
                        style="display: none"
                      />
                    </div>
                  </div>
                  <div class="col-lg-9 p-0 ps-lg-4">
                    <div class="row mb-1">
                      <label
                        class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
                      >
                        <span>RAGIONE SOCIALE</span>
                      </label>
                      <div
                        class="align-items-center col-lg-10 d-flex justify-content-center"
                      >
                        <input
                          type="text"
                          v-input-textarea
                          class="form-control"
                          id="inputRagioneSociale"
                          v-model.trim="azienda.ragionesociale"
                          @input="markAsModified('anagrafica')"
                          spellcheck="false"
                          autocomplete="off"
                          autocapitalize="none"
                          placeholder="RAGIONE SOCIALE"
                        />
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label
                        class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
                      >
                        <span>DESCRIZIONE</span>
                      </label>
                      <div
                        class="align-items-center col-lg-10 d-flex justify-content-center"
                      >
                        <input
                          type="text"
                          v-input-textarea
                          class="form-control"
                          id="inputDescrizione"
                          v-model.trim="azienda.descrizione"
                          @input="markAsModified('anagrafica')"
                          spellcheck="false"
                          autocomplete="off"
                          autocapitalize="none"
                          placeholder="DESCRIZIONE"
                        />
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label
                        class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
                      >
                        <span>IDENTIFICATIVO NAV</span>
                      </label>
                      <div
                        class="align-items-center col-lg-10 d-flex justify-content-center"
                      >
                        <input
                          type="text"
                          v-input-textarea
                          class="form-control"
                          id="inputIdentificativoNav"
                          v-model.trim="azienda.identificativonav"
                          @input="markAsModified('anagrafica')"
                          spellcheck="false"
                          autocomplete="off"
                          autocapitalize="none"
                          placeholder="IDENTIFICATIVO NAV"
                        />
                      </div>
                    </div>
                    <div class="row mb-1">
                      <label
                        class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
                      >
                        <span>CODICE PAGHE</span>
                      </label>
                      <div
                        class="align-items-center col-lg-10 d-flex justify-content-center"
                      >
                        <input
                          type="number"
                          inputmode="numeric"
                          pattern="[0-9]*"
                          class="form-control no-spin"
                          id="inputCodicePaghe"
                          v-model="azienda.codicepaghe"
                          @input="markAsModified('anagrafica')"
                          placeholder="CODICE PAGHE"
                        />
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label
                        class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
                      >
                        <span>CODICE AZIENDA</span>
                      </label>
                      <div
                        class="align-items-center col-lg-10 d-flex justify-content-center"
                      >
                        <input
                          type="text"
                          v-input-textarea
                          class="form-control"
                          id="inputCodiceAzienda"
                          v-model.trim="azienda.codiceazienda"
                          @input="markAsModified('anagrafica')"
                          spellcheck="false"
                          autocomplete="off"
                          autocapitalize="none"
                          placeholder="CODICE AZIENDA"
                        />
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label
                        class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
                      >
                        <span>GIORNI DI FERIE</span>
                      </label>
                      <div
                        class="align-items-center col-lg-10 d-flex justify-content-center"
                      >
                        <input
                          type="number"
                          inputmode="numeric"
                          pattern="[0-9]*"
                          class="form-control no-spin"
                          id="inputGiorniFerie"
                          v-model="azienda.giorniFerie"
                          @input="markAsModified('anagrafica')"
                          placeholder="GIORNI DI FERIE"
                        />
                      </div>
                    </div>
                    <!-- Errors model -->
                    <div v-if="errorsModel" class="mb-4">
                      <div
                        v-for="(errorModel, key) in errorsModel"
                        :key="key"
                        class="text-danger small mt-1"
                      >
                        {{ errorModel }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="contentSedi">
                <div
                  class="align-items-center container-fluid d-flex flex-row justify-content-end py-4 px-0"
                >
                  <button
                    @click="openAddSede"
                    type="button"
                    class="btn button-outline-1 small"
                  >
                    <span><i class="zmdi zmdi-plus"></i>Aggiungi Sede</span>
                  </button>
                </div>
                <!-- No data -->
                <div v-if="azienda.sedi.length == 0">
                  <h5 class="mt-1">Nessun dato da visualizzare</h5>
                </div>
                <div v-if="azienda.sedi.length > 0" class="container-fluid m-0 p-0 pt-4">
                  <div class="row m-0">
                    <table class="table-bordered table-responsive-md table-setup">
                      <thead class="table-header-font">
                        <tr class="td-center">
                          <th>SEDE</th>
                          <th>INDIRIZZO</th>
                          <th width="70">CAP</th>
                          <th>CITTÀ</th>
                          <th width="60">PROV</th>
                          <th width="80">CODICE FILIALE</th>
                          <th
                            style="min-width: 100px !important; width: 100px !important"
                          >
                            AZIONI
                          </th>
                        </tr>
                      </thead>
                      <tbody class="table-body-font td-vertical-center">
                        <tr v-for="(sede, index) in azienda.sedi" :key="sede.id">
                          <td>{{ sede.sede }}</td>
                          <td>{{ sede.indirizzo }}</td>
                          <td class="td-center">{{ sede.cap }}</td>
                          <td>{{ sede.citta }}</td>
                          <td class="td-center">{{ sede.prov }}</td>
                          <td class="td-center">{{ sede.codicefiliale }}</td>
                          <td class="td-actions td-center">
                            <button
                              alt="MODIFICA"
                              title="MODIFICA"
                              @click="editSede(sede, index)"
                            >
                              <i class="zmdi zmdi-edit zmdi-hc-lg"></i>
                            </button>
                            <button
                              class="delete"
                              alt="ELIMINA"
                              title="ELIMINA"
                              @click="removeSede(index)"
                            >
                              <i class="zmdi zmdi-delete zmdi-hc-lg"></i>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <hr class="sepGray" />
            <div class="container-fluid p-0 my-3">
              <button
                @click="backToList()"
                type="button"
                class="btn button-outline-1 float-start"
              >
                <span><i class="zmdi zmdi-arrow-left"></i>Torna alla lista</span>
              </button>
              <button
                type="button"
                class="btn button-outline-1 float-end"
                :class="{ notSaved: isModified.anagrafica || isModified.sedi }"
                @click="saveAzienda"
              >
                <span
                  ><i class="zmdi zmdi-floppy"></i
                  >{{ isEditMode ? "Aggiorna i dati" : "Salva i dati" }}</span
                >
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <DialogAddEditSede
    :isVisible="isDialogVisible"
    :dialogWidth="'780px'"
    :dialogTitle="dialogTitle"
    :dataItem="selectedSede"
    :index="selectedIndex"
    @closeModal="closeModal"
    @saveSede="saveSede"
  />
</template>

<script>
import { AziendaSchema, SedeSchema, Azienda, Sede } from "@/models/AziendaJoiModel";
import {
  fetchAziendaById,
  createAzienda,
  updateAzienda,
  uploadLogoAzienda,
  deleteLogoAzienda,
} from "@/services/api";
import { mapGetters } from "vuex";
import DialogAddEditSede from "@/components/dialogs/DialogAddEditSede.vue";
import { UtilityMixins } from "@/mixins/UtilityMixins.js";

export default {
  mixins: [UtilityMixins],
  components: {
    DialogAddEditSede,
  },
  data() {
    return {
      azienda: new Azienda(),
      loading: false,
      errorsModel: null,
      errorMessage: null,
      isEditMode: false,
      isModified: {
        anagrafica: false,
        sedi: false,
      },
      headerTitle: "",
      blank: `&nbsp;`,
      // dialog
      isDialogVisible: false,
      dialogTitle: "",
      selectedSede: {},
      selectedIndex: null,
    };
  },
  computed: {
    ...mapGetters(["getCurrentPageAziende"]),
  },
  methods: {
    async fetchAzienda() {
      this.loading = true;
      try {
        const response = await fetchAziendaById(this.$route.params.id);
        if (response) {
          this.azienda = new Azienda(response);
          this.isEditMode = true;
          this.headerTitle = "Modifica";
        } else {
          this.errorMessage = "L'elemento non esiste";
        }
      } catch (error) {
        // console.error("Errore durante il caricamento dei dati", error);
        this.errorMessage = "Errore durante il caricamento dei dati";
      } finally {
        this.loading = false;
      }
    },

    async saveAzienda() {
      // controllo che ci siano dati da salvare
      if (!this.hasUnsavedChanges()) return;

      // valido i campi
      const { error, value } = AziendaSchema.validate(this.azienda, {
        abortEarly: false,
      });
      if (error) {
        this.errorsModel = error.details.reduce((acc, err) => {
          acc[err.path.join(".")] = err.message;
          return acc;
        }, {});
        this.showAnagraficaTab();
        return;
      }

      // salvo i dati
      this.loading = true;
      try {
        if (this.isEditMode) {
          await updateAzienda(this.azienda._id, value);
          this.mixins_showMessage(
            "Aggiornamento dati",
            "Dati salvati correttamente",
            "info"
          );
        } else {
          const newAzienda = await createAzienda(value);
          this.azienda = newAzienda;
          this.isEditMode = true;
          this.headerTitle = "Modifica";
          this.$router.replace({
            name: "route-aziende-edit",
            params: { id: newAzienda._id },
          });
        }
        this.isModified.anagrafica = false;
        this.isModified.sedi = false;
        this.errorsModel = null;
      } catch (error) {
        console.error("Errore durante il salvataggio dei dati", error);
        this.errorMessage = "Errore durante il salvataggio dei dati";
      } finally {
        this.loading = false;
      }
    },
    showAnagraficaTab() {
      this.$refs.tabs.querySelector('a[href="#contentAnagrafica"]').click();
    },
    markAsModified(section) {
      this.isModified[section] = true;
    },
    backToList() {
      this.$router.push({
        name: "route-aziende",
        query: { page: this.getCurrentPageAziende },
      });
    },
    openAddSede() {
      this.dialogTitle = "CREA NUOVA SEDE";
      this.selectedSede = new Sede();
      this.selectedIndex = null;
      this.isDialogVisible = true;
    },
    editSede(sede, index) {
      this.dialogTitle = "MODIFICA SEDE";
      this.selectedSede = { ...sede }; // Crea una copia dell'oggetto sede
      console.log("** this.selectedSede", this.selectedSede);
      this.selectedIndex = index;
      this.isDialogVisible = true;
    },
    closeModal() {
      this.isDialogVisible = false;
    },
    saveSede(sede, index) {
      const { error, value } = SedeSchema.validate(sede);
      if (error) {
        // Handle validation errors
        console.log(error, value);
        return;
      }
      if (index !== null) {
        this.azienda.sedi[index] = value;
      } else {
        this.azienda.sedi.push(value);
      }
      this.markAsModified("sedi");
      this.closeModal();
    },
    removeSede(index) {
      this.azienda.sedi.splice(index, 1);
      this.markAsModified("sedi");
    },
    async uploadImage() {
      this.$refs.fileInput.click();
    },
    async onFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.loading = true;
        try {
          const response = await uploadLogoAzienda(this.azienda._id, file);
          if (response && response.url) {
            this.azienda.logo = response.url;
          }
        } catch (error) {
          console.error("Errore durante il caricamento del logo", error);
          this.errorMessage = "Errore durante il caricamento del logo";
        } finally {
          this.loading = false;
        }
      }
    },
    async removeImage() {
      if (!this.azienda.logo) return;
      this.loading = true;
      try {
        const response = await deleteLogoAzienda(this.azienda._id);
        if (response) {
          this.azienda.logo = "";
        }
      } catch (error) {
        console.error("Errore durante la cancellazione del logo", error);
        this.errorMessage = "Errore durante la cancellazione del logo";
      } finally {
        this.loading = false;
      }
    },
    //
    // Metodi controllo modifiche non salvate
    //
    hasUnsavedChanges() {
      // Verifico se ci sono modifiche non salvate
      return this.isModified.anagrafica || this.isModified.sedi;
    },
    handleBeforeUnload(event) {
      // Gestore dell'evento beforeunload
      if (this.hasUnsavedChanges()) {
        event.preventDefault();
        event.returnValue = ""; // Questo è necessario per far funzionare il messaggio in alcuni browser
      }
    },
    handleBeforeRouteLeave(next) {
      // Gestione del beforeRouteLeave
      if (this.hasUnsavedChanges()) {
        this.$swal
          .fire({
            title: "Dati non salvati",
            html:
              "Ci sono dati non salvati. Sei sicuro di voler abbandonare le modifiche?",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: '<i class="fa fa-check"></i> Conferma',
            cancelButtonText: '<i class="fa fa-xmark"></i> Annulla',
          })
          .then((result) => {
            if (result.isConfirmed) {
              next();
            } else {
              next(false);
            }
          });
      } else {
        next();
      }
    },
  },
  //
  // Hooks
  //
  async created() {
    if (this.$route.params.id) {
      await this.fetchAzienda();
    } else {
      this.azienda = new Azienda();
      this.isEditMode = false;
      this.headerTitle = "Crea Nuovo";
    }
    console.log("Azienda dopo created:", this.azienda); // Debug
  },
  mounted() {
    // Aggiungo l'evento beforeunload per intercettare la chiusura della pagina
    window.addEventListener("beforeunload", this.handleBeforeUnload);
  },
  beforeUnmount() {
    // Rimuovo l'evento beforeunload quando il componente viene distrutto
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  },
  beforeRouteLeave(to, from, next) {
    this.handleBeforeRouteLeave(next);
  },
};
</script>
