<template>
  <transition name="backdrop-modal">
    <div v-if="isVisible" class="backdrop-dialog"></div>
  </transition>

  <transition name="modal">
    <div class="dialog background-gray" :style="`width: ${dialogWidth}`" v-if="isVisible">
      <div class="container-fluid">
        <div class="row">
          <div class="header-text w-100">
            <h1>{{ dialogTitle }}</h1>
          </div>
        </div>
        <hr class="sepGray" />
        <div class="container-fluid p-0 m-0">
          <!-- <div v-if="!isProfiloEsterno" class="row m-0"> -->
          <div class="row m-0">
            <div class="col-4 p-0 text-center small">
              Ore imputabili:
              <span>{{
                mixins_formatNumberWithLocale(localData.info.oreImputabili, 1)
              }}</span>
            </div>
            <div class="col-4 p-0 text-center small">
              Ore Straordinario Mensili Consentite:
              <span>{{
                mixins_formatNumberWithLocale(localData.info.oreStrConsentite, 1)
              }}</span>
            </div>
            <div class="col-4 p-0 text-center small">
              Straordinario Residuo:
              <span class="p-1" :class="{ danger: exceededFlags.isStrResiduoExceeded }">
                {{ mixins_formatNumberWithLocale(localData.info.strResiduo, 1) }}
              </span>
            </div>
          </div>
          <div class="container-fluid px-0 py-3">
            <div class="scroll-x-container p-0">
              <table class="table-bordered table-responsive-md table-rapportino mb-2">
                <!-- Intestazione se turnista e il giorno è una domenica o una festività -->
                <thead
                  v-if="
                    userRapporto?.turnista &&
                    (localData.day.isDomenica || localData.day.isFestivita)
                  "
                  class="table-header-font"
                >
                  <tr class="td-center">
                    <th>COMMESSA</th>
                    <th style="min-width: 130px !important; width: 130px !important">
                      ORDINARIO
                    </th>
                    <th style="min-width: 130px !important; width: 130px !important">
                      ORDINARIO FESTIVO
                    </th>
                    <th style="min-width: 130px !important; width: 130px !important">
                      ORDINARIO NOTTURNO
                    </th>
                    <th style="min-width: 130px !important; width: 130px !important">
                      REPERIBILITÀ
                    </th>
                    <th style="min-width: 130px !important; width: 130px !important">
                      TOTALE COMMESSA
                    </th>
                    <th style="min-width: 110px !important; width: 110px !important">
                      COMPLETA MESE
                    </th>
                  </tr>
                </thead>
                <!-- Intestazione se turnista e il giorno è un feriale -->
                <thead
                  v-else-if="
                    userRapporto?.turnista &&
                    (!localData.day.isDomenica || !localData.day.isFestivita)
                  "
                  class="table-header-font"
                >
                  <tr class="td-center">
                    <th>COMMESSA</th>
                    <th style="min-width: 130px !important; width: 130px !important">
                      ORDINARIO
                    </th>
                    <th style="min-width: 130px !important; width: 130px !important">
                      STRAORDINARIO ORDINARIO
                    </th>
                    <th style="min-width: 130px !important; width: 130px !important">
                      ORDINARIO NOTTURNO
                    </th>
                    <th style="min-width: 130px !important; width: 130px !important">
                      REPERIBILITÀ
                    </th>
                    <th style="min-width: 130px !important; width: 130px !important">
                      TOTALE COMMESSA
                    </th>
                    <th style="min-width: 110px !important; width: 110px !important">
                      COMPLETA MESE
                    </th>
                  </tr>
                </thead>
                <!-- Intestazione se esterno -->
                <thead v-else-if="isProfiloEsterno" class="table-header-font">
                  <tr class="td-center">
                    <th>COMMESSA</th>
                    <th style="min-width: 130px !important; width: 130px !important">
                      ORDINARIO
                    </th>
                    <th
                      v-if="
                        localData.day.isSabato ||
                        (!localData.day.isFestivita &&
                          !localData.day.isDomenica &&
                          !localData.day.isSabato)
                      "
                      style="min-width: 130px !important; width: 130px !important"
                    >
                      STRAORDINARIO ORDINARIO
                    </th>
                    <th
                      v-if="localData.day.isDomenica || localData.day.isFestivita"
                      style="min-width: 130px !important; width: 130px !important"
                    >
                      STRAORDINARIO FESTIVO
                    </th>
                    <th style="min-width: 130px !important; width: 130px !important">
                      STRAORDINARIO NOTTURNO
                    </th>
                    <th style="min-width: 130px !important; width: 130px !important">
                      REPERIBILITÀ
                    </th>
                    <th style="min-width: 130px !important; width: 130px !important">
                      TOTALE COMMESSA
                    </th>
                    <th style="min-width: 110px !important; width: 110px !important">
                      COMPLETA MESE
                    </th>
                  </tr>
                </thead>
                <!-- Intestazione caso predefinito -->
                <thead v-else class="table-header-font">
                  <tr class="td-center">
                    <th>COMMESSA</th>
                    <th style="min-width: 130px !important; width: 130px !important">
                      ORDINARIO
                    </th>
                    <th
                      v-if="
                        localData.day.isSabato ||
                        (!localData.day.isFestivita &&
                          !localData.day.isDomenica &&
                          !localData.day.isSabato)
                      "
                      style="min-width: 130px !important; width: 130px !important"
                    >
                      STRAORDINARIO ORDINARIO
                    </th>
                    <th
                      v-if="localData.day.isDomenica || localData.day.isFestivita"
                      style="min-width: 130px !important; width: 130px !important"
                    >
                      STRAORDINARIO FESTIVO
                    </th>
                    <th style="min-width: 130px !important; width: 130px !important">
                      STRAORDINARIO NOTTURNO
                    </th>
                    <th style="min-width: 130px !important; width: 130px !important">
                      REPERIBILITÀ
                    </th>
                    <th style="min-width: 130px !important; width: 130px !important">
                      TOTALE COMMESSA
                    </th>
                    <th style="min-width: 110px !important; width: 110px !important">
                      COMPLETA MESE
                    </th>
                  </tr>
                </thead>
                <!-- Table Records -->
                <tbody class="table-body-font td-vertical-center">
                  <template v-for="(commessa, index) in localData.ordinario" :key="index">
                    <!-- Riga TR, controllo se turnista e il giorno è una domenica o una festività -->
                    <tr
                      v-if="
                        userRapporto?.turnista &&
                        (localData.day.isDomenica || localData.day.isFestivita)
                      "
                      class="tr-bkg-dati"
                    >
                      <!-- commessa -->
                      <td
                        :class="{
                          danger: commessa.eliminato,
                        }"
                      >
                        {{ commessa._id.commessaDescrizione
                        }}{{
                          commessa._id.commessaNav
                            ? ` | Codice NAV: ${commessa._id.commessaNav}`
                            : ""
                        }}
                      </td>
                      <!-- ore_ordinarie -->
                      <td
                        class="td-center"
                        :class="{
                          danger: commessa.eliminato,
                        }"
                      >
                        <input
                          type="number"
                          inputmode="numeric"
                          pattern="[0-9]*"
                          class="form-control"
                          v-model="commessa.ore_ordinarie"
                          @blur="checkMaxValue('ore_ordinarie', index, $event)"
                          @input="checkMaxValue('ore_ordinarie', index, $event)"
                          step="0.5"
                          min="0"
                          :max="localData.orecontrattuali"
                          :disabled="true"
                        />
                      </td>
                      <!-- ore_ordinarie_festive -->
                      <td
                        class="td-center"
                        :class="{
                          danger: commessa.eliminato,
                        }"
                      >
                        <input
                          type="number"
                          inputmode="numeric"
                          pattern="[0-9]*"
                          class="form-control"
                          v-model="commessa.ore_ordinarie_festive"
                          @blur="checkMaxValue('ore_ordinarie', index, $event)"
                          @input="checkMaxValue('ore_ordinarie', index, $event)"
                          step="0.5"
                          min="0"
                          :max="localData.orecontrattuali"
                          :disabled="false"
                        />
                      </td>
                      <!-- ore_ordinarie_notturne -->
                      <td
                        class="td-center"
                        :class="{
                          danger: commessa.eliminato,
                        }"
                      >
                        <input
                          type="number"
                          inputmode="numeric"
                          pattern="[0-9]*"
                          class="form-control"
                          v-model="commessa.ore_ordinarie_notturne"
                          @blur="checkMaxValue('ore_ordinarie', index, $event)"
                          @input="checkMaxValue('ore_ordinarie', index, $event)"
                          step="0.5"
                          min="0"
                          :max="localData.orecontrattuali"
                          :disabled="false"
                        />
                      </td>
                      <!-- reperibilita -->
                      <td
                        class="td-center"
                        :class="{
                          danger: commessa.eliminato,
                        }"
                      >
                        <input
                          type="number"
                          inputmode="numeric"
                          pattern="[0-9]*"
                          class="form-control"
                          v-model="commessa.reperibilita"
                          @blur="checkMaxValue('reperibilita', index, $event)"
                          @input="checkMaxValue('reperibilita', index, $event)"
                          step="0.5"
                          min="0"
                          :max="maxOreReperibilita"
                          :disabled="isDisabled || !commessa.abilitazioni.reperibilita"
                        />
                      </td>
                      <!-- totale singola commessa -->
                      <td
                        class="td-center"
                        :class="{
                          danger: exceededFlags.isSingolaCommessaExceeded[index],
                        }"
                      >
                        {{
                          mixins_formatNumberWithLocale(totaleSingolaCommessa[index], 1)
                        }}
                      </td>
                      <!-- completa mese -->
                      <td>
                        <div
                          class="d-flex align-items-center justify-content-center m-0 p-0"
                        >
                          <div
                            class="form-check form-switch d-flex align-items-center justify-content-center m-0 p-0"
                          >
                            <div
                              class="d-flex align-items-center justify-content-center m-0 p-0"
                            >
                              <input
                                class="form-check-input m-0 p-0"
                                style="top: 0 !important"
                                type="checkbox"
                                v-model="completaMese[index]"
                                @change="toggleCompletaMese(index)"
                                :disabled="
                                  localData.day.isFestivita ||
                                  localData.day.isDomenica ||
                                  totaleGiornoOreOrdinarie > localData.orecontrattuali
                                "
                              />
                              <label class="content-form text-end m-0 p-0 label-yes-no">
                                {{ completaMese[index] ? "SI" : "NO" }}
                              </label>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <!-- Riga TR, controllo se turnista e il giorno è feriale -->
                    <tr
                      v-else-if="
                        userRapporto?.turnista &&
                        (!localData.day.isDomenica || !localData.day.isFestivita)
                      "
                      class="tr-bkg-dati"
                    >
                      <!-- commessa -->
                      <td
                        :class="{
                          danger: commessa.eliminato,
                        }"
                      >
                        {{ commessa._id.commessaDescrizione
                        }}{{
                          commessa._id.commessaNav
                            ? ` | Codice NAV: ${commessa._id.commessaNav}`
                            : ""
                        }}
                      </td>
                      <!-- ore_ordinarie -->
                      <td
                        class="td-center"
                        :class="{
                          danger: commessa.eliminato,
                        }"
                      >
                        <input
                          type="number"
                          inputmode="numeric"
                          pattern="[0-9]*"
                          class="form-control"
                          v-model="commessa.ore_ordinarie"
                          @blur="checkMaxValue('ore_ordinarie', index, $event)"
                          @input="checkMaxValue('ore_ordinarie', index, $event)"
                          step="0.5"
                          min="0"
                          :max="localData.orecontrattuali"
                          :disabled="isDisabled"
                        />
                      </td>
                      <!-- straordinario_ordinario -->
                      <td
                        v-if="
                          localData.day.isSabato ||
                          (!localData.day.isFestivita &&
                            !localData.day.isDomenica &&
                            !localData.day.isSabato)
                        "
                        class="td-center"
                        :class="{
                          danger: commessa.eliminato,
                        }"
                      >
                        <input
                          type="number"
                          inputmode="numeric"
                          pattern="[0-9]*"
                          class="form-control"
                          v-model="commessa.straordinario_ordinario"
                          @blur="checkMaxValue('straordinario_ordinario', index, $event)"
                          @input="checkMaxValue('straordinario_ordinario', index, $event)"
                          step="0.5"
                          min="0"
                          :max="maxOreStraordinario"
                          :disabled="
                            isDisabled ||
                            !commessa.abilitazioni.straordinario ||
                            totaleAssenze > 0
                          "
                        />
                      </td>
                      <!-- ore_ordinarie_notturne -->
                      <td
                        class="td-center"
                        :class="{
                          danger: commessa.eliminato,
                        }"
                      >
                        <input
                          type="number"
                          inputmode="numeric"
                          pattern="[0-9]*"
                          class="form-control"
                          v-model="commessa.ore_ordinarie_notturne"
                          @blur="checkMaxValue('ore_ordinarie', index, $event)"
                          @input="checkMaxValue('ore_ordinarie', index, $event)"
                          step="0.5"
                          min="0"
                          :max="localData.orecontrattuali"
                          :disabled="false"
                        />
                      </td>
                      <!-- reperibilita -->
                      <td
                        class="td-center"
                        :class="{
                          danger: commessa.eliminato,
                        }"
                      >
                        <input
                          type="number"
                          inputmode="numeric"
                          pattern="[0-9]*"
                          class="form-control"
                          v-model="commessa.reperibilita"
                          @blur="checkMaxValue('reperibilita', index, $event)"
                          @input="checkMaxValue('reperibilita', index, $event)"
                          step="0.5"
                          min="0"
                          :max="maxOreReperibilita"
                          :disabled="isDisabled || !commessa.abilitazioni.reperibilita"
                        />
                      </td>
                      <!-- totale singola commessa -->
                      <td
                        class="td-center"
                        :class="{
                          danger: exceededFlags.isSingolaCommessaExceeded[index],
                        }"
                      >
                        {{
                          mixins_formatNumberWithLocale(totaleSingolaCommessa[index], 1)
                        }}
                      </td>
                      <!-- completa mese -->
                      <td>
                        <div
                          class="d-flex align-items-center justify-content-center m-0 p-0"
                        >
                          <div
                            class="form-check form-switch d-flex align-items-center justify-content-center m-0 p-0"
                          >
                            <div
                              class="d-flex align-items-center justify-content-center m-0 p-0"
                            >
                              <input
                                class="form-check-input m-0 p-0"
                                style="top: 0 !important"
                                type="checkbox"
                                v-model="completaMese[index]"
                                @change="toggleCompletaMese(index)"
                                :disabled="
                                  localData.day.isFestivita ||
                                  localData.day.isDomenica ||
                                  totaleGiornoOreOrdinarie > localData.orecontrattuali
                                "
                              />
                              <label class="content-form text-end m-0 p-0 label-yes-no">
                                {{ completaMese[index] ? "SI" : "NO" }}
                              </label>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <!-- Riga TR, controllo se esterno -->
                    <tr v-else-if="isProfiloEsterno" class="tr-bkg-dati">
                      <!-- commessa -->
                      <td
                        :class="{
                          danger: commessa.eliminato,
                        }"
                      >
                        {{ commessa._id.commessaDescrizione
                        }}{{
                          commessa._id.commessaNav
                            ? ` | Codice NAV: ${commessa._id.commessaNav}`
                            : ""
                        }}
                      </td>
                      <!-- ore_ordinarie -->
                      <td
                        class="td-center"
                        :class="{
                          danger: commessa.eliminato,
                        }"
                      >
                        <input
                          type="number"
                          inputmode="numeric"
                          pattern="[0-9]*"
                          class="form-control"
                          v-model="commessa.ore_ordinarie"
                          @blur="checkMaxValue('ore_ordinarie', index, $event)"
                          @input="checkMaxValue('ore_ordinarie', index, $event)"
                          step="0.5"
                          min="0"
                          :max="localData.orecontrattuali"
                          :disabled="isDisabled"
                        />
                      </td>
                      <!-- straordinario_ordinario -->
                      <td
                        v-if="
                          localData.day.isSabato ||
                          (!localData.day.isFestivita &&
                            !localData.day.isDomenica &&
                            !localData.day.isSabato)
                        "
                        class="td-center"
                        :class="{
                          danger: commessa.eliminato,
                        }"
                      >
                        <input
                          type="number"
                          inputmode="numeric"
                          pattern="[0-9]*"
                          class="form-control"
                          v-model="commessa.straordinario_ordinario"
                          @blur="checkMaxValue('straordinario_ordinario', index, $event)"
                          @input="checkMaxValue('straordinario_ordinario', index, $event)"
                          step="0.5"
                          min="0"
                          :max="maxOreStraordinario"
                          :disabled="
                            isDisabled ||
                            !commessa.abilitazioni.straordinario ||
                            totaleAssenze > 0
                          "
                        />
                      </td>
                      <!-- straordinario_festivo -->
                      <td
                        v-if="localData.day.isDomenica || localData.day.isFestivita"
                        class="td-center"
                        :class="{
                          danger: commessa.eliminato,
                        }"
                      >
                        <input
                          type="number"
                          inputmode="numeric"
                          pattern="[0-9]*"
                          class="form-control"
                          v-model="commessa.straordinario_festivo"
                          @blur="checkMaxValue('straordinario_festivo', index, $event)"
                          @input="checkMaxValue('straordinario_festivo', index, $event)"
                          step="0.5"
                          min="0"
                          :max="maxOreStraordinario"
                          :disabled="isDisabled || !commessa.abilitazioni.straordinario"
                        />
                      </td>
                      <!-- straordinario_notturno -->
                      <td
                        class="td-center"
                        :class="{
                          danger: commessa.eliminato,
                        }"
                      >
                        <input
                          type="number"
                          inputmode="numeric"
                          pattern="[0-9]*"
                          class="form-control"
                          v-model="commessa.straordinario_notturno"
                          @blur="checkMaxValue('straordinario_notturno', index, $event)"
                          @input="checkMaxValue('straordinario_notturno', index, $event)"
                          step="0.5"
                          min="0"
                          :max="maxOreStraordinario"
                          :disabled="isDisabled || !commessa.abilitazioni.straordinario"
                        />
                      </td>
                      <!-- reperibilita -->
                      <td
                        class="td-center"
                        :class="{
                          danger: commessa.eliminato,
                        }"
                      >
                        <input
                          type="number"
                          inputmode="numeric"
                          pattern="[0-9]*"
                          class="form-control"
                          v-model="commessa.reperibilita"
                          @blur="checkMaxValue('reperibilita', index, $event)"
                          @input="checkMaxValue('reperibilita', index, $event)"
                          step="0.5"
                          min="0"
                          :max="maxOreReperibilita"
                          :disabled="isDisabled || !commessa.abilitazioni.reperibilita"
                        />
                      </td>
                      <!-- totale singola commessa -->
                      <td
                        class="td-center"
                        :class="{
                          danger: exceededFlags.isSingolaCommessaExceeded[index],
                        }"
                      >
                        {{
                          mixins_formatNumberWithLocale(totaleSingolaCommessa[index], 1)
                        }}
                      </td>
                      <!-- completa mese -->
                      <td>
                        <div
                          class="d-flex align-items-center justify-content-center m-0 p-0"
                        >
                          <div
                            class="form-check form-switch d-flex align-items-center justify-content-center m-0 p-0"
                          >
                            <div
                              class="d-flex align-items-center justify-content-center m-0 p-0"
                            >
                              <input
                                class="form-check-input m-0 p-0"
                                style="top: 0 !important"
                                type="checkbox"
                                v-model="completaMese[index]"
                                @change="toggleCompletaMese(index)"
                                :disabled="
                                  localData.day.isFestivita ||
                                  localData.day.isDomenica ||
                                  totaleGiornoOreOrdinarie > localData.orecontrattuali
                                "
                              />
                              <label class="content-form text-end m-0 p-0 label-yes-no">
                                {{ completaMese[index] ? "SI" : "NO" }}
                              </label>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <!-- Riga TR, caso predefinito -->
                    <tr v-else class="tr-bkg-dati">
                      <!-- commessa -->
                      <td
                        :class="{
                          danger: commessa.eliminato,
                        }"
                      >
                        {{ commessa._id.commessaDescrizione
                        }}{{
                          commessa._id.commessaNav
                            ? ` | Codice NAV: ${commessa._id.commessaNav}`
                            : ""
                        }}
                      </td>
                      <!-- ore_ordinarie -->
                      <td
                        class="td-center"
                        :class="{
                          danger: commessa.eliminato,
                        }"
                      >
                        <input
                          type="number"
                          inputmode="numeric"
                          pattern="[0-9]*"
                          class="form-control"
                          v-model="commessa.ore_ordinarie"
                          @blur="checkMaxValue('ore_ordinarie', index, $event)"
                          @input="checkMaxValue('ore_ordinarie', index, $event)"
                          step="0.5"
                          min="0"
                          :max="localData.orecontrattuali"
                          :disabled="
                            isDisabled ||
                            localData.day.isSabato ||
                            localData.day.isDomenica
                          "
                        />
                      </td>
                      <!-- straordinario_ordinario -->
                      <td
                        v-if="
                          localData.day.isSabato ||
                          (!localData.day.isFestivita &&
                            !localData.day.isDomenica &&
                            !localData.day.isSabato)
                        "
                        class="td-center"
                        :class="{
                          danger: commessa.eliminato,
                        }"
                      >
                        <input
                          type="number"
                          inputmode="numeric"
                          pattern="[0-9]*"
                          class="form-control"
                          v-model="commessa.straordinario_ordinario"
                          @blur="checkMaxValue('straordinario_ordinario', index, $event)"
                          @input="checkMaxValue('straordinario_ordinario', index, $event)"
                          step="0.5"
                          min="0"
                          :max="maxOreStraordinario"
                          :disabled="
                            isDisabled ||
                            !commessa.abilitazioni.straordinario ||
                            totaleAssenze > 0
                          "
                        />
                      </td>
                      <!-- straordinario_festivo -->
                      <td
                        v-if="localData.day.isDomenica || localData.day.isFestivita"
                        class="td-center"
                        :class="{
                          danger: commessa.eliminato,
                        }"
                      >
                        <input
                          type="number"
                          inputmode="numeric"
                          pattern="[0-9]*"
                          class="form-control"
                          v-model="commessa.straordinario_festivo"
                          @blur="checkMaxValue('straordinario_festivo', index, $event)"
                          @input="checkMaxValue('straordinario_festivo', index, $event)"
                          step="0.5"
                          min="0"
                          :max="maxOreStraordinario"
                          :disabled="isDisabled || !commessa.abilitazioni.straordinario"
                        />
                      </td>
                      <!-- straordinario_notturno -->
                      <td
                        class="td-center"
                        :class="{
                          danger: commessa.eliminato,
                        }"
                      >
                        <input
                          type="number"
                          inputmode="numeric"
                          pattern="[0-9]*"
                          class="form-control"
                          v-model="commessa.straordinario_notturno"
                          @blur="checkMaxValue('straordinario_notturno', index, $event)"
                          @input="checkMaxValue('straordinario_notturno', index, $event)"
                          step="0.5"
                          min="0"
                          :max="maxOreStraordinario"
                          :disabled="isDisabled || !commessa.abilitazioni.straordinario"
                        />
                      </td>
                      <!-- reperibilita -->
                      <td
                        class="td-center"
                        :class="{
                          danger: commessa.eliminato,
                        }"
                      >
                        <input
                          type="number"
                          inputmode="numeric"
                          pattern="[0-9]*"
                          class="form-control"
                          v-model="commessa.reperibilita"
                          @blur="checkMaxValue('reperibilita', index, $event)"
                          @input="checkMaxValue('reperibilita', index, $event)"
                          step="0.5"
                          min="0"
                          :max="maxOreReperibilita"
                          :disabled="isDisabled || !commessa.abilitazioni.reperibilita"
                        />
                      </td>
                      <!-- totale singola commessa -->
                      <td
                        class="td-center"
                        :class="{
                          danger: exceededFlags.isSingolaCommessaExceeded[index],
                        }"
                      >
                        {{
                          mixins_formatNumberWithLocale(totaleSingolaCommessa[index], 1)
                        }}
                      </td>
                      <!-- completa mese -->
                      <td>
                        <div
                          class="d-flex align-items-center justify-content-center m-0 p-0"
                        >
                          <div
                            class="form-check form-switch d-flex align-items-center justify-content-center m-0 p-0"
                          >
                            <div
                              class="d-flex align-items-center justify-content-center m-0 p-0"
                            >
                              <input
                                class="form-check-input m-0 p-0"
                                style="top: 0 !important"
                                type="checkbox"
                                v-model="completaMese[index]"
                                @change="toggleCompletaMese(index)"
                                :disabled="
                                  localData.day.isFestivita ||
                                  localData.day.isDomenica ||
                                  totaleGiornoOreOrdinarie > localData.orecontrattuali
                                "
                              />
                              <label class="content-form text-end m-0 p-0 label-yes-no">
                                {{ completaMese[index] ? "SI" : "NO" }}
                              </label>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </template>

                  <tr>
                    <td colSpan="7"></td>
                  </tr>

                  <!-- totale assenze -->
                  <tr class="tr-bkg-assenza">
                    <td>Assenze / Permessi</td>
                    <td class="td-center">
                      {{ mixins_formatNumberWithLocale(totaleAssenze, 1) }}
                    </td>
                    <td colSpan="5"></td>
                  </tr>
                  <tr>
                    <td colSpan="7"></td>
                  </tr>

                  <!-- Totali TR, controllo se turnista e il giorno è una domenica o una festività -->
                  <template
                    v-if="
                      userRapporto?.turnista &&
                      (localData.day.isDomenica || localData.day.isFestivita)
                    "
                  >
                    <tr class="active">
                      <td>Totale giorno</td>
                      <!-- tot_ore_ordinarie -->
                      <td
                        class="td-center"
                        :class="{ danger: exceededFlags.isOrdinarioExceeded }"
                      >
                        {{ mixins_formatNumberWithLocale(totaleGiornoOreOrdinarie, 1) }}
                      </td>
                      <!-- tot_ordinario_festivo -->
                      <td
                        class="td-center"
                        :class="{ danger: exceededFlags.isOrdinarioFestivoExceeded }"
                      >
                        {{
                          mixins_formatNumberWithLocale(
                            totaleGiornoOreOrdinarieFestive,
                            1
                          )
                        }}
                      </td>
                      <!-- tot_ordinario_notturno -->
                      <td
                        class="td-center"
                        :class="{
                          danger: exceededFlags.isOrdinarioNotturnoExceeded,
                        }"
                      >
                        {{
                          mixins_formatNumberWithLocale(
                            totaleGiornoOreOrdinarieNotturne,
                            1
                          )
                        }}
                      </td>
                      <!-- tot_reperibilita -->
                      <td
                        class="td-center"
                        :class="{ danger: exceededFlags.isReperibilitaExceeded }"
                      >
                        {{ mixins_formatNumberWithLocale(totaleGiornoReperibilita, 1) }}
                      </td>
                      <!-- tot_commessa -->
                      <td
                        class="td-center"
                        :class="{ danger: exceededFlags.isTotaleCommesseExceeded }"
                      >
                        {{ mixins_formatNumberWithLocale(totaleGiornoCommesse, 1) }}
                      </td>
                      <td class="td-center">&nbsp;</td>
                    </tr>
                  </template>
                  <!-- Totali TR -->
                  <template v-else>
                    <tr class="active">
                      <td>Totale giorno</td>
                      <!-- tot_ore_ordinarie -->
                      <td
                        class="td-center"
                        :class="{ danger: exceededFlags.isOrdinarioExceeded }"
                      >
                        {{ mixins_formatNumberWithLocale(totaleGiornoOreOrdinarie, 1) }}
                      </td>
                      <!-- tot_straordinario_ordinario -->
                      <td
                        v-if="
                          localData.day.isSabato ||
                          (!localData.day.isFestivita &&
                            !localData.day.isDomenica &&
                            !localData.day.isSabato)
                        "
                        class="td-center"
                        :class="{ danger: exceededFlags.isStrOrdinarioExceeded }"
                      >
                        {{ mixins_formatNumberWithLocale(totaleGiornoStrOrdinario, 1) }}
                      </td>
                      <!-- tot_straordinario_festivo -->
                      <td
                        v-if="localData.day.isDomenica || localData.day.isFestivita"
                        class="td-center"
                        :class="{ danger: exceededFlags.isStrFestivoExceeded }"
                      >
                        {{ mixins_formatNumberWithLocale(totaleGiornoStrFestivo, 1) }}
                      </td>
                      <!-- tot_straordinario_notturno -->
                      <td
                        class="td-center"
                        :class="{ danger: exceededFlags.isStrNotturnoExceeded }"
                      >
                        {{ mixins_formatNumberWithLocale(totaleGiornoStrNotturno, 1) }}
                      </td>
                      <!-- tot_reperibilita -->
                      <td
                        class="td-center"
                        :class="{ danger: exceededFlags.isReperibilitaExceeded }"
                      >
                        {{ mixins_formatNumberWithLocale(totaleGiornoReperibilita, 1) }}
                      </td>
                      <!-- tot_commessa -->
                      <td
                        class="td-center"
                        :class="{ danger: exceededFlags.isTotaleCommesseExceeded }"
                      >
                        {{ mixins_formatNumberWithLocale(totaleGiornoCommesse, 1) }}
                      </td>
                      <td class="td-center">&nbsp;</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
          <!-- Completa mese tuttle le commesse -->
          <div class="align-items-center d-flex justify-content-start">
            <label class="col-form-label form-label d-inline-flex me-2">
              <span>COMPLETA MESE PER TUTTE LE COMMESSE</span>
            </label>
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                v-model="completaMeseTutteCommesse"
                @change="toggleCompletaMeseTutteCommesse"
                :disabled="
                  localData.day.isFestivita ||
                  localData.day.isDomenica ||
                  totaleGiornoOreOrdinarie > localData.orecontrattuali
                "
              />
              <label class="content-form text-end label-yes-no">
                {{ completaMeseTutteCommesse ? "SI" : "NO" }}
              </label>
            </div>
          </div>
        </div>
        <hr class="sepGray" />
        <div class="row">
          <div class="container-fluid my-3">
            <button
              @click="closeModal"
              type="button"
              class="btn button-outline-1 float-start"
            >
              <span><i class="zmdi zmdi-close"></i>Annulla</span>
            </button>
            <button
              @click="saveData()"
              type="button"
              class="btn button-outline-1 float-end"
              :disabled="isSaveDisabled || isDisabled"
            >
              <span><i class="zmdi zmdi-save"></i>Salva i dati</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { UtilityMixins } from "@/mixins/UtilityMixins.js";

export default {
  mixins: [UtilityMixins],
  components: {},
  props: {
    isVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
    dialogWidth: {
      type: String,
      default: "1200px",
    },
    dialogTitle: {
      type: String,
      default: "",
    },
    dataItem: {
      type: Object,
      default: () => ({}),
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["closeModal", "saveData"],
  data() {
    return {
      localData: {},
      maxOreStraordinario: 24,
      maxOreReperibilita: 24,
      // maxOrdinario: 8,
      // maxOrdinarioEsterno: 24,
      maxStrOrdinario: 16,
      maxStrFestivo: 16,
      maxStrNotturno: 8,
      maxSingCommessa: 24,
      totaleGiornoCommesse: 0,
      totaleGiornoOreOrdinarie: 0,
      totaleGiornoOreOrdinarieFestive: 0,
      totaleGiornoOreOrdinarieNotturne: 0,
      totaleGiornoStrOrdinario: 0,
      totaleGiornoStrFestivo: 0,
      totaleGiornoStrNotturno: 0,
      totaleGiornoReperibilita: 0,
      totaleSingolaCommessa: [],
      previousStrOrdinario: [],
      previousStrFestivo: [],
      previousStrNotturno: [],
      // Flag per ogni massimale
      exceededFlags: {
        isStrResiduoExceeded: false,
        isOrdinarioExceeded: false,
        isOrdinarioFestivoExceeded: false,
        isOrdinarioNotturnoExceeded: false,
        isStrOrdinarioExceeded: false,
        isStrFestivoExceeded: false,
        isStrNotturnoExceeded: false,
        isReperibilitaExceeded: false,
        isSingolaCommessaExceeded: [],
        isTotaleCommesseExceeded: false,
      },
      completaMese: [], // Array di booleani per ogni commessa
      completaMeseTutteCommesse: false,
      userRapporto: {},
    };
  },
  computed: {
    isProfiloEsterno() {
      // Restituisce se è un profilo di tipo "Esterno"
      return this.localData.profili.some(
        (item) => item.descrizione.toLowerCase() === "esterno"
      );
    },
    totaleAssenze() {
      // Restituisce il totale delle assenze
      return (
        this.localData.tot_ferie +
        this.localData.tot_malattia +
        this.localData.tot_permessi_giustificati +
        this.localData.tot_rol +
        this.localData.tot_altro
      );
    },
    isDisabled() {
      // Restituisce true se il totale delle assenze è maggiore o uguale alle ore contrattuali
      if (this.totaleAssenze >= this.localData.orecontrattuali) {
        return true;
      } else {
        return false;
      }
    },
    // Computed per verificare se disabilitare il pulsante "Salva i dati"
    isSaveDisabled() {
      return Object.values(this.exceededFlags).some((flag) =>
        Array.isArray(flag) ? flag.some((f) => f) : flag
      );
    },
    maxOrdinario() {
      return this.localData.orecontrattuali;
    },
    maxOrdinarioEsterno() {
      return this.localData.orecontrattuali;
    },
    maxOrdinarioFestivoTurnista() {
      return this.localData.orecontrattuali;
    },
    maxOrdinarioNotturnoTurnista() {
      return this.localData.orecontrattuali;
    },
  },
  watch: {
    isVisible(newVal) {
      if (newVal) {
        // Inizializza localData
        this.localData = JSON.parse(JSON.stringify(this.dataItem));
        this.userRapporto = this.$parent.getUserRapporto();

        // Inizializza gli array per i valori precedenti
        this.previousStrOrdinario = this.localData.ordinario.map(
          (commessa) => commessa.straordinario_ordinario || 0
        );
        this.previousStrFestivo = this.localData.ordinario.map(
          (commessa) => commessa.straordinario_festivo || 0
        );
        this.previousStrNotturno = this.localData.ordinario.map(
          (commessa) => commessa.straordinario_notturno || 0
        );

        // Reset degli switch e dei totali
        this.completaMeseTutteCommesse = false;
        this.completaMese = this.localData.ordinario.map(() => false);
        this.calcolaTotali();
      }
    },
    totaleGiornoCommesse(newVal) {
      if (newVal <= 0) {
        // Resetta completaMese come array di false
        if (Array.isArray(this.localData.ordinario)) {
          this.completaMese = this.localData.ordinario.map(() => false);
        } else {
          this.completaMese = []; // Fallback per evitare errori
        }

        this.completaMeseTutteCommesse = false; // Anche il toggle globale deve essere false
      }
    },
    "localData.ordinario": {
      immediate: true,
      handler(newVal) {
        if (Array.isArray(newVal)) {
          this.completaMese = newVal.map(() => false); // Inizializza con false per ogni commessa
        } else {
          this.completaMese = []; // Fallback in caso di errore
        }
      },
    },
  },

  methods: {
    closeModal() {
      // Ripristina i dati originali
      this.localData = JSON.parse(JSON.stringify(this.dataItem));
      this.$emit("closeModal"); // Emetto l'evento per chiudere il modal
    },
    saveData() {
      this.$emit("saveData", this.localData, this.completaMese);
    },

    checkMaxValue(field, index, event) {
      let value = this.localData.ordinario[index][field];

      if (value === "") {
        // Imposta il valore a 0 se è vuoto e poi esce
        this.localData.ordinario[index][field] = 0;
        return;
      }

      const parsedValue = parseFloat(value);
      const maxValue = parseFloat(event.target.max);
      const minValue = parseFloat(event.target.min);

      if (isNaN(parsedValue)) {
        // Se il valore non è un numero valido, imposta a 0
        this.localData.ordinario[index][field] = 0;
      } else if (parsedValue > maxValue) {
        // Se il valore supera il massimo, imposta al massimo consentito
        this.localData.ordinario[index][field] = maxValue;
      } else if (parsedValue < minValue) {
        // Se il valore è inferiore al minimo, imposta al minimo consentito
        this.localData.ordinario[index][field] = minValue;
      }

      this.calcolaTotali(); // Aggiorna i totali
      this.aggiornaStrResiduo(field, index, parsedValue); // Aggiorna lo straordinario residuo
    },

    // checkMaxValue(field, index, event) {
    //   const value = this.localData.ordinario[index][field];
    //   const parsedValue = parseFloat(value);

    //   const maxValue = parseFloat(event.target.max);
    //   const minValue = parseFloat(event.target.min);

    //   if (value === "") {
    //     // Se il valore è vuoto, non modifico nulla
    //     return;
    //   }

    //   if (isNaN(parsedValue)) {
    //     // Se il valore non è un numero, imposta a 0 (o un valore valido di default)
    //     this.localData.ordinario[index][field] = 0;
    //   } else if (parsedValue > maxValue) {
    //     this.localData.ordinario[index][field] = maxValue;
    //   } else if (parsedValue < minValue) {
    //     this.localData.ordinario[index][field] = minValue;
    //   }

    //   this.calcolaTotali(); // Aggiorna i totali dopo ogni modifica
    //   this.aggiornaStrResiduo(field, index, parsedValue); // Aggiorna lo strResiduo
    // },

    // checkMaxValue(field, index, event) {
    //   const value = this.localData.ordinario[index][field];
    //   const parsedValue = parseFloat(value);

    //   const maxValue = parseFloat(event.target.max);
    //   const minValue = parseFloat(event.target.min);

    //   if (value === "" || isNaN(parsedValue)) {
    //     this.localData.ordinario[index][field] = 0;
    //   } else if (parsedValue > maxValue) {
    //     this.localData.ordinario[index][field] = maxValue;
    //   } else if (parsedValue < minValue) {
    //     this.localData.ordinario[index][field] = minValue;
    //   }

    //   this.calcolaTotali(); // Aggiorna i totali dopo ogni modifica
    //   this.aggiornaStrResiduo(field, index, parsedValue); // Aggiorna lo strResiduo
    // },

    aggiornaStrResiduo(field, index, newValue) {
      let previousValue;

      if (field === "straordinario_ordinario") {
        previousValue = this.previousStrOrdinario[index] || 0;
        this.previousStrOrdinario[index] = newValue;
      } else if (field === "straordinario_festivo") {
        previousValue = this.previousStrFestivo[index] || 0;
        this.previousStrFestivo[index] = newValue;
      } else if (field === "straordinario_notturno") {
        previousValue = this.previousStrNotturno[index] || 0;
        this.previousStrNotturno[index] = newValue;
      } else {
        return;
      }

      // Aggiorna il residuo in base alla differenza tra il nuovo e il precedente valore
      const diff = newValue - previousValue;
      this.localData.info.strResiduo -= diff;

      // Verifica se il residuo è sotto zero
      this.exceededFlags.isStrResiduoExceeded = this.localData.info.strResiduo < 0;
    },

    calcolaTotali() {
      // Reset dei totali e flag
      this.totaleGiornoOreOrdinarie = 0;
      this.totaleGiornoOreOrdinarieFestive = 0;
      this.totaleGiornoOreOrdinarieNotturne = 0;
      this.totaleGiornoStrOrdinario = 0;
      this.totaleGiornoStrFestivo = 0;
      this.totaleGiornoStrNotturno = 0;
      this.totaleGiornoReperibilita = 0;
      this.totaleSingolaCommessa = [];
      this.exceededFlags = {
        isStrResiduoExceeded: false,
        isOrdinarioExceeded: false,
        isOrdinarioFestivoExceeded: false,
        isOrdinarioNotturnoExceeded: false,
        isStrOrdinarioExceeded: false,
        isStrFestivoExceeded: false,
        isStrNotturnoExceeded: false,
        isReperibilitaExceeded: false,
        isSingolaCommessaExceeded: [],
        isTotaleCommesseExceeded: false,
      };

      this.localData.ordinario.forEach((commessa, index) => {
        const oreOrdinarie = parseFloat(commessa.ore_ordinarie) || 0;
        const oreOrdinarieFestive = parseFloat(commessa.ore_ordinarie_festive) || 0;
        const oreOrdinarieNotturne = parseFloat(commessa.ore_ordinarie_notturne) || 0;
        const straordinarioOrdinario = parseFloat(commessa.straordinario_ordinario) || 0;
        const straordinarioFestivo = parseFloat(commessa.straordinario_festivo) || 0;
        const straordinarioNotturno = parseFloat(commessa.straordinario_notturno) || 0;
        const reperibilita = parseFloat(commessa.reperibilita) || 0;

        // Calcola i totali per ogni colonna
        this.totaleGiornoOreOrdinarie += oreOrdinarie;
        this.totaleGiornoOreOrdinarieFestive += oreOrdinarieFestive;
        this.totaleGiornoOreOrdinarieNotturne += oreOrdinarieNotturne;
        this.totaleGiornoStrOrdinario += straordinarioOrdinario;
        this.totaleGiornoStrFestivo += straordinarioFestivo;
        this.totaleGiornoStrNotturno += straordinarioNotturno;
        this.totaleGiornoReperibilita += reperibilita;

        // Calcola il totale per ogni singola commessa
        this.totaleSingolaCommessa[index] =
          oreOrdinarie +
          oreOrdinarieFestive +
          oreOrdinarieNotturne +
          straordinarioOrdinario +
          straordinarioFestivo +
          straordinarioNotturno;

        // Verifica i massimali per ogni singola commessa
        this.exceededFlags.isSingolaCommessaExceeded[index] =
          this.totaleSingolaCommessa[index] > this.maxSingCommessa;
      });

      // Aggiunge il totale delle assenze a quello delle ore ordinarie
      this.totaleGiornoOreOrdinarie += this.totaleAssenze;

      // Verifica massimale per ogni colonna
      this.exceededFlags.isOrdinarioExceeded = this.isProfiloEsterno
        ? this.totaleGiornoOreOrdinarie > this.maxOrdinarioEsterno
        : this.totaleGiornoOreOrdinarie > this.maxOrdinario;

      this.exceededFlags.isOrdinarioFestivoExceeded =
        this.totaleGiornoOreOrdinarieFestive > this.maxOrdinarioFestivoTurnista;
      this.exceededFlags.isOrdinarioNotturnoExceeded =
        this.totaleGiornoOreOrdinarieNotturne > this.maxOrdinarioNotturnoTurnista;

      this.exceededFlags.isStrOrdinarioExceeded =
        this.totaleGiornoStrOrdinario > this.maxStrOrdinario;
      this.exceededFlags.isStrFestivoExceeded =
        this.totaleGiornoStrFestivo > this.maxStrFestivo;
      this.exceededFlags.isStrNotturnoExceeded =
        this.totaleGiornoStrNotturno > this.maxStrNotturno;
      this.exceededFlags.isReperibilitaExceeded =
        this.totaleGiornoReperibilita > this.maxOreReperibilita;

      // Totale giorno complessivo
      this.totaleGiornoCommesse =
        this.totaleGiornoOreOrdinarie +
        this.totaleGiornoOreOrdinarieFestive +
        this.totaleGiornoOreOrdinarieNotturne +
        this.totaleGiornoStrOrdinario +
        this.totaleGiornoStrFestivo +
        this.totaleGiornoStrNotturno +
        this.totaleGiornoReperibilita;

      // Verifica massimale totale commesse
      this.exceededFlags.isTotaleCommesseExceeded =
        this.totaleGiornoCommesse > this.maxSingCommessa;
    },

    resetCompletaMeseIfNecessary() {
      console.log("** this.totaleAssenze", this.totaleAssenze);
      // Controlla se completaMese è un array
      if (Array.isArray(this.completaMese)) {
        // Verifica le condizioni per resettare
        if (this.isLastDayOfMonth(this.localData.day.dateString)) {
          this.completaMese = this.completaMese.map(() => false);
          this.completaMeseTutteCommesse = false;
        }
      } else {
        console.warn("completaMese non è un array, inizializzazione necessaria.");
        this.completaMese = []; // Prevenzione di errori
        this.completaMeseTutteCommesse = false;
      }
    },
    toggleCompletaMese(index) {
      if (
        Array.isArray(this.completaMese) &&
        index >= 0 &&
        index < this.completaMese.length
      ) {
        if (!this.completaMese[index]) {
          this.completaMeseTutteCommesse = false; // Disattiva il toggle globale
        } else if (this.completaMese.every((value) => value)) {
          this.completaMeseTutteCommesse = true; // Attiva il toggle globale se tutti sono true
        }
      }

      // Applica la logica comune
      this.resetCompletaMeseIfNecessary();
    },
    toggleCompletaMeseTutteCommesse() {
      if (Array.isArray(this.completaMese)) {
        // Imposta tutti i valori di completaMese in base a completaMeseTutteCommesse
        this.completaMese = this.completaMese.map(() => this.completaMeseTutteCommesse);
      } else {
        console.warn("completaMese non è un array, inizializzazione necessaria.");
        this.completaMese = []; // Prevenzione di errori
      }

      // Applica la logica comune
      this.resetCompletaMeseIfNecessary();
    },
    isLastDayOfMonth(dateString) {
      const date = new Date(dateString);
      const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      return date.getDate() === lastDayOfMonth.getDate();
    },
  },
  mounted() {},
};
</script>
