<template>
  <main>
    <div class="container-fluid">
      <div class="row">
        <!-- Sidebar -->
        <sidebar @update-sort="handleSortUpdate" />
        <!-- Main content -->
        <router-view :loading="loading" :currentSortType="currentSortType"></router-view>
      </div>
    </div>
  </main>
</template>

<script>
import Sidebar from "@/views/menu_impostazioni/festivita/FestivitaSidebar.vue";

export default {
  components: { Sidebar },
  data() {
    return {
      loading: false, // Variabile per tracciare lo stato di caricamento
      currentSortType: 3, // Default: Data Crescente
    };
  },
  methods: {
    handleSortUpdate(sortType) {
      this.currentSortType = sortType;
    },
  },
};
</script>
