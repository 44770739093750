<template>
  <div class="col-4 sidebar">
    <nav class="main-left-nav" role="navigation">
      <!-- Routes / Filters -->
      <ul class="unstyled nav-parent-item">
        <li>
          <router-link
            :to="{ name: 'route-aziende-add' }"
            exact
            :class="{ active: $route.name === 'route-aziende-add' }"
            >+ Crea Nuovo</router-link
          >
        </li>

        <hr role="separator" aria-orientation="horizontal" class="divider" />
        <div>
          <li>
            <router-link
              @click.prevent="resetSearch()"
              :to="{ name: 'route-aziende' }"
              exact
              :class="{ active: $route.name === 'route-aziende' }"
              >Tutti</router-link
            >
          </li>
        </div>
      </ul>

      <hr role="separator" aria-orientation="horizontal" class="divider" />

      <!-- Sort -->
      <ul class="unstyled nav-parent-item" :class="{ 'element-disabled': isEditing }">
        <li v-for="item in sortOrderList" :key="item.text" @click="setOrder(item.value)">
          <a href="#" :class="{ active: currentSortType === item.value }">{{
            item.text
          }}</a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import sortOrderList from "@/data/sort_order_list_aziende.json";

export default {
  components: {},
  data() {
    return { sortOrderList };
  },
  computed: {
    ...mapGetters(["getAziendeSortType", "getSearchAziende"]),
    currentSortType() {
      return this.getAziendeSortType;
    },
    isEditing() {
      return (
        this.$route.name === "route-aziende-edit" ||
        this.$route.name === "route-aziende-add"
      );
    },
  },
  methods: {
    ...mapActions(["setAziendeSortType", "setCurrentPageAziende", "setSearchAziende"]),
    setOrder(value) {
      this.setAziendeSortType(value);
      this.setCurrentPageAziende(1);
      this.$emit("fetchAziende");
    },
    resetSearch() {
      this.setSearchAziende("");
      this.setCurrentPageAziende(1);
      this.$emit("fetchAziende");
    },
  },
};
</script>
