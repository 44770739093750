<template>
  <overlay-loading v-model:isLoading="isLoading"></overlay-loading>
  <!-- Seconda colonna -->
  <hr v-if="isAssenzeVisible" class="sepGray" />
  <!-- Filtri e Periodo da selezionare -->
  <div
    v-if="isAssenzeVisible"
    class="container-fluid align-items-center d-flex flex-row justify-content-between padding-x-4px m-0 p-0 my-4"
  >
    <!-- Filtri -->
    <div class="align-items-start d-flex flex-column justify-content-center">
      <!-- Filtri Tipo Assenze-->
      <div class="d-flex m-0 p-0 mb-3" :key="'tipo-' + filterKey">
        <div class="align-items-center d-flex flex-row justify-content-center">
          <span class="text-black me-2">Tipologia Assenza:</span>
          <div class="btn-group d-block" role="group">
            <template v-for="tipo in tipiAssenze" :key="tipo.id">
              <input
                type="radio"
                class="btn-check"
                name="filterTipoAssenze"
                :id="'assenze_' + tipo.id"
                :value="tipo.id"
                v-model="selectedFilterAssenze"
              />
              <label class="btn btn-outline-primary default" :for="'assenze_' + tipo.id">
                {{ tipo.label }} <span>({{ calcolaTotaleAssenze(tipo.id) }})</span>
              </label>
            </template>
          </div>
        </div>
      </div>

      <!-- Filtri Stato Assenze -->
      <div class="d-flex m-0 p-0" :key="'stato-' + filterKey">
        <div class="align-items-center d-flex flex-row justify-content-center">
          <span class="text-black me-2">Stato Assenza:</span>
          <div class="btn-group d-block" role="group">
            <input
              type="radio"
              class="btn-check"
              name="filterStatoAssenze"
              id="stato_all"
              value=""
              v-model="selectedFilterStato"
            />
            <label
              class="btn btn-outline-primary default"
              :class="{ checked: selectedFilterStato === '' }"
              for="stato_all"
              >TUTTI <span>({{ calcolaTotaleStato("") }})</span></label
            >
            <template v-for="stato in status" :key="stato">
              <input
                type="radio"
                class="btn-check"
                name="filterStatoAssenze"
                :id="'stato_' + stato.toLowerCase()"
                :value="stato"
                v-model="selectedFilterStato"
              />
              <label
                class="btn btn-outline-primary"
                :class="[
                  statusClass[status.indexOf(stato)],
                  { checked: selectedFilterStato === stato },
                ]"
                :for="'stato_' + stato.toLowerCase()"
                >{{ statusStr[status.indexOf(stato)] }}
                <span>({{ calcolaTotaleStato(stato) }})</span></label
              >
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="align-items-end d-flex flex-column justify-content-center">
      <!-- Selezione periodo -->
      <div class="d-flex py-2 m-0">
        <!-- Mese, visibile in vista giornaliera e mensile -->
        <div
          class="dropdown me-2"
          v-if="['giornaliera', 'mensile'].includes(visualizzazione)"
        >
          <button
            class="btn button-outline-1 small dropdown-toggle"
            type="button"
            id="dropdown_mese"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span>{{ selectedMese }}</span>
          </button>
          <ul class="dropdown-menu dropdown-menu-end">
            <li v-for="mese in mesi" :key="mese">
              <button
                class="dropdown-item"
                :class="{ active: selectedMese == mese }"
                type="button"
                @click="setMese(mese)"
              >
                {{ mese }}
              </button>
            </li>
          </ul>
        </div>

        <!-- Anno, visibile in tutte le viste tranne la settimanale -->
        <div class="dropdown me-2" v-if="visualizzazione !== 'settimanale'">
          <button
            class="btn button-outline-1 small dropdown-toggle"
            type="button"
            id="dropdown_anno"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span>{{ selectedAnno }}</span>
          </button>
          <ul class="dropdown-menu dropdown-menu-end">
            <li v-for="anno in anni" :key="anno">
              <button
                class="dropdown-item"
                :class="{ active: selectedAnno == anno }"
                type="button"
                @click="setAnno(anno)"
              >
                {{ anno }}
              </button>
            </li>
          </ul>
        </div>

        <!-- Bottone Vai, visibile in tutte le viste -->
        <button
          id="but_vai"
          ref="but_vai"
          type="button"
          class="btn button-outline-1 small"
          @click="onVai"
        >
          <i class="zmdi zmdi-long-arrow-return zmdi-hc-lg"></i> VAI
        </button>
      </div>
      <!-- Risorse -->
      <div class="d-flex py-2 m-0">
        <div class="row align-items-center" style="width: 600px">
          <div class="col-lg-3 text-lg-end text-start m-0 p-0">
            <span class="text-black">Risorse:</span>
          </div>
          <div class="col-lg-9">
            <MultiSelect
              v-model="selectedRisorse"
              :options="risorseOptions"
              :searchable="true"
              placeholder="- SELEZIONA -"
              label="label"
              track-by="value"
              :show-no-results="true"
              selectLabel="Seleziona"
              deselectLabel="Rimuovi"
              selectedLabel="Selezionato"
              :openDirection="'below'"
              tagPlaceholder="Premi enter per creare un tag"
              :multiple="true"
              :taggable="false"
              :showLabels="false"
              @select="onRisorseUpdate"
              :maxHeight="400"
            >
              <template #clear>
                <div
                  v-if="selectedRisorse && selectedRisorse.length"
                  class="multiselect__clear"
                  @mousedown.prevent.stop="clearRisorse"
                ></div>
              </template>
              <template #noOptions>
                <span>L'elenco è vuoto</span>
              </template>
              <template #noResult>
                <span>Nessun elemento trovato</span>
              </template>
            </MultiSelect>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Table -->
  <div v-if="isAssenzeVisible" class="pt-1">
    <div v-if="filteredHasData" class="container-fluid m-0 p-0 padding-x-4px">
      <h4 class="color-mainblue">Riepilogo Assenze</h4>
    </div>
    <div v-if="filteredHasData" class="container-fluid m-0 p-0 padding-x-4px">
      <div class="row m-0 mb-3">
        <div class="scroll-x-container p-0">
          <table class="table-bordered table-responsive-md table-setup mb-2">
            <thead class="table-header-font">
              <tr class="td-center">
                <th>NOME E COGNOME</th>
                <th style="min-width: 140px !important">TIPO</th>
                <th style="width: 125px !important; min-width: 125px !important">DAL</th>
                <th style="width: 125px !important; min-width: 125px !important">AL</th>
                <th style="width: 80px !important; min-width: 80px !important">
                  ORE<br />GIORNALIERE
                </th>
                <th style="width: 80px !important; min-width: 80px !important">GIORNI</th>
                <th style="width: 160px !important; min-width: 160px !important">
                  CODICE PUC
                </th>
                <th style="width: 380px !important; min-width: 380px !important">NOTE</th>
                <th style="width: 60px !important; min-width: 60px !important">
                  ALLEGATO
                </th>
                <th style="width: 180px !important; min-width: 180px !important">
                  STATO
                </th>
                <th style="min-width: 100px !important; width: 100px !important">
                  AZIONI
                </th>
              </tr>
            </thead>
            <tbody class="table-body-font td-vertical-center">
              <tr v-for="(assenza, index) in filteredAssenze" :key="index">
                <td>{{ assenza.fullname }}</td>
                <td>
                  <ul class="list-tags" style="--type-display: flex; --justify: start">
                    <li>
                      <span
                        class="badge-colored whitespace tipo-assenze"
                        :title="assenza.dettaglioassenza_descrizione"
                        :alt="assenza.dettaglioassenza_descrizione"
                        ><span style="font-size: 15px !important">{{
                          assenza.dettaglioassenza_descrizione
                        }}</span></span
                      >
                    </li>
                  </ul>
                </td>
                <td class="td-center">
                  {{ assenza.datainizio ? mixins_getLocalDate(assenza.datainizio) : "-" }}
                </td>
                <td class="td-center">
                  {{ assenza.datafine ? mixins_getLocalDate(assenza.datafine) : "-" }}
                </td>
                <td
                  class="td-center"
                  :class="{
                    'zero-value': isZeroValue(
                      mixins_formatNumberWithLocale(assenza.oregiornaliere, 1)
                    ),
                  }"
                >
                  {{ mixins_formatNumberWithLocale(assenza.oregiornaliere, 1) }}
                </td>
                <td
                  class="td-center"
                  :class="{
                    'zero-value': isZeroValue(assenza.giorni),
                  }"
                >
                  {{ mixins_formatNumberWithLocale(assenza.giorni) }}
                </td>
                <td class="td-center">
                  {{ assenza.puc ? assenza.puc : "-" }}
                </td>
                <td>
                  {{ assenza.note ? assenza.note : "" }}
                </td>
                <td class="td-center">
                  <button
                    v-if="assenza.documentoallegato != ''"
                    type="button"
                    class="btn icon-button"
                    alt="VISUALIZZA ALLEGATO"
                    title="VISUALIZZA ALLEGATO"
                    @click="viewDocumento(assenza.documentoallegato)"
                  >
                    <i class="bi bi-eye-fill"></i>
                  </button>
                </td>
                <!-- Status -->
                <td class="td-center">
                  <ul class="list-tags" style="--type-display: flex; --justify: start">
                    <li>
                      <span
                        class="badge-colored"
                        :class="getStatusParameters(assenza.status).class"
                        :title="getStatusParameters(assenza.status).status"
                        :alt="getStatusParameters(assenza.status).status"
                        >{{ getStatusParameters(assenza.status).status }}</span
                      >
                    </li>
                    <button
                      v-if="!isApprovedRejected(assenza.status)"
                      type="button"
                      class="btn icon-button approved"
                      alt="APPROVA"
                      title="APPROVA"
                      @click="askApprovedRejected(assenza, true)"
                      :disabled="
                        !isAuthenticatedSuperAdmin &&
                        !isAuthenticatedDTC &&
                        !isAuthenticatedGestionePersonale
                      "
                    >
                      <i class="fa-solid fa-thumbs-up"></i>
                    </button>
                    <button
                      v-if="!isApprovedRejected(assenza.status)"
                      type="button"
                      class="btn icon-button rejected"
                      alt="RESPINGI"
                      title="RESPINGI"
                      @click="askApprovedRejected(assenza, false)"
                      :disabled="
                        !isAuthenticatedSuperAdmin &&
                        !isAuthenticatedDTC &&
                        !isAuthenticatedGestionePersonale
                      "
                    >
                      <i class="fa-solid fa-thumbs-down"></i>
                    </button>
                  </ul>
                </td>
                <!-- Actions -->
                <td class="td-actions td-center">
                  <button
                    type="button"
                    class="btn icon-button"
                    alt="MODIFICA"
                    title="MODIFICA"
                    @click="editRichiestaAssenza(assenza)"
                    :disabled="
                      !isAuthenticatedSuperAdmin &&
                      !isAuthenticatedDTC &&
                      !isAuthenticatedGestionePersonale
                    "
                  >
                    <i class="zmdi zmdi-edit zmdi-hc-lg"></i>
                  </button>
                  <button
                    type="button"
                    class="btn icon-button delete"
                    alt="ELIMINA"
                    title="ELIMINA"
                    @click="askRemoveAssenza(assenza)"
                    :disabled="
                      !isAuthenticatedSuperAdmin &&
                      !isAuthenticatedDTC &&
                      !isAuthenticatedGestionePersonale
                    "
                  >
                    <i class="zmdi zmdi-delete zmdi-hc-lg"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div v-else-if="hasData" class="container-fluid m-0 p-0 padding-x-4px">
      <h5>Nessun dato da visualizzare per i filtri selezionati</h5>
    </div>
    <div v-else class="container-fluid m-0 p-0 padding-x-4px">
      <h5>Nessun dato da visualizzare</h5>
    </div>
  </div>
  <DialogAddEditAssenze
    :isVisible="isDialogVisible"
    :dialogWidth="'780px'"
    :dialogTitle="getDialogTitle"
    :dataItem="selectedAssenza"
    @closeModal="closeModal"
    @saveData="saveAssenza"
    :isEditMode="getIsEditMode"
    :userData="getUserData"
    :isFilterFerie="getIsFilterFerie"
  />
</template>

<script>
import { mapGetters } from "vuex";
import { UtilityMixins } from "@/mixins/UtilityMixins.js";
import {
  updateAssenza,
  fetchUserAttivitaAssenzeAggregato,
  fetchUserRapporto,
  uploadAllegatoAssenza,
  deleteAssenza,
} from "@/services/api";
import {
  RichiestaAssenza,
  RichiestaAssenzaSchema,
} from "@/models/RichiestaAssenzaJoiModel";
import OverlayLoading from "@/components/ui/OverlayLoading.vue";
import ProfiliPermissions from "@/mixins/ProfiliPermissions.js";
import DialogAddEditAssenze from "@/components/dialogs/DialogAddEditAssenze.vue";
import { Rapporto } from "@/models/TeamJoiModel";

export default {
  mixins: [UtilityMixins, ProfiliPermissions],
  components: {
    OverlayLoading,
    DialogAddEditAssenze,
  },
  data() {
    return {
      localRapporto: new Rapporto(),
      isLoading: false, // Gestione del loader per indicare il caricamento
      visualizzazione: "mensile", // Vista di default, può essere 'giornaliera', 'settimanale', 'mensile', 'annuale'
      selectedGiorno: "", // Giorno selezionato
      selectedMese: "", // Mese selezionato
      selectedAnno: "", // Anno selezionato
      selectedSettimana: { display: "", start: "", end: "" }, // Settimana corrente selezionata
      giorni: [], // Lista dei giorni per il mese selezionato
      settimane: [], // Lista di settimane popolate dinamicamente
      mesi: [
        "Gennaio",
        "Febbraio",
        "Marzo",
        "Aprile",
        "Maggio",
        "Giugno",
        "Luglio",
        "Agosto",
        "Settembre",
        "Ottobre",
        "Novembre",
        "Dicembre",
      ],
      anni: [], // Lista degli anni
      intestazione: "", // Variabile intestazione da aggiornare
      // dialog
      isDialogVisible: false,
      dialogTitle: "",
      selectedAssenza: {},
      isEditMode: false,
      isFilterFerie: false,
      // data assenze
      dataAssenzeAggregato: [], // Array assenze aggregato proveniente dal server
      dataAssenzeAggregatoSorted: [],
      providerUserId: null,
      userFullName: "",
      userCitta: null,
      userProfili: [],
      userOreContrattuali: 8,
      userData: null,
      status: ["Approved", "Pending", "Rejected"],
      statusStr: ["Approvata", "In attesa", "Respinta"],
      statusClass: ["approved", "pending", "rejected"],
      isAssenzeVisible: false,
      selectedFilterAssenze: "", // Filtro per tipo di assenza
      selectedFilterStato: "", // Filtro per stato assenza
      tipiAssenze: [
        { id: "", label: "TUTTE" },
        { id: "0", label: "FERIE" },
        { id: "1", label: "ROL" },
        { id: "2", label: "MALATTIA" },
        { id: "3", label: "PERMESSI GIUSTIFICATI" },
      ],
      // multiselect Risorse
      risorseOptions: [], // Dati da visualizzare nel multiselect
      selectedRisorse: [], // Valore selezionato
      // per mantenere lo stato dei filtri
      filterState: {
        risorse: [],
        mese: "",
        anno: "",
        tipoAssenza: "",
        statoAssenza: "",
      },
      userAuthenticated: null,
    };
  },
  computed: {
    ...mapGetters(["getViewsTypeAttivita"]),
    hasData() {
      return this.dataAssenzeAggregato.data && this.dataAssenzeAggregato.data.length > 0;
    },
    filteredHasData() {
      return this.filteredAssenze.length > 0;
    },
    filteredAssenze() {
      return this.dataAssenzeAggregatoSorted.filter((assenza) => {
        const tipoMatch =
          this.selectedFilterAssenze === "" ||
          assenza.tipoassenza.toString() === this.selectedFilterAssenze;

        const statoMatch =
          this.selectedFilterStato === "" || assenza.status === this.selectedFilterStato;

        const risorseMatch =
          this.selectedRisorse.length === 0 ||
          this.selectedRisorse.some((risorsa) => risorsa.value === assenza.fullname);

        return tipoMatch && statoMatch && risorseMatch;
      });
    },
    filterKey() {
      // Questa chiave cambierà ogni volta che i filtri vengono modificati
      return `${this.selectedFilterAssenze}-${this.selectedFilterStato}-${this.selectedRisorse.length}`;
    },
    //
    // Dialog
    //
    getUserData() {
      return this.userData;
    },
    getIsFilterFerie() {
      return this.isFilterFerie;
    },
    getDialogTitle() {
      return this.dialogTitle;
    },
    getIsEditMode() {
      return this.isEditMode;
    },
    //
    // Authenticated
    //
    isAuthenticatedSuperAdmin() {
      return this.permissions_isAuthenticatedSuperAdmin(this.userAuthenticated);
    },
    isAuthenticatedDTC() {
      return this.permissions_isAuthenticatedDTC(this.userAuthenticated);
    },
    isAuthenticatedGestionePersonale() {
      return this.permissions_isAuthenticatedGestionePersonale(this.userAuthenticated);
    },
  },
  watch: {
    // Watch per il getter di Vuex che osserva il cambio del valore
    getViewsTypeAttivita(newValue) {
      console.log("** getViewsTypeAttivita", newValue);
      // this.visualizzazione = newValue; // Aggiorna la variabile locale
      this.visualizzazione = "mensile"; // Aggiorna la variabile locale

      // Aggiorno l'intestazione iniziale
      this.aggiornaIntestazione();

      // fetch api
      this.fetchDataAssenze();
    },
    // Watcher per il mese, anno e giorno selezionati
    selectedGiorno() {
      this.updateSettimane(); // Ripopola le settimane quando cambia il giorno
    },
    selectedMese() {
      this.updateSettimane(); // Ripopola le settimane quando cambia il mese
    },
    selectedAnno() {
      this.updateSettimane(); // Ripopola le settimane quando cambia l'anno
    },
    selectedRisorse: {
      handler() {
        // Questo trigger farà ricalcolare filteredAssenze
        // Questo watcher assicura che ogni volta che selectedRisorse cambia, il componente si aggiorni e ricalcoli filteredAssenze
        this.$nextTick(() => {
          this.$forceUpdate();
        });
      },
      deep: true,
    },
  },

  methods: {
    // Metodo per salvare lo stato dei filtri
    saveFilterState() {
      this.filterState = {
        risorse: [...this.selectedRisorse],
        mese: this.selectedMese,
        anno: this.selectedAnno,
        tipoAssenza: this.selectedFilterAssenze,
        statoAssenza: this.selectedFilterStato,
      };
    },
    // Metodo per ripristinare lo stato dei filtri
    restoreFilterState() {
      if (this.filterState.risorse) {
        this.selectedRisorse = [...this.filterState.risorse];
      }
      if (this.filterState.mese) {
        this.selectedMese = this.filterState.mese;
      }
      if (this.filterState.anno) {
        this.selectedAnno = this.filterState.anno;
      }
      if (this.filterState.tipoAssenza !== undefined) {
        this.selectedFilterAssenze = this.filterState.tipoAssenza;
      }
      if (this.filterState.statoAssenza !== undefined) {
        this.selectedFilterStato = this.filterState.statoAssenza;
      }
    },
    //
    // Gestione filtro Risorse
    //
    onRisorseUpdate(selected) {
      console.log("** onRisorseUpdate", selected);
    },
    clearRisorse() {
      this.selectedRisorse = [];
    },
    populateFilterRisorse() {
      // Salva la selezione corrente
      const currentSelection = [...this.selectedRisorse];

      // Creo un oggetto per contare le occorrenze di ogni fullname
      const conteggio = this.dataAssenzeAggregatoSorted.reduce((acc, item) => {
        acc[item.fullname] = (acc[item.fullname] || 0) + 1;
        return acc;
      }, {});

      // Aggrego le risorse per fullname, includo il totale e ordino alfabeticamente
      const risorseUniche = Object.keys(conteggio).sort((a, b) =>
        a.localeCompare(b, "it", { sensitivity: "case" })
      );

      this.risorseOptions = risorseUniche.map((fullname) => ({
        value: fullname,
        label: `${fullname} (${conteggio[fullname]})`,
        totale: conteggio[fullname],
      }));

      // Ripristina la selezione precedente se le risorse esistono ancora nelle opzioni
      if (currentSelection.length > 0) {
        this.selectedRisorse = currentSelection.filter((selected) =>
          this.risorseOptions.some((option) => option.value === selected.value)
        );
      }
    },
    //
    //
    //
    calcolaTotaleAssenze(tipo) {
      let assenzeFiltrate = this.dataAssenzeAggregatoSorted;

      // Applica il filtro delle risorse
      if (this.selectedRisorse.length > 0) {
        const risorseSelezionate = this.selectedRisorse.map((r) => r.value);
        assenzeFiltrate = assenzeFiltrate.filter((assenza) =>
          risorseSelezionate.includes(assenza.fullname)
        );
      }

      if (tipo === "") {
        return assenzeFiltrate.length;
      }
      return assenzeFiltrate.filter((assenza) => assenza.tipoassenza.toString() === tipo)
        .length;
    },
    calcolaTotaleStato(stato) {
      let assenzeFiltrate = this.dataAssenzeAggregatoSorted;

      // Applica il filtro delle risorse
      if (this.selectedRisorse.length > 0) {
        const risorseSelezionate = this.selectedRisorse.map((r) => r.value);
        assenzeFiltrate = assenzeFiltrate.filter((assenza) =>
          risorseSelezionate.includes(assenza.fullname)
        );
      }

      // Filtra per tipo di assenza se è selezionato
      if (this.selectedFilterAssenze !== "") {
        assenzeFiltrate = assenzeFiltrate.filter(
          (assenza) => assenza.tipoassenza.toString() === this.selectedFilterAssenze
        );
      }

      // Poi filtra per stato
      if (stato === "") {
        return assenzeFiltrate.length;
      }
      return assenzeFiltrate.filter((assenza) => assenza.status === stato).length;
    },
    //
    // Gestione impostazione periodo
    //
    // Funzione per impostare il giorno selezionato
    setGiorno(giorno) {
      this.selectedGiorno = giorno;
      // Aggiorno l'intestazione iniziale
      this.aggiornaIntestazione();
    },

    // Funzione per selezionare la settimana
    setSettimana(settimana) {
      this.selectedSettimana = settimana;

      // Verifica se il giorno corrente cade nell'intervallo della settimana selezionata
      const startDate = new Date(settimana.start);
      const endDate = new Date(settimana.end);
      const selectedDate = new Date(
        this.selectedAnno,
        this.mesi.indexOf(this.selectedMese),
        this.selectedGiorno
      );

      // Se il giorno selezionato non è nell'intervallo della settimana, aggiorna giorno e mese
      if (selectedDate < startDate || selectedDate > endDate) {
        this.selectedGiorno = startDate.getDate(); // Imposta il giorno al primo giorno della settimana
        this.selectedMese = this.mesi[startDate.getMonth()]; // Imposta il mese corretto
      }

      // Aggiorno l'intestazione iniziale
      this.aggiornaIntestazione();
    },

    // Funzione per impostare il mese selezionato e ripopolare i giorni
    setMese(mese) {
      this.selectedMese = mese;
      const meseIndex = this.mesi.indexOf(mese) + 1;
      this.populateGiorni(this.selectedAnno, meseIndex);

      if (this.selectedGiorno > this.giorni.length) {
        this.selectedGiorno = 1; // Se il giorno selezionato non esiste nel nuovo mese, seleziona il primo giorno
      }
      // Aggiorno l'intestazione iniziale
      this.aggiornaIntestazione();
    },

    // Funzione per impostare l'anno selezionato e ripopolare i giorni
    setAnno(anno) {
      this.selectedAnno = anno;
      const meseIndex = this.mesi.indexOf(this.selectedMese) + 1;
      this.populateGiorni(anno, meseIndex);

      if (this.selectedGiorno > this.giorni.length) {
        this.selectedGiorno = 1; // Se il giorno selezionato non esiste nel nuovo mese, seleziona il primo giorno
      }

      // Aggiorno l'intestazione iniziale
      this.aggiornaIntestazione();
    },

    // Popola gli anni dinamicamente dall'anno corrente al 2010
    populateAnni() {
      this.anni = this.mixins_getArrayYearsUpToCurrentYear();
    },

    // Popola i giorni del mese selezionato
    populateGiorni(anno, mese) {
      const daysInMonth = new Date(anno, mese, 0).getDate();
      this.giorni = Array.from({ length: daysInMonth }, (v, i) => i + 1);
    },

    // Funzione per ripopolare le settimane in base al giorno, mese e anno selezionati
    updateSettimane() {
      const date = new Date(
        this.selectedAnno,
        this.mesi.indexOf(this.selectedMese),
        this.selectedGiorno
      );
      const currentWeek = this.getStartOfWeek(date);
      this.settimane = this.calculateSurroundingWeeks(currentWeek, 5);
      this.selectedSettimana = this.settimane[5]; // Seleziona la settimana corrente
    },

    getStartOfWeek(date) {
      const day = date.getDay();
      const diff = date.getDate() - (day === 0 ? 6 : day - 1); // Ottieni il lunedì
      return new Date(date.setDate(diff));
    },
    // Calcolo settimane, 5 precedenti e 5 successive
    calculateSurroundingWeeks(startDate, range) {
      const weeks = [];
      const monday = new Date(startDate);

      // 5 settimane precedenti
      for (let i = range; i > 0; i--) {
        const previousMonday = new Date(monday);
        previousMonday.setDate(monday.getDate() - 7 * i);
        const sunday = new Date(previousMonday);
        sunday.setDate(previousMonday.getDate() + 6);
        weeks.push({
          display: `${this.formatDateForDisplay(
            previousMonday
          )} - ${this.formatDateForDisplay(sunday)}`,
          start: this.formatDate(previousMonday),
          end: this.formatDate(sunday),
        });
      }

      // Settimana corrente
      const currentMonday = new Date(monday);
      const currentSunday = new Date(currentMonday);
      currentSunday.setDate(currentMonday.getDate() + 6);
      weeks.push({
        display: `${this.formatDateForDisplay(
          currentMonday
        )} - ${this.formatDateForDisplay(currentSunday)}`,
        start: this.formatDate(currentMonday),
        end: this.formatDate(currentSunday),
      });

      // 5 settimane successive
      for (let i = 1; i <= range; i++) {
        const nextMonday = new Date(monday);
        nextMonday.setDate(monday.getDate() + 7 * i);
        const nextSunday = new Date(nextMonday);
        nextSunday.setDate(nextMonday.getDate() + 6);
        weeks.push({
          display: `${this.formatDateForDisplay(
            nextMonday
          )} - ${this.formatDateForDisplay(nextSunday)}`,
          start: this.formatDate(nextMonday),
          end: this.formatDate(nextSunday),
        });
      }

      return weeks;
    },

    formatDateForDisplay(date) {
      const day = date.getDate().toString().padStart(2, "0");
      const month = this.mesi[date.getMonth()].substring(0, 3);
      return `${day} ${month}`;
    },

    formatDate(date) {
      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        date.getDate().toString().padStart(2, "0")
      );
    },

    aggiornaIntestazione() {
      if (this.visualizzazione === "giornaliera") {
        // Formato: "Giornaliero del 4 settembre 2024"
        this.intestazione = `Giornaliero del ${
          this.selectedGiorno
        } ${this.selectedMese.toLowerCase()} ${this.selectedAnno}`;
      } else if (this.visualizzazione === "settimanale") {
        // Formato: "Settimanale - 2 settembre 8 settembre 2024"
        const start = new Date(this.selectedSettimana.start);
        const end = new Date(this.selectedSettimana.end);
        this.intestazione = `Settimanale - ${start.getDate()} ${this.mesi[
          start.getMonth()
        ].toLowerCase()}, ${end.getDate()} ${this.mesi[end.getMonth()].toLowerCase()} ${
          this.selectedAnno
        }`;
      } else if (this.visualizzazione === "mensile") {
        // Formato: "Mensile - Settembre 2024"
        this.intestazione = `Mensile - ${this.selectedMese} ${this.selectedAnno}`;
      } else if (this.visualizzazione === "annuale") {
        // Formato: "Annuale - 2024"
        this.intestazione = `Annuale - ${this.selectedAnno}`;
      }
    },
    async onVai() {
      await this.fetchDataAssenze();
    },
    //
    // Fetch data
    //
    async fetchDataAssenze() {
      this.isAssenzeVisible = true;
      // Salva lo stato dei filtri prima del fetch
      this.saveFilterState();

      // Eseguo il fetch dei dati
      await this.fetchAllData(99, "N");

      // Ripristino lo stato dei filtri dopo il fetch
      this.$nextTick(() => {
        this.restoreFilterState();
      });
    },
    async fetchAllData(tipoAssenza, currentUser) {
      // 0 = Ferie 1=ROL, 2=Malattia, 3=Permessi Giustificati, 99=Tutte le assenze
      // Eseguo il fetch dei dati delle assenze aggregato
      const { start: dateFrom, end: dateTo } = this.composeDateRangeAssenzeAggregato();
      await this.fetchAssenzeAggregato(dateFrom, dateTo, tipoAssenza, currentUser);
      this.populateFilterRisorse();
      console.log("** assenze aggregato", this.dataAssenzeAggregato);
    },
    async fetchAssenzeAggregato(dateFrom, dateTo, tipoAssenza, currentUser) {
      // Mostro il loader subito dopo il montaggio del componente
      this.$nextTick(() => {
        this.isLoading = true;
      });

      this.dataAssenzeAggregato = [];

      try {
        const response = await fetchUserAttivitaAssenzeAggregato(
          dateFrom,
          dateTo,
          tipoAssenza,
          currentUser
        );
        if (response) {
          this.dataAssenzeAggregato = response;
          console.log("** assenze aggregato response", response);
          this.dataAssenzeAggregatoSorted = this.ordinaPerDataInizioDesc(
            this.dataAssenzeAggregato
          );
          console.log("** assenze aggregato sorted", this.dataAssenzeAggregatoSorted);
        }
      } catch (error) {
        this.mixins_showMessage(
          "Caricamento dati",
          `Errore durante il caricamento dei dati: ${error.message}`,
          "error"
        );
      } finally {
        this.$nextTick(() => {
          this.isLoading = false;
        });
      }
    },
    //
    // Gestione assenze
    //
    composeDateRangeAssenzeAggregato() {
      let result;

      if (this.visualizzazione === "giornaliera") {
        const anno = this.selectedAnno;
        const mese = (this.mesi.indexOf(this.selectedMese) + 1)
          .toString()
          .padStart(2, "0");
        const giorno = this.selectedGiorno.toString().padStart(2, "0");
        result = {
          start: `${anno}-${mese}-${giorno}`,
          end: `${anno}-${mese}-${giorno}`,
        };
      } else if (this.visualizzazione === "settimanale") {
        result = {
          start: this.selectedSettimana.start,
          end: this.selectedSettimana.end,
        };
      } else if (this.visualizzazione === "mensile") {
        const anno = this.selectedAnno;
        const mese = (this.mesi.indexOf(this.selectedMese) + 1)
          .toString()
          .padStart(2, "0");
        const firstDay = `${anno}-${mese}-01`;
        const lastDay = `${anno}-${mese}-${new Date(anno, parseInt(mese), 0)
          .getDate()
          .toString()
          .padStart(2, "0")}`;
        result = {
          start: firstDay,
          end: lastDay,
        };
      } else if (this.visualizzazione === "annuale") {
        const anno = this.selectedAnno;
        result = {
          start: `${anno}-01-01`,
          end: `${anno}-12-31`,
        };
      }
      console.log("** assenze composeDateRangeAssenzeAggregato", result);
      return result;
    },
    ordinaPerDataInizioDesc(obj) {
      if (!obj || !Array.isArray(obj.data)) {
        console.error("L'oggetto o la proprietà data non è valido", obj, obj.data);
        return [];
      }

      return obj.data.sort((a, b) => new Date(b.datainizio) - new Date(a.datainizio));
    },
    isZeroValue(value) {
      return value === 0.0 || value === 0 || value === "0,0" || value === "0.0";
    },
    getStatusParameters(status) {
      // status = "Rejected";
      const statusIndex = this.status.indexOf(status);

      if (statusIndex === -1) {
        // Se lo status non esiste nell'array, ritorno un valore di default o null
        return { status: "Sconosciuto", class: "black" };
      }

      return {
        status: this.statusStr[statusIndex],
        class: this.statusClass[statusIndex],
      };
    },
    isApprovedRejected(status) {
      return (
        this.status.includes(status) && (status === "Approved" || status === "Rejected")
      );
    },
    viewDocumento(url) {
      if (url) {
        window.open(url, "_blank");
      } else {
        this.mixins_showMessage("Errore", "Documento non disponibile", "error");
      }
    },
    hideAssenze() {
      this.isAssenzeVisible = false;
    },
    validateRichiestaAssenza(data) {
      const { error } = RichiestaAssenzaSchema.validate(data, { abortEarly: false });
      if (error) {
        console.log("** assenze validation errors:", error.details);
        return false;
      }
      return true;
    },
    async askApprovedRejected(assenza, isApproved) {
      let message = "";
      let title = "";
      if (isApproved) {
        title = "Approva Assenza";
        message = "Confermi di voler APPROVARE l'assenza?";
      } else {
        title = "Respingi Assenza";
        message = "Confermi di voler RESPINGERE l'assenza?";
      }
      this.$swal
        .fire({
          title: title,
          html: message,
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: '<i class="fa fa-check"></i> Conferma',
          cancelButtonText: '<i class="fa fa-xmark"></i> Annulla',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await this.saveApprovedRejected(assenza, isApproved);
          }
        });
    },
    async saveApprovedRejected(assenza, isApproved) {
      const clonedAssenza = structuredClone(assenza);

      clonedAssenza.status = isApproved ? "Approved" : "Rejected";

      const isValid = this.validateRichiestaAssenza(clonedAssenza);

      if (!isValid) {
        this.mixins_showMessage(
          "Stato Assenza",
          "Errore nella validazione dei dati",
          "error"
        );
        return;
      }

      this.isLoading = true;
      try {
        const apiResponse = await updateAssenza(clonedAssenza, clonedAssenza._id);

        if (apiResponse != undefined) {
          this.mixins_showMessage("Stato Assenza", "Dati inviati correttamente", "info");
          await this.fetchDataAssenze();
        } else {
          clonedAssenza.status = "Pending";
        }
      } catch (error) {
        console.error("Errore durante il salvataggio dei dati", error);
        clonedAssenza.status = "Pending";
      } finally {
        this.isLoading = false;
      }
    },
    //
    // Gestione dialog
    //
    async editRichiestaAssenza(assenza) {
      console.log("** assenza", assenza);

      if (!assenza?.iduser) {
        this.mixins_showMessage(
          "Dati Rapporto",
          "Impossibile recuperare i dati, ID utente inesistente o non disponibile",
          "warning"
        );
        return;
      }

      // Ottengo il rapporto dell'utente
      const isRapportoLoaded = await this.fetchRapporto(assenza?.iduser);
      if (!isRapportoLoaded) return;

      // Compongo l'oggetto user da passare al dialog
      this.userData = {
        providerUserId: assenza.userid,
        profili: this.localRapporto.profili,
        fullname: assenza.fullname,
        citta: this.localRapporto.sede.descrizione
          ? this.localRapporto.sede.descrizione
          : "",
        orecontrattuali: this.localRapporto.orecontrattuali
          ? this.localRapporto.orecontrattuali
          : 8,
      };

      // Se tipoassenza = 0 sono ferie
      this.isFilterFerie = assenza?.tipoassenza == 0 ? true : false;

      this.isEditMode = true;
      this.dialogTitle =
        "MODIFICA RICHIESTA " + (this.isFilterFerie ? "FERIE" : "ASSENZE");
      this.selectedAssenza = new RichiestaAssenza(assenza);
      console.log("** this.selectedAssenza", this.selectedAssenza);
      this.isDialogVisible = true;
    },
    async saveAssenza(dataRecived, fileRecived) {
      console.log(
        "** Dati aggiornati ricevuti da DialogAddEditAssenze:",
        dataRecived,
        fileRecived
      );

      this.closeModal();

      let allegatoUrl = dataRecived.documentoallegato
        ? dataRecived.documentoallegato
        : "";

      // Salvo allegato, se va a buon fine salvo assenza
      const file = fileRecived;
      if (file) {
        this.isLoading = true;
        try {
          const response = await uploadAllegatoAssenza(file);
          if (response && response.url) {
            console.log("** allegato url", response.url);
            allegatoUrl = response.url;
            this.mixins_showMessage(
              "Caricamento Allegato",
              "Documento caricato correttamente",
              "info"
            );
          }
        } catch (error) {
          this.mixins_showMessage(
            "Caricamento Allegato",
            `Errore durante il caricamento del documento allegato: ${error}`,
            "error"
          );
          this.isLoading = false;
          return;
        } finally {
          this.isLoading = false;
        }
      }

      // Aggiorna la url
      dataRecived.documentoallegato = allegatoUrl;

      // Salvo i dati dell'assenza
      this.isLoading = true;
      try {
        if (this.isEditMode) {
          const apiResponse = await updateAssenza(dataRecived, dataRecived._id);
          console.log("** assenze updateAssenza", apiResponse);
          if (apiResponse != undefined) {
            this.mixins_showMessage(
              "Aggiornamento Richiesta Assenza",
              "Dati modificati correttamente",
              "info"
            );
            await this.fetchDataAssenze();
          }
        }
      } catch (error) {
        console.error("Errore durante il salvataggio dei dati", error);
      } finally {
        this.isLoading = false;
      }
    },
    async askRemoveAssenza(assenza) {
      this.$swal
        .fire({
          title: "Cancellazione Assenza",
          html: "Sei sicuro di voler cancellare l'assenza?",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: '<i class="fa fa-check"></i> Conferma',
          cancelButtonText: '<i class="fa fa-xmark"></i> Annulla',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await this.removeAssenza(assenza);
          }
        });
    },
    async removeAssenza(assenza) {
      console.log("** delete assenza id", assenza._id);
      this.isLoading = true;
      try {
        const apiResponse = await deleteAssenza(assenza._id);
        console.log("** assenze removeAssenza", apiResponse);
        if (apiResponse != undefined) {
          this.mixins_showMessage(
            "Cancellazione Assenza",
            "Assenza cancellata correttamente",
            "info"
          );
          await this.fetchDataAssenze();
        }
      } catch (error) {
        console.error("Errore durante l'eliminazione dell'assenza");
      } finally {
        this.isLoading = false;
      }
    },
    closeModal() {
      this.isDialogVisible = false;
    },
    async fetchRapporto(iduser) {
      // Mostro il loader subito dopo il montaggio del componente
      this.$nextTick(() => {
        this.isLoading = true;
      });

      let isRapportoLoaded = false;

      try {
        const response = await fetchUserRapporto(iduser);
        if (response) {
          // Se l'oggetto mandato dal server è vuoto, mando un messaggio di errore
          if (this.mixins_isEmptyObject(response)) {
            this.errorMessage =
              "Si è verificato un errore durante il caricamento dei dati";
            this.mixins_showMessage(
              "Dati Rapporto",
              "Si è verificato un errore durante il caricamento dei dati",
              "error"
            );
            return;
          }

          this.localRapporto = new Rapporto(response.rapporto);
          console.log("** dati localRapporto", this.localRapporto);
          isRapportoLoaded = true;
        } else {
          this.mixins_showMessage(
            "Dati Rapporto",
            "Dati inesistenti per il rapporto",
            "error"
          );
          isRapportoLoaded = false;
        }
      } catch (error) {
        this.mixins_showMessage(
          "Dati Rapporto",
          "Dati inesistenti per il rapporto",
          "error"
        );
        isRapportoLoaded = false;
      } finally {
        // Nascondo il loader al termine del caricamento
        this.$nextTick(() => {
          this.isLoading = false;
        });
      }
      return isRapportoLoaded;
    },
  },
  async created() {
    // Oggetto user da local storage
    this.userAuthenticated = this.mixin_getUserDataLS();

    // Imposta variabili date
    const today = new Date();
    this.selectedGiorno = today.getDate();
    this.selectedMese = this.mesi[today.getMonth()];
    this.selectedAnno = today.getFullYear();

    // Popola giorni e settimane per la vista giornaliera e settimanale
    this.populateGiorni(today.getFullYear(), today.getMonth() + 1);
    this.updateSettimane(); // Popola le settimane inizialmente

    // Popola anni dall'anno corrente fino al 2010
    this.populateAnni();

    // Imposto la viasualizzazione corrente
    if (this.$route.path.includes("team")) {
      //Se mi trovo in /team allora la visualizzazione è solo mensile
      this.visualizzazione = "mensile";
    } else {
      this.visualizzazione = this.getViewsTypeAttivita;
    }

    // Aggiorno l'intestazione iniziale
    this.aggiornaIntestazione();

    // await this.fetchDataAssenze();
  },
};
</script>
