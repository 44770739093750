<template>
  <loading
    v-model:active="localLoading"
    :can-cancel="false"
    :is-full-page="true"
    :width="64"
    :height="64"
    backgroundColor="#777777"
    color="#1882c8"
    loader="bars"
  />
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: {
    Loading,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localLoading: this.isLoading, // Copia locale della prop
    };
  },
  watch: {
    isLoading: {
      immediate: true,
      handler(newVal) {
        this.localLoading = newVal; // Aggiorno la copia locale quando cambia la prop
      },
    },
    localLoading(newVal) {
      // Notifico il genitore del cambiamento (posso anche ometterlo)
      this.$emit("update:isLoading", newVal);
    },
  },
};
</script>
