import Joi from "joi";

// Costruttore per l'oggetto RichiestaAssenza
class RichiestaAssenza {
    constructor(data = {}) {
        this._id = data._id || "";
        this.note = data.note || "";
        // this.iduser = data.iduser || "";
        this.datainizio = data.datainizio || null;
        this.datafine = data.datafine || null;
        this.datainizio_old = data.datainizio_old || null;
        this.datafine_old = data.datafine_old || null;
        this.oregiornaliere = data.oregiornaliere || 0;
        this.giorni = data.giorni || 0;
        this.puc = data.puc || "";
        this.documentoallegato = data.documentoallegato || "";
        this.tipoassenza = data.tipoassenza || -1;
        this.tipoassenza_descrizione = data.tipoassenza_descrizione || "";
        this.dettaglioassenza = data.dettaglioassenza || -1;
        this.dettaglioassenza_descrizione = data.dettaglioassenza_descrizione || "";
        this.status = data.status || "Pending";
        this.fullname = data.fullname || "";
    }
}

// Schema di validazione Joi per RichiestaAssenza
const RichiestaAssenzaSchema = Joi.object({
    _id: Joi.string().allow(""),
    note: Joi.string().allow("").allow(null),
    datainizio: Joi.date().iso().required().messages({
        "date.base": 'Il campo "DATA INIZIO" deve essere una data valida',
        "any.required": 'Il campo "DATA INIZIO" è obbligatorio',
    }),
    datafine: Joi.date().iso().required().messages({
        "date.base": 'Il campo "DATA FINE" deve essere una data valida',
        "any.required": 'Il campo "DATA FINE" è obbligatorio',
    }),
    datainizio_old: Joi.date().iso().allow(null),
    datafine_old: Joi.date().iso().allow(null),
    oregiornaliere: Joi.number().default(0).messages({
        "number.base": 'Il campo "ORE GIORNALIERE" deve essere un numero',
    }),
    giorni: Joi.number().default(0).messages({
        "number.base": 'Il campo "GIORNI" deve essere un numero',
    }),
    puc: Joi.string()
        .when('tipoassenza', {
            is: 2, // Quando tipoassenza è 2 (Malattia)
            then: Joi.string()
                .pattern(/^\d{9}$/)
                .required()
                .messages({
                    "string.empty": 'Il campo "PUC" è obbligatorio',
                    "string.pattern.base": 'Il campo "PUC" deve essere di 9 caratteri numerici',
                    "any.required": 'Il campo "PUC" è obbligatorio'
                }),
            otherwise: Joi.string()
                .allow("") // Consento stringa vuota per altri tipi di assenza
                .optional() // Non obbligatorio
        }),
    documentoallegato: Joi.string().allow(""),
    tipoassenza: Joi.number().default(-1).messages({
        "number.base": 'Il campo "TIPO ASSENZA" deve essere un numero',
    }),
    tipoassenza_descrizione: Joi.string().required().messages({
        "string.empty": 'Il campo "TIPO ASSENZA" è obbligatorio',
        "any.required": 'Il campo "TIPO ASSENZA" è obbligatorio',
    }),
    dettaglioassenza: Joi.number().default(-1).messages({
        "number.base": 'Il campo "TIPO PERMESSO" deve essere un numero',
    }),
    dettaglioassenza_descrizione: Joi.string().required().messages({
        "string.empty": 'Il campo "TIPO PERMESSO" è obbligatorio',
        "any.required": 'Il campo "TIPO PERMESSO" è obbligatorio',
    }),
    status: Joi.string().valid("Pending", "Approved", "Rejected").default("Pending").messages({
        "any.only": 'Lo "STATUS" deve essere uno tra "Pending", "Approved" o "Rejected"',
    }),
    fullname: Joi.string().allow("").allow(null),
    updatedAt: Joi.string().allow("").allow(null),
    updatedby: Joi.string().allow("").allow(null),
    iduser: Joi.string().allow("").allow(null),
}).custom((value, helpers) => {
    // Verifico se datafine è minore di datainizio
    if (value.datafine < value.datainizio) {
        return helpers.message('La "DATA FINE" non può essere antecedente alla "DATA INIZIO"');
    }

    // Verifico se oregiornaliere > 0 quando tipoassenza è 1 o 3
    if ((value.tipoassenza === 1 || value.tipoassenza === 3) && value.oregiornaliere <= 0) {
        return helpers.message('Il campo "ORE GIORNALIERE" deve essere maggiore di 0');
    }

    // Verifico se tipoassenza è 3 e dettaglioassenza_descrizione è vuoto
    if (value.tipoassenza === 3 && (!value.dettaglioassenza_descrizione || value.dettaglioassenza_descrizione.trim() === "")) {
        return helpers.message('Il campo "TIPO PERMESSO" è obbligatorio');
    }

    return value;
});


// const RichiestaAssenzaSchema = Joi.object({
//     _id: Joi.string().allow(""),
//     note: Joi.string().allow("").allow(null),
//     datainizio: Joi.date().iso().required().messages({
//         "date.base": 'Il campo "DATA INIZIO" deve essere una data valida',
//         "any.required": 'Il campo "DATA INIZIO" è obbligatorio',
//     }),
//     datainizio_old: Joi.date().iso().allow(null),
//     datafine: Joi.date().iso().required().messages({
//         "date.base": 'Il campo "DATA FINE" deve essere una data valida',
//         "any.required": 'Il campo "DATA FINE" è obbligatorio',
//     }),
//     datafine_old: Joi.date().iso().allow(null),
//     oregiornaliere: Joi.number().default(0).messages({
//         "number.base": 'Il campo "ORE GIORNALIERE" deve essere un numero',
//     }),
//     giorni: Joi.number().default(0).messages({
//         "number.base": 'Il campo "GIORNI" deve essere un numero',
//     }),
//     // puc: Joi.string()
//     //     .pattern(/^\d{9}$/) // Esattamente 9 caratteri numerici
//     //     .required()
//     //     .messages({
//     //         "string.empty": 'Il campo "PUC" è obbligatorio',
//     //         "string.pattern.base": 'Il campo "PUC" deve essere di 9 caratteri numerici',
//     //         "any.required": 'Il campo "PUC" è obbligatorio',
//     //     }),
//     puc: Joi.string()
//         .pattern(/^\d{9}$/) // Esattamente 9 caratteri numerici
//         .allow("") // Consenti stringa vuota per casi non obbligatori
//         .messages({
//             "string.pattern.base": 'Il campo "PUC" deve essere di 9 caratteri numerici',
//         }),
//     documentoallegato: Joi.string().allow(""),
//     // il tipoassenza può essere 0= Ferie, 1=ROL, 2=Malattia, 3=Permessi Giustificati
//     tipoassenza: Joi.number().default(-1).messages({
//         "number.base": 'Il campo "TIPO ASSENZA" deve essere un numero',
//     }),
//     tipoassenza_descrizione: Joi.string().required().messages({
//         "string.empty": 'Il campo "TIPO ASSENZA" è obbligatorio',
//         "any.required": 'Il campo "TIPO ASSENZA" è obbligatorio',
//     }),
//     dettaglioassenza: Joi.number().default(-1).messages({
//         "number.base": 'Il campo "TIPO PERMESSO" deve essere un numero',
//     }),
//     dettaglioassenza_descrizione: Joi.string().required().messages({
//         "string.empty": 'Il campo "TIPO PERMESSO" è obbligatorio',
//         "any.required": 'Il campo "TIPO PERMESSO" è obbligatorio',
//     }),
//     status: Joi.string().valid('Pending', 'Approved', 'Rejected').default('Pending').messages({
//         "any.only": 'Lo "STATUS" deve essere uno tra "Pending", "Approved" o "Rejected"',
//     }),
//     fullname: Joi.string().allow("").allow(null),
//     updatedAt: Joi.string().allow("").allow(null),
//     updatedby: Joi.string().allow("").allow(null),
//     iduser: Joi.string().allow("").allow(null),
// }).custom((value, helpers) => {
//     // Verifico se datafine è minore di datainizio
//     if (value.datafine < value.datainizio) {
//         return helpers.message('La "DATA FINE" non può essere antecedente alla "DATA INIZIO"');
//     }

//     // Verifico se oregiornaliere > 0 quando tipoassenza è 1 o 3
//     if ((value.tipoassenza === 1 || value.tipoassenza === 3) && value.oregiornaliere <= 0) {
//         return helpers.message('Il campo "ORE GIORNALIERE" deve essere maggiore di 0');
//     }

//     // Verifico se tipoassenza è 3 e dettaglioassenza_descrizione è vuoto
//     if (value.tipoassenza === 3 && (!value.dettaglioassenza_descrizione || value.dettaglioassenza_descrizione.trim() === "")) {
//         return helpers.message('Il campo "TIPO PERMESSO" è obbligatorio quando "TIPO ASSENZA" è "Permessi Giustificati"');
//     }

//     // Verifico se tipoassenza è 2 e puc è vuoto
//     if (value.tipoassenza === 2 && (!value.puc || value.puc.trim() === "")) {
//         return helpers.message('Il campo "PUC" è obbligatorio');
//     }

//     // Quando tipoassenza non è 2, puc deve essere vuoto
//     if (value.tipoassenza !== 2 && value.puc && value.puc.trim() !== "") {
//         return helpers.message('Il campo "PUC" deve essere vuoto se "TIPO ASSENZA" non è "Malattia"');
//     }

//     return value;
// });

export { RichiestaAssenza, RichiestaAssenzaSchema };
