<template>
  <transition name="backdrop-modal">
    <div v-if="isVisible" class="backdrop-dialog"></div>
  </transition>

  <transition name="modal">
    <div class="dialog background-gray" :style="`width: ${dialogWidth}`" v-if="isVisible">
      <div class="container-fluid">
        <div class="row">
          <div class="header-text w-100">
            <h1>{{ dialogTitle }}</h1>
          </div>
        </div>
        <hr class="sepGray" />
        <div class="container-fluid p-0 m-0">
          <div class="container-fluid px-0 py-3">
            <!-- Invia a tutti i profili -->
            <div class="row mb-3">
              <label
                class="col-form-label form-label col-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
                ><span>TUTTI I PROFILI</span></label
              >
              <div class="align-items-center col d-flex justify-content-start">
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="tuttiProfili"
                    @change="toggleTuttiProfili"
                  />
                  <label class="content-form text-end label-yes-no" for="inputTurnista">
                    {{ tuttiProfili === true ? "SI" : "NO" }}
                  </label>
                </div>
              </div>
            </div>
            <!-- Profili -->
            <div class="row mb-3">
              <label
                class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
                ><span>PROFILI</span>
              </label>
              <div class="align-items-center col-lg-10 d-flex justify-content-center">
                <MultiSelect
                  ref="multiselectProfili"
                  v-model="selectedLocalModels.profiles_recipient"
                  :options="profiliOptions"
                  :searchable="true"
                  placeholder="- SELEZIONA -"
                  label="label"
                  track-by="value"
                  :show-no-results="true"
                  selectLabel="Seleziona"
                  deselectLabel="Rimuovi"
                  selectedLabel="Selezionato"
                  :openDirection="'below'"
                  tagPlaceholder="Premi enter per creare un tag"
                  :multiple="true"
                  :taggable="false"
                  :showLabels="false"
                  @update:modelValue="onProfiliUpdate"
                  :maxHeight="210"
                  :disabled="isDisabled"
                >
                  <template #clear>
                    <div
                      v-if="
                        localData.profiles_recipient &&
                        localData.profiles_recipient.length
                      "
                      class="multiselect__clear"
                      @mousedown.prevent.stop="clearProfili"
                    ></div>
                  </template>
                  <template #noOptions>
                    <span>L'elenco è vuoto</span>
                  </template>
                  <template #noResult>
                    <span>Nessun elemento trovato</span>
                  </template>
                </MultiSelect>
              </div>
            </div>
            <!-- Messaggio -->
            <div class="row mb-3">
              <label
                class="col-form-label form-label col-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
                ><span>MESSAGGIO</span></label
              >
              <div class="align-items-center col d-flex justify-content-center">
                <textarea
                  rows="6"
                  type="text"
                  v-input-textarea
                  class="form-control"
                  v-model.trim="localData.text"
                  spellcheck="false"
                  autocomplete="off"
                  autocapitalize="none"
                  placeholder="MESSAGGIO"
                />
              </div>
            </div>
          </div>
        </div>
        <hr class="sepGray" />
        <div class="row">
          <div class="container-fluid my-3">
            <button
              @click="closeModal"
              type="button"
              class="btn button-outline-1 float-start"
            >
              <span><i class="zmdi zmdi-close"></i>Annulla</span>
            </button>
            <button
              @click="saveData"
              type="button"
              class="btn button-outline-1 float-end"
            >
              <span
                ><i class="bi bi-send-fill"></i
                >{{ isEditMode ? "Modifica messaggio" : "Invia messaggio" }}</span
              >
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { UtilityMixins } from "@/mixins/UtilityMixins.js";

export default {
  mixins: [UtilityMixins],
  components: {},
  props: {
    isVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
    dialogWidth: {
      type: String,
      default: "1200px",
    },
    dialogTitle: {
      type: String,
      default: "",
    },
    dataItem: {
      type: Object,
      default: () => ({}),
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["closeModal", "saveData"],
  data() {
    return {
      localData: null,
      // multiselect
      profiliOptions: [],
      tuttiProfili: false,
      isDisabled: false,
      selectedLocalModels: {
        // Raccolgo i modelli locali in un unico oggetto
        profiles_recipient: [],
      },
    };
  },
  computed: {},
  watch: {
    // Quando il modal diventa visibile, inizializzo i dati locali e le selezioni
    isVisible(newVal) {
      if (newVal) {
        // Inizializzo `localData`
        this.localData = this.dataItem;
        this.tuttiProfili = false;
        this.enableMultiselect();

        console.log("** watch localData", this.localData);

        // Verifico se profiles_recipient contiene "all"
        if (this.localData.profiles_recipient.includes("all")) {
          // Se contiene "all", richiamo la funzione per selezionare tutti i profili
          this.setAllProfiles();
        } else if (
          Array.isArray(this.localData.profiles_recipient) &&
          this.localData.profiles_recipient.length > 0
        ) {
          // Mappo gli ID dei profili in `profiles_recipient` con i profili corrispondenti in `profiliOptions`
          this.selectedLocalModels.profiles_recipient = this.localData.profiles_recipient.map(
            (profiloId) => {
              // Cerco il profilo corrispondente in `profiliOptions` usando l'ID
              const profilo = this.profiliOptions.find(
                (option) => option.value === profiloId
              );
              // Se il profilo è trovato, ritorno il profilo; altrimenti ritorna un oggetto "sconosciuto"
              return profilo || { value: profiloId, label: "- Profilo sconosciuto -" };
            }
          );
        } else {
          // Se non ci sono profili, imposto un array vuoto
          this.selectedLocalModels.profiles_recipient = [];
        }
      }
    },
  },
  methods: {
    closeModal() {
      this.localData = null;
      this.$emit("closeModal");
    },
    async saveData() {
      // Controllo se `tuttiProfili` è true con `localData.text` non vuoto, oppure se ci sono profili selezionati e `localData.text` non è vuoto
      if (
        this.tuttiProfili &&
        this.localData.text !== "" &&
        this.localData.profiles_recipient.length === 0
      ) {
        this.localData.profiles_recipient = ["all"];
        this.$emit("saveData", this.localData, this.tuttiProfili);
      } else if (
        !this.tuttiProfili &&
        this.localData.profiles_recipient.length > 0 &&
        this.localData.text !== ""
      ) {
        this.$emit("saveData", this.localData, this.tuttiProfili);
      } else {
        // Se le condizioni non sono soddisfatte
        console.warn(
          "Condizioni non soddisfatte: selezionare almeno un profilo o attivare 'Tutti i profili' e inserire un messaggio."
        );
        this.mixins_showMessage(
          "Validazione Dati",
          "Selezionare almeno un 'PROFILO' o attivare 'TUTTI I PROFILI' e inserire un 'MESSAGGIO'",
          "warning"
        );
      }
    },
    toggleTuttiProfili(event) {
      console.log("** toggleTuttiProfili", event.target.checked);
      if (event.target.checked == true) {
        this.localData.profiles_recipient = [];
        this.selectedLocalModels.profiles_recipient = [];
        this.disableMultiselect();
      } else {
        this.localData.profiles_recipient = [];
        this.selectedLocalModels.profiles_recipient = [];
        this.enableMultiselect();
      }
    },
    disableMultiselect() {
      this.isDisabled = true;
    },
    enableMultiselect() {
      this.isDisabled = false;
    },
    //
    // Profili
    //
    onProfiliUpdate(selected) {
      if (Array.isArray(selected) && selected.length > 0) {
        // Mappa l'array selezionato per ottenere solo gli ID dei profili
        this.localData.profiles_recipient = selected.map((profilo) => profilo.value);
      } else {
        // Se non ci sono profili selezionati, imposta come array vuoto
        this.localData.profiles_recipient = [];
      }
    },
    clearProfili() {
      this.localData.profiles_recipient = [];
      this.selectedLocalModels.profiles_recipient = [];
    },
    setAllProfiles() {
      this.tuttiProfili = true;
      this.localData.profiles_recipient = [];
      this.selectedLocalModels.profiles_recipient = [];
      this.disableMultiselect();
    },
  },
  async mounted() {
    // Carico le opzioni dei profili
    const tabProfili = await this.mixins_getTabProfili();
    this.profiliOptions =
      tabProfili?.map((profilo) => ({
        value: profilo.id,
        label: profilo.nome,
      })) || [];
    console.log("** profiliOptions", this.profiliOptions);
  },
};
</script>
