import axios from "axios";
import { refreshToken, isTokenExpired } from "./auth";
import store from "@/store";
import config from "@/config";
import { UtilityMixins } from "@/mixins/UtilityMixins.js";
import MessagesService from "@/services/MessagesService";

const API_URL = config.API_URL;
const AUTH_URL = config.AUTH_URL;

export const callLogout = async () => {
  try {
    await store.dispatch("logout"); // Chiama l'azione logout

    // Import dinamico del router
    const { default: router } = await import("@/router");
    router.push({ name: "route-login" }); // Reindirizza all'endpoint di login
  } catch (error) {
    console.error("Errore durante il logout:", error);
  }
};

// Funzione che restituisce la data odierna nel formato YYYY-MM-DD (utile nelle query string)
export const todayFormatDate = (date = new Date()) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // getMonth() ritorna da 0 (gennaio) a 11 (dicembre)
  const day = date.getDate();
  return `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;
};

// Funzione per controllare e rinnovare il token se necessario
const checkAndRefreshToken = async () => {
  // Recupera i dati dell'utente dal localStorage
  const user = JSON.parse(localStorage.getItem(config.sessionDataLS));

  // Se non ci sono dati utente, ignora il controllo del token
  if (!user) {
    console.log("No user data found in localStorage, skipping token check.");
    return;
  }

  const validUntil = user.validUntil || null;

  console.log(
    `Checking token before API request. Valid until: ${validUntil}, Current time: ${Date.now()}`
  );

  // Controlla se il token è scaduto
  if (isTokenExpired(validUntil)) {
    try {
      // Se il token è scaduto, provo a rinnovarlo
      const newUserData = await refreshToken();

      // Aggiorna i dati dell'utente con il nuovo token
      newUserData.refreshToken = newUserData.newRefreshToken;
      delete newUserData.newRefreshToken;

      // Aggiorna il token nello store e sincronizza con il localStorage
      store.dispatch("updateToken", newUserData);
      store.dispatch("syncWithLocalStorage");

      console.log("Token refreshed successfully");
    } catch (error) {
      // In caso di errore, esegui il logout e segnala che la sessione è scaduta
      store.dispatch("logout");
      throw new Error("Session expired. Please log in again.");
    }
  }
};

// Funzione per fare richieste API con controllo del token
export const apiRequest = async (method, endpoint, data = null, customError = null) => {
  await checkAndRefreshToken();

  const token = store.getters.getToken;

  console.log("** data", method, endpoint, data)
  const requestConfig = {
    method,
    url: `${API_URL}${endpoint}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  };

  try {
    // Effettua la richiesta API utilizzando Axios con la configurazione specificata
    const response = await axios(requestConfig);
    // Se la richiesta ha successo, restituisce i dati della risposta
    console.log(`** call ${method} - ${endpoint} - ${data}`, response);
    if (response.status === 200) {
      return response.data;
    } else {
      return response
    }
  } catch (error) {
    // Funzioni errori
    console.error("API request error:", error);
    if (error.code === "ERR_NETWORK") {
      // Errore di rete
      console.error("API request error:", "Network Error");
      const errorData = MessagesService.getMessageData("generic", 9001);
      UtilityMixins.methods.mixins_showMessage(
        "",
        errorData.text,
        errorData.notificationType
      );
      throw new Error();
    } else if (error.response && error.response.status) {
      console.error("API request error:", error.response.status, data);

      if (error.response.status === 401 || error.response.status === 403) {
        // Logout
        console.warn("** call logout")
        await this.callLogout()
        const errorData = MessagesService.getMessageData("authentication", 8001);
        UtilityMixins.methods.mixins_showMessage(
          "API request",
          errorData.text,
          errorData.notificationType
        );
        // throw new Error();
        return error
      }

      if (data === "bypassError") return { status: error.response.status }
      if (customError === "bypassError") return { status: error.response.status, data: error.response?.data }

      const errorData = MessagesService.getMessageData("generic", error.response.status);
      UtilityMixins.methods.mixins_showMessage(
        "API request",
        errorData.text,
        errorData.notificationType
      );
      return error
    }
  }
};
// Funzione per fare richieste AUTH con controllo del token
export const authRequest = async (method, endpoint, data = null) => {
  await checkAndRefreshToken();

  const token = store.getters.getToken;

  const requestConfig = {
    method,
    url: `${AUTH_URL}${endpoint}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  };

  try {
    // Effettua la richiesta API utilizzando Axios con la configurazione specificata
    const response = await axios(requestConfig);
    // Se la richiesta ha successo, restituisce i dati della risposta
    console.log(`** call ${method} - ${endpoint} - ${data}`, response);
    if (response.status === 200) {
      return response.data;
    } else {
      return response
    }
  } catch (error) {
    // Funzioni errori
    console.error("API request error:", error);
    if (error.code === "ERR_NETWORK") {
      // Errore di rete
      console.error("API request error:", "Network Error");
      const errorData = MessagesService.getMessageData("generic", 9001);
      UtilityMixins.methods.mixins_showMessage(
        "",
        errorData.text,
        errorData.notificationType
      );
      throw new Error();
    } else if (error.response && error.response.status) {
      console.error("API request error:", error.response.status);

      if (error.response.status === 401 || error.response.status === 403) {
        // Logout
        console.warn("** call logout")
        await this.callLogout()
        const errorData = MessagesService.getMessageData("authentication", 8001);
        UtilityMixins.methods.mixins_showMessage(
          "API request",
          errorData.text,
          errorData.notificationType
        );
        // throw new Error();
        return error
      }

      const errorData = MessagesService.getMessageData("generic", error.response.status);
      UtilityMixins.methods.mixins_showMessage(
        "API request",
        errorData.text,
        errorData.notificationType
      );
      return error
    }
  }
};

// ************************
// *** Funzioni Aziende ***
// ************************
// Funzione per ottenere le aziende
export const fetchAziende = async (
  page = 1,
  limit = 50,
  sortType = 0,
  textsearch = ""
) => {
  return await apiRequest(
    "get",
    `/api/settings/aziende?page=${page}&limit=${limit}&sortType=${sortType}&textsearch=${textsearch}`
  );
};

// Funzione per ottenere un'azienda per ID
export const fetchAziendaById = async (id) => {
  return await apiRequest("get", `/api/settings/aziende/getsingle/${id}`);
};

// Funzione per caricare il logo dell'azienda
export const uploadLogoAzienda = async (aziendaId, file) => {
  const formData = new FormData();
  formData.append("picture", file);
  return await apiRequest("post", `/api/settings/aziende/${aziendaId}/logo`, formData);
};

// Funzione per cancellare il logo dell'azienda
export const deleteLogoAzienda = async (aziendaId) => {
  return await apiRequest("delete", `/api/settings/aziende/${aziendaId}/logo`);
};

// Funzione per creare una nuova azienda
export const createAzienda = async (azienda) => {
  return await apiRequest("post", "/api/settings/aziende/", azienda);
};

// Funzione per aggiornare un'azienda esistente
export const updateAzienda = async (aziendaId, azienda) => {
  return await apiRequest("put", `/api/settings/aziende/${aziendaId}`, azienda);
};

// Funzione per cancellare un'azienda
export const deleteAzienda = async (aziendaId) => {
  return await apiRequest("delete", `/api/settings/aziende/${aziendaId}`);
};

// ************************
// *** Funzioni Clienti ***
// ************************
// Funzione per ottenere i clienti
export const fetchClienti = async (
  page = 1,
  limit = 50,
  sortType = 0,
  textsearch = ""
) => {
  return await apiRequest(
    "get",
    `/api/settings/clienti?page=${page}&limit=${limit}&sortType=${sortType}&textsearch=${textsearch}`
  );
};

// Funzione per ottenere un cliente per ID
export const fetchClienteById = async (id) => {
  return await apiRequest("get", `/api/settings/clienti/getsingle/${id}`);
};

// Funzione per caricare il logo del cliente
export const uploadLogoCliente = async (clienteId, file) => {
  const formData = new FormData();
  formData.append("picture", file);
  return await apiRequest("post", `/api/settings/clienti/${clienteId}/logo`, formData);
};

// Funzione per cancellare il logo del cliente
export const deleteLogoCliente = async (clienteId) => {
  return await apiRequest("delete", `/api/settings/clienti/${clienteId}/logo`);
};

// Funzione per creare una nuovo cliente
export const createCliente = async (cliente) => {
  return await apiRequest("post", "/api/settings/clienti/", cliente);
};

// Funzione per aggiornare un cliente esistente
export const updateCliente = async (clienteId, cliente) => {
  return await apiRequest("put", `/api/settings/clienti/${clienteId}`, cliente);
};

// Funzione per cancellare un cliente
export const deleteCliente = async (clienteId) => {
  return await apiRequest("delete", `/api/settings/clienti/${clienteId}`);
};

// **************************
// *** Funzioni Fornitori ***
// **************************
// Funzione per ottenere i fornitori
export const fetchFornitori = async (
  page = 1,
  limit = 50,
  sortType = 0,
  textsearch = ""
) => {
  return await apiRequest(
    "get",
    `/api/settings/fornitori?page=${page}&limit=${limit}&sortType=${sortType}&textsearch=${textsearch}`
  );
};

// Funzione per ottenere un fornitore per ID
export const fetchFornitoreById = async (id) => {
  return await apiRequest("get", `/api/settings/fornitori/getsingle/${id}`);
};

// Funzione per caricare il logo del fornitore
export const uploadLogoFornitore = async (fornitoreId, file) => {
  const formData = new FormData();
  formData.append("picture", file);
  return await apiRequest(
    "post",
    `/api/settings/fornitori/${fornitoreId}/logo`,
    formData
  );
};

// Funzione per cancellare il logo del fornitore
export const deleteLogoFornitore = async (fornitoreId) => {
  return await apiRequest("delete", `/api/settings/fornitori/${fornitoreId}/logo`);
};

// Funzione per creare una nuovo fornitore
export const createFornitore = async (fornitore) => {
  return await apiRequest("post", "/api/settings/fornitori/", fornitore);
};

// Funzione per aggiornare un fornitore esistente
export const updateFornitore = async (fornitoreId, fornitore) => {
  return await apiRequest("put", `/api/settings/fornitori/${fornitoreId}`, fornitore);
};

// Funzione per cancellare un fornitore
export const deleteFornitore = async (fornitoreId) => {
  return await apiRequest("delete", `/api/settings/fornitori/${fornitoreId}`);
};

// **************************
// *** Funzioni Dotazioni ***
// **************************
// Funzione per ottenere le dotazioni
export const fetchDotazioni = async (
  page = 1,
  limit = 50,
  sortType = 0,
  textsearch = "",
  filtri = []
) => {
  const filtriParam = (filtri || []).map((filtro) => filtro.id).join(",");
  return await apiRequest(
    "get",
    `/api/settings/dotazioni?page=${page}&limit=${limit}&sortType=${sortType}&textsearch=${textsearch}&filtri=${filtriParam}`
  );
};

// Funzione per ottenere una dotazione per ID
export const fetchDotazioneById = async (id) => {
  return await apiRequest("get", `/api/settings/dotazioni/getsingle/${id}`);
};

// Funzione per caricare il logo della dotazione
export const uploadLogoDotazione = async (dotazioneId, file) => {
  const formData = new FormData();
  formData.append("picture", file);
  return await apiRequest(
    "post",
    `/api/settings/dotazioni/${dotazioneId}/logo`,
    formData
  );
};

// Funzione per cancellare il logo della dotazione
export const deleteLogoDotazione = async (dotazioneId) => {
  return await apiRequest("delete", `/api/settings/dotazioni/${dotazioneId}/logo`);
};

// Funzione per creare una nuova dotazione
export const createDotazione = async (dotazione) => {
  return await apiRequest("post", "/api/settings/dotazioni/", dotazione);
};

// Funzione per aggiornare una dotazione esistente
export const updateDotazione = async (dotazioneId, dotazione) => {
  return await apiRequest("put", `/api/settings/dotazioni/${dotazioneId}`, dotazione);
};

// Funzione per cancellare una dotazione
export const deleteDotazione = async (dotazioneId) => {
  return await apiRequest("delete", `/api/settings/dotazioni/${dotazioneId}`);
};

// *********************
// *** Funzioni Team ***
// *********************
// Funzione per ottenere il team
export const fetchTeam = async ({
  page = 1,
  limit = 50,
  sortType = 0,
  textsearch = "",
  filtersIds = {},
  rapporto = "",
  idsupplier = ""
} = {}) => {
  console.log("** team list fetchTeam filtersIds", filtersIds);

  let profiliFilters = "";
  let aziendeFilters = ""
  let sediFilters = ""
  let cessatiFilters = filtersIds.cessati || ""
  let mercatoFilter = filtersIds.mercato || ""
  let tipologiaFilter = filtersIds.tipologia || ""
  let tiposkillFilter = filtersIds.tiposkill || ""
  let livelloFilter = filtersIds.livello || ""

  if (
    UtilityMixins.methods.mixins_isEmptyObject &&
    UtilityMixins.methods.mixins_objectHasKey(filtersIds, "profili")
  ) {
    profiliFilters = filtersIds.profili;
  }
  if (
    UtilityMixins.methods.mixins_isEmptyObject &&
    UtilityMixins.methods.mixins_objectHasKey(filtersIds, "aziende")
  ) {
    aziendeFilters = filtersIds.aziende;
  }
  if (
    UtilityMixins.methods.mixins_isEmptyObject &&
    UtilityMixins.methods.mixins_objectHasKey(filtersIds, "sedi")
  ) {
    sediFilters = filtersIds.sedi;
  }
  console.log("** api team filtri", profiliFilters, aziendeFilters, sediFilters);
  console.log("** api team cessati/non cessati", cessatiFilters);

  return await apiRequest(
    "get",
    `/api/team?page=${page}&limit=${limit}&sortType=${sortType}&textsearch=${textsearch}&clientid=${config.CLIENT_ID}&profiles=${profiliFilters}&companies=${aziendeFilters}&branches=${sediFilters}&mercato=${mercatoFilter}&tipologia=${tipologiaFilter}&tiposkill=${tiposkillFilter}&cessati=${cessatiFilters}&livello=${livelloFilter}&section=${rapporto}&idsupplier=${idsupplier}`
  );
};
// Funzione per ottenere un singolo user del team a fronte dello userid
export const fetchSingleUserTeamByUserId = async (
  page = 1,
  limit = 50,
  sortType = 0,
  userid = null
) => {
  return await apiRequest(
    "get",
    `/api/team?page=${page}&limit=${limit}&sortType=${sortType}&clientid=${config.CLIENT_ID}&userid=${userid}`
  );
};
// Funzione per creare un nuovo utente
export const addNewUser = async (newUser) => {
  return await authRequest(
    "post",
    `/api/authuser/createuser`, newUser
  );
};
// Funzione per cancellare un utente
export const deleteUser = async (userid) => {
  return await apiRequest("delete", `/api/users/${userid}?clientid=${config.CLIENT_ID}`);
};
// *******************************
// *** Funzioni Area Personale ***
// *******************************
// Funzione per ottenere la sezione anagrafica dell'utente (si trova all'interno di "metadata")
export const fetchUserAnagrafica = async (id) => {
  return await apiRequest(
    "get",
    `/api/users/personal-area/anagrafica/${id}?clientid=${config.CLIENT_ID}`
  );
};
// Funzione per aggiornare sezione anagrafica dell'utente (si trova all'interno di "metadata")
export const updateUserAnagrafica = async (id, payload) => {
  return await apiRequest(
    "put",
    `/api/users/personal-area/anagrafica/${id}?clientid=${config.CLIENT_ID}`,
    payload
  );
};
// Funzione per ottenere la sezione rapporto dell'utente (si trova all'interno di "metadata")
export const fetchUserRapporto = async (id) => {
  return await apiRequest(
    "get",
    `/api/users/personal-area/rapporto/${id}?clientid=${config.CLIENT_ID}`
  );
};
// Funzione per aggiornare sezione rapporto dell'utente (si trova all'interno di "metadata")
export const updateUserRapporto = async (id, payload) => {
  return await apiRequest(
    "put",
    `/api/users/personal-area/rapporto/${id}?clientid=${config.CLIENT_ID}`,
    payload
  );
};
// Funzione per ottenere la sezione skill dell'utente (si trova all'interno di "metadata")
export const fetchUserSkill = async (id) => {
  return await apiRequest(
    "get",
    `/api/users/personal-area/skills/${id}?clientid=${config.CLIENT_ID}`
  );
};
// Funzione per aggiornare sezione skills dell'utente (si trova all'interno di "metadata")
export const updateUserSkill = async (id, payload) => {
  return await apiRequest(
    "post",
    `/api/users/personal-area/skills/${id}?clientid=${config.CLIENT_ID}`,
    payload
  );
};
// Funzione per caricare un documento
export const uploadFileDocument = async (file) => {
  const formData = new FormData();
  formData.append("file", file);
  return await apiRequest("post", `/api/users/personal-area/file/`, formData);
};
// Funzione per ottenere la sezione documenti dell'utente (si trova all'interno di "metadata")
export const fetchUserDocumento = async (id) => {
  return await apiRequest(
    "get",
    `/api/users/personal-area/documenti/${id}?clientid=${config.CLIENT_ID}`
  );
};
// Funzione per aggiornare sezione documenti dell'utente (si trova all'interno di "metadata")
export const updateUserDocumento = async (id, payload) => {
  return await apiRequest(
    "post",
    `/api/users/personal-area/documenti/${id}?clientid=${config.CLIENT_ID}`,
    payload
  );
};
// Funzione per aggiornare sezione sicurezza, cambio password utente
export const updateUserSicurezza = async (payload) => {
  return await authRequest(
    "put",
    `/api/auth/modify-password`,
    payload
  );
};
// *************************
// *** Funzioni Attività ***
// *************************
// Funzione per ottenere il rapportino delle attività di un utente
export const fetchUserAttivitaRapportino = async (
  iduser,
  year = new Date().getFullYear().toString(),
  month = (new Date().getMonth() + 1).toString()
) => {
  return await apiRequest(
    "get",
    `/api/attivita/getrapportino/${year}/${month}/${iduser}`
  );
};
// Funzione per ottenere il rapportino aggregato delle attività di un utente
export const fetchUserAttivitaRapportinoAggregato = async (
  iduser,
  from = todayFormatDate(),
  to = todayFormatDate()
) => {
  //from = "2024-01-01" // solo per debug
  //to = "2024-12-31" // solo per debug
  return await apiRequest(
    "get",
    `/api/attivita/getrapportinoaggregato/${from}/${to}/${iduser}`
  );
};
// Funzione per chiudere un rapportino
export const closeRapportino = async (
  iduser,
  from = todayFormatDate(),
  to = todayFormatDate(),
  bypassError
) => {
  return await apiRequest("put", `/api/attivita/close-rapportino/${from}/${to}/${iduser}`, bypassError);
};
// Funzione per riaprire un rapportino
export const reopenRapportino = async (
  iduser,
  from = todayFormatDate(),
  to = todayFormatDate()
) => {
  return await apiRequest(
    "put",
    `/api/attivita/reopen-rapportino/${from}/${to}/${iduser}
`
  );
};
// Funzione per validare un rapportino
export const validaRapportino = async (
  iduser,
  from = todayFormatDate(),
  to = todayFormatDate()
) => {
  return await apiRequest(
    "put",
    `/api/attivita/validate-rapportino/${from}/${to}/${iduser}
`
  );
};
// Funzione per creare un nuovo rapportino
export const createRapportino = async (item) => {
  return await apiRequest("post", `/api/attivita/rapportino`, item);
};
// Funzione per aggiornare un rapportino esistente
export const updateRapportino = async (item) => {
  return await apiRequest("put", `/api/attivita/rapportino/${item._id}`, item);
};
// Funzione per completare il mese di un rapportino a fronte di un giorno
export const completaMeseRapportino = async (day, month, year, body) => {
  console.log(`** /api/attivita/copy-days/${month}/${day}/${year}`, body)
  return await apiRequest("put", `/api/attivita/copy-days/${month}/${day}/${year}`, body);
};
// Funzione per azzerare i dati del rapportino escluse le assenze
export const azzeraDatiRapportino = async (day, month, year, body) => {
  console.log(`** /api/attivita/zero-days/${month}/${day}/${year}`, body)
  return await apiRequest("put", `/api/attivita/zero-days/${month}/${day}/${year}`, body);
};
// Funzione per creare una nuova assenza
export const createAssenza = async (assenza, bypassError) => {
  return await apiRequest("post", "/api/attivita/richiesta-assenza", assenza, bypassError);
};
// Funzione per modificare un'assenza esistente
export const updateAssenza = async (assenza, assenzaId, bypassError) => {
  return await apiRequest("put", `/api/attivita/richiesta-assenza/${assenzaId}`, assenza, bypassError);
};
// Funzione per ottenere le assenze aggregato di un utente
// 1=ROL, 2=Malattia, 3=Permessi Giustificati, 99=Tutte le assenze tranne le ferie (0=Ferie)
export const fetchUserAttivitaAssenzeAggregato = async (
  from = todayFormatDate(),
  to = todayFormatDate(),
  tipoAssenza = 99,
  currentUser = "Y",
  id = ""
) => {
  return await apiRequest(
    "get",
    `/api/attivita/get-assenzaaggregato/${from}/${to}/${tipoAssenza}/${currentUser}?id=${id}`
  );
};
// Funzione per cancellare un'assenza esistente
export const deleteAssenza = async (assenzaId) => {
  return await apiRequest("delete", `/api/attivita/richiesta-assenza/${assenzaId}`);
};
// Funzione per caricare un allegato malattia/permessi giustificati
export const uploadAllegatoAssenza = async (file) => {
  const formData = new FormData();
  formData.append("file", file);
  return await apiRequest("post", `/api/attivita/documenti-assenza/`, formData);
};
// Funzione per caricare il picture del profilo (attenzione, è una richiesta auth)
export const uploadPictureProfile = async (file) => {
  const formData = new FormData();
  formData.append("picture", file);
  return await authRequest("post", `/api/authprofile/manageProfilePicture?clientid=${config.CLIENT_ID}`, formData);
};
// Funzione per cancellare il picture del profilo (attenzione, è una richiesta auth)
export const deleteProfilePicture = async () => {
  return await authRequest("delete", `/api/authprofile/manageProfilePicture?clientid=${config.CLIENT_ID}`);
};
// Funzione per esportare i dati Paco
export const exportDataPaco = async (anno, mese, idAzienda, idSede) => {
  return await apiRequest(
    "get",
    `/api/attivita/export-paco/${anno}/${mese}/${idAzienda}/${idSede}`
  );
};
// Funzione per inviare i dati NAV
export const sendDataNAV = async (anno, mese, idAzienda, idSede) => {
  return await apiRequest(
    "get",
    `/api/attivita/export-nav/${anno}/${mese}/${idAzienda}/${idSede}`
  );
};
// Funzione per esportare i dati Dettaglio Presenze in Excel HR
export const exportDettaglioPresenzeExcelHR = async (body) => {
  return await apiRequest("post", `/api/attivita/export-hr`, body);
};
// Funzione per esportare i dati Dettaglio Presenze in Excel
export const exportDettaglioPresenzeExcel = async (body) => {
  return await apiRequest("post", `/api/attivita/export-excel`, body);
};
// Funzione per sollecitare chiusura rapportino
export const sendSollecitoChiusuraRapportino = async (idUser, mese, anno) => {
  return await apiRequest("post", `/api/attivita/sollecita-rapportino/${idUser}/${mese}/${anno}`);
};
// Funzione per ottenere i rapportini per il team
export const fetchAllRapportini = async (
  from = todayFormatDate(),
  to = todayFormatDate(),
  page = 1,
  limit = 50,
  textsearch = "",
  dtcId = ""
) => {
  return await apiRequest(
    "get",
    `/api/attivita/get-all-rapportini/${from}/${to}?page=${page}&limit=${limit}&textsearch=${textsearch}&dtc=${dtcId}`
  );
};
// *************************
// *** Funzioni Commesse ***
// *************************
// Funzione per ottenere le commesse
export const fetchCommesse = async (
  page = 1,
  limit = 50,
  sortType = 0,
  textsearch = "",
  filtersIds = {}
) => {
  console.log("** commesse list filtersIds", filtersIds);
  let societaFilters = "";
  let tipologiaFilters = "";
  let statusFilters = filtersIds.status || "";
  let commercialeFilters = filtersIds.commerciale || "";
  let clienteFilters = filtersIds.cliente || "";
  if (
    UtilityMixins.methods.mixins_isEmptyObject &&
    UtilityMixins.methods.mixins_objectHasKey(filtersIds, "societa")
  ) {
    societaFilters = filtersIds.societa;
  }
  if (
    UtilityMixins.methods.mixins_isEmptyObject &&
    UtilityMixins.methods.mixins_objectHasKey(filtersIds, "tipologia")
  ) {
    tipologiaFilters = filtersIds.tipologia;
  }
  console.log("** api commessa filtri", societaFilters, tipologiaFilters);
  return await apiRequest(
    "get",
    `/api/commesse?page=${page}&limit=${limit}&sortType=${sortType}&textsearch=${textsearch}&clientid=${config.CLIENT_ID}&tipologia=${tipologiaFilters}&societa=${societaFilters}&status=${statusFilters}&commerciale=${commercialeFilters}&cliente=${clienteFilters}`
  );
};
// Funzione per ottenere la singola commessa
export const fetchCommessaById = async (id) => {
  return await apiRequest("get", `/api/commesse/getsingle/${id}`);
};
// Funzione per ottenere la sezione attività della commessa
export const fetchCommessaAttivita = async (id) => {
  return await apiRequest("get", `/api/commesse/commessa-attivita/${id}`);
};
// Funzione per aggiornare una commessa
export const updateCommessa = async (id, payload) => {
  return await apiRequest("put", `/api/commesse/${id}`, payload);
};
// Funzione per ottenere le risorse libere
export const fetchCommessaRisorseLibere = async (
  page = 1,
  limit = 50,
  idresources = "",
  sortType = 2,
  textsearch = ""
) => {
  return await apiRequest(
    "get",
    `/api/commesse/getresourcestoassign?idresources=${idresources}&page=${page}&limit=${limit}&sortType=${sortType}&textsearch=${textsearch}&clientid=${config.CLIENT_ID}`
  );
};
// Funzione per cancellare la singola commessa
export const deleteCommessa = async (id) => {
  return await apiRequest("delete", `/api/commesse/${id}`);
};
// Funzione per creare una commessa
export const addNewCommessa = async (payload) => {
  return await apiRequest("post", `/api/commesse/`, payload);
}
// **************************
// *** Funzioni Tabelle ***
// **************************
// Funzione per ottenere le tabelle
export const fetchTabelle = async () => {
  return await apiRequest("get", `/api/settings/tabelle`);
};

// Funzione per ottenere una tabella per ID
export const fetchTabellaById = async (id) => {
  return await apiRequest("get", `/api/settings/tabelle/${id}`);
};

// Funzione per creare una nuova tabella
export const createTabella = async (tabella) => {
  return await apiRequest("post", "/api/settings/tabelle/", tabella);
};

// Funzione per aggiornare una tabella esistente
export const updateTabella = async (tabellaId, tabella) => {
  return await apiRequest("put", `/api/settings/tabelle/${tabellaId}`, tabella);
};
// ******************************************
// *** Funzioni Dashboard & Communication ***
// ******************************************
// Funzione per ottenere la dashboard
export const fetchDashboard = async (month) => {
  return await apiRequest("get", `/api/dashboard/${month}`);
};
// Funzione per creare una nuova comunicazione (messaggio)
export const sendNewCommunication = async (body) => {
  return await apiRequest("post", `/api/dashboard/communication`, body);
};
// Funzione per modificare una comunicazione (messaggio)
export const updateCommunication = async (id, body) => {
  return await apiRequest("put", `/api/dashboard/communication/${id}`, body);
};
// Funzione per cancellare una comunicazione (messaggio)
export const deleteCommunication = async (id) => {
  return await apiRequest("delete", `/api/dashboard/communication/${id}`);
};
// ****************************
// *** Funzioni Festività ***
// ****************************
// Funzione per ottenere la tabella delle festività
export const fetchFestivita = async () => {
  return await apiRequest("get", `/api/settings/festivita`);
};
// Funzione per aggiornare un elemento nella tabella delle festività
export const updateFestivita = async (idItem, body) => {
  return await apiRequest("put", `/api/settings/festivita/${idItem}`, body);
};
// Funzione per aggiungere un elemento nella tabella delle festività
export const addFestivita = async (body) => {
  return await apiRequest("post", `/api/settings/festivita/`, body);
};
// Funzione per cancellare un elemento dalla tabella delle festività
export const deleteFestivita = async (idItem) => {
  return await apiRequest("delete", `/api/settings/festivita/${idItem}`);
};

// ***********************
// *** Funzioni Utenti ***
// ***********************
// Funzione per ottenere gli utenti
export const fetchUtenti = async (page = 1, limit = 50, textsearch = "") => {
  return await apiRequest(
    "get",
    `/api/users?clientid=${config.CLIENT_ID}&page=${page}&limit=${limit}&textsearch=${textsearch}`
  );
};

// ***************************
// *** Funzioni specifiche ***
// ***************************
// Funzioni per le richieste specifiche
export const getData = async (endpoint) => {
  return await apiRequest("get", endpoint);
};

export const postData = async (endpoint, data) => {
  return await apiRequest("post", endpoint, data);
};

export const putData = async (endpoint, data) => {
  return await apiRequest("put", endpoint, data);
};

export const deleteData = async (endpoint) => {
  return await apiRequest("delete", endpoint);
};
