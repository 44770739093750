import { createStore } from "vuex";
import config from "@/config";

export default createStore({
  state: {
    // authentication
    user: JSON.parse(localStorage.getItem(config.sessionDataLS)) || null,
    // current page
    currentPageAziende: 1,
    currentPageClienti: 1,
    currentPageFornitori: 1,
    currentPageDotazioni: 1,
    currentPageTeams: 1,
    currentPageCommesse: 1,
    // sort
    aziendeSortType: 0,
    clientiSortType: 0,
    fornitoriSortType: 0,
    dotazioniSortType: 0,
    teamsSortType: 0,
    commesseSortType: 0,
    // search
    searchAziende: "",
    searchClienti: "",
    searchFornitori: "",
    searchDotazioni: "",
    searchTeams: "",
    searchCommesse: "",
    // filtri dotazioni
    FilterDotazioniTipologia: [],
    // filtri teams
    FilterTeamsProfili: [],
    FilterTeamsAziende: [],
    FilterTeamsSedi: [],
    FilterTeamsUtentiCessati: [],
    // filtri commesse
    FilterCommesseSocieta: [],
    FilterCommesseTipologia: [],
    // tipo visualizzazione attività
    viewsTypeAttivita: "mensile",
  },
  mutations: {
    // authentication
    login(state, userData) {
      state.user = userData;
      localStorage.setItem(config.sessionDataLS, JSON.stringify(userData));
    },
    logout(state) {
      state.user = null;
      localStorage.removeItem(config.sessionDataLS);
      // Cancella il cookie XSRF-TOKEN
      // Il cookie non si può cancellare lato client con javascript perché ha il flag HttpOnly impostato a true, è possibile farlo solo lato server
      // const name = "XSRF-TOKEN"
      // const path = "/"
      // const domain = "localhost"
      // document.cookie = 'XSRF-TOKEN=; Max-Age=-99999999; path=/;';
      // document.cookie = name + `=; Max-Age=-99999999; path=${path || '/'}; domain=${domain || ''};`
    },
    updateToken(state, newTokenData) {
      if (state.user) {
        state.user.token = newTokenData.token;
        state.user.refreshToken = newTokenData.refreshToken;
        state.user.validUntil = newTokenData.validUntil;
        localStorage.setItem(config.sessionDataLS, JSON.stringify(state.user));
      }
    },
    syncWithLocalStorage(state) {
      state.user = JSON.parse(localStorage.getItem(config.sessionDataLS));
    },
    // current page
    setCurrentPageAziende(state, page) {
      state.currentPageAziende = page;
    },
    setCurrentPageClienti(state, page) {
      state.currentPageClienti = page;
    },
    setCurrentPageFornitori(state, page) {
      state.currentPageFornitori = page;
    },
    setCurrentPageDotazioni(state, page) {
      state.currentPageDotazioni = page;
    },
    setCurrentPageTeams(state, page) {
      state.currentPageTeams = page;
    },
    setCurrentPageCommesse(state, page) {
      state.currentPageCommesse = page;
    },
    // sort
    setAziendeSortType(state, sortType) {
      state.aziendeSortType = sortType;
    },
    setClientiSortType(state, sortType) {
      state.clientiSortType = sortType;
    },
    setFornitoriSortType(state, sortType) {
      state.fornitoriSortType = sortType;
    },
    setDotazioniSortType(state, sortType) {
      state.dotazioniSortType = sortType;
    },
    setTeamsSortType(state, sortType) {
      state.teamsSortType = sortType;
    },
    setCommesseSortType(state, sortType) {
      state.commesseSortType = sortType;
    },
    // search
    setSearchAziende(state, text) {
      state.searchAziende = text;
    },
    setSearchClienti(state, text) {
      state.searchClienti = text;
    },
    setSearchDotazioni(state, text) {
      state.searchDotazioni = text;
    },
    setSearchFornitori(state, text) {
      state.searchFornitori = text;
    },
    setSearchTeams(state, text) {
      state.searchTeams = text;
    },
    setSearchCommesse(state, text) {
      state.searchCommesse = text;
    },
    // filtri dotazioni
    setFilterDotazioniTipologia(state, payload) {
      state.FilterDotazioniTipologia = payload;
    },
    // filtri teams
    setFilterTeamsAziende(state, payload) {
      state.FilterTeamsAziende = payload;
    },
    setFilterTeamsProfili(state, payload) {
      state.FilterTeamsProfili = payload;
    },
    setFilterTeamsSedi(state, payload) {
      state.FilterTeamsSedi = payload;
    },
    setFilterTeamsUtentiCessati(state, payload) {
      state.FilterTeamsUtentiCessati = payload;
    },
    // filtri commesse
    setFilterCommesseSocieta(state, payload) {
      state.FilterCommesseSocieta = payload;
    },
    setFilterCommesseTipologia(state, payload) {
      state.FilterCommesseTipologia = payload;
    },
    // tipo visualizzazione attività
    setViewsTypeAttivita(state, payload) {
      state.viewsTypeAttivita = payload;
    },
  },
  actions: {
    // authentication
    login({ commit }, userData) {
      commit("login", userData);
    },
    logout({ commit }) {
      commit("logout");
    },
    updateToken({ commit }, newTokenData) {
      commit("updateToken", newTokenData);
    },
    syncWithLocalStorage({ commit }) {
      commit("syncWithLocalStorage");
    },
    // current page
    setCurrentPageAziende({ commit }, page) {
      commit("setCurrentPageAziende", page);
    },
    setCurrentPageClienti({ commit }, page) {
      commit("setCurrentPageClienti", page);
    },
    setCurrentPageFornitori({ commit }, page) {
      commit("setCurrentPageFornitori", page);
    },
    setCurrentPageDotazioni({ commit }, page) {
      commit("setCurrentPageDotazioni", page);
    },
    setCurrentPageTeams({ commit }, page) {
      commit("setCurrentPageTeams", page);
    },
    setCurrentPageCommesse({ commit }, page) {
      commit("setCurrentPageCommesse", page);
    },
    // sort
    setAziendeSortType({ commit }, sortType) {
      commit("setAziendeSortType", sortType);
    },
    setClientiSortType({ commit }, sortType) {
      commit("setClientiSortType", sortType);
    },
    setFornitoriSortType({ commit }, sortType) {
      commit("setFornitoriSortType", sortType);
    },
    setDotazioniSortType({ commit }, sortType) {
      commit("setDotazioniSortType", sortType);
    },
    setTeamsSortType({ commit }, sortType) {
      commit("setTeamsSortType", sortType);
    },
    setCommesseSortType({ commit }, sortType) {
      commit("setCommesseSortType", sortType);
    },
    // search
    setSearchAziende({ commit }, text) {
      commit("setSearchAziende", text);
    },
    setSearchClienti({ commit }, text) {
      commit("setSearchClienti", text);
    },
    setSearchFornitori({ commit }, text) {
      commit("setSearchFornitori", text);
    },
    setSearchDotazioni({ commit }, text) {
      commit("setSearchDotazioni", text);
    },
    setSearchTeams({ commit }, text) {
      commit("setSearchTeams", text);
    },
    setSearchCommesse({ commit }, text) {
      commit("setSearchCommesse", text);
    },
    // filtri dotazioni
    setFilterDotazioniTipologia({ commit }, filter) {
      commit("setFilterDotazioniTipologia", filter);
    },
    // filtri teams
    setFilterTeamsAziende({ commit }, filter) {
      commit("setFilterTeamsAziende", filter);
    },
    setFilterTeamsProfili({ commit }, filter) {
      commit("setFilterTeamsProfili", filter);
    },
    setFilterTeamsSedi({ commit }, filter) {
      commit("setFilterTeamsSedi", filter);
    },
    setFilterTeamsUtentiCessati({ commit }, filter) {
      commit("setFilterTeamsUtentiCessati", filter);
    },
    // filtri commesse
    setFilterCommesseSocieta({ commit }, filter) {
      commit("setFilterCommesseSocieta", filter);
    },
    setFilterCommesseTipologia({ commit }, filter) {
      commit("setFilterCommesseTipologia", filter);
    },
    // tipo visualizzazione attività
    setViewsTypeAttivita({ commit }, type) {
      commit("setViewsTypeAttivita", type);
    },
  },
  getters: {
    // authentication
    isAuthenticated: (state) => !!state.user,
    getToken: (state) => (state.user ? state.user.token : null),
    getRefreshToken: (state) => (state.user ? state.user.refreshToken : null),
    getValidUntil: (state) => (state.user ? state.user.validUntil : null),
    getUsername: (state) => (state.user ? state.user.username : null),
    // current page
    getCurrentPageAziende: (state) => state.currentPageAziende,
    getCurrentPageClienti: (state) => state.currentPageClienti,
    getCurrentPageFornitori: (state) => state.currentPageFornitori,
    getCurrentPageDotazioni: (state) => state.currentPageDotazioni,
    getCurrentPageTeams: (state) => state.currentPageTeams,
    getCurrentPageCommesse: (state) => state.currentPageCommesse,
    // sort
    getAziendeSortType: (state) => state.aziendeSortType,
    getClientiSortType: (state) => state.clientiSortType,
    getFornitoriSortType: (state) => state.fornitoriSortType,
    getDotazioniSortType: (state) => state.dotazioniSortType,
    getTeamsSortType: (state) => state.teamsSortType,
    getCommesseSortType: (state) => state.commesseSortType,
    // search
    getSearchAziende: (state) => state.searchAziende,
    getSearchClienti: (state) => state.searchClienti,
    getSearchFornitori: (state) => state.searchFornitori,
    getSearchDotazioni: (state) => state.searchDotazioni,
    getSearchTeams: (state) => state.searchTeams,
    getSearchCommesse: (state) => state.searchCommesse,
    // filtri dotazioni
    getFilterDotazioniTipologia: (state) => state.FilterDotazioniTipologia,
    // filtri teams
    getFilterTeamsAziende: (state) => state.FilterTeamsAziende,
    getFilterTeamsProfili: (state) => state.FilterTeamsProfili,
    getFilterTeamsSedi: (state) => state.FilterTeamsSedi,
    getFilterTeamsUtentiCessati: (state) => state.FilterTeamsUtentiCessati,
    // filtri commesse
    getFilterCommesseSocieta: (state) => state.FilterCommesseSocieta,
    getFilterCommesseTipologia: (state) => state.FilterCommesseTipologia,
    // tipo visualizzazione attività
    getViewsTypeAttivita: (state) => state.viewsTypeAttivita,
  },
});
