<template>
  <div class="col-sm-12 main-col">
    <div class="row p-0">
      <div class="align-items-center d-flex flex-row justify-content-between m-0 p-0 row">
        <div class="col-sm-10 p-0">
          <div
            class="align-items-center bg-light d-flex header-text justify-content-start p-0 w-100"
          >
            <h1>
              GESTIONE COMMESSE<span class="text-black small">
                <span class="mx-3 single-list">
                  <i class="fa-solid fa-building-user"></i>
                </span>
                <span v-if="getSearchCommesse" class="mx-3 text-black"
                  >[ <span class="color-mainblue">{{ getSearchCommesse }}</span> ]</span
                >
                <span>
                  {{ labelsString }}
                  <span class="ms-2" style="font-size: smaller">
                    ({{ mixins_formatNumberWithLocale(totalRecords) }}
                    {{ totalRecords == 1 ? "elemento" : "elementi" }})
                  </span>
                </span>
              </span>
            </h1>
          </div>
        </div>
        <div class="col-sm-2 p-0">
          <!-- Search -->
          <search-button
            :searchText="getSearchCommesse"
            @txtToSearch="filterTextToSearch"
            @clearTxtToSearch="filterClearTextToSearch"
            v-if="!loading"
          />
        </div>
      </div>
      <!-- Content -->
      <div
        class="container-fluid m-0 p-2 background-gray overflow-auto container-contents"
      >
        <!-- Linear Loader -->
        <div v-if="loading" class="linear-loader">
          <span></span>
        </div>
        <!-- Error -->
        <div v-if="error" class="alert alert-danger">{{ error }}</div>

        <!-- Data -->
        <div v-if="!loading">
          <!-- Filters / Export Data -->
          <div class="row m-0 my-2 padding-x-4px">
            <div class="container-fluid p-0 mb-2">
              <!-- Seleziona Commerciale -->
              <div class="dropdown float-end me-2">
                <button
                  class="btn button-outline-1 small dropdown-toggle"
                  type="button"
                  id="dropdown_commerciale"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>Seleziona Commerciale</span>
                </button>
                <ul
                  class="dropdown-menu dropdown-menu-end"
                  aria-labelledby="dropdown_commerciale"
                >
                  <li id="filtro_commerciale" class="li-container-med">
                    <div class="row mb-2">
                      <label
                        class="col-form-label form-label col-lg-3 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
                        ><span>COMMERCIALE</span>
                      </label>
                      <div
                        class="align-items-center col-lg-9 d-flex justify-content-center"
                      >
                        <MultiSelect
                          :searchable="true"
                          v-model="selectedLocalModels.commerciale"
                          :options="commercialeOptions"
                          placeholder="- SELEZIONA -"
                          label="label"
                          track-by="value"
                          :openDirection="'below'"
                          :showLabels="false"
                          :maxHeight="310"
                          @select="onCommercialeSelect"
                          @remove="onCommercialeRemove"
                          @open="onOpenMultiselectCommerciale()"
                          @close="onCloseMultiselectCommerciale()"
                          @blur="onOpenMultiselectCommerciale()"
                          @click.stop
                        >
                          <template #noOptions>
                            <span>L'elenco è vuoto</span>
                          </template>
                          <template #noResult>
                            <span>Nessun elemento trovato</span>
                          </template>
                        </MultiSelect>
                      </div>
                    </div>
                  </li>
                  <li class="li-container-med">
                    <button
                      @click="sendFiltersQueryString()"
                      type="button"
                      class="btn button-outline-1 small float-end"
                    >
                      <i class="bi bi-check-lg"></i>Conferma
                    </button>
                  </li>
                </ul>
              </div>
              <!-- Seleziona Cliente -->
              <div class="dropdown float-end me-2">
                <button
                  class="btn button-outline-1 small dropdown-toggle"
                  type="button"
                  id="dropdown_cliente"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>Seleziona Cliente</span>
                </button>
                <ul
                  class="dropdown-menu dropdown-menu-end"
                  aria-labelledby="dropdown_cliente"
                >
                  <li id="filtro_cliente" class="li-container-med">
                    <div class="row mb-2">
                      <label
                        class="col-form-label form-label col-lg-3 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
                        ><span>CLIENTE</span>
                      </label>
                      <div
                        class="align-items-center col-lg-9 d-flex justify-content-center"
                      >
                        <MultiSelect
                          v-model="selectedLocalModels.cliente"
                          :options="clienteOptions"
                          :searchable="true"
                          placeholder="- CERCA... -"
                          label="label"
                          track-by="value"
                          :show-no-results="true"
                          selectLabel="Seleziona"
                          deselectLabel="Rimuovi"
                          selectedLabel="Selezionato"
                          :openDirection="'below'"
                          tagPlaceholder="Premi enter per creare un tag"
                          :multiple="false"
                          :taggable="false"
                          :showLabels="false"
                          :loading="isSearchingCliente"
                          @search-change="asyncFetchCliente"
                          :maxHeight="310"
                          @select="onClienteSelect"
                          @remove="onClienteRemove"
                          @open="onOpenMultiselectCliente()"
                          @close="onCloseMultiselectCliente()"
                          @blur="onOpenMultiselectCliente()"
                          @click.stop
                        >
                          <template #noOptions>
                            <span>L'elenco è vuoto</span>
                          </template>
                          <template #noResult>
                            <span>Nessun elemento trovato</span>
                          </template>
                        </MultiSelect>
                      </div>
                    </div>
                  </li>
                  <li class="li-container-med">
                    <button
                      @click="sendFiltersQueryString()"
                      type="button"
                      class="btn button-outline-1 small float-end"
                    >
                      <i class="bi bi-check-lg"></i>Conferma
                    </button>
                  </li>
                </ul>
              </div>
              <!-- Visualizza -->
              <div class="dropdown float-end me-2">
                <button
                  class="btn button-outline-1 small dropdown-toggle"
                  type="button"
                  id="dropdown_visualizza"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span
                    >Visualizza
                    <span class="text-black">{{ currentStatus?.label }}</span></span
                  >
                </button>
                <ul
                  class="dropdown-menu dropdown-menu-end"
                  aria-labelledby="dropdown_visualizza"
                >
                  <li v-for="commessa in statusCommesse" :key="commessa.value">
                    <button
                      class="dropdown-item"
                      :class="{ active: currentStatus?.label == commessa?.label }"
                      type="button"
                      @click="changeStatusFilter(commessa.value)"
                    >
                      {{ commessa?.label }}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- No data -->
          <div v-if="commesse.length == 0 && !isFirstTime && !loading && !error">
            <h5 class="mt-1">Nessun dato da visualizzare</h5>
          </div>
          <!-- Table Data -->
          <div v-if="commesse.length > 0 && !loading" class="row m-0 padding-x-4px">
            <table class="table-bordered table-responsive-md table-setup table-hover">
              <thead class="table-header-font">
                <tr class="td-center">
                  <th style="min-width: 155px !important">CLIENTE</th>
                  <th>TITOLO</th>
                  <th style="width: 155px !important; min-width: 155px !important">
                    TIPOLOGIA
                  </th>
                  <th style="width: 125px !important; min-width: 125px !important">
                    DATA INIZIO
                  </th>
                  <th style="width: 125px !important; min-width: 125px !important">
                    DATA FINE
                  </th>
                  <th style="width: 170px !important; min-width: 170px !important">
                    COMMERCIALE
                  </th>
                  <th style="min-width: 100px !important; width: 100px !important">
                    AZIONI
                  </th>
                </tr>
              </thead>
              <!-- Table Records -->
              <tbody class="table-body-font td-vertical-center">
                <tr
                  v-for="(commessa, index) in commesse"
                  :key="commessa._id"
                  @click="toggleRow(index)"
                  :class="{
                    expanded:
                      isRowExpanded(index) && commessa.risorseassociate.length > 0,
                  }"
                >
                  <td>{{ commessa.cliente.descrizione }}</td>
                  <td>
                    <div
                      class="align-items-center d-flex flex-row justify-content-between"
                    >
                      <span>{{ commessa.titolo }}</span
                      >( {{ commessa.risorseassociate.length }} )
                    </div>
                    <div
                      v-if="commessa.risorseassociate.length"
                      :class="['custom-collapse', { show: isRowExpanded(index) }]"
                    >
                      <div class="mt-2">
                        <hr />
                        <h6 class="color-mainblue">
                          Risorse assegnate ( {{ commessa.risorseassociate.length }} ):
                        </h6>
                        <ul class="list-tags">
                          <li
                            v-for="risorsaassociata in sortResourcesByFullnameCaseSensitive(
                              commessa.risorseassociate
                            )"
                            :key="risorsaassociata.idrisorsa"
                            @click.stop.prevent="
                              routeUserTeam(
                                risorsaassociata.fullname,
                                risorsaassociata.idrisorsa
                              )
                            "
                          >
                            <span
                              class="badge-colored blue"
                              :title="risorsaassociata.fullname"
                              :alt="risorsaassociata.fullname"
                              >{{ risorsaassociata.fullname }}</span
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </td>
                  <td>{{ commessa.tipologia }}</td>
                  <td class="td-center">
                    {{
                      commessa.datainizio ? mixins_getLocalDate(commessa.datainizio) : "-"
                    }}
                  </td>
                  <td class="td-center">
                    {{ commessa.datafine ? mixins_getLocalDate(commessa.datafine) : "-" }}
                  </td>
                  <td class="td-center">{{ commessa.commerciale.fullname }}</td>
                  <!-- Actions -->
                  <td class="td-actions td-center">
                    <button
                      alt="MODIFICA"
                      title="MODIFICA"
                      @click.stop.prevent="editCommessa(commessa)"
                    >
                      <i class="zmdi zmdi-edit zmdi-hc-lg"></i>
                    </button>
                    <button
                      v-if="
                        isElementVisible &&
                        (isAuthenticatedSuperAdmin || isAuthenticatedGestionePersonale)
                      "
                      class="delete"
                      alt="ELIMINA"
                      title="ELIMINA"
                      @click.stop.prevent="askRemoveCommessa(commessa._id)"
                    >
                      <i class="zmdi zmdi-delete zmdi-hc-lg"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- Pagination -->
            <div
              class="align-items-center container-fluid d-flex justify-content-center my-4 p-0"
            >
              <div class="row" v-if="commesse.length > 0 && totalPages > 1">
                <vue-pagination
                  :totalPages="totalPages"
                  :currentPage="page"
                  :maxVisibleButtons="6"
                  @pagechanged="onPageChange"
                ></vue-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchButton from "@/components/ui/SearchButton.vue";
import { fetchCommesse, deleteCommessa } from "@/services/api";
import VuePagination from "@/components/ui/VuePagination.vue";
import { mapGetters, mapActions } from "vuex";
import { UtilityMixins } from "@/mixins/UtilityMixins.js";
import { Commessa } from "@/models/CommessaJoiModel";
import sortOrderList from "@/data/sort_order_list.json";
import ProfiliPermissions from "@/mixins/ProfiliPermissions.js";

class QueryFilters {
  constructor(data = {}) {
    this.status = data.status || "";
    this.commerciale = data.commerciale || "";
    this.cliente = data.cliente || "";
  }
}

export default {
  mixins: [UtilityMixins, ProfiliPermissions],
  components: {
    SearchButton,
    VuePagination,
  },
  data() {
    return {
      isElementVisible: false,
      commesse: new Commessa(),
      totalRecords: 0,
      loading: false,
      error: null,
      isFirstTime: true,
      // pagination
      page: this.getCurrentPageCommesse,
      limit: 20,
      totalPages: 1,
      // filters
      allFilters: {
        labels: [],
        ids: {
          societa: [],
          tipologia: [],
          status: "",
          commerciale: "",
          cliente: "",
        },
      },
      labelMap: {},
      filterLabels: [],
      filtersIds: {},
      expandedRows: [], // Array che gestice le righe espanse per la visualizzazione delle risorse
      sortOrderList,
      selectedLocalModels: {
        // Raccolgo i modelli locali in un unico oggetto
        commerciale: [],
        cliente: [],
      },
      // local filters
      localFilters: new QueryFilters(),
      isSearchingCliente: false,
      statusCommesse: [
        { value: 0, label: "TUTTE" },
        { value: 1, label: "APERTE" },
        { value: 2, label: "TERMINATE" },
      ],
      currentStatusId: 0, //Tutte le commesse
      commercialeOptions: [], // Array delle opzioni per il commerciale
      clienteOptions: [], // Array delle opzioni per il cliente
      userAuthenticated: null,
    };
  },
  computed: {
    ...mapGetters(["getCurrentPageCommesse", "getCommesseSortType", "getSearchCommesse"]),
    labelsString() {
      return Array.isArray(this.allFilters.labels) && this.allFilters.labels.length > 0
        ? this.allFilters.labels.join(", ")
        : "Tutti"; // Messaggio predefinito se non ci sono labels
    },
    currentStatus() {
      return this.statusCommesse.find((c) => c.value === this.currentStatusId);
    },
    //
    // Authenticated
    //
    isAuthenticatedSuperAdmin() {
      return this.permissions_isAuthenticatedSuperAdmin(this.userAuthenticated);
    },
    isAuthenticatedGestionePersonale() {
      return this.permissions_isAuthenticatedGestionePersonale(this.userAuthenticated);
    },
  },
  watch: {
    "$route.query": {
      handler(newQuery) {
        if (this.$route.name === "route-commesse") {
          this.syncFiltersWithQueryParams(newQuery);
          this.applyFilters(newQuery); // Applica solo se siamo nella lista delle commessa
        }
      },
      immediate: true,
    },
    getSearchCommesse() {
      this.fetchCommesseData();
    },
  },
  methods: {
    ...mapActions(["setCurrentPageCommesse", "setSearchCommesse"]),
    //
    // ** Fetch Data **
    //
    async fetchCommesseData() {
      this.loading = true;
      this.error = null;
      this.totalRecords = 0;
      try {
        const filters = this.allFilters.ids;
        const response = await fetchCommesse(
          this.page,
          this.limit,
          this.getCommesseSortType,
          this.getSearchCommesse,
          filters
        );
        const totalCount = response?.totalCount ?? 0;
        this.commesse = response.commesse?.map((item) => new Commessa(item)) ?? [];
        this.totalPages = Math.ceil(totalCount / this.limit);
        this.totalRecords = totalCount;
        this.isFirstTime = false;
        this.expandedRows = [];
      } catch (err) {
        this.error = "Si è verificato un errore ";
      } finally {
        this.loading = false;
      }
    },
    //
    // Gestione multiselect
    //
    onOpenMultiselectCliente() {
      setTimeout(() => {
        // Aggiungo la classe no-overflow per permettere alla multiselect di andare in primo piano
        const dropdownMenu = document.querySelector(
          `ul[aria-labelledby="dropdown_cliente"]`
        );
        const li1 = document.getElementById("filtro_cliente");

        if (dropdownMenu) {
          dropdownMenu.classList.add("no-overflow");
          li1.classList.add("no-overflow");
        }
      }, 50);
    },
    onCloseMultiselectCliente() {
      // Rimuovo la classe no-overflow
      const dropdownMenu = document.querySelector(
        `ul[aria-labelledby="dropdown_cliente"]`
      );
      const li1 = document.getElementById("filtro_cliente");
      if (dropdownMenu) {
        dropdownMenu.classList.remove("no-overflow");
        li1.classList.remove("no-overflow");
      }
    },
    //
    // ** Gestione Filtri **
    //
    applyFilters(filtersData) {
      // Inizializza filtersData se undefined, con labels come array vuoto
      filtersData = filtersData || {
        societa: "",
        tipologia: "",
        status: "",
        commerciale: "",
        cliente: "",
        labels: [],
      };

      // Verifica se societa, tipologia sono già array, altrimenti applica split
      this.allFilters.ids.societa = Array.isArray(filtersData.societa)
        ? filtersData.societa
        : filtersData.societa
        ? filtersData.societa.split(",")
        : [];

      this.allFilters.ids.tipologia = Array.isArray(filtersData.tipologia)
        ? filtersData.tipologia
        : filtersData.tipologia
        ? filtersData.tipologia.split(",")
        : [];

      // Gestisco le labels
      this.allFilters.labels = Array.isArray(filtersData.labels)
        ? filtersData.labels
        : [];

      // Gestisco i filtri status, cliente & commerciale
      this.allFilters.ids.status = filtersData.status;
      this.allFilters.ids.commerciale = filtersData.commerciale;
      this.allFilters.ids.cliente = filtersData.cliente;

      // Aggiorna la pagina corrente e fetch dei dati
      this.page = this.getCurrentPageCommesse;
      this.fetchCommesseData();

      // Aggiorna la query string nell'URL senza reindirizzare
      this.$router.push({ query: { ...filtersData } });
    },
    syncFiltersWithQueryParams(queryParams = this.$route.query) {
      // sync status
      this.localFilters.status = Number(queryParams.status);
      this.currentStatusId = this.localFilters.status;

      // sync commerciale
      this.localFilters.commerciale = queryParams.commerciale;
      const foundCommerciale = this.commercialeOptions.find(
        (option) => option.value === this.localFilters.commerciale
      );
      this.selectedLocalModels.commerciale = foundCommerciale ? foundCommerciale : null;

      // sync cliente
      this.localFilters.cliente = queryParams.cliente;
      const foundCliente = this.clienteOptions.find(
        (option) => option.value === this.localFilters.cliente
      );
      this.selectedLocalModels.cliente = foundCliente ? foundCliente : null;
    },
    async asyncFetchCliente(query) {
      // rimuovo i caratteri *^? insieme ai tag HTML/XML per le query
      query = this.mixins_cleanTextForQuery(query);
      // Carico le opzioni dei fornitori trovati
      this.isSearchingCliente = true;
      const tabCliente = await this.mixins_getClienti(query);
      this.clienteOptions = tabCliente.map((cliente) => ({
        value: cliente._id,
        label: cliente.ragionesociale,
      }));
      this.isSearchingCliente = false;
      console.log("** clienteOptions", this.clienteOptions);
    },
    sendFiltersQueryString() {
      const newQuery = { ...this.$route.query, ...this.localFilters };

      this.setCurrentPageCommesse(1);

      // Applica i nuovi filtri
      this.applyFilters(newQuery);
    },
    //
    // Gestione multiselect
    //
    onOpenMultiselectCommerciale() {
      setTimeout(() => {
        // Aggiungo la classe no-overflow per permettere alla multiselect di andare in primo piano
        const dropdownMenu = document.querySelector(
          `ul[aria-labelledby="dropdown_commerciale"]`
        );
        const li1 = document.getElementById("filtro_commerciale");

        if (dropdownMenu) {
          dropdownMenu.classList.add("no-overflow");
          li1.classList.add("no-overflow");
        }
      }, 50);
    },
    onCloseMultiselectCommerciale() {
      // Rimuovo la classe no-overflow
      const dropdownMenu = document.querySelector(
        `ul[aria-labelledby="dropdown_commerciale"]`
      );
      const li1 = document.getElementById("filtro_commerciale");
      if (dropdownMenu) {
        dropdownMenu.classList.remove("no-overflow");
        li1.classList.remove("no-overflow");
      }
    },
    //
    // Commerciale
    //
    onCommercialeSelect(selected) {
      this.localFilters.commerciale = selected.value;
      console.log("** multiselect filter commerciale", this.localFilters.commerciale);
    },
    onCommercialeRemove() {
      this.localFilters.commerciale = "";
    },
    //
    // Cliente
    //
    onClienteSelect(selected) {
      this.localFilters.cliente = selected.value;
      console.log("** multiselect filter cliente", this.localFilters.cliente);
    },
    onClienteRemove() {
      this.localFilters.cliente = "";
    },
    //
    // ** Gestione ricerca **
    //
    filterTextToSearch(text) {
      this.setSearchCommesse(text);
      this.setCurrentPageCommesse(1);
      this.page = 1;
    },
    filterClearTextToSearch() {
      this.setSearchCommesse("");
      this.setCurrentPageCommesse(1);
      this.page = 1;
    },
    changeStatusFilter(id) {
      this.currentStatusId = id;
      this.localFilters.status = this.currentStatusId;

      const newQuery = { ...this.$route.query, ...this.localFilters };

      this.setCurrentPageCommesse(1);

      // Applica i nuovi filtri
      this.applyFilters(newQuery);
    },
    //
    // ** Altri metodi **
    //
    onPageChange(page) {
      this.page = page;
      this.setCurrentPageCommesse(page);
      this.fetchCommesseData(this.filtersIds);
    },
    editCommessa(commessa) {
      this.$router.push({
        name: "route-commesse-edit",
        params: { id: commessa._id },
        query: { ...this.$route.query },
      });
    },
    async askRemoveCommessa(id) {
      console.log(id);
      if (!this.isAuthenticatedSuperAdmin && !this.isAuthenticatedGestionePersonale)
        return;
      this.$swal
        .fire({
          title: "Cancellazione Commessa",
          html: "Sei sicuro di voler cancellare la commessa?",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: '<i class="fa fa-check"></i> Conferma',
          cancelButtonText: '<i class="fa fa-xmark"></i> Annulla',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await this.removeCommessa(id);
          }
        });
    },
    async removeCommessa(id) {
      if (!this.isAuthenticatedSuperAdmin && !this.isAuthenticatedGestionePersonale)
        return;
      console.log("** delete commessa id", id);
      this.loading = true;
      try {
        await deleteCommessa(id);
        this.page = 1;
        this.setCurrentPageCommesse(1);
        await this.fetchCommesseData(this.filtersIds);
      } catch (error) {
        this.error = "Errore durante l'eliminazione dell'utente";
      } finally {
        this.loading = false;
      }
    },
    routeUserTeam(userFullName, id) {
      if (id) {
        // Apro la pagina di dettaglio del team in un nuovo tab del browser
        const routeData = this.$router.resolve({
          name: "route-teams-edit",
          params: { id: id },
        });
        console.log("** apertura user in un nuovo tab del browser", routeData);
        window.open(routeData.href, "_blank");
      } else {
        this.mixins_showMessage("Errore", `ID mancante per '${userFullName}'`, "error");
      }
    },
    toggleRow(index) {
      const rowIndex = this.expandedRows.indexOf(index);
      if (rowIndex > -1) {
        this.expandedRows.splice(rowIndex, 1); // Rimuovo l'indice se esiste già
      } else {
        this.expandedRows.push(index); // Aggiungo l'indice se non esiste
      }
    },
    isRowExpanded(index) {
      return this.expandedRows.includes(index);
    },
    sortResourcesByFullnameCaseSensitive(resourcesArray) {
      const sortedArray = resourcesArray.sort((a, b) => {
        if (a.fullname < b.fullname) return -1;
        if (a.fullname > b.fullname) return 1;
        return 0;
      });

      return sortedArray;
    },
    getSortLabeltByValue(value) {
      const foundItem = this.sortOrderList.find((item) => item.value === value);
      return foundItem ? foundItem.text : "";
    },
  },
  async mounted() {
    console.log("** CommesseContent montato");

    // Oggetto user da local storage
    this.userAuthenticated = this.mixin_getUserDataLS();

    // Carico le opzioni dei commerciali cercando il commerciale nel fullname
    const tabellaCommerciale = await this.mixins_getCommerciale();
    console.log("** tabellaCommerciale", tabellaCommerciale);
    this.commercialeOptions = tabellaCommerciale.map((commerciale) => ({
      value: commerciale.providerUserId,
      label: commerciale.metadata.fullname,
    }));
    console.log("** commercialeOptions", this.commercialeOptions);

    // Carico le opzioni dei clienti cercando il cliente nella ragionesociale
    const tabellaClienti = await this.mixins_getClienti();
    this.clienteOptions = tabellaClienti.map((cliente) => ({
      value: cliente._id,
      label: cliente.ragionesociale,
    }));

    // Sincronizza i filtri
    this.syncFiltersWithQueryParams();
  },
};
</script>

<style scoped>
.custom-collapse {
  overflow: hidden;
  transition: max-height 0.4s ease-in-out, opacity 0.4s ease-in-out;
  max-height: 0;
  opacity: 0;
}

.custom-collapse.show {
  max-height: 10000px;
  opacity: 1;
}

tr {
  transition: background-color 0.4s ease-in-out;
}

tr.expanded {
  background-color: rgb(133 199 255 / 60%) !important;
}
</style>
