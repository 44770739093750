<template>
  <div
    class="align-items-center container-fluid d-flex flex-row justify-content-end pt-4 px-0"
  >
    <button type="button" class="btn button-outline-1 small">
      <span><i class="zmdi zmdi-plus"></i>Richiedi Dotazione</span>
    </button>
  </div>
  <div class="container-fluid m-0 p-0 pt-4">
    <div class="row m-0">
      <table class="table-bordered table-responsive-md table-setup">
        <thead class="table-header-font">
          <tr class="td-center">
            <th width="200">TIPOLOGIA</th>
            <th>DESCRIZIONE</th>
            <th>MATRICOLA</th>
            <th width="150">DATA CONSEGNA</th>
            <th>STATO</th>
            <th style="min-width: 50px !important; width: 50px !important">AZIONI</th>
          </tr>
        </thead>
        <!-- Table Records -->
        <tbody class="table-body-font td-vertical-center">
          <tr>
            <td class="td-center">
              <ul class="list-tags">
                <li>
                  <span class="badge-colored blue" title="Hardware" alt="Hardware"
                    >Hardware</span
                  >
                </li>
              </ul>
            </td>
            <td>Laptop Mackbook Pro</td>
            <td class="td-center">AGHF5993744</td>
            <td class="td-center">04/06/2024</td>
            <td class="td-center">In dotazione</td>
            <td class="td-actions td-center">
              <div class="dropdown dropstart table-dropdown-action">
                <button
                  class="but"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i class="bi bi-three-dots-vertical"></i>
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <a class="dropdown-item small red" href="#"
                      ><i class="bi bi-ticket-detailed-fill me-2"></i>Apri Ticket</a
                    >
                  </li>
                  <li>
                    <a href="#" class="dropdown-item small"
                      ><i class="bi bi-arrow-clockwise me-2"></i>Restituisci</a
                    >
                  </li>
                  <li>
                    <a class="dropdown-item small" href="#"
                      ><i class="bi bi-eye me-2"></i>Visualizza</a
                    >
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
