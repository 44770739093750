const config = {
    // AUTH_URL: 'http://10.8.0.2:5002',
    // API_URL: 'http://10.8.0.2:5003',
    // AUTH_URL: 'http://localhost:3000',
    // API_URL: 'http://localhost:3000',
    // AUTH_URL: 'https://unigate.silicondev.com:448',
    // API_URL: 'https://unigate.silicondev.com:448',
    AUTH_URL: 'https://unigatetest.silicondev.com',
    API_URL: 'https://unigatetest.silicondev.com',
    sessionDataLS: 'UnigateUser',
    CLIENT_ID: 'ehrti9569LHRY84',
    PROVIDER: 'auth0',
    LANG: 'it',
    COUNTRY: 'it',
    APP_VERSION: "v1.5"
};

export default config;