<template>
  <transition name="backdrop-modal">
    <div v-if="isVisible" class="backdrop-dialog"></div>
  </transition>

  <transition name="modal">
    <div class="dialog background-gray" :style="`width: ${dialogWidth}`" v-if="isVisible">
      <div class="container-fluid">
        <div class="row">
          <div class="header-text w-100">
            <h1>{{ dialogTitle }}</h1>
            <div>{{ userData.fullname }}</div>
          </div>
        </div>
        <hr class="sepGray" />
        <div class="container-fluid p-0 m-0">
          <div class="container-fluid px-0 py-3">
            <!-- Tipo assenza -->
            <div v-if="isFieldVisible.tipoassenza" class="row mb-3">
              <label
                class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
              >
                <span>TIPO ASSENZA</span>
              </label>
              <div
                class="align-items-center col-lg-10 d-flex flex-column justify-content-center"
              >
                <MultiSelect
                  :searchable="false"
                  v-model="selectedLocalModels.tipoassenza"
                  :options="tipoassenzaOptions"
                  placeholder="- SELEZIONA -"
                  label="label"
                  track-by="value"
                  :openDirection="'below'"
                  :showLabels="false"
                  @update:modelValue="onTipoassenzaUpdate"
                  @open="onOpenMultiselect"
                  @close="onCloseMultiselect"
                  @blur="onOpenMultiselect"
                  :maxHeight="210"
                  :disabled="isEditMode"
                >
                  <template #noOptions>
                    <span>L'elenco è vuoto</span>
                  </template>
                  <template #noResult>
                    <span>Nessun elemento trovato</span>
                  </template>
                </MultiSelect>
              </div>
            </div>
            <!-- Tipo permesso giustificato -->
            <div v-if="isFieldVisible.permessogiustificato" class="row mb-3">
              <label
                class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
              >
                <span>TIPO PERMESSO</span>
              </label>
              <div
                class="align-items-center col-lg-10 d-flex flex-column justify-content-center"
              >
                <MultiSelect
                  :searchable="true"
                  v-model="selectedLocalModels.dettaglioassenza"
                  :options="tipopermessoOptions"
                  placeholder="- SELEZIONA -"
                  label="label"
                  track-by="value"
                  :openDirection="'below'"
                  :showLabels="false"
                  @update:modelValue="onTipopermessoUpdate"
                  @open="onOpenMultiselect"
                  @close="onCloseMultiselect"
                  @blur="onOpenMultiselect"
                  :maxHeight="210"
                  :disabled="isEditMode || localData.tipoassenza === 0"
                >
                  <template #noOptions>
                    <span>L'elenco è vuoto</span>
                  </template>
                  <template #noResult>
                    <span>Nessun elemento trovato</span>
                  </template>
                </MultiSelect>
              </div>
            </div>
            <!-- Data inizio, data fine -->
            <div v-if="isFieldVisible.periodo" class="row mb-3">
              <label
                class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
                ><span>DATA INIZIO</span></label
              >
              <div class="align-items-center col-lg-4 d-flex justify-content-center">
                <VueDatePicker
                  v-model="localData.datainizio"
                  dark
                  locale="it"
                  format="dd/MM/yyyy"
                  cancelText="Annulla"
                  selectText="Conferma"
                  :enable-time-picker="false"
                  @update:modelValue="onDatainizioUpdate"
                  @cleared="onDatainizioClear"
                  placeholder="DATA INIZIO"
                  :teleport="true"
                >
                  <template #input-icon>
                    <i class="input-slot-image2 zmdi zmdi-calendar"></i>
                  </template>
                  <template #clear-icon="{ clear }">
                    <i
                      @click="clear"
                      class="input-slot-image zmdi zmdi-close zmdi-hc-lg"
                    ></i>
                  </template>
                </VueDatePicker>
                <button
                  class="timepicker-button"
                  alt="OGGI"
                  title="OGGI"
                  @click="setToday('datainizio')"
                >
                  <i class="zmdi zmdi-calendar zmdi-hc-lg"></i>
                </button>
              </div>
              <label
                class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
                ><span>DATA FINE</span></label
              >
              <div class="align-items-center col-lg-4 d-flex justify-content-center">
                <VueDatePicker
                  v-model="localData.datafine"
                  dark
                  locale="it"
                  format="dd/MM/yyyy"
                  cancelText="Annulla"
                  selectText="Conferma"
                  :enable-time-picker="false"
                  @update:modelValue="onDatafineUpdate"
                  @cleared="onDatafineClear"
                  placeholder="DATA FINE"
                  :teleport="true"
                  style="font-size: 18px !important"
                >
                  <template #input-icon>
                    <i class="input-slot-image2 zmdi zmdi-calendar"></i>
                  </template>
                  <template #clear-icon="{ clear }">
                    <i
                      @click="clear"
                      class="input-slot-image zmdi zmdi-close zmdi-hc-lg"
                    ></i>
                  </template>
                </VueDatePicker>
                <button
                  class="timepicker-button"
                  alt="OGGI"
                  title="OGGI"
                  @click="setToday('datafine')"
                >
                  <i class="zmdi zmdi-calendar zmdi-hc-lg"></i>
                </button>
              </div>
            </div>
            <!-- Calcolo giorni -->
            <div v-if="isFieldVisible.giorni" class="row mb-3">
              <label
                class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
                ><span>CALCOLO GIORNI</span></label
              >
              <div class="align-items-center col-lg-10 d-flex justify-content-center">
                <input
                  type="number"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  class="form-control"
                  v-model="localData.giorni"
                  step="1"
                  min="0"
                  disabled
                />
              </div>
            </div>
            <!-- Ore giornaliere -->
            <div v-if="isFieldVisible.ore" class="row mb-3">
              <label
                class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
                ><span>ORE GIORNALIERE</span></label
              >
              <div class="align-items-center col-lg-10 d-flex justify-content-center">
                <input
                  type="number"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  class="form-control"
                  v-model="localData.oregiornaliere"
                  @blur="checkMaxValue($event, 'oregiornaliere')"
                  @input="onInputChange($event, 'oregiornaliere')"
                  step="0.5"
                  min="0"
                  :max="userData.orecontrattuali"
                />
              </div>
            </div>
            <!-- Protocollo certificato medico (PUC) -->
            <div v-if="isFieldVisible.puc" class="row mb-3">
              <label
                class="col-form-label form-label col-lg-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
              >
                <i
                  class="bi bi-info-circle-fill me-2"
                  alt="PROTOCOLLO CERTIFICATO MEDICO"
                  title="PROTOCOLLO CERTIFICATO MEDICO"
                ></i
                ><span>CODICE PUC</span>
              </label>
              <div
                class="align-items-center col-lg-10 d-flex flex-column justify-content-start"
              >
                <input
                  type="text"
                  class="form-control"
                  v-model="localData.puc"
                  @input="onInputChange($event, 'puc')"
                  @paste.prevent
                  spellcheck="false"
                  autocomplete="off"
                  autocapitalize="none"
                  placeholder="CODICE PUC"
                />
              </div>
            </div>
            <!-- Note -->
            <div v-if="isFieldVisible.note" class="row mb-3">
              <label
                class="col-form-label form-label col-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
                ><span>NOTE</span></label
              >
              <div class="align-items-center col d-flex justify-content-center">
                <textarea
                  rows="3"
                  type="text"
                  v-input-textarea
                  class="form-control"
                  v-model.trim="localData.note"
                  @input="onInputChange($event, 'note')"
                  spellcheck="false"
                  autocomplete="off"
                  autocapitalize="none"
                  placeholder="NOTE"
                />
              </div>
            </div>
            <!-- Allegato -->
            <div v-if="shouldShowDocumentoAllegato" class="row mb-3">
              <label
                class="col-form-label form-label col-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
              >
                <span>ALLEGATO</span>
              </label>
              <div class="align-items-center col d-flex justify-content-center">
                <div class="container-fluid m-0 p-0">
                  <div class="form-group p-0">
                    <div class="input-group">
                      <span class="input-group-text px-3">
                        <i class="zmdi zmdi-file"></i>
                      </span>
                      <input
                        type="file"
                        ref="fileInput"
                        :accept="acceptedFileTypes"
                        @change="handleFileUpload($event)"
                        class="d-none"
                      />
                      <input
                        type="text"
                        class="form-control form-browse"
                        :placeholder="
                          fileName
                            ? `${fileName} (${fileSize})`
                            : localData.documentoallegato
                            ? getFileNameFromUrl(localData.documentoallegato)
                            : 'Scegliere un allegato...'
                        "
                        disabled
                      />
                      <button
                        v-if="fileName || localData.documentoallegato"
                        @click="clearFile"
                        class="btn-clear-file"
                        type="button"
                      >
                        <i class="zmdi zmdi-close zmdi-hc-lg"></i>
                      </button>
                      <button
                        @click="triggerFileInput"
                        class="btn button-outline-1 small px-4"
                        type="button"
                      >
                        <i class="fa-solid fa-folder-open"></i>Aggiungi Allegato
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Info -->
            <div v-if="isElementVisible && shouldShowInfo" class="row">
              <div class="container-fluid">
                <div class="alert info" role="alert">
                  <strong>Info</strong><br />{{ selectedPermessoNote }}
                </div>
              </div>
            </div>
          </div>

          <!-- Errors model -->
          <div v-if="Object.keys(errorsModel).length > 0" class="mb-4">
            <div
              v-for="(errorModel, field) in errorsModel"
              :key="field"
              class="text-danger small mt-1"
            >
              {{ errorModel }}
            </div>
          </div>
        </div>
        <hr class="sepGray" />
        <div class="row">
          <div class="container-fluid my-3">
            <button
              @click="closeModal"
              type="button"
              class="btn button-outline-1 float-start"
            >
              <span><i class="zmdi zmdi-close"></i>Annulla</span>
            </button>
            <button
              @click="saveData"
              type="button"
              class="btn button-outline-1 float-end"
              :disabled="localData.tipoassenza === -1"
            >
              <span
                ><i class="bi bi-send-fill"></i
                >{{ isEditMode ? "Modifica richiesta" : "Invia richiesta" }}</span
              >
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { UtilityMixins } from "@/mixins/UtilityMixins.js";
import {
  RichiestaAssenzaSchema,
  RichiestaAssenza,
} from "@/models/RichiestaAssenzaJoiModel";
import "vue-multiselect/dist/vue-multiselect.css";
import "@/assets/css/vue-multiselect.css";
import {
  set,
  differenceInCalendarDays,
  formatISO,
  parseISO,
  isValid,
  addDays,
  getDay,
  isSameDay,
} from "date-fns";

export default {
  mixins: [UtilityMixins],
  components: {},
  props: {
    isVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
    dialogWidth: {
      type: String,
      default: "1200px",
    },
    dialogTitle: {
      type: String,
      default: "",
    },
    dataItem: {
      type: Object,
      default: () => ({}),
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
    isFilterFerie: {
      type: Boolean,
      default: false,
    },
    userData: {
      type: Object,
      default: Object,
    },
  },
  emits: ["closeModal", "saveData"],
  data() {
    return {
      isElementVisible: false,
      localData: new RichiestaAssenza(), // Inizializzo localData come una nuova istanza di RichiestaAssenza
      errorsModel: {}, // Oggetto per gestire gli errori di validazione
      tabellaTipoAssenze: [], // Dati completi dei tipi di assenze
      tabellaFestivita: [], // Dati completi delle festività
      tipoassenzaOptions: [], // Array delle opzioni per il tipo assenze (0=Ferie comparià nel filtro "Ferie" della sidebar, 1=ROL, 2=Malattia, 3=Permessi Giustificati)
      tipopermessoOptions: [], // Array delle opzioni per i permessi giustificati
      selectedLocalModels: {
        // Raccolgo i modelli locali in un unico oggetto
        tipoassenza: [],
        dettaglioassenza: [],
        datainizio: null,
        datafine: null,
      },
      acceptedFileTypes: ".pdf,.txt,image/*,.zip", // accetta PDF, TXT, immagini e ZIP
      acceptedTypes: [
        // MIME types dei file accettati
        "application/pdf",
        "text/plain",
        "image/png",
        "image/jpeg",
        "image/jpg",
        "image/bmp",
        "image/webp",
        "application/zip", // MIME type per file ZIP
        "application/x-zip-compressed", // Altri possibili MIME types per ZIP
      ],
      fileName: "",
      fileSize: "",
      file: null,
      isFieldVisible: [
        { permessogiustificato: false },
        { periodo: false },
        { giorni: false },
        { ore: false },
        { puc: false },
        { note: false },
        { documentoallegato: false },
        { info: false },
      ],
      status: ["Pending", "Approved", "Rejected"],
      statusStr: ["In attesa", "Approvata", "Respinta"],
    };
  },
  computed: {
    calcolaGiorni() {
      // 1=ROL, 2=Malattia, 3=Permessi Giustificati

      const datainizio = this.localData.datainizio
        ? parseISO(this.localData.datainizio)
        : null;
      const datafine = this.localData.datafine ? parseISO(this.localData.datafine) : null;

      // Controlla che entrambe le date siano valide
      if (!isValid(datainizio) || !isValid(datafine)) {
        return 0; // Se una delle due date non è valida, restituisci 0 o un valore di fallback
      }

      // Controlla che datafine sia >= datainizio
      if (datafine < datainizio) {
        return 0; // Se datafine è inferiore a datainizio, restituisci 0
      }

      // Calcola la differenza di giorni
      const totalDays = differenceInCalendarDays(datafine, datainizio) + 1;

      if (this.localData.tipoassenza === 2 || this.localData.tipoassenza === 3) {
        // Se il tipo di assenza è === 2 (malattia) oppure se il tipo di assenza è === 3 (permessi giustificati)
        return totalDays;
      } else if (this.localData.tipoassenza === 0 || this.localData.tipoassenza === 1) {
        // Se il tipo di assenza è === 1 (ROL) oppure è === 0 (Ferie)
        // bisogna fare il calcolo delle festività anche con il locale e la città
        let validDays = 0;

        for (let day = 0; day < totalDays; day++) {
          const currentDate = addDays(datainizio, day);
          const dayOfWeek = getDay(currentDate); // 0 = Domenica, 6 = Sabato

          // Verifica se è sabato o domenica
          if (dayOfWeek === 0 || dayOfWeek === 6) {
            continue;
          }

          // Verifica se è una festività
          const isFestivity =
            this.tabellaFestivita &&
            this.tabellaFestivita.some((festivita) => {
              // Se la festività è locale, confronta la città, altrimenti basta che le date coincidano
              if (festivita.locale === true) {
                return (
                  festivita.citta === this.userData.citta &&
                  isSameDay(parseISO(festivita.data), currentDate)
                );
              }
              return isSameDay(parseISO(festivita.data), currentDate);
            });

          if (isFestivity) {
            continue; // Salta i giorni festivi
          }

          validDays++;
        }

        return validDays;
      } else {
        return 0;
      }
    },

    // Trova l'opzione selezionata per dettaglioassenza
    selectedPermesso() {
      const selectedPermesso = this.selectedLocalModels.dettaglioassenza;

      // Controllo che l'oggetto selectedPermesso non sia undefined
      if (!selectedPermesso || !selectedPermesso.value) {
        return null;
      }

      // Restituisce l'opzione corrispondente al valore di selectedPermesso.value
      return this.tipopermessoOptions.find(
        (option) => option.value === selectedPermesso.value
      );
    },

    // Verifica se il campo "ALLEGATO" deve essere mostrato
    shouldShowDocumentoAllegato() {
      return (
        (this.isFieldVisible.documentoallegato &&
          this.selectedPermesso &&
          this.selectedPermesso.allegato) ||
        (this.isFieldVisible.documentoallegato && this.localData.tipoassenza === 2)
      );
    },

    // Verifica se il campo "INFO" deve essere mostrato
    shouldShowInfo() {
      return (
        this.isFieldVisible.info &&
        this.selectedPermesso &&
        this.selectedPermesso.note &&
        this.selectedPermesso.note.trim() !== ""
      );
    },

    // Mostra il contenuto del campo "note" dell'elemento selezionato
    selectedPermessoNote() {
      return this.selectedPermesso ? this.selectedPermesso.note : "";
    },
  },
  watch: {
    // Quando il modal diventa visibile, inizializzo i dati locali e le selezioni
    isVisible(newVal) {
      if (newVal) {
        // Inizializzo `localData` come una nuova istanza di RichiestaAssenza o come una copia dell'oggetto `dataItem`
        this.localData = new RichiestaAssenza(this.dataItem);

        // console.log("** assenze isEditMode", this.isEditMode);
        if (this.isFilterFerie) {
          this.setLocalDataFerie();
        }

        this.errorsModel = {};
        if (!this.isEditMode) this.clearFile();

        // memorizzo le date ricevute dal server
        this.localData.datainizio_old = this.localData.datainizio;
        this.localData.datafine_old = this.localData.datafine;

        // Sincronizzo i dati locali
        this.updateLocalModels();

        console.log("** watch localData", this.localData);
        console.log("** watch userData", this.userData);
      }
    },
    "selectedLocalModels.tipoassenza"(newValue) {
      if (!this.isFilterFerie) {
        if (newValue && newValue.value !== undefined) {
          console.log("** tipo assenza", newValue.value, typeof newValue);
          if (newValue.value === 2) {
            // Tipo selezionato "Malattia"
            this.setView("malattia");
          } else if (newValue.value === 1) {
            // Tipo selezionato "ROL"
            this.setView("rol");
          } else if (newValue.value === 3) {
            // Tipo selezionato "Permessi Giustificati"
            this.setView("permessi-giustificati");
          }
        } else {
          console.log("newValue è undefined o null", newValue);
          this.setView("default");
        }
      } else {
        // Tipo selezionato "Ferie"
        if (newValue && newValue.value !== undefined) {
          console.log("** tipo assenza", newValue.value, typeof newValue);
          if (newValue.value === 0) {
            this.setLocalDataFerie();
            this.setView("ferie");
          }
        } else {
          console.log("newValue è undefined o null", newValue);
          this.setLocalDataFerie();
          this.setView("ferie");
        }
      }
    },
    calcolaGiorni(newGiorni) {
      // Aggiorno il campo localData.giorni ogni volta che calcolaGiorni cambia
      this.localData.giorni = newGiorni;
    },
  },
  methods: {
    setLocalDataFerie() {
      this.localData.tipoassenza = 0;
      this.localData.tipoassenza_descrizione = "Ferie";
      this.localData.dettaglioassenza = 2;
      this.localData.dettaglioassenza_descrizione = "Ferie";
    },
    closeModal() {
      // Inizializzo localData
      this.localData = new RichiestaAssenza();
      // this.setView("default");
      this.$emit("closeModal"); // Emetto l'evento per chiudere il modal
    },
    validateImmediate() {
      // valido i campi
      this.errorsModel = {};
      const { error } = RichiestaAssenzaSchema.validate(this.localData, {
        abortEarly: false,
      });
      if (error) {
        this.errorsModel = error.details.reduce((acc, err) => {
          acc[err.path.join(".")] = err.message;
          return acc;
        }, {});
        return false;
      } else {
        this.errorsModel = {};
        return true;
      }
    },
    async saveData() {
      this.localData.datainizio = this.formatToYYYYMMDD(this.localData.datainizio);
      this.localData.datafine = this.formatToYYYYMMDD(this.localData.datafine);
      this.localData.fullname = this.userData.fullname;
      if (this.localData.tipoassenza === 0 || this.localData.tipoassenza === 2) {
        // se malattia o ferie forzo ore giornaliere == a ore contrattuali
        this.localData.oregiornaliere = this.userData.orecontrattuali;
      }
      if (this.localData.tipoassenza !== 2) {
        // se tipoassenza diverso da 2 (malattia) puc = ""
        this.localData.puc = "";
      }

      // Valido i campi
      if (!this.validateImmediate()) return false;

      // Validazione è ok, si inviano i dati al parent per il salvataggio
      this.errorsModel = {};

      this.$emit("saveData", this.localData, this.file); // Emetto l'evento di salvataggio dei dati
    },
    findOption(value, options) {
      // Trovo l'opzione corrispondente in `options`, o converto `value` in un oggetto `{ value, label }`
      return (
        options.find((option) => option.value === value) || this.convertToObject(value)
      );
    },
    convertToObject(value) {
      // Converto un valore stringa in un oggetto `{ value, label }`
      return value ? { value, label: value } : null;
    },
    convertToValue(obj) {
      // Estraggo la stringa `value` dall'oggetto `{ value, label }`
      return obj && typeof obj === "object" ? obj.value : obj;
    },
    //
    // Gestione multiselect
    //
    updateLocalModels() {
      // Sincronizzo i dati ricevuti

      // tipoassenza
      this.selectedLocalModels.tipoassenza = this.localData.tipoassenza
        ? this.tipoassenzaOptions.find(
            (item) => item.value === this.localData.tipoassenza
          )
        : [];
      // dettaglioassenza
      this.selectedLocalModels.dettaglioassenza = this.localData.dettaglioassenza
        ? this.tipopermessoOptions.find(
            (item) => item.value === this.localData.dettaglioassenza
          )
        : [];
      // datainizio
      this.selectedLocalModels.datainizio = this.localData.datainizio
        ? parseISO(this.localData.datainizio)
        : null;
      // datafine
      this.selectedLocalModels.datafine = this.localData.datafine
        ? parseISO(this.localData.datafine)
        : null;
    },
    onTipoassenzaUpdate(newValue) {
      this.errorsModel = {};

      this.localData.tipoassenza = newValue?.value || -1;
      this.localData.tipoassenza_descrizione = newValue?.label || "";
      // Cerco all'interno della tabella dei tipo di assenze se esiste il tipodiassenza corrispondente
      // Cerco solo 1=ROL o 2=Malattia (0=ferie ma faremo un componente apposito per le ferie e 3=permessi giustificati, abbiamo una tipopermessoOptions apposito)
      if (newValue) {
        if (newValue.value === 1 || newValue.value === 2) {
          const objectTipoAss = this.tabellaTipoAssenze.filter(
            (item) => item.tipoassenza === newValue.value
          );
          if (objectTipoAss && objectTipoAss.length > 0) {
            this.localData.dettaglioassenza = objectTipoAss[0]?.dettaglioassenza || -1;
            this.localData.dettaglioassenza_descrizione =
              objectTipoAss[0]?.dettaglioassenza_descrizione || "";
          }
        } else if (newValue.value === 3) {
          this.localData.dettaglioassenza = -1;
          this.localData.dettaglioassenza_descrizione = "";
          this.selectedLocalModels.dettaglioassenza = this.localData.dettaglioassenza
            ? this.tipopermessoOptions.find(
                (item) => item.value === this.localData.dettaglioassenza
              )
            : [];
        }
      }
    },
    onTipopermessoUpdate(newValue) {
      this.localData.dettaglioassenza = newValue?.value || -1;
      this.localData.dettaglioassenza_descrizione = newValue?.label || "";
      this.selectedLocalModels.dettaglioassenza = newValue;
      this.validateImmediate();
    },

    onOpenMultiselect() {
      // Aggiungo la classe no-overflow per permettere alla multiselect di andare in primo piano
      const dialog = document.querySelector(".dialog");
      if (dialog) {
        dialog.classList.add("no-overflow");
      }
    },
    onCloseMultiselect() {
      // Rimuovo la classe no-overflow
      const dialog = document.querySelector(".dialog");
      if (dialog) {
        dialog.classList.remove("no-overflow");
      }
    },
    //
    // Gestione date
    //
    setToday(fieldName) {
      // Crea una nuova data e imposta l'orario a mezzanotte (00:00:00)
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Imposta l'orario a 00:00:00:000

      this.localData[fieldName] = formatISO(today); // Usa la data con orario 00:00:00

      // Mappo i campi ai rispettivi metodi di aggiornamento
      const updateMethods = {
        datainizio: this.onDatainizioUpdate,
        datafine: this.onDatafineUpdate,
      };

      // Invoco il metodo corretto in modo dinamico
      if (updateMethods[fieldName]) {
        updateMethods[fieldName](today);
      }
    },
    onDatainizioUpdate(newDate) {
      //
      // Non eseguo più il controllo della data che non può essere un sabato, una domenica o un festivo
      //
      // if (newDate) {
      //   if (this.localData.tipoassenza === 0 || this.localData.tipoassenza === 1) {
      //     // Se il tipo di assenza è === 1 (ROL) oppure è === 0 (Ferie)
      //     // la data non può essere un sabato, una domenica o un festivo
      //     const isAllowed = this.isDateAllowed(formatISO(newDate));
      //     if (!isAllowed) {
      //       this.localData.datainizio = null;
      //       this.mixins_showMessage(
      //         "Attenzione",
      //         'Come "DATA INIZIO" non si può selezionare un sabato, una domenica o un festivo',
      //         "warning"
      //       );
      //       return;
      //     }
      //   }
      // }

      this.localData.datainizio = newDate ? formatISO(newDate) : null;
      console.log("** this.localData.datainizio", this.localData.datainizio);
      this.validateImmediate();
    },
    onDatainizioClear() {
      this.localData.datainizio = null;
      this.validateImmediate();
    },
    onDatafineUpdate(newDate) {
      //
      // Non eseguo più il controllo della data che non può essere un sabato, una domenica o un festivo
      //
      // if (newDate) {
      //   if (this.localData.tipoassenza === 0 || this.localData.tipoassenza === 1) {
      //     // Se il tipo di assenza è === 1 (ROL) oppure è === 0 (Ferie)
      //     // la data non può essere un sabato, una domenica o un festivo
      //     console.log("** assenza formatISO(newDate)", formatISO(newDate));
      //     const isAllowed = this.isDateAllowed(formatISO(newDate));
      //     if (!isAllowed) {
      //       this.localData.datafine = null;
      //       this.mixins_showMessage(
      //         "Attenzione",
      //         'Come "DATA FINE" non si può selezionare un sabato, una domenica o un festivo',
      //         "warning"
      //       );
      //       return;
      //     }
      //   }
      // }

      this.localData.datafine = newDate ? formatISO(newDate) : null;
      console.log("** this.localData.datafine", this.localData.datafine);
      this.validateImmediate();
    },
    onDatafineClear() {
      this.localData.datafine = null;
      this.validateImmediate();
    },
    formatToYYYYMMDD(dateString) {
      let date;

      // Provo a fare il parsing della data come ISO
      try {
        date = parseISO(dateString);
      } catch (error) {
        console.log("Non è una data ISO valida:", error);
        return null;
      }

      // Se la data non è valida, provo a creare una data con il costruttore Date()
      if (!isValid(date)) {
        date = new Date(dateString);
      }

      // Controllo se la data è valida
      if (!isValid(date)) {
        console.error("Input non è una data valida:", dateString);
        return null;
      }

      // Ritorno la data nel formato YYYY-MM-DD
      return formatISO(date, { representation: "date" });
    },
    setTimeToZero(dateString) {
      // Controllo se dateString è null o undefined
      if (!dateString) {
        console.error("La data fornita è null o undefined");
        return null; // Restituisco null o una stringa vuota a seconda del contesto
      }
      // Converte la stringa ISO in una data
      let date = parseISO(dateString);

      // Imposta l'ora a 00:00:00
      date = set(date, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });

      // Restituisce la data modificata nel formato ISO
      return date.toISOString();
    },
    isDateAllowed(dateISO) {
      // Verifica se la data è null o undefined
      if (!dateISO) {
        console.error("La data è nulla o indefinita.");
        return false;
      }

      // Se è una stringa, proviamo a convertirla in una data ISO
      if (typeof dateISO === "string") {
        try {
          dateISO = parseISO(dateISO);
        } catch (error) {
          console.error("Formato della data non valido.");
          return false;
        }
      }

      // Verifica se è una data valida
      if (!isValid(dateISO)) {
        console.error("La data non è valida.");
        return false;
      }

      // Controlla che non sia sabato, domenica o festività
      const dayOfWeek = getDay(dateISO);
      if (dayOfWeek === 0 || dayOfWeek === 6) {
        return false; // Sabato o domenica
      }

      // Controlla se è una festività, tenendo conto delle festività locali
      const isHoliday = this.tabellaFestivita.some((festivita) => {
        // Se è una festività locale, confronta la città
        if (festivita.locale === true) {
          return (
            festivita.citta === this.userData.citta &&
            isSameDay(parseISO(festivita.data), dateISO)
          );
        }

        // Se non è una festività locale, confronta solo la data
        return !festivita.locale && isSameDay(parseISO(festivita.data), dateISO);
      });

      if (isHoliday) {
        return false; // È una festività
      }

      return true; // È un giorno valido
    },
    //
    // Gestione views
    //
    setView(key) {
      switch (key) {
        case "default":
          this.isFieldVisible.tipoassenza = true;
          this.isFieldVisible.permessogiustificato = false;
          this.isFieldVisible.periodo = false;
          this.isFieldVisible.giorni = false;
          this.isFieldVisible.ore = false;
          this.isFieldVisible.puc = false;
          this.isFieldVisible.note = false;
          this.isFieldVisible.documentoallegato = false;
          this.isFieldVisible.info = false;
          break;
        case "malattia":
          this.isFieldVisible.tipoassenza = true;
          this.isFieldVisible.permessogiustificato = false;
          this.isFieldVisible.periodo = true;
          this.isFieldVisible.giorni = true;
          this.isFieldVisible.ore = false;
          this.isFieldVisible.puc = true;
          this.isFieldVisible.note = true;
          this.isFieldVisible.documentoallegato = true;
          this.isFieldVisible.info = false;
          break;
        case "rol":
          this.isFieldVisible.tipoassenza = true;
          this.isFieldVisible.permessogiustificato = false;
          this.isFieldVisible.periodo = true;
          this.isFieldVisible.giorni = true;
          this.isFieldVisible.ore = true;
          this.isFieldVisible.puc = false;
          this.isFieldVisible.note = true;
          this.isFieldVisible.documentoallegato = false;
          this.isFieldVisible.info = false;
          break;
        case "permessi-giustificati":
          this.isFieldVisible.tipoassenza = true;
          this.isFieldVisible.permessogiustificato = true;
          this.isFieldVisible.periodo = true;
          this.isFieldVisible.giorni = true;
          this.isFieldVisible.ore = true;
          this.isFieldVisible.puc = false;
          this.isFieldVisible.note = true;
          this.isFieldVisible.documentoallegato = true;
          this.isFieldVisible.info = true;
          break;
        case "ferie":
          this.isFieldVisible.tipoassenza = false;
          this.isFieldVisible.permessogiustificato = false;
          this.isFieldVisible.periodo = true;
          this.isFieldVisible.giorni = true;
          this.isFieldVisible.ore = false;
          this.isFieldVisible.puc = false;
          this.isFieldVisible.note = true;
          this.isFieldVisible.documentoallegato = false;
          this.isFieldVisible.info = false;
          break;
      }
    },
    //
    // ** Gestione aggiornamenti **
    //
    onInputChange(event, field) {
      this.$nextTick(() => {
        if (field === "puc") {
          // Ottieni il valore corrente dall'input
          let value = event.target.value;

          // Se il valore contiene caratteri non numerici, rimuovili
          let numericValue = value.replace(/\D/g, ""); // Rimuove tutto ciò che non è numero

          // Limita il valore a un massimo di 9 caratteri numerici
          if (numericValue.length > 9) {
            numericValue = numericValue.slice(0, 9);
          }

          // Aggiorna il modello Vue, garantendo che sia sempre una stringa numerica valida o vuota
          this.localData.puc = numericValue || ""; // Mantieni vuoto se non ci sono numeri

          // Aggiorna visivamente l'input
          event.target.value = numericValue;
        } else if (field != "note") {
          this.checkMaxValue(event, field);
        }

        this.validateImmediate();
      });
    },
    checkMaxValue(event, field) {
      const value = this.localData[field];
      const parsedValue = parseFloat(value);

      const maxValue = parseFloat(event.target.max);
      const minValue = parseFloat(event.target.min);

      if (value === "" || isNaN(parsedValue)) {
        this.localData[field] = 0;
      } else if (parsedValue > maxValue) {
        this.localData[field] = maxValue;
      } else if (parsedValue < minValue) {
        this.localData[field] = minValue;
      }
    },
    onPaste(event, field) {
      event.preventDefault();
      const pastedText = (event.clipboardData || window.Clipboard).getData("text");
      const trimmedText = pastedText.trim();
      this.localData[field] = trimmedText;
      this.validateImmediate();
    },
    //
    // Gestione upload di un allegato
    //
    triggerFileInput() {
      // Attiva l'input file per un determinato documento
      this.$refs.fileInput.click();
    },
    resetFileInput() {
      // Resetta l'input file per permettere un nuovo upload dello stesso file
      if (this.$refs.fileInput) {
        this.$refs.fileInput.value = "";
      }
    },
    clearFile() {
      this.fileName = "";
      this.fileSize = "";
      this.localData.documentoallegato = "";
      this.file = null;
      this.resetFileInput();
    },
    async handleFileUpload(event) {
      const file = event.target.files[0];

      if (!file) return;

      // Verifica se il file è del tipo accettato
      if (!this.acceptedTypes.includes(file.type)) {
        this.mixins_showMessage(
          "Tipo di file non supportato",
          "Sono accettati solo PDF, TXT, ZIP o Immagini",
          "warning"
        );
        this.resetFileInput();
        return;
      }

      // Aggiorna il nome e la dimensione del file selezionato
      this.fileName = file.name;
      this.fileSize = this.mixin_formatBytes(file.size);
      this.file = file;
      this.resetFileInput();
    },
    getFileNameFromUrl(url) {
      // Estrai il nome del file dall'URL
      return url.substring(url.lastIndexOf("/") + 1);
    },
  },
  async mounted() {
    // Ottengo tabella festività
    this.tabellaFestivita = await this.mixins_getTabFestivita();
    console.log("** assenze tabella festività", this.tabellaFestivita);

    // Popolo la tabella tabellaTipoAssenze
    this.tabellaTipoAssenze = await this.mixins_getTabTipoAssenze();

    console.log(
      "** tabellaTipoAssenze generale con tutti i tipi di assenza:",
      this.tabellaTipoAssenze
    );

    if (this.isFilterFerie) {
      this.tipoassenzaOptions = [{ value: 0, label: "Ferie" }];
    } else {
      this.tipoassenzaOptions = [
        { value: 2, label: "Malattia" },
        { value: 1, label: "ROL" },
        { value: 3, label: "Permessi Giustificati" },
      ];
    }

    console.log("** tipoassenzaOptions:", this.tipoassenzaOptions);

    // Popolo l'array dei permessi giustificati filtrando tabellaTipoAssenze per chiave "tipoassenza" = 3 (permessi giustificati)
    this.tipopermessoOptions = this.tabellaTipoAssenze
      .filter((item) => item.tipoassenza === 3) // Filtra gli elementi con tipoassenza = 3
      .map((item) => ({
        value: item.dettaglioassenza,
        label: item.dettaglioassenza_descrizione,
        nome: item.nome,
        note: item.note,
        tipoassenza: item.tipoassenza,
        tipoassenza_descrizione: item.tipoassenza_descrizione,
        id: item.id,
        allegato: item.allegato,
      }))
      .sort((a, b) => a.label.localeCompare(b.label)); // Ordinato per "dettaglioassenza_descrizione"
    console.log("** tipopermessoOptions:", this.tipopermessoOptions);
  },
};
</script>
<style scoped>
.custom-datepicker-container {
  position: absolute;
  top: 50px;
  left: 50px;
  z-index: 9999;
}

.btn-clear-file {
  position: relative;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  right: 22px;
  width: 0;
  color: #a50202 !important;
}
</style>
