<template>
  <div class="col-sm-12 main-col">
    <div class="row p-0">
      <div class="align-items-center d-flex flex-row justify-content-between m-0 p-0 row">
        <div class="col-sm-10 p-0">
          <div
            class="align-items-center bg-light d-flex header-text justify-content-start p-0 w-100"
          >
            <h1>
              GESTIONE FORNITORI<span class="text-black small">
                <span class="mx-3 single-list">
                  <i class="fa-solid fa-building fa-fw"></i>
                </span>
                <span v-if="getSearchFornitori" class="mx-3 text-black"
                  >[ <span class="color-mainblue">{{ getSearchFornitori }}</span> ]</span
                >
                <span v-if="fornitori.length > 0 && !loading">
                  Tutti
                  <span class="ms-2" style="font-size: smaller">
                    ({{ totalRecords }} {{ totalRecords == 1 ? "elemento" : "elementi" }})
                  </span>
                </span>
              </span>
            </h1>
          </div>
        </div>
        <div class="col-sm-2 p-0">
          <!-- Search -->
          <search-button
            :searchText="getSearchFornitori"
            @txtToSearch="filterTextToSearch"
            @clearTxtToSearch="filterClearTextToSearch"
            v-if="!loading"
          />
        </div>
      </div>
      <!-- Content -->
      <div
        class="container-fluid m-0 p-2 background-gray overflow-auto container-contents"
      >
        <!-- Linear Loader -->
        <div v-if="loading" class="linear-loader">
          <span></span>
        </div>
        <!-- Error -->
        <div v-if="error" class="alert alert-danger">{{ error }}</div>
        <!-- No data -->
        <div v-if="fornitori.length == 0 && !loading && !error">
          <h5 class="mt-1">Nessun dato da visualizzare</h5>
        </div>
        <!-- Data -->
        <div v-if="fornitori.length > 0 && !loading">
          <!-- Table Data -->
          <div class="row m-0 padding-x-4px">
            <table class="table-bordered table-responsive-md table-setup">
              <thead class="table-header-font">
                <tr class="td-center">
                  <th>
                    RAGIONE SOCIALE<span class="small text-black fw-light ms-2"
                      >[ {{ getSortLabeltByValue(getFornitoriSortType) }} ]</span
                    >
                  </th>
                  <th style="min-width: 200px !important; width: 400px !important">
                    INFO
                  </th>
                  <th style="min-width: 100px !important; width: 280px !important">
                    RIFERIMENTI
                  </th>
                  <th style="min-width: 100px !important; width: 100px !important">
                    AZIONI
                  </th>
                </tr>
              </thead>
              <tbody class="table-body-font td-vertical-center">
                <tr v-for="fornitore in fornitori" :key="fornitore._id">
                  <td>{{ fornitore.ragionesociale }}</td>
                  <td>{{ fornitore.info }}</td>
                  <td style="white-space: pre-wrap">{{ fornitore.riferimenti }}</td>
                  <td class="td-actions td-center">
                    <button
                      alt="MODIFICA"
                      title="MODIFICA"
                      @click="editFornitore(fornitore)"
                    >
                      <i class="zmdi zmdi-edit zmdi-hc-lg"></i>
                    </button>
                    <button
                      class="delete"
                      alt="ELIMINA"
                      title="ELIMINA"
                      @click="askRemoveFornitore(fornitore._id)"
                    >
                      <i class="zmdi zmdi-delete zmdi-hc-lg"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- Pagination -->
            <div
              class="align-items-center container-fluid d-flex justify-content-center my-4 p-0"
            >
              <div class="row" v-if="fornitori.length > 0 && totalPages > 1">
                <vue-pagination
                  :totalPages="totalPages"
                  :currentPage="page"
                  :maxVisibleButtons="6"
                  @pagechanged="onPageChange"
                ></vue-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchButton from "@/components/ui/SearchButton.vue";
import { fetchFornitori, deleteFornitore } from "@/services/api";
// import JsonExcel from "vue-json-excel3";
import VuePagination from "@/components/ui/VuePagination.vue";
import { mapGetters, mapActions } from "vuex";
import { Fornitore } from "@/models/FornitoreJoiModel";
import sortOrderList from "@/data/sort_order_list.json";

export default {
  components: {
    SearchButton,
    VuePagination,
    // esportaExcel: JsonExcel,
  },
  data() {
    return {
      isSearching: false,
      fornitori: [],
      loading: false,
      error: null,
      page: 1,
      limit: 20,
      totalPages: 1,
      export_json_meta: [
        {
          key: "charset",
          value: "utf-8",
        },
      ],
      totalRecords: 0,
      sortOrderList,
    };
  },
  computed: {
    ...mapGetters([
      "getCurrentPageFornitori",
      "getFornitoriSortType",
      "getSearchFornitori",
    ]),
  },
  watch: {
    getFornitoriSortType() {
      this.fetchFornitori();
    },
    getSearchFornitori() {
      this.fetchFornitori();
    },
  },
  methods: {
    ...mapActions(["setCurrentPageFornitori", "setSearchFornitori"]),
    async fetchFornitoriData() {
      this.loading = true;
      this.error = null;
      try {
        const response = await fetchFornitori(
          this.page,
          this.limit,
          this.getFornitoriSortType,
          this.getSearchFornitori
        );
        const totalCount = response?.totalCount ?? 0;
        this.fornitori = response.fornitori?.map((item) => new Fornitore(item)) ?? [];
        this.totalPages = Math.ceil(totalCount / this.limit);
        this.totalRecords = totalCount;
        console.log(this.fornitori);
      } catch (err) {
        this.error = "Si è verificato un errore";
      } finally {
        this.loading = false;
      }
    },
    editFornitore(fornitore) {
      this.$router.push({ name: "route-fornitori-edit", params: { id: fornitore._id } });
    },
    async askRemoveFornitore(id) {
      this.$swal
        .fire({
          title: "Cancellazione Fornitore",
          html: "Sei sicuro di voler cancellare il fornitore?",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: '<i class="fa fa-check"></i> Conferma',
          cancelButtonText: '<i class="fa fa-xmark"></i> Annulla',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await this.removeFornitore(id);
          }
        });
    },
    async removeFornitore(id) {
      this.loading = true;
      try {
        await deleteFornitore(id);
        this.page = 1;
        this.setCurrentPageFornitori(1);
        await this.fetchFornitoriData();
      } catch (error) {
        console.error("Errore durante l'eliminazione dell'fornitore", error);
        console.error("Error details:", error.response?.data);
        this.error = "Errore durante l'eliminazione dell'fornitore";
      } finally {
        this.loading = false;
      }
    },
    onPageChange(page) {
      this.page = page;
      this.setCurrentPageFornitori(page);
      this.fetchFornitoriData();
    },
    fetchFornitori() {
      this.page = 1;
      this.setCurrentPageFornitori(1);
      this.fetchFornitoriData();
    },
    getSortLabeltByValue(value) {
      const foundItem = this.sortOrderList.find((item) => item.value === value);
      return foundItem ? foundItem.text : "";
    },
    //
    // ** Gestione ricerca **
    //
    filterTextToSearch(text) {
      this.setSearchFornitori(text);
    },
    filterClearTextToSearch() {
      this.setSearchFornitori("");
    },
  },
  async created() {
    this.page = this.getCurrentPageFornitori;
    await this.fetchFornitoriData();
  },
};
</script>
