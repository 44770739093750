<template>
  <overlay-loading v-model:isLoading="isLoading"></overlay-loading>
  <div class="col-sm-12 main-col">
    <div class="row p-0">
      <div class="align-items-center d-flex flex-row justify-content-between m-0 p-0 row">
        <div class="col-sm-12 p-0">
          <!-- Main Title -->
          <div
            class="h-100 align-items-end bg-light d-flex header-text justify-content-start p-0 w-100"
          >
            <h1>
              DASHBOARD<span class="text-black small"
                ><span class="mx-3 single-list"
                  ><i class="bi bi-calendar-event-fill"></i></span
                ><span>{{ getFormattedDateOfToday }}</span></span
              >
            </h1>
          </div>
        </div>
      </div>
      <!-- Content -->
      <div
        class="container-fluid m-0 p-2 background-gray overflow-auto container-contents"
      >
        <div class="row m-0 padding-x-4px">
          <!-- Visualizzazione provvisoria -->
          <div
            v-if="!isViewTemporary"
            class="d-flex justify-content-center m-0 p-0 pb-1 pt-4 row"
          >
            <!-- Colonna sinistra -->
            <div class="col-xl-6 p-0 pe-0 pe-xl-2">
              <!-- I tuoi progetti -->
              <div class="p-2" style="border: 1px solid var(--main-blue)">
                <div class="bg-gradient-a p-2 text-white">
                  <i class="fa-solid fa-building-user"></i
                  ><span class="ms-1"
                    >I TUOI PROGETTI ({{
                      dashboardData?.progetti?.length
                        ? dashboardData?.progetti?.length
                        : "0"
                    }})</span
                  >
                </div>
                <!-- Table progetti -->
                <div v-if="!hasProgetti" class="p-0 my-3">
                  <h5>Nessun dato da visualizzare per i progetti</h5>
                </div>
                <div v-if="hasProgetti" class="scroll-x-container p-0">
                  <table class="table-bordered table-responsive-md table-setup mt-2">
                    <thead class="table-header-font">
                      <tr class="td-center">
                        <th>CLIENTE</th>
                        <th>TITOLO COMMESSA</th>
                        <th style="width: 100px !important; min-width: 100px !important">
                          DAL
                        </th>
                        <th style="width: 100px !important; min-width: 100px !important">
                          AL
                        </th>
                      </tr>
                    </thead>
                    <!-- Table Records -->
                    <tbody class="table-body-font td-vertical-center">
                      <tr
                        v-for="(progetto, index) in dashboardData?.progetti"
                        :key="index"
                        :class="{ danger: progetto?.eliminato }"
                      >
                        <td>{{ progetto?.clientedescrizione }}</td>
                        <td>
                          <span v-if="progetto?.eliminato" class="py-2 m-0">
                            <span class="fs-6">[ COMMESSA ELIMINATA ]&nbsp;</span><br
                          /></span>
                          {{ progetto?.titolocommessa
                          }}{{
                            progetto?.identificativonav
                              ? ` | Codice NAV: ${progetto?.identificativonav}`
                              : ""
                          }}
                        </td>
                        <td class="td-center">
                          {{ mixins_getLocalDate(progetto?.datainizio) }}
                        </td>
                        <td class="td-center">
                          {{ mixins_getLocalDate(progetto?.datafine) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- Riepilogo attività -->
              <div class="my-4 p-2" style="border: 1px solid var(--main-blue)">
                <div class="bg-gradient-a p-2 text-white">
                  <i class="bi bi-bullseye"></i>
                  <span class="ms-1"
                    >RIEPILOGO ATTIVITÀ - {{ getCurrentMonthInfo.mensile }}</span
                  >
                </div>
                <!-- Table attività -->
                <div v-if="!hasCommesse" class="p-0 my-3">
                  <h5>Nessun dato da visualizzare per le attività</h5>
                </div>
                <div v-if="hasCommesse" class="scroll-x-container p-0">
                  <table class="table-bordered table-responsive-md table-setup mt-2">
                    <thead class="table-header-font">
                      <tr class="td-center">
                        <th>TITOLO COMMESSA</th>
                        <th style="width: 100px !important; min-width: 100px !important">
                          ORDINARIO
                        </th>
                        <th style="width: 100px !important; min-width: 100px !important">
                          STRAORDINARIO
                        </th>
                        <th style="width: 100px !important; min-width: 100px !important">
                          REPERIBILITÀ
                        </th>
                      </tr>
                    </thead>
                    <!-- Table Records -->
                    <tbody class="table-body-font td-vertical-center">
                      <tr
                        v-for="(commessa, index) in dashboardData?.attivita?.commesse"
                        :key="index"
                      >
                        <td>
                          {{ commessa?.titolocommessa
                          }}{{
                            commessa?.identificativonav
                              ? ` | Codice NAV: ${commessa?.identificativonav}`
                              : ""
                          }}
                        </td>
                        <td
                          class="td-right"
                          :class="{
                            'zero-value': isZeroValue(commessa?.tot_ore_ordinarie),
                          }"
                        >
                          {{
                            mixins_formatNumberWithLocale(commessa?.tot_ore_ordinarie, 1)
                          }}
                        </td>
                        <td
                          class="td-right"
                          :class="{
                            'zero-value': isZeroValue(commessa?.tot_ore_straordinario),
                          }"
                        >
                          {{
                            mixins_formatNumberWithLocale(
                              commessa?.tot_ore_straordinario,
                              1
                            )
                          }}
                        </td>
                        <td
                          class="td-right"
                          :class="{
                            'zero-value': isZeroValue(commessa?.tot_ore_reperibilita),
                          }"
                        >
                          {{
                            mixins_formatNumberWithLocale(
                              commessa?.tot_ore_reperibilita,
                              1
                            )
                          }}
                        </td>
                      </tr>
                      <!-- Totali -->
                      <tr class="active">
                        <td class="td-right fs-6">TOTALE:</td>
                        <td
                          class="td-right fs-6"
                          :class="{
                            'zero-value': isZeroValue(
                              dashboardData?.attivita?.tot_commesse?.tot_ore_ordinarie
                            ),
                          }"
                        >
                          {{
                            mixins_formatNumberWithLocale(
                              dashboardData?.attivita?.tot_commesse?.tot_ore_ordinarie,
                              1
                            )
                          }}
                        </td>
                        <td
                          class="td-right fs-6"
                          :class="{
                            'zero-value': isZeroValue(
                              dashboardData?.attivita?.tot_commesse?.tot_ore_straordinario
                            ),
                          }"
                        >
                          {{
                            mixins_formatNumberWithLocale(
                              dashboardData?.attivita?.tot_commesse
                                ?.tot_ore_straordinario,
                              1
                            )
                          }}
                        </td>
                        <td
                          class="td-right fs-6"
                          :class="{
                            'zero-value': isZeroValue(
                              dashboardData?.attivita?.tot_commesse?.tot_ore_reperibilita
                            ),
                          }"
                        >
                          {{
                            mixins_formatNumberWithLocale(
                              dashboardData?.attivita?.tot_commesse?.tot_ore_reperibilita,
                              1
                            )
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- Table Assenze -->
                <div v-if="!hasAssenze" class="p-0 my-3">
                  <h5>Nessun dato da visualizzare per le assenze</h5>
                </div>
                <div v-if="hasAssenze" class="scroll-x-container p-0">
                  <table class="table-bordered table-responsive-md table-setup mt-2">
                    <thead class="table-header-font">
                      <tr class="td-center">
                        <th>TIPO ASSENZA</th>
                        <th style="width: 130px !important; min-width: 130px !important">
                          TOTALE
                        </th>
                      </tr>
                    </thead>

                    <!-- Table Records -->
                    <tbody class="table-body-font td-vertical-center">
                      <tr>
                        <td>MALATTIA</td>
                        <td
                          class="td-right"
                          :class="{
                            'zero-value': isZeroValue(
                              dashboardData?.assenze?.tot_malattia
                            ),
                          }"
                        >
                          {{
                            mixins_formatNumberWithLocale(
                              dashboardData?.assenze?.tot_malattia,
                              1
                            )
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td>FERIE</td>
                        <td
                          class="td-right"
                          :class="{
                            'zero-value': isZeroValue(dashboardData?.assenze?.tot_ferie),
                          }"
                        >
                          {{
                            mixins_formatNumberWithLocale(
                              dashboardData?.assenze?.tot_ferie,
                              1
                            )
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td>PERMESSI GIUSTIFICATI</td>
                        <td
                          class="td-right"
                          :class="{
                            'zero-value': isZeroValue(
                              dashboardData?.assenze?.tot_permessi_giustificati
                            ),
                          }"
                        >
                          {{
                            mixins_formatNumberWithLocale(
                              dashboardData?.assenze?.tot_permessi_giustificati,
                              1
                            )
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td>ROL</td>
                        <td
                          class="td-right"
                          :class="{
                            'zero-value': isZeroValue(dashboardData?.assenze?.tot_rol),
                          }"
                        >
                          {{
                            mixins_formatNumberWithLocale(
                              dashboardData?.assenze?.tot_rol,
                              1
                            )
                          }}
                        </td>
                      </tr>
                      <!-- Totali -->
                      <tr class="active">
                        <td class="td-right fs-6">TOTALE:</td>
                        <td
                          class="td-right fs-6"
                          :class="{
                            'zero-value': isZeroValue(
                              dashboardData?.assenze?.tot_globale_assenze
                            ),
                          }"
                        >
                          {{
                            mixins_formatNumberWithLocale(
                              dashboardData?.assenze?.tot_globale_assenze,
                              1
                            )
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <!-- Visualizzazione definitiva -->
          <div v-if="isViewTemporary" class="row m-0 p-0 pt-4 pb-1">
            <!-- Colonna sinistra -->
            <div class="col-xl-6 p-0 pe-0 pe-xl-2">
              <!-- I tuoi progetti -->
              <div class="p-2" style="border: 1px solid var(--main-blue)">
                <div class="bg-gradient-a p-2 text-white">
                  <i class="fa-solid fa-building-user"></i
                  ><span class="ms-1"
                    >I TUOI PROGETTI ({{
                      dashboardData?.progetti?.length
                        ? dashboardData?.progetti?.length
                        : "0"
                    }})</span
                  >
                </div>
                <!-- Table progetti -->
                <div v-if="!hasProgetti" class="p-0 my-3">
                  <h5>Nessun dato da visualizzare per i progetti</h5>
                </div>
                <div v-if="hasProgetti" class="scroll-x-container p-0">
                  <table class="table-bordered table-responsive-md table-setup mt-2">
                    <thead class="table-header-font">
                      <tr class="td-center">
                        <th>CLIENTE</th>
                        <th>TITOLO COMMESSA</th>
                        <th style="width: 100px !important; min-width: 100px !important">
                          DAL
                        </th>
                        <th style="width: 100px !important; min-width: 100px !important">
                          AL
                        </th>
                      </tr>
                    </thead>
                    <!-- Table Records -->
                    <tbody class="table-body-font td-vertical-center">
                      <tr
                        v-for="(progetto, index) in dashboardData?.progetti"
                        :key="index"
                        :class="{ danger: progetto?.eliminato }"
                      >
                        <td>{{ progetto?.clientedescrizione }}</td>
                        <td>
                          <span v-if="progetto?.eliminato" class="py-2 m-0">
                            <span class="fs-6">[ COMMESSA ELIMINATA ]&nbsp;</span><br
                          /></span>
                          {{ progetto?.titolocommessa
                          }}{{
                            progetto?.identificativonav
                              ? ` | Codice NAV: ${progetto?.identificativonav}`
                              : ""
                          }}
                        </td>
                        <td class="td-center">
                          {{
                            progetto?.datainizio
                              ? mixins_getLocalDate(progetto.datainizio)
                              : "-"
                          }}
                        </td>
                        <td class="td-center">
                          {{
                            progetto?.datafine
                              ? mixins_getLocalDate(progetto.datafine)
                              : "-"
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- Riepilogo attività -->
              <div class="my-4 p-2" style="border: 1px solid var(--main-blue)">
                <div class="bg-gradient-a p-2 text-white">
                  <i class="bi bi-bullseye"></i>
                  <span class="ms-1"
                    >RIEPILOGO ATTIVITÀ - {{ getCurrentMonthInfo.mensile }}</span
                  >
                </div>
                <!-- Table attività -->
                <div v-if="!hasCommesse" class="p-0 my-3">
                  <h5>Nessun dato da visualizzare per le attività</h5>
                </div>
                <div v-if="hasCommesse" class="scroll-x-container p-0">
                  <table class="table-bordered table-responsive-md table-setup mt-2">
                    <thead class="table-header-font">
                      <tr class="td-center">
                        <th>TITOLO COMMESSA</th>
                        <th style="width: 100px !important; min-width: 100px !important">
                          ORDINARIO
                        </th>
                        <th style="width: 100px !important; min-width: 100px !important">
                          STRAORDINARIO
                        </th>
                        <th style="width: 100px !important; min-width: 100px !important">
                          REPERIBILITÀ
                        </th>
                      </tr>
                    </thead>
                    <!-- Table Records -->
                    <tbody class="table-body-font td-vertical-center">
                      <tr
                        v-for="(commessa, index) in dashboardData?.attivita?.commesse"
                        :key="index"
                      >
                        <td>
                          {{ commessa?.titolocommessa
                          }}{{
                            commessa?.identificativonav
                              ? ` | Codice NAV: ${commessa?.identificativonav}`
                              : ""
                          }}
                        </td>
                        <td
                          class="td-right"
                          :class="{
                            'zero-value': isZeroValue(commessa?.tot_ore_ordinarie),
                          }"
                        >
                          {{
                            mixins_formatNumberWithLocale(commessa?.tot_ore_ordinarie, 1)
                          }}
                        </td>
                        <td
                          class="td-right"
                          :class="{
                            'zero-value': isZeroValue(commessa?.tot_ore_straordinario),
                          }"
                        >
                          {{
                            mixins_formatNumberWithLocale(
                              commessa?.tot_ore_straordinario,
                              1
                            )
                          }}
                        </td>
                        <td
                          class="td-right"
                          :class="{
                            'zero-value': isZeroValue(commessa?.tot_ore_reperibilita),
                          }"
                        >
                          {{
                            mixins_formatNumberWithLocale(
                              commessa?.tot_ore_reperibilita,
                              1
                            )
                          }}
                        </td>
                      </tr>
                      <!-- Totali -->
                      <tr class="active">
                        <td class="td-right fs-6">TOTALE:</td>
                        <td
                          class="td-right fs-6"
                          :class="{
                            'zero-value': isZeroValue(
                              dashboardData?.attivita?.tot_commesse?.tot_ore_ordinarie
                            ),
                          }"
                        >
                          {{
                            mixins_formatNumberWithLocale(
                              dashboardData?.attivita?.tot_commesse?.tot_ore_ordinarie,
                              1
                            )
                          }}
                        </td>
                        <td
                          class="td-right fs-6"
                          :class="{
                            'zero-value': isZeroValue(
                              dashboardData?.attivita?.tot_commesse?.tot_ore_straordinario
                            ),
                          }"
                        >
                          {{
                            mixins_formatNumberWithLocale(
                              dashboardData?.attivita?.tot_commesse
                                ?.tot_ore_straordinario,
                              1
                            )
                          }}
                        </td>
                        <td
                          class="td-right fs-6"
                          :class="{
                            'zero-value': isZeroValue(
                              dashboardData?.attivita?.tot_commesse?.tot_ore_reperibilita
                            ),
                          }"
                        >
                          {{
                            mixins_formatNumberWithLocale(
                              dashboardData?.attivita?.tot_commesse?.tot_ore_reperibilita,
                              1
                            )
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- Table Assenze -->
                <div v-if="!hasAssenze" class="p-0 my-3">
                  <h5>Nessun dato da visualizzare per le assenze</h5>
                </div>
                <div v-if="hasAssenze" class="scroll-x-container p-0">
                  <table class="table-bordered table-responsive-md table-setup mt-2">
                    <thead class="table-header-font">
                      <tr class="td-center">
                        <th>TIPO ASSENZA</th>
                        <th style="width: 130px !important; min-width: 130px !important">
                          TOTALE
                        </th>
                      </tr>
                    </thead>

                    <!-- Table Records -->
                    <tbody class="table-body-font td-vertical-center">
                      <tr>
                        <td>MALATTIA</td>
                        <td
                          class="td-right"
                          :class="{
                            'zero-value': isZeroValue(
                              dashboardData?.assenze?.tot_malattia
                            ),
                          }"
                        >
                          {{
                            mixins_formatNumberWithLocale(
                              dashboardData?.assenze?.tot_malattia,
                              1
                            )
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td>FERIE</td>
                        <td
                          class="td-right"
                          :class="{
                            'zero-value': isZeroValue(dashboardData?.assenze?.tot_ferie),
                          }"
                        >
                          {{
                            mixins_formatNumberWithLocale(
                              dashboardData?.assenze?.tot_ferie,
                              1
                            )
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td>PERMESSI GIUSTIFICATI</td>
                        <td
                          class="td-right"
                          :class="{
                            'zero-value': isZeroValue(
                              dashboardData?.assenze?.tot_permessi_giustificati
                            ),
                          }"
                        >
                          {{
                            mixins_formatNumberWithLocale(
                              dashboardData?.assenze?.tot_permessi_giustificati,
                              1
                            )
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td>ROL</td>
                        <td
                          class="td-right"
                          :class="{
                            'zero-value': isZeroValue(dashboardData?.assenze?.tot_rol),
                          }"
                        >
                          {{
                            mixins_formatNumberWithLocale(
                              dashboardData?.assenze?.tot_rol,
                              1
                            )
                          }}
                        </td>
                      </tr>
                      <!-- Totali -->
                      <tr class="active">
                        <td class="td-right fs-6">TOTALE:</td>
                        <td
                          class="td-right fs-6"
                          :class="{
                            'zero-value': isZeroValue(
                              dashboardData?.assenze?.tot_globale_assenze
                            ),
                          }"
                        >
                          {{
                            mixins_formatNumberWithLocale(
                              dashboardData?.assenze?.tot_globale_assenze,
                              1
                            )
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- Colonna destra -->
            <div class="col-xl-6 p-0 ps-0 ps-xl-2">
              <div class="p-2" style="border: 1px solid var(--main-blue)">
                <!-- Comunicazioni -->
                <div class="bg-gradient-a p-2 text-white">
                  <i class="bi bi-inbox-fill"></i
                  ><span class="ms-1"
                    >COMUNICAZIONI ({{
                      dashboard?.messages?.length ? dashboard?.messages?.length : "0"
                    }})</span
                  >
                </div>
                <!-- Table comunicazioni -->
                <div
                  class="scroll-x-container scroll-y-container mt-2"
                  style="height: 50vh"
                >
                  <!-- No data -->
                  <div class="mt-4" v-if="messages.length == 0">
                    <h5>Nessun dato da visualizzare per le comunicazioni</h5>
                  </div>
                  <table v-else class="table-bordered table-responsive-md table-setup">
                    <thead class="table-header-font">
                      <tr class="td-center">
                        <th>MESSAGGIO</th>
                        <th style="min-width: 130px !important; width: 130px !important">
                          PROFILO
                        </th>
                        <th style="min-width: 140px !important; width: 140px !important">
                          DATA
                        </th>
                        <th
                          v-if="
                            isAuthenticatedSuperAdmin || isAuthenticatedGestionePersonale
                          "
                          style="min-width: 100px !important; width: 100px !important"
                        >
                          AZIONI
                        </th>
                      </tr>
                    </thead>
                    <!-- Table Records -->
                    <tbody class="table-body-font td-vertical-center">
                      <tr v-for="message in messages" :key="message._id">
                        <!-- Messaggio con HTML -->
                        <td v-html="message.text"></td>
                        <!-- Profilo -->
                        <td class="td-center">
                          <ul class="list-tags">
                            <li
                              v-for="profileId in message.profiles_recipient"
                              :key="profileId"
                            >
                              <span
                                class="badge-colored blue"
                                :title="getProfileLabel(profileId)"
                                :alt="getProfileLabel(profileId)"
                              >
                                {{ getProfileLabel(profileId) }}
                              </span>
                            </li>
                          </ul>
                        </td>
                        <!-- Data -->
                        <td class="td-center small">{{ formatDate(message.date) }}</td>
                        <!-- Actions -->
                        <td
                          v-if="
                            isAuthenticatedSuperAdmin || isAuthenticatedGestionePersonale
                          "
                          class="td-actions td-center"
                        >
                          <button
                            alt="MODIFICA"
                            title="MODIFICA"
                            class="btn icon-button"
                            @click="editComunicazione(message)"
                          >
                            <i class="zmdi zmdi-edit zmdi-hc-lg"></i>
                          </button>
                          <button
                            class="delete btn icon-button"
                            alt="ELIMINA"
                            title="ELIMINA"
                            @click="askDeleteMessage(message?._id)"
                          >
                            <i class="zmdi zmdi-delete zmdi-hc-lg"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- Nuova comunicazione -->
              <div
                v-if="isAuthenticatedSuperAdmin || isAuthenticatedGestionePersonale"
                class="w-100 align-items-center d-flex justify-content-end mt-4"
              >
                <button
                  type="button"
                  class="btn button-outline-1 small"
                  @click="openAddComunicazione"
                >
                  <i class="zmdi zmdi-plus zmdi-hc-lg"></i> Nuova Comunicazione
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <DialogAddEditComunicazione
    :isVisible="isDialogVisible"
    :dialogWidth="'780px'"
    :dialogTitle="getDialogTitle"
    :dataItem="dataMessage"
    @closeModal="closeModal"
    @saveData="saveData"
    :isEditMode="getIsEditMode"
  />
</template>

<script>
import {
  fetchDashboard,
  sendNewCommunication,
  updateCommunication,
  deleteCommunication,
} from "@/services/api";
import { UtilityMixins } from "@/mixins/UtilityMixins.js";
import OverlayLoading from "@/components/ui/OverlayLoading.vue";
import DialogAddEditComunicazione from "@/components/dialogs/DialogAddEditComunicazione.vue";
import ProfiliPermissions from "@/mixins/ProfiliPermissions.js";

class Message {
  constructor(data = {}) {
    this._id = data._id || ""; // ID del messaggio
    this.profiles_recipient = Array.isArray(data.profiles_recipient)
      ? data.profiles_recipient
      : []; // Array dei profili destinatari
    this.text = data.text || ""; // Testo del messaggio
    this.date = data.date ? new Date(data.date) : null; // Oggetto Date, se disponibile
  }
}

export default {
  mixins: [UtilityMixins, ProfiliPermissions],
  components: { OverlayLoading, DialogAddEditComunicazione },
  data() {
    return {
      isViewTemporary: true,
      isLoading: false,
      dashboard: {},
      dashboardData: {},
      messages: [],
      profiliOptions: [],
      userAuthenticated: null,
      // dialog
      isDialogVisible: false,
      dialogTitle: "",
      dataMessage: new Message(),
      isEditMode: false,
    };
  },
  computed: {
    hasProgetti() {
      return this.dashboardData?.progetti?.length ? true : false;
    },
    hasCommesse() {
      return this.dashboardData?.attivita?.commesse.length ? true : false;
    },
    hasAssenze() {
      return this.dashboardData?.assenze &&
        Object.keys(this.dashboardData.assenze).length > 0
        ? true
        : false;
    },
    getFormattedDateOfToday() {
      const oggi = new Date();

      const giorniSettimana = [
        "Domenica",
        "Lunedì",
        "Martedì",
        "Mercoledì",
        "Giovedì",
        "Venerdì",
        "Sabato",
      ];

      const mesiAnno = [
        "gennaio",
        "febbraio",
        "marzo",
        "aprile",
        "maggio",
        "giugno",
        "luglio",
        "agosto",
        "settembre",
        "ottobre",
        "novembre",
        "dicembre",
      ];

      const giornoSettimana = giorniSettimana[oggi.getDay()];
      const giorno = oggi.getDate();
      const mese = mesiAnno[oggi.getMonth()];
      const anno = oggi.getFullYear();

      return `${giornoSettimana}, ${giorno} ${mese} ${anno}`;
    },
    getCurrentMonthInfo() {
      const oggi = new Date();

      const mesiAnno = [
        "Gennaio",
        "Febbraio",
        "Marzo",
        "Aprile",
        "Maggio",
        "Giugno",
        "Luglio",
        "Agosto",
        "Settembre",
        "Ottobre",
        "Novembre",
        "Dicembre",
      ];

      const meseCorrente = oggi.getMonth() + 1; // i mesi partono da 0 in JavaScript
      const nomeMeseCorrente = mesiAnno[oggi.getMonth()];
      const annoCorrente = oggi.getFullYear();

      return {
        mese: meseCorrente,
        mensile: `${nomeMeseCorrente} ${annoCorrente}`,
      };
    },
    getDialogTitle() {
      return this.dialogTitle;
    },
    getIsEditMode() {
      return this.isEditMode;
    },
    //
    // Authenticated
    //
    isAuthenticatedSuperAdmin() {
      return this.permissions_isAuthenticatedSuperAdmin(this.userAuthenticated);
    },
    isAuthenticatedGestionePersonale() {
      return this.permissions_isAuthenticatedGestionePersonale(this.userAuthenticated);
    },
  },
  methods: {
    async fetchDashboardData() {
      this.isLoading = true;
      this.error = null;
      try {
        const response = await fetchDashboard(this.getCurrentMonthInfo.mese);

        if (response) {
          this.dashboard = response;
          console.log("** dashboard", this.dashboard);

          this.dashboardData = this.generateDashboardData();
          console.log("** dashboard data", this.dashboardData);

          // Ordino i messaggi per data decrescente
          this.messages = response?.messages.sort(
            (a, b) => new Date(b.date) - new Date(a.date)
          );
        }
      } catch (err) {
        console.error("Si è verificato un errore");
      } finally {
        this.isLoading = false;
      }
    },
    isZeroValue(value) {
      return value === 0.0 || value === 0 || value === "0,0" || value === "0.0";
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const now = new Date();
      const diffInMs = now - date;

      const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
      const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));

      if (diffInMinutes < 1) return "adesso";
      if (diffInMinutes < 60) {
        return `${diffInMinutes} ${diffInMinutes === 1 ? "minuto" : "minuti"} fa`;
      }
      if (diffInHours < 12) {
        return `${diffInHours} ${diffInHours === 1 ? "ora" : "ore"} fa`;
      }

      // Formattazione della data con " - " come separatore
      const formattedDate = date.toLocaleDateString("it-IT", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });

      const formattedTime = date.toLocaleTimeString("it-IT", {
        hour: "2-digit",
        minute: "2-digit",
      });

      return `${formattedDate} - ${formattedTime}`;
    },
    generateDashboardData() {
      const result = {
        progetti: [],
        attivita: {
          commesse: [],
          tot_commesse: {
            tot_ore_ordinarie: 0,
            tot_ore_straordinario: 0,
            tot_ore_reperibilita: 0,
          },
        },
        assenze: {},
      };

      // Controllo che dashboard.progetti esista e sia un array
      if (Array.isArray(this.dashboard?.progetti)) {
        this.dashboard.progetti.forEach((progetto) => {
          result.progetti.push({
            clientedescrizione: progetto.cliente?.descrizione || "",
            titolocommessa: progetto.titolo || "",
            identificativonav: progetto.identificativonav || "",
            datainizio: progetto.datainizio || null,
            datafine: progetto.datafine || null,
            eliminato: progetto.eliminato ?? false,
          });
        });
      }

      // Controllo che dashboard.attivita esista e sia un array
      if (Array.isArray(this.dashboard?.attivita)) {
        this.dashboard.attivita.forEach((attivita) => {
          const commessaId = attivita._id?.commessaId || "";

          if (commessaId !== "") {
            // Aggiungo alla lista delle commesse
            const tot_ore_straordinario =
              (attivita.straordinario_ordinario || 0) +
              (attivita.straordinario_festivo || 0) +
              (attivita.straordinario_notturno || 0);

            const newCommessa = {
              titolocommessa: attivita._id?.commessaDescrizione || "",
              identificativonav: attivita._id?.commessaNav || "",
              tot_ore_ordinarie: attivita.ore_ordinarie || 0,
              tot_ore_straordinario: tot_ore_straordinario,
              tot_ore_reperibilita: attivita.reperibilita || 0,
            };

            result.attivita.commesse.push(newCommessa);

            // Aggiorno i totali delle commesse
            result.attivita.tot_commesse.tot_ore_ordinarie +=
              newCommessa.tot_ore_ordinarie;
            result.attivita.tot_commesse.tot_ore_straordinario +=
              newCommessa.tot_ore_straordinario;
            result.attivita.tot_commesse.tot_ore_reperibilita +=
              newCommessa.tot_ore_reperibilita;
          } else if (Object.keys(result.assenze).length === 0) {
            // Aggiungo i dati delle assenze (solo il primo con commessaId === "")
            result.assenze = {
              tot_malattia: attivita.malattia || 0,
              tot_ferie: attivita.ferie || 0,
              tot_rol: attivita.rol || 0,
              tot_permessi_giustificati: attivita.permessi_giustificati || 0,
              tot_globale_assenze:
                (attivita.malattia || 0) +
                (attivita.ferie || 0) +
                (attivita.rol || 0) +
                (attivita.permessi_giustificati || 0),
            };
          }
        });
      }
      console.log("** dashboard dati raggruppati", result);
      return result;
    },
    //
    // Gestione dialog
    //
    closeModal() {
      this.isDialogVisible = false;
    },
    openAddComunicazione() {
      this.isEditMode = false;
      this.dialogTitle = "NUOVA COMUNICAZIONE";
      this.dataMessage = new Message();
      this.isDialogVisible = true;
    },
    editComunicazione(comunicazione) {
      this.isEditMode = true;
      this.dialogTitle = "MODIFICA COMUNICAZIONE";
      this.dataMessage = new Message(comunicazione);
      console.log("** edit comunicazione", comunicazione);
      this.isDialogVisible = true;
    },
    async saveData(dataRecived, isTuttiProfili) {
      console.log(
        "** Dati aggiornati ricevuti da DialogAddEditComunicazione:",
        dataRecived,
        isTuttiProfili
      );

      this.closeModal();

      // Salvo i dati
      this.isLoading = true;

      const body = {
        profiles_recipient: dataRecived.profiles_recipient,
        text: dataRecived.text,
      };

      try {
        if (this.isEditMode) {
          const apiResponse = await updateCommunication(dataRecived._id, body);
          console.log("** communication updateCommunication", apiResponse);
          if (apiResponse != undefined) {
            this.mixins_showMessage(
              "Aggiornamento Comunicazione",
              "Comunicazione aggiornata correttamente",
              "info"
            );
            await this.fetchDashboardData();
          }
        } else {
          const apiResponse = await sendNewCommunication(body);
          console.log("** communication sendNewCommunication", apiResponse);
          if (apiResponse) {
            this.mixins_showMessage(
              "Nuova Comunicazione",
              "Nuova comunicazione inviata correttamente",
              "info"
            );
            await this.fetchDashboardData();
          }
        }
      } catch (error) {
        console.error("Errore durante il salvataggio dei dati", error);
      } finally {
        this.isLoading = false;
      }
    },
    async askDeleteMessage(id) {
      if (!this.isAuthenticatedSuperAdmin && !this.isAuthenticatedGestionePersonale)
        return;

      if (!id) return;

      this.$swal
        .fire({
          title: "Cancellazione Comunicazione",
          html: "Sei sicuro di voler cancellare la comunicazione?",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: '<i class="fa fa-check"></i> Conferma',
          cancelButtonText: '<i class="fa fa-xmark"></i> Annulla',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await this.removeMessage(id);
          }
        });
    },
    async removeMessage(id) {
      if (!this.isAuthenticatedSuperAdmin && !this.isAuthenticatedGestionePersonale)
        return;

      this.loading = true;
      try {
        const response = await deleteCommunication(id);
        if (response) {
          this.mixins_showMessage(
            "Cancellazione Comunicazione",
            "Comunicazione cancellata correttamente",
            "info"
          );
          await this.fetchDashboardData();
        } else {
          this.mixins_showMessage(
            "Cancellazione Comunicazione",
            "Si è verificato un errore durante la cancellazione",
            "error"
          );
        }
      } catch (error) {
        this.mixins_showMessage(
          "Cancellazione Comunicazione",
          "Si è verificato un errore durante la cancellazione",
          "error"
        );
      } finally {
        this.loading = false;
      }
    },
    getProfileLabel(profileId) {
      // Controlla se profileId è "all" e restituisci "Tutti"
      if (profileId === "all") {
        return "Tutti";
      }

      // Cerca il profilo in profiliOptions
      const profile = this.profiliOptions.find((option) => option.value === profileId);
      return profile ? profile.label : "Profilo sconosciuto";
    },
  },
  async created() {
    await this.fetchDashboardData();
  },
  async mounted() {
    // Oggetto user da local storage
    this.userAuthenticated = this.mixin_getUserDataLS();

    // Carico le opzioni dei profili
    const tabProfili = await this.mixins_getTabProfili();
    this.profiliOptions =
      tabProfili?.map((profilo) => ({
        value: profilo.id,
        label: profilo.nome,
      })) || [];
    console.log("** profiliOptions", this.profiliOptions);
  },
};
</script>
