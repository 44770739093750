<template>
  <div class="col-sm-12 main-col">
    <div class="row p-0">
      <div class="align-items-center d-flex flex-row justify-content-between m-0 p-0 row">
        <div class="col-sm-10 p-0">
          <div
            class="align-items-center bg-light d-flex header-text justify-content-start p-0 w-100"
          >
            <h1>
              GESTIONE TEAM<span class="text-black small">
                <span class="mx-3 single-list">
                  <i class="fa-solid fa-users fa-fw"></i>
                </span>
                <span v-if="getSearchTeams" class="mx-3 text-black"
                  >[ <span class="color-mainblue">{{ getSearchTeams }}</span> ]</span
                >
                <span>
                  {{ labelsString }}
                  <span class="ms-2" style="font-size: smaller">
                    ({{ mixins_formatNumberWithLocale(totalRecords) }}
                    {{ totalRecords == 1 ? "elemento" : "elementi" }})
                  </span>
                </span>
              </span>
            </h1>
          </div>
        </div>
        <div class="col-sm-2 p-0">
          <!-- Search -->
          <search-button
            :searchText="getSearchTeams"
            @txtToSearch="filterTextToSearch"
            @clearTxtToSearch="filterClearTextToSearch"
            v-if="!loading"
            :class="{ 'element-disabled': !isTeamVisible }"
          />
        </div>
      </div>
      <!-- Content -->
      <div class="container-fluid m-0 p-2 background-gray container-contents">
        <!-- Linear Loader -->
        <div v-if="loading" class="linear-loader">
          <span></span>
        </div>
        <!-- Error -->
        <div v-if="error" class="alert alert-danger">{{ error }}</div>

        <!-- Data -->
        <div v-if="!loading">
          <!-- Filters / Export Data -->
          <div class="row m-0 my-2 padding-x-4px">
            <div class="container-fluid p-0 mb-2">
              <!-- Seleziona Commessa -->
              <div v-if="isFilterCommessaVisible" class="dropdown float-end me-2">
                <button
                  class="btn button-outline-1 small dropdown-toggle"
                  type="button"
                  id="dropdown_commessa"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  :class="{ 'element-disabled': !isTeamVisible }"
                >
                  <span>Seleziona Commessa</span>
                </button>
                <ul
                  class="dropdown-menu dropdown-menu-end"
                  aria-labelledby="dropdown_commessa"
                  @click.stop
                >
                  <li id="commessa_idcommessa" class="li-container-big">
                    <div class="row mb-3">
                      <label
                        class="col-form-label form-label col-lg-3 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
                      >
                        <span>PROGETTO</span>
                      </label>
                      <div
                        class="align-items-center col-lg-9 d-flex justify-content-center"
                      >
                        <MultiSelect
                          :searchable="true"
                          v-model="selectedLocalModels.idcommessa"
                          :options="commessaOptions"
                          placeholder="- CERCA... -"
                          label="label"
                          track-by="value"
                          :openDirection="'below'"
                          :showLabels="false"
                          :maxHeight="310"
                          :multiple="false"
                          :taggable="false"
                          @select="onCommessaSelect"
                          @remove="onCommessaRemove"
                          @open="onOpenMultiselectCommessa()"
                          @close="onCloseMultiselectCommessa()"
                          @blur="onOpenMultiselectCommessa()"
                          @click.stop
                          :loading="isSearchingCommessa"
                          @search-change="asyncFetchCommessa"
                        >
                          <template #noOptions>
                            <span>L'elenco è vuoto</span>
                          </template>
                          <template #noResult>
                            <span>Nessun elemento trovato</span>
                          </template>
                        </MultiSelect>
                      </div>
                    </div>
                    <button
                      @click="sendFiltersQueryString()"
                      type="button"
                      class="btn button-outline-1 small float-end"
                    >
                      <i class="bi bi-check-lg"></i>Conferma
                    </button>
                  </li>
                </ul>
              </div>
              <!-- Seleziona Skill -->
              <div class="dropdown float-end">
                <button
                  class="btn button-outline-1 small dropdown-toggle"
                  type="button"
                  id="dropdown_skill"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  :class="{ 'element-disabled': !isTeamVisible }"
                >
                  <span>Seleziona Skill</span>
                </button>
                <ul
                  class="dropdown-menu dropdown-menu-end"
                  aria-labelledby="dropdown_skill"
                  @click.stop
                >
                  <li id="skill_mercato" class="li-container-big">
                    <div class="row mb-2">
                      <label
                        class="col-form-label form-label col-lg-4 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
                      >
                        <span>MERCATO DI RIFERIMENTO</span>
                      </label>
                      <div
                        class="align-items-center col-lg-8 d-flex justify-content-center"
                      >
                        <MultiSelect
                          :searchable="true"
                          v-model="selectedLocalModels.mercato"
                          :options="mercatoOptions"
                          placeholder="- SELEZIONA -"
                          label="label"
                          track-by="value"
                          :openDirection="'below'"
                          :showLabels="false"
                          :maxHeight="310"
                          @select="onMercatoSelect"
                          @remove="onMercatoRemove"
                          @open="onOpenMultiselectSkill()"
                          @close="onCloseMultiselectSkill()"
                          @blur="onOpenMultiselectSkill()"
                          @click.stop
                        >
                          <template #noOptions>
                            <span>L'elenco è vuoto</span>
                          </template>
                          <template #noResult>
                            <span>Nessun elemento trovato</span>
                          </template>
                        </MultiSelect>
                      </div>
                    </div>
                  </li>
                  <li id="skill_tipologia" class="li-container-big">
                    <div class="row mb-2">
                      <label
                        class="col-form-label form-label col-lg-4 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
                      >
                        <span>TIPOLOGIA</span>
                      </label>
                      <div
                        class="align-items-center col-lg-8 d-flex justify-content-center"
                      >
                        <MultiSelect
                          :searchable="true"
                          v-model="selectedLocalModels.tipologia"
                          :options="tipologiaOptions"
                          placeholder="- SELEZIONA -"
                          label="label"
                          track-by="value"
                          :openDirection="'below'"
                          :showLabels="false"
                          :maxHeight="310"
                          @select="onSkillTipologiaSelect"
                          @remove="onSkillTipologiaRemove"
                          @open="onOpenMultiselectSkill()"
                          @close="onCloseMultiselectSkill()"
                          @blur="onOpenMultiselectSkill()"
                          @click.stop
                        >
                          <template #noOptions>
                            <span>L'elenco è vuoto</span>
                          </template>
                          <template #noResult>
                            <span>Nessun elemento trovato</span>
                          </template>
                        </MultiSelect>
                      </div>
                    </div>
                  </li>
                  <li id="skill_skill" class="li-container-big">
                    <div class="row mb-2">
                      <label
                        class="col-form-label form-label col-lg-4 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
                      >
                        <span>SKILL</span>
                      </label>
                      <div
                        class="align-items-center col-lg-8 d-flex justify-content-center"
                      >
                        <MultiSelect
                          :searchable="true"
                          v-model="selectedLocalModels.tiposkill"
                          :options="tiposkillOptions"
                          placeholder="- SELEZIONA -"
                          label="label"
                          track-by="value"
                          :openDirection="'below'"
                          :showLabels="false"
                          :maxHeight="310"
                          @select="onTipoSkillSelect"
                          @remove="onTipoSkillRemove"
                          @open="onOpenMultiselectSkill()"
                          @close="onCloseMultiselectSkill()"
                          @blur="onOpenMultiselectSkill()"
                          @click.stop
                        >
                          <template #noOptions>
                            <span>L'elenco è vuoto</span>
                          </template>
                          <template #noResult>
                            <span>Nessun elemento trovato</span>
                          </template>
                        </MultiSelect>
                      </div>
                    </div>
                  </li>
                  <li id="skill_livello" class="li-container-big">
                    <div class="row mb-3">
                      <label
                        class="col-form-label form-label col-lg-4 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
                      >
                        <span>LIVELLO (minimo)</span>
                      </label>
                      <div
                        class="align-items-center col-lg-8 d-flex justify-content-center"
                      >
                        <MultiSelect
                          :searchable="true"
                          v-model="selectedLocalModels.livello"
                          :options="livelloOptions"
                          placeholder="- SELEZIONA -"
                          label="label"
                          track-by="value"
                          :openDirection="'below'"
                          :showLabels="false"
                          :maxHeight="310"
                          @select="onLivelloSelect"
                          @remove="onLivelloRemove"
                          @open="onOpenMultiselectSkill()"
                          @close="onCloseMultiselectSkill()"
                          @blur="onOpenMultiselectSkill()"
                          @click.stop
                        >
                          <template #noOptions>
                            <span>L'elenco è vuoto</span>
                          </template>
                          <template #noResult>
                            <span>Nessun elemento trovato</span>
                          </template>
                        </MultiSelect>
                      </div>
                    </div>
                    <button
                      @click="sendFiltersQueryString()"
                      type="button"
                      class="btn button-outline-1 small float-end"
                    >
                      <i class="bi bi-check-lg"></i>Conferma
                    </button>
                  </li>
                </ul>
              </div>
              <!-- Visualizza -->
              <div class="dropdown float-end me-2">
                <button
                  class="btn button-outline-1 small dropdown-toggle"
                  type="button"
                  id="dropdown_visualizza"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span
                    >Visualizza
                    <span class="text-black">{{ currentVisualization.label }}</span></span
                  >
                </button>
                <ul
                  class="dropdown-menu dropdown-menu-end"
                  aria-labelledby="dropdown_visualizza"
                >
                  <li v-for="item in filteredVisualizations" :key="item.id">
                    <button
                      v-if="item.id !== 'ticket'"
                      class="dropdown-item"
                      :class="{ active: currentVisualization.label == item.label }"
                      type="button"
                      @click="changeVisualization(item.id), this[item.action]()"
                    >
                      {{ item.label }}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- No data -->
          <div v-if="teams.length == 0 && !isFirstTime && !loading && !error">
            <h5 class="mt-1">Nessun dato da visualizzare</h5>
          </div>
          <!-- Table Data -->
          <div
            v-if="teams.length > 0 && !loading && isTeamVisible"
            class="row m-0 padding-x-4px"
          >
            <table class="table-bordered table-responsive-md table-setup">
              <thead class="table-header-font">
                <tr class="td-center">
                  <!-- Colonne fisse -->
                  <th>NOME</th>
                  <th>COGNOME</th>
                  <th>SOCIETÀ</th>
                  <th width="200">SEDE</th>
                  <th width="120">PROFILO</th>
                  <!-- Colonne dinamiche -->
                  <!-- Colonne CONTATTI -->
                  <th :class="{ 'd-none': !isColumnVisible('email') }" width="170">
                    EMAIL
                  </th>
                  <th
                    :class="{ 'd-none': !isColumnVisible('telefonocellulare') }"
                    width="130"
                  >
                    TELEFONO CELLULARE
                  </th>
                  <!-- Colonne TICKET -->
                  <th :class="{ 'd-none': !isColumnVisible('ruolo') }" width="170">
                    RUOLO
                  </th>
                  <th :class="{ 'd-none': !isColumnVisible('ticket') }" width="130">
                    TICKET
                  </th>
                  <!-- Colonne COSTI -->
                  <th
                    :class="{ 'd-none': !isColumnVisible('costoOrarioAttuale') }"
                    width="170"
                  >
                    COSTO ORARIO ATTUALE
                  </th>
                  <th
                    :class="{ 'd-none': !isColumnVisible('costoOrarioMedio') }"
                    width="170"
                  >
                    COSTO ORARIO MEDIO
                  </th>
                  <!-- Colonne COMMESSE -->
                  <th
                    :class="{ 'd-none': !isColumnVisible('commesseAttive') }"
                    width="270"
                  >
                    COMMESSE ATTIVE
                  </th>
                  <th :class="{ 'd-none': !isColumnVisible('dal') }" width="110">DAL</th>
                  <!-- Colonna fissa -->
                  <th style="min-width: 100px !important; width: 100px !important">
                    AZIONI
                  </th>
                </tr>
              </thead>
              <tbody class="table-body-font td-vertical-center">
                <tr v-for="team in teams" :key="team._id">
                  <!-- Colonne fisse -->
                  <td>{{ team.metadata.anagrafica?.nome || "" }}</td>
                  <td>{{ team.metadata.anagrafica?.cognome || "" }}</td>
                  <td>
                    {{ team.metadata.rapporto?.societa?.descrizione || "" }}
                  </td>
                  <td>
                    {{ team.metadata.rapporto?.sede?.descrizione || "" }}
                  </td>
                  <td class="td-center">
                    <ul class="list-tags">
                      <li
                        v-for="profilo in team.metadata.rapporto.profili"
                        :key="profilo.id"
                      >
                        <span
                          class="badge-colored blue"
                          :title="profilo.descrizione"
                          :alt="profilo.descrizione"
                        >
                          {{ profilo.descrizione }}
                        </span>
                      </li>
                    </ul>
                  </td>
                  <!-- Colonne dinamiche -->
                  <!-- Colonne CONTATTI -->
                  <td v-if="isColumnVisible('email')">{{ team.email }}</td>
                  <td v-if="isColumnVisible('telefonocellulare')">
                    {{
                      team.metadata.anagrafica.telefonocellulare !== null ||
                      team.metadata.anagrafica.telefonocellulare !== "-" ||
                      team.metadata.anagrafica.telefonocellulare !== " - " ||
                      team.metadata.anagrafica.telefonocellulare !== "-"
                        ? team.metadata.anagrafica.telefonocellulare
                        : ""
                    }}
                  </td>
                  <!-- Colonne TICKET -->
                  <td v-if="isColumnVisible('ruolo')" class="td-center">
                    {{ team.metadata.rapporto?.ruolo?.descrizione }}
                  </td>
                  <td v-if="isColumnVisible('ticket')" class="td-center">
                    {{ team?.ticket }}
                  </td>
                  <!-- Colonne COSTI -->
                  <td v-if="isColumnVisible('costoOrarioAttuale')" class="td-right">
                    {{
                      team.costoOrarioAttuale
                        ? mixins_formatCurrency()
                        : mixins_formatCurrency(0)
                    }}
                  </td>
                  <td v-if="isColumnVisible('costoOrarioMedio')" class="td-right">
                    {{
                      team.costoOrarioMedio
                        ? mixins_formatCurrency(team.costoOrarioMedio)
                        : mixins_formatCurrency(0)
                    }}
                  </td>
                  <!-- Colonne COMMESSE -->
                  <td v-if="isColumnVisible('commesseAttive')">
                    <ul class="list-tags">
                      <li
                        v-for="commessa in team.commesse"
                        :key="commessa._id"
                        :style="`--type-display:flex; --justify:start;`"
                      >
                        <!-- <li
                        v-for="commessa in team.commesse"
                        :key="commessa._id"
                        :style="`--type-display:flex; --justify:start;`"
                        @click.stop.prevent="routeCommessa(commessa.titolo, commessa._id)"
                      > -->
                        <span
                          class="badge-colored green truncate-45-ch"
                          :title="`${commessa.cliente.descrizione}: ${commessa.titolo}`"
                          :alt="`${commessa.cliente.descrizione}: ${commessa.titolo}`"
                        >
                          {{ commessa.cliente.descrizione }}: {{ commessa.titolo }}
                        </span>
                      </li>
                    </ul>
                  </td>
                  <td v-if="isColumnVisible('dal')" class="td-center">
                    <ul class="list-tags">
                      <li v-for="commessa in team.commesse" :key="commessa.id">
                        <span
                          class="badge-colored green1"
                          :title="`${mixins_getLocalDate(commessa.datainizio)}`"
                          :alt="`${mixins_getLocalDate(commessa.datainizio)}`"
                        >
                          {{ mixins_getLocalDate(commessa.datainizio) }}
                        </span>
                      </li>
                    </ul>
                  </td>
                  <!-- Colonna fissa -->
                  <td class="td-actions td-center">
                    <button
                      alt="MODIFICA"
                      title="MODIFICA"
                      class="btn icon-button"
                      @click="editTeam(team)"
                      :disabled="userAuthenticated?.providerUserId == team.providerUserId"
                    >
                      <i class="zmdi zmdi-edit zmdi-hc-lg"></i>
                    </button>
                    <button
                      v-if="isAuthenticatedSuperAdmin || isAuthenticatedGestionePersonale"
                      class="delete btn icon-button"
                      alt="ELIMINA"
                      title="ELIMINA"
                      @click="askDeleteUser(team.providerUserId)"
                    >
                      <i class="zmdi zmdi-delete zmdi-hc-lg"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- Pagination -->
            <div
              class="align-items-center container-fluid d-flex justify-content-center my-4 p-0"
            >
              <div class="row" v-if="teams.length > 0 && totalPages > 1">
                <vue-pagination
                  :totalPages="totalPages"
                  :currentPage="page"
                  :maxVisibleButtons="6"
                  @pagechanged="onPageChange"
                ></vue-pagination>
              </div>
            </div>
          </div>
          <!-- Componente Assenze del team -->
          <TeamAssenze ref="teamAssenze" />

          <!-- Componente Rapportini del team -->
          <TeamRapportini ref="teamRapportini" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchButton from "@/components/ui/SearchButton.vue";
import { fetchTeam, deleteUser } from "@/services/api";
import VuePagination from "@/components/ui/VuePagination.vue";
import { mapGetters, mapActions } from "vuex";
import { UtilityMixins } from "@/mixins/UtilityMixins.js";
import { User } from "@/models/TeamJoiModel";
import visualizationConfig from "@/data/team_visualizations_list.json";
import sortOrderList from "@/data/sort_order_list.json";
import TeamAssenze from "@/views/menu_team/TeamAssenze.vue";
import TeamRapportini from "@/views/menu_team/TeamRapportini.vue";
import EventBus from "@/eventBus"; // Importa l'EventBus
import ProfiliPermissions from "@/mixins/ProfiliPermissions.js";

class QueryFilters {
  constructor(data = {}) {
    this.mercato = data.mercato || "";
    this.tipologia = data.tipologia || "";
    this.tiposkill = data.tiposkill || "";
    this.livello = data.livello || "";
  }
}

export default {
  mixins: [UtilityMixins, ProfiliPermissions],
  components: {
    SearchButton,
    VuePagination,
    TeamAssenze,
    TeamRapportini,
  },
  data() {
    return {
      teams: new User(),
      totalRecords: 0,
      loading: false,
      error: null,
      isFirstTime: true,
      // visualization
      visualizationConfig: visualizationConfig,
      currentVisualizationId: "contatti",
      // pagination
      page: this.getCurrentPageTeams,
      limit: 20,
      totalPages: 1,
      // filters
      allFilters: {
        labels: [],
        ids: {
          profili: [],
          aziende: [],
          sedi: [],
          mercato: "",
          tipologia: "",
          tiposkill: "",
          livello: "",
        },
      },
      labelMap: {},
      filterLabels: [],
      filtersIds: {},
      sortOrderList,
      isTeamVisible: true,
      userAuthenticated: null,
      // tabelle skills
      skillsData: [], // Array di tutti gli skills
      mercatoOptions: [], // Array delle opzioni per il mercato di riferimento
      tipologiaOptions: [], // Array delle opzioni per il tipo di skill
      livelloOptions: [], // Array delle opzioni per il livello
      tiposkillOptions: [], // Array delle opzioni per gli skill
      commessaOptions: [], // Array delle opzioni per le commesse
      selectedLocalModels: {
        // Raccolgo i modelli locali in un unico oggetto
        mercato: [],
        livello: [],
        tipologia: [],
        tiposkill: [],
      },
      // local filters
      localFilters: new QueryFilters(),
      isSearchingCommessa: false,
      isFilterCommessaVisible: false,
    };
  },
  computed: {
    ...mapGetters(["getCurrentPageTeams", "getTeamsSortType", "getSearchTeams"]),
    currentVisualization() {
      return this.visualizationConfig.visualizations.find(
        (v) => v.id === this.currentVisualizationId
      );
    },
    labelsString() {
      return Array.isArray(this.allFilters.labels) && this.allFilters.labels.length > 0
        ? this.allFilters.labels.join(", ")
        : "Tutti"; // Messaggio predefinito se non ci sono labels
    },
    filteredVisualizations() {
      // Filtro gli elementi dove item.id non è 'costi'
      return this.visualizationConfig.visualizations.filter(
        (item) => item.id !== "costi"
      );
    },
    //
    // Authenticated
    //
    isAuthenticatedSuperAdmin() {
      return this.permissions_isAuthenticatedSuperAdmin(this.userAuthenticated);
    },
    isAuthenticatedGestionePersonale() {
      return this.permissions_isAuthenticatedGestionePersonale(this.userAuthenticated);
    },
  },
  watch: {
    "$route.query": {
      handler(newQuery) {
        if (this.$route.name === "route-teams") {
          console.log("** team list content watch $route.query", newQuery);
          this.syncFiltersWithQueryParams(newQuery);
          this.applyFilters(newQuery); // Applica solo se siamo nella lista del team
        }
      },
      immediate: true,
    },
    getSearchTeams() {
      this.fetchTeamsData();
    },
  },
  methods: {
    ...mapActions(["setCurrentPageTeams", "setSearchTeams"]),
    //
    // ** Fetch Data **
    //
    async fetchTeamsData() {
      this.loading = true;
      this.error = null;
      this.totalRecords = 0;
      this.hideAssenze();
      try {
        const filters = this.allFilters.ids;

        const response = await fetchTeam({
          page: this.page,
          limit: this.limit,
          sortType: this.getTeamsSortType,
          textsearch: this.getSearchTeams,
          filtersIds: filters,
        });
        const totalCount = response?.totalCount ?? 0;
        this.teams = response.users?.map((item) => new User(item)) ?? [];
        this.totalPages = Math.ceil(totalCount / this.limit);
        this.totalRecords = totalCount;
        this.isFirstTime = false;
      } catch (err) {
        this.error = "Si è verificato un errore";
      } finally {
        this.loading = false;
      }
    },
    fetchAssenzeHandler() {
      // Visualizzo il componente TeamAssenze.vue e richiamo il metodo per caricare i dati fetchDataAssenze()
      // Il metodo fetchAssenzeHandler() viene richiamato dalla action dell'item ASSENZE in team_visualizations_list.json
      if (this.$refs.teamAssenze && this.$refs.teamAssenze.fetchDataAssenze) {
        this.hideRapportini();
        this.showAssenze();
        this.$refs.teamAssenze.fetchDataAssenze();
      } else {
        console.error(
          "** Il metodo $refs.teamAssenze.fetchDataAssenze() non è disponibile nel componente child."
        );
      }
    },
    fetchRapportiniHandler() {
      // Visualizzo il componente TeamRapportini.vue e richiamo il metodo per caricare i dati fetchDataRapportini()
      // Il metodo fetchRapportiniHandler() viene richiamato dalla action dell'item RAPPORTINI in team_visualizations_list.json
      if (this.$refs.teamRapportini && this.$refs.teamRapportini.fetchDataRapportini) {
        this.hideAssenze();
        this.showRapportini();
        this.$refs.teamRapportini.fetchDataRapportini();
      } else {
        console.error(
          "** Il metodo $refs.teamRapportini.fetchDataRapportini() non è disponibile nel componente child."
        );
      }
    },
    showAssenze() {
      this.isTeamVisible = false;
      EventBus.emit("changeViewAssenze", true);
    },
    showRapportini() {
      this.isTeamVisible = false;
      EventBus.emit("changeViewRapportini", true);
    },
    hideAssenzeAndRapportini() {
      this.hideAssenze();
      this.hideRapportini();
    },
    hideAssenze() {
      this.isTeamVisible = true;
      EventBus.emit("changeViewAssenze", false);
      if (this.$refs.teamAssenze && this.$refs.teamAssenze.hideAssenze) {
        this.$refs.teamAssenze.hideAssenze();
      }
    },
    hideRapportini() {
      this.isTeamVisible = true;
      EventBus.emit("changeViewRapportini", false);
      if (this.$refs.teamRapportini && this.$refs.teamRapportini.hideRapportini) {
        this.$refs.teamRapportini.hideRapportini();
      }
    },
    //
    // ** Gestione Filtri **
    //
    applyFilters(filtersData) {
      // Inizializza filtersData se undefined, con labels come array vuoto

      console.log("** applyFilters filtersData", filtersData);

      filtersData = filtersData || {
        profili: "",
        aziende: "",
        sedi: "",
        cessati: "",
        mercato: "",
        tipologia: "",
        tiposkill: "",
        livello: "",
        labels: [],
      };

      // Verifica se profili, aziende e sedi sono già array, altrimenti applica split
      this.allFilters.ids.profili = Array.isArray(filtersData.profili)
        ? filtersData.profili
        : (filtersData.profili || "").split(",");

      this.allFilters.ids.aziende = Array.isArray(filtersData.aziende)
        ? filtersData.aziende
        : (filtersData.aziende || "").split(",");

      this.allFilters.ids.sedi = Array.isArray(filtersData.sedi)
        ? filtersData.sedi
        : (filtersData.sedi || "").split(",");

      this.allFilters.ids.cessati = filtersData.cessati;

      // Gestisco le labels
      this.allFilters.labels = Array.isArray(filtersData.labels)
        ? filtersData.labels
        : [];

      // Gestisco i filtri per gli skill
      this.allFilters.ids.mercato = filtersData.mercato;
      this.allFilters.ids.tipologia = filtersData.tipologia;
      this.allFilters.ids.tiposkill = filtersData.tiposkill;
      this.allFilters.ids.livello = filtersData.livello;

      // Gestisco i filtri per le commesse
      // this.allFilters.ids.idcommessa = filtersData.idcommessa;

      // Aggiorna la pagina corrente e fetch dei dati
      this.page = this.getCurrentPageTeams;
      this.fetchTeamsData();

      // Aggiorna la query string nell'URL senza reindirizzare
      this.$router.push({ query: { ...filtersData } });
    },
    syncFiltersWithQueryParams(queryParams = this.$route.query) {
      // sync mercato
      this.localFilters.mercato = queryParams.mercato;
      const foundMercato = this.mercatoOptions.find(
        (option) => option.value === this.localFilters.mercato
      );
      this.selectedLocalModels.mercato = foundMercato ? foundMercato : null;

      // sync tipologia
      this.localFilters.tipologia = queryParams.tipologia;
      const foundTipologia = this.tipologiaOptions.find(
        (option) => option.value === this.localFilters.tipologia
      );
      this.selectedLocalModels.tipologia = foundTipologia ? foundTipologia : null;
      this.onSkillTipologiaSelect(foundTipologia);

      // sync tiposkill
      this.localFilters.tiposkill = queryParams.tiposkill;
      const foundTiposkill = this.tiposkillOptions.find(
        (option) => option.value === this.localFilters.tiposkill
      );
      this.selectedLocalModels.tiposkill = foundTiposkill ? foundTiposkill : null;

      // sync livello
      this.localFilters.livello = queryParams.livello;
      const foundLivello = this.livelloOptions.find(
        (option) => option.value === Number(this.localFilters.livello)
      );
      this.selectedLocalModels.livello = foundLivello ? foundLivello : null;
    },
    //
    // ** Gestione ricerca **
    //
    filterTextToSearch(text) {
      this.setSearchTeams(text);
      this.setCurrentPageTeams(1);
      this.page = 1;
    },
    filterClearTextToSearch() {
      this.setSearchTeams("");
      this.setCurrentPageTeams(1);
      this.page = 1;
    },
    //
    // ** Altri metodi **
    //
    onPageChange(page) {
      this.page = page;
      this.setCurrentPageTeams(page);
      this.fetchTeamsData(this.filtersIds);
    },
    editTeam(team) {
      this.$router.push({
        name: "route-teams-edit",
        params: { id: team.providerUserId },
        query: { ...this.$route.query },
      });
    },
    async askDeleteUser(id) {
      if (!this.isAuthenticatedSuperAdmin && !this.isAuthenticatedGestionePersonale)
        return;
      this.$swal
        .fire({
          title: "Cancellazione Utente",
          html: "Sei sicuro di voler cancellare l'utente?",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: '<i class="fa fa-check"></i> Conferma',
          cancelButtonText: '<i class="fa fa-xmark"></i> Annulla',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await this.removeUser(id);
          }
        });
    },
    async removeUser(id) {
      if (!this.isAuthenticatedSuperAdmin && !this.isAuthenticatedGestionePersonale)
        return;
      console.log("** delete user id", id);
      this.loading = true;
      try {
        const response = await deleteUser(id);
        if (response) {
          this.mixins_showMessage(
            "Cancellazione Utente",
            "Utente cancellato correttamente",
            "info"
          );
          this.page = 1;
          this.setCurrentPageTeams(1);
          await this.fetchTeamsData(this.filtersIds);
        } else {
          this.mixins_showMessage(
            "Cancellazione Utente",
            "Si è verificato un errore durante la cancellazione",
            "error"
          );
        }
      } catch (error) {
        this.mixins_showMessage(
          "Cancellazione Utente",
          "Si è verificato un errore durante la cancellazione",
          "error"
        );
      } finally {
        this.loading = false;
      }
    },
    changeVisualization(visualizationId) {
      this.currentVisualizationId = visualizationId;
    },
    isColumnVisible(columnName) {
      return this.currentVisualization.columns.includes(columnName);
    },
    routeCommessa(titolo, id) {
      if (id) {
        // Apro la pagina di dettaglio della commessa in un nuovo tab del browser
        const routeData = this.$router.resolve({
          name: "route-commesse-edit",
          params: { id: id },
        });
        window.open(routeData.href, "_blank");
      } else {
        this.mixins_showMessage("Errore", `ID mancante per '${titolo}'`, "error");
      }
    },
    getSortLabeltByValue(value) {
      const foundItem = this.sortOrderList.find((item) => item.value === value);
      return foundItem ? foundItem.text : "";
    },
    //
    // Caricamento tabelle
    //
    async getAllTables() {
      // Carico la tabella degli skills
      this.skillsData = await this.getTabSkillsData();
      console.log("** tab skills data", this.skillsData);

      // Raggruppo i dati degli skill per type
      const skillsGroupedByType = this.skillsData.reduce((acc, skill) => {
        if (!acc[skill.type]) {
          acc[skill.type] = [];
        }
        acc[skill.type].push(skill);
        return acc;
      }, {});

      // Popolo le tipologie
      if (skillsGroupedByType && typeof skillsGroupedByType === "object") {
        this.tipologiaOptions = Object.keys(skillsGroupedByType).map((type) => ({
          value: type,
          label: type,
        }));
      } else {
        console.error(
          "skillsGroupedByType non è un oggetto valido:",
          skillsGroupedByType
        );
        this.tipologiaOptions = [];
      }

      // Carico la tabella mercato di riferimento e livello del filtro skill
      await this.getTabMercatoDiRiferimento();
      await this.getTabSkillsLevelType();

      // Carico la tabella delle commesse
      if (this.isFilterCommessaVisible) {
        await this.fetchCommesseData();
      }
    },
    async getTabSkillsData() {
      // Tabella di tutti gli skill, i dati saranno raggruppati e filtrati
      return await this.mixins_getTabSkillsData();
    },

    async getTabMercatoDiRiferimento() {
      // Tabella 'Mercato Di Riferimento'
      const array = await this.mixins_getTabMercatoDiRiferimento();

      if (Array.isArray(array)) {
        this.mercatoOptions = array
          .filter((item) => item.descrizione) // Filtra gli elementi che hanno 'descrizione'
          .map((item) => ({
            value: item.descrizione,
            label: item.descrizione,
          }));

        console.log("** tab mercatoOptions", this.mercatoOptions);
      } else {
        console.error(
          "mixins_getTabMercatoDiRiferimento, la risposta non è un array:",
          array
        );
        this.mercatoOptions = [];
      }
    },
    async getTabSkillsLevelType() {
      // Tabella 'Skills Level Type'
      const array = await this.mixins_getTabSkillsLevelType();
      this.livelloOptions = array.map((item) => ({
        value: item.livellonumber,
        label: item.livello,
      }));
      console.log("** tab livelloOptions", this.livelloOptions);
    },
    async fetchCommesseData() {
      // Tabella per popolare le commesse nei filtri
      const tabCommesse = await this.mixins_getCommesse("");

      if (Array.isArray(tabCommesse)) {
        this.commessaOptions = tabCommesse
          .filter((item) => item._id && item.titolo)
          .map((item) => ({
            value: item._id,
            label: item.titolo,
          }));

        console.log("** tab commessaOptions", this.commessaOptions);
      } else {
        console.error("tabCommesse non è un array valido:", tabCommesse);
        this.commessaOptions = [];
      }
    },
    async asyncFetchCommessa(query) {
      // Rimuovo i caratteri *^? insieme ai tag HTML/XML per le query
      query = this.mixins_cleanTextForQuery(query);

      // Carico le opzioni delle commesse trovate
      this.isSearchingCommessa = true;

      try {
        const tabCommessa = await this.mixins_getCommesse(query);

        if (Array.isArray(tabCommessa)) {
          this.commessaOptions = tabCommessa
            .filter((commessa) => commessa._id && commessa.titolo) // Filtra solo le commesse con _id e titolo
            .map((commessa) => ({
              value: commessa._id,
              label: commessa.titolo,
            }));
        } else {
          console.error("tabCommessa non è un array valido:", tabCommessa);
          this.commessaOptions = [];
        }
      } catch (error) {
        console.error("Errore durante il fetch delle commesse:", error);
        this.commessaOptions = [];
      } finally {
        this.isSearchingCommessa = false;
      }
    },
    //
    // Gestione multiselect
    //
    onOpenMultiselectSkill() {
      setTimeout(() => {
        // Aggiungo la classe no-overflow per permettere alla multiselect di andare in primo piano
        const dropdownMenu = document.querySelector(
          `ul[aria-labelledby="dropdown_skill"]`
        );
        const li1 = document.getElementById("skill_mercato");
        const li2 = document.getElementById("skill_tipologia");
        const li3 = document.getElementById("skill_livello");
        const li4 = document.getElementById("skill_skill");

        if (dropdownMenu) {
          dropdownMenu.classList.add("no-overflow");
          li1.classList.add("no-overflow");
          li2.classList.add("no-overflow");
          li3.classList.add("no-overflow");
          li4.classList.add("no-overflow");
        }
      }, 50);
    },
    onCloseMultiselectSkill() {
      // Rimuovo la classe no-overflow
      const dropdownMenu = document.querySelector(`ul[aria-labelledby="dropdown_skill"]`);
      const li1 = document.getElementById("skill_mercato");
      const li2 = document.getElementById("skill_tipologia");
      const li3 = document.getElementById("skill_livello");
      const li4 = document.getElementById("skill_skill");
      if (dropdownMenu) {
        dropdownMenu.classList.remove("no-overflow");
        li1.classList.remove("no-overflow");
        li2.classList.remove("no-overflow");
        li3.classList.remove("no-overflow");
        li4.classList.remove("no-overflow");
      }
    },
    onOpenMultiselectCommessa() {
      setTimeout(() => {
        // Aggiungo la classe no-overflow per permettere alla multiselect di andare in primo piano
        const dropdownMenu = document.querySelector(
          `ul[aria-labelledby="dropdown_commessa"]`
        );
        const li1 = document.getElementById("commessa_idcommessa");

        if (dropdownMenu) {
          dropdownMenu.classList.add("no-overflow");
          li1.classList.add("no-overflow");
        }
      }, 50);
    },
    onCloseMultiselectCommessa() {
      // Rimuovo la classe no-overflow
      const dropdownMenu = document.querySelector(
        `ul[aria-labelledby="dropdown_commessa"]`
      );
      const li1 = document.getElementById("commessa_idcommessa");
      if (dropdownMenu) {
        dropdownMenu.classList.remove("no-overflow");
        li1.classList.remove("no-overflow");
      }
    },
    //
    // Mercato di riferimento
    //
    onMercatoSelect(selected) {
      this.localFilters.mercato = selected.value;
      console.log("** multiselect filter mercato", this.localFilters.mercato);
    },
    onMercatoRemove() {
      this.localFilters.mercato = "";
    },
    //
    // ** Tipologia
    //
    onSkillTipologiaSelect(selected) {
      if (!selected) return;

      // Verifica che this.skillsData sia un array
      if (!Array.isArray(this.skillsData)) {
        console.error("skillsData non è un array valido:", this.skillsData);
        this.tiposkillOptions = [];
        return;
      }

      const tipo = this.skillsData.find((item) => item.type === selected.value);

      // Popolo le opzioni per gli skill solo se 'tipo' è trovato
      this.tiposkillOptions = tipo
        ? this.skillsData
            .filter((skill) => skill.type === selected.value && skill.descrizione)
            .map((skill) => ({
              value: skill.descrizione,
              label: skill.descrizione,
            }))
        : [];

      // Aggiorno i filtri locali
      if (this.localFilters.tipologia !== selected.value) {
        this.localFilters.tiposkill = "";
        this.selectedLocalModels.tiposkill = null;
      }

      this.localFilters.tipologia = selected.value;

      console.log("** multiselect filter tipologia", this.localFilters.tipologia);
    },
    onSkillTipologiaRemove() {
      this.tiposkillOptions = [];
      this.selectedLocalModels.tiposkill = [];
      this.localFilters.tipologia = "";
      this.localFilters.tiposkill = "";
    },
    //
    // ** Tipo Skill
    //
    onTipoSkillSelect(selected) {
      this.localFilters.tiposkill = selected.value;
      console.log("** multiselect filter tiposkill", this.localFilters.tiposkill);
    },
    onTipoSkillRemove() {
      this.localFilters.tiposkill = "";
    },
    //
    // ** Livello
    //
    onLivelloSelect(selected) {
      this.localFilters.livello = selected.value;
      console.log("** multiselect filter livello", this.localFilters.livello);
    },
    onLivelloRemove() {
      this.localFilters.livello = "";
    },
    //
    // Mercato di riferimento
    //
    onCommessaSelect(selected) {
      this.localFilters.idcommessa = selected.value;
      console.log("** multiselect filter idcommessa", this.localFilters.idcommessa);
    },
    onCommessaRemove() {
      this.localFilters.mercato = "";
    },

    sendFiltersQueryString() {
      const newQuery = { ...this.$route.query, ...this.localFilters };

      this.setCurrentPageTeams(1);

      // Applica i nuovi filtri
      this.applyFilters(newQuery);
    },
  },
  async mounted() {
    console.log("** TeamsContent montato");
    // Oggetto user da local storage
    this.userAuthenticated = this.mixin_getUserDataLS();

    // Carico le tabelle per il filtro Skill
    await this.getAllTables();

    // Sincronizza i filtri
    this.syncFiltersWithQueryParams();
  },
};
</script>
