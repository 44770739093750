<template>
  <main>
    <overlay-loading v-model:isLoading="isLoading"></overlay-loading>
    <div class="container content-container">
      <div class="align-items-center d-flex justify-content-center container p-0 m-0">
        <div class="header-text">
          <h1>Recupero Password</h1>
        </div>
      </div>
      <div class="align-items-center d-flex justify-content-center row mx-1">
        <div class="login-wrap col-md-7 col-lg-6">
          <form novalidate class="m-3" @submit.prevent="submitForgotPassword()">
            <div v-if="!isEmailSent" class="alert info" role="alert">
              Inserisci il tuo <strong>indirizzo email</strong> per reimpostare la
              password e premi il tasto <strong>Invia</strong>. Controlla la tua email e
              clicca sul link per reimpostare la password.<br /><br />Clicca sul bottone
              <strong>Login</strong> per tornare alla pagina di login.
            </div>
            <div v-if="isEmailSent" class="alert info" role="alert">
              Una email è stata inviata al seguente indirizzo:<br /><strong>{{
                v$.form.email.$model
              }}</strong
              ><br /><br /><span
                >Controlla la tua email e clicca sul link per reimpostare la
                password.</span
              >
            </div>
            <!-- E-mail -->
            <div class="form-group" :class="{ error: v$.form.email.$errors.length }">
              <label for="email" class="form-label">EMAIL</label>
              <input
                id="email"
                ref="email"
                v-model.trim="v$.form.email.$model"
                type="email"
                class="form-control"
                placeholder="INSERISCI LA TUA EMAIL"
                required
                spellcheck="false"
                @click.right.prevent
                @keydown.space.prevent
                autocomplete="off"
                autocapitalize="none"
                :disabled="isEmailSent"
              />
            </div>
            <div
              class="input-errors"
              v-for="(error, index) of v$.form.email.$errors"
              :key="index"
            >
              <div class="text-danger mb-4">{{ error.$message }}</div>
            </div>

            <div class="row mt-4">
              <div class="col-md-12">
                <!-- Login Button -->
                <base-icon-button
                  :typeButton="'button'"
                  :alignClass="'float-start'"
                  @click.prevent="$router.push({ name: 'route-login' })"
                  ><i class="bi bi-box-arrow-in-right me-2"></i>Login</base-icon-button
                >
                <!-- Send Button -->
                <base-icon-button
                  v-if="!isEmailSent"
                  id="loginBtn"
                  ref="loginBtn"
                  :alignClass="'float-end'"
                  :typeButton="'submit'"
                  v-html="isLoading ? buttonCaption.wait : buttonCaption.send"
                ></base-icon-button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import BaseIconButton from "@/components/ui/BaseIconButton.vue";
import useVuelidate from "@vuelidate/core";
import { required, helpers, email } from "@vuelidate/validators";
import OverlayLoading from "@/components/ui/OverlayLoading.vue";
import { forgotPassword } from "@/services/auth";
import { UtilityMixins } from "@/mixins/UtilityMixins.js";

export default {
  components: { BaseIconButton, OverlayLoading },
  mixins: [UtilityMixins],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      form: {
        email: "",
      },
      buttonCaption: {
        wait:
          '<span class="spinner-border spinner-border-sm" role="status"></span>&nbsp;Attendere',
        send: '<i class="zmdi zmdi-email"></i>Invia',
      },
      isLoading: false,
      isEmailSent: false,
    };
  },
  validations() {
    return {
      form: {
        email: {
          required: helpers.withMessage("Questo campo non può essere vuoto", required),
          email: helpers.withMessage("Non è una email valida", email),
        },
      },
    };
  },
  methods: {
    async submitForgotPassword() {
      this.v$.form.$touch();
      if (this.v$.form.$invalid) {
        this.focusInput();
        return false;
      }

      this.isLoading = true;
      this.isEmailSent = false;

      try {
        const response = await forgotPassword(this.v$.form.email.$model);
        console.log("** forgotPassword response", response);
        if (response?.url) {
          console.log("** forgotPassword ok", response?.response?.data);
          this.isEmailSent = true;
        } else if (response?.response?.status === 401) {
          if (response?.response?.data) {
            const code = response.response.data.code;
            if (code === 109) {
              this.mixins_showMessage("Recupero Password", "Email inesistente", "error");
            } else {
              this.mixins_showMessage(
                "Recupero Password",
                "Errore durante il recupero della password",
                "error"
              );
            }
          }
        } else {
          this.mixins_showMessage(
            "Recupero Password",
            `Errore ${response.response.status} durante il recupero della password`,
            "error"
          );
        }
      } catch (error) {
        console.error("Errore durante il recupero password", error);
        this.mixins_showMessage(
          "Recupero Password",
          "Errore durante il recupero della password",
          "error"
        );
      } finally {
        this.isLoading = false;
      }
    },
    focusInput() {
      this.$nextTick(() => {
        this.$refs.email.focus();
      });
    },
  },
  mounted() {
    this.focusInput();
  },
};
</script>
