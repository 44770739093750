<template>
  <main>
    <overlay-loading v-model:isLoading="isLoading"></overlay-loading>
    <div v-if="isVisible" class="container content-container">
      <div class="align-items-center d-flex justify-content-center container p-0 m-0">
        <div class="header-text">
          <h1>Cambia Password</h1>
        </div>
      </div>
      <div class="align-items-center d-flex justify-content-center row mx-1">
        <div class="login-wrap col-md-7 col-lg-6">
          <form novalidate class="m-3">
            <div v-if="isPasswordChanged" class="alert info" role="alert">
              La password è stata modificata correttamente.
            </div>
            <!-- Nuova Password -->
            <div v-if="!isPasswordChanged" class="container p-0 my-5">
              <div class="container p-0">
                <div class="form-label my-2">NUOVA PASSWORD</div>
                <!-- Field -->
                <div class="input-group">
                  <input
                    ref="inputNuovaPassword"
                    v-model.trim="localDataSicurezza.newPassword"
                    type="password"
                    class="form-control"
                    placeholder="NUOVA PASSWORD"
                    required
                    spellcheck="false"
                    @click.right.prevent
                    autocomplete="new-password"
                    autocapitalize="off"
                    @keydown.space.prevent
                    @keydown.enter.prevent
                    @input="validateImmediate()"
                  />
                  <button
                    class="input-group-button"
                    alt="GENERA UNA PASSWORD"
                    title="GENERA UNA PASSWORD"
                    @keydown.enter.prevent
                    @click.prevent="
                      localDataSicurezza.confirmPassword = localDataSicurezza.newPassword = mixins_generaPassword();
                      validateImmediate();
                    "
                  >
                    <i class="zmdi zmdi-lock zmdi-hc-lg"></i>
                  </button>
                  <button
                    class="input-group-button"
                    :alt="eye_new_psw ? 'NASCONDI PASSWORD' : 'MOSTRA PASSWORD'"
                    :title="eye_new_psw ? 'NASCONDI PASSWORD' : 'MOSTRA PASSWORD'"
                    @keydown.enter.prevent
                    @click.prevent="eye_new_psw = toggleVisibility('inputNuovaPassword')"
                  >
                    <i
                      :class="
                        eye_new_psw
                          ? 'zmdi zmdi-eye-off zmdi-hc-lg'
                          : 'zmdi zmdi-eye zmdi-hc-lg'
                      "
                    ></i>
                  </button>
                </div>
              </div>
            </div>
            <!-- Ripeti Nuova Password -->
            <div v-if="!isPasswordChanged" class="container p-0 my-5">
              <div class="container p-0">
                <div class="form-label my-2">RIPETI NUOVA PASSWORD</div>
                <!-- Field -->
                <div class="input-group">
                  <input
                    ref="inputRipetiNuovaPassword"
                    v-model.trim="localDataSicurezza.confirmPassword"
                    type="password"
                    class="form-control"
                    placeholder="RIPETI NUOVA PASSWORD"
                    required
                    spellcheck="false"
                    @click.right.prevent
                    autocomplete="new-password"
                    autocapitalize="off"
                    @keydown.space.prevent
                    @keydown.enter.prevent
                    @input="validateImmediate()"
                  />
                  <button
                    class="input-group-button"
                    :alt="eye_cnf_psw ? 'NASCONDI PASSWORD' : 'MOSTRA PASSWORD'"
                    :title="eye_cnf_psw ? 'NASCONDI PASSWORD' : 'MOSTRA PASSWORD'"
                    @keydown.enter.prevent
                    @click.prevent="
                      eye_cnf_psw = toggleVisibility('inputRipetiNuovaPassword')
                    "
                  >
                    <i
                      :class="
                        eye_cnf_psw
                          ? 'zmdi zmdi-eye-off zmdi-hc-lg'
                          : 'zmdi zmdi-eye zmdi-hc-lg'
                      "
                    ></i>
                  </button>
                </div>
              </div>
            </div>
            <!-- Messaggi validità password -->
            <div class="row my-5">
              <div v-if="!isPasswordValid">
                <div
                  v-for="(msg, index) in passwordMessages"
                  :key="index"
                  class="text-danger small mt-1"
                >
                  {{ msg }}
                </div>
              </div>
              <div v-if="!isPasswordMatched" class="text-danger small mt-1">
                Le password nei campi "NUOVA PASSWORD" e "RIPETI NUOVA PASSWORD" non
                coincidono
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-12">
                <!-- Login Button -->
                <base-icon-button
                  :typeButton="'button'"
                  :alignClass="'float-start'"
                  @click.prevent="$router.push({ name: 'route-login' })"
                  ><i class="bi bi-box-arrow-in-right me-2"></i>Login</base-icon-button
                >
                <!-- Reset password Button -->
                <base-icon-button
                  v-if="!isPasswordChanged"
                  :alignClass="'float-end'"
                  :typeButton="'button'"
                  v-html="isLoading ? buttonCaption.wait : buttonCaption.send"
                  @click="cambiaPassword()"
                ></base-icon-button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import BaseIconButton from "@/components/ui/BaseIconButton.vue";
import { UtilityMixins } from "@/mixins/UtilityMixins.js";
import OverlayLoading from "@/components/ui/OverlayLoading.vue";
import { resetPassword } from "@/services/auth";

class SicurezzaChange {
  constructor(data = {}) {
    this.newPassword = data.newPassword || "";
    this.confirmPassword = data.confirmPassword || "";
  }
}

export default {
  mixins: [UtilityMixins],
  components: { BaseIconButton, OverlayLoading },
  data() {
    return {
      eye_new_psw: false,
      eye_cnf_psw: false,
      isPasswordMatched: true,
      isPasswordValid: true,
      buttonCaption: {
        wait:
          '<span class="spinner-border spinner-border-sm" role="status"></span>&nbsp;Attendere',
        send: '<i class="bi bi-lock-fill"></i>Cambia Password',
      },
      spinnerLoader: null,
      isVisible: false,
      isPasswordChanged: false,
      passwordToken: null,
      passwordMessages: [],
      isLoading: false,
      localDataSicurezza: new SicurezzaChange(),
    };
  },
  methods: {
    toggleVisibility(refId) {
      const input = this.$refs[refId];
      input.type = input.type === "password" ? "text" : "password";
      return input.type === "text";
    },
    resetFieldToPassword(refId) {
      const input = this.$refs[refId];
      input.type = "password";
    },
    validatePassword(password) {
      const result = this.mixins_controllaPasswordMsg(password);
      this.isPasswordValid = result.isValid;
      this.passwordMessages = result.messages;
    },
    validateRepeatPassword() {
      this.isPasswordMatched =
        this.localDataSicurezza.newPassword === this.localDataSicurezza.confirmPassword;
    },
    validateImmediate() {
      // Eseguo tutte le validazioni
      this.validatePassword(this.localDataSicurezza.newPassword);
      this.validateRepeatPassword();

      // Controlla tutte le condizioni: Nuova password valida e le due password coincidono
      if (this.isPasswordValid && this.isPasswordMatched) {
        return true;
      } else {
        return false;
      }
    },
    focusInput() {
      this.$nextTick(() => {
        this.$refs?.inputNuovaPassword?.focus();
      });
    },
    azzeraCampi() {
      this.localDataSicurezza = new SicurezzaChange();
      this.isPasswordValid = true;
      this.isPasswordMatched = true;
      this.eye_new_psw = false;
      this.eye_cnf_psw = false;
      this.resetFieldToPassword("inputNuovaPassword");
      this.resetFieldToPassword("inputRipetiNuovaPassword");
    },
    async cambiaPassword() {
      let isUpdated = false;
      if (this.validateImmediate() && this.passwordToken) {
        console.log("** Cambia password", this.localDataSicurezza);
        this.isLoading = true;

        try {
          const response = await resetPassword(
            this.passwordToken,
            this.localDataSicurezza.newPassword
          );
          if (typeof response == "object") {
            console.log("** response cambio password", response);
            if (response.success === true) {
              this.mixins_showMessage(
                "Cambio Password",
                "Password cambiata correttamente",
                "info"
              );
              isUpdated = true;
              this.azzeraCampi();
            } else {
              this.mixins_showMessage(
                "Cambia Password",
                "Si è verificato un errore durante il cambio password",
                "warning"
              );
              isUpdated = false;
            }
          }
        } catch (error) {
          this.mixins_showMessage(
            "Cambia Password",
            "Si è verificato un errore durante il cambio password",
            "warning"
          );
          isUpdated = false;
        } finally {
          this.isLoading = false;
        }
      }
      // Se non verificato o modificato correttamente, restituisco false
      return isUpdated;
    },
    showLoader(isShow) {
      if (isShow) {
        this.spinnerLoader = this.$loading.show();
      } else {
        if (this.spinnerLoader) {
          this.spinnerLoader.hide();
        }
      }
    },
  },
  async created() {
    if (this.$route.query.token) {
      this.isVisible = true;
      this.passwordToken = this.$route.query.token;
      console.log("** token: " + this.passwordToken);
      this.focusInput();
    }
  },
};
</script>
