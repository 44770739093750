<template>
  <p class="text-center">Pagina {{ currentPage }} di {{ totalPages }}</p>
  <ul class="pagination-ul">
    <li>
      <button
        type="button"
        @click="onClickFirstPage"
        :disabled="isInFirstPage"
        :class="{ disabled: isInFirstPage }"
        aria-label="Vai alla prima pagina"
        alt="Vai alla prima pagina"
        title="Vai alla prima pagina"
        class="pagination-item"
      >
        Prima
      </button>
    </li>

    <li>
      <button
        type="button"
        @click="onClickPreviousPage"
        :disabled="isInFirstPage"
        :class="{ disabled: isInFirstPage }"
        aria-label="Vai alla pagina precedente"
        alt="Vai alla pagina precedente"
        title="Vai alla pagina precedente"
        class="pagination-item"
      >
        Precedente
      </button>
    </li>

    <li v-for="(page, index) in pages" :key="index">
      <button
        type="button"
        @click="onClickPage(page.name)"
        :disabled="page.isDisabled"
        :class="{ active: isPageActive(page.name), disabled: page.isDisabled }"
        :aria-label="`Vai alla pagina n° ${page.name}`"
        :alt="`Vai alla pagina n° ${page.name}`"
        :title="`Vai alla pagina n° ${page.name}`"
        class="pagination-item"
      >
        {{ page.name }}
      </button>
    </li>

    <li>
      <button
        type="button"
        @click="onClickNextPage"
        :disabled="isInLastPage"
        :class="{ disabled: isInLastPage }"
        aria-label="Vai alla pagina successiva"
        alt="Vai alla pagina successiva"
        title="Vai alla pagina successiva"
        class="pagination-item"
      >
        Successiva
      </button>
    </li>

    <li>
      <button
        type="button"
        @click="onClickLastPage"
        :disabled="isInLastPage"
        :class="{ disabled: isInLastPage }"
        aria-label="Vai all'ultima pagina"
        alt="Vai all'ultima pagina"
        title="Vai all'ultima pagina"
        class="pagination-item"
      >
        Ultima
      </button>
    </li>
  </ul>
</template>

<script>
export default {
  emits: ["pagechanged"],
  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 3,
    },
    totalPages: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },
  computed: {
    startPage() {
      let startPage = 1;
      if (this.currentPage === 1) {
        startPage = 1;
        return startPage;
      }
      if (this.currentPage === this.totalPages) {
        startPage = this.totalPages - this.maxVisibleButtons + 1;
        if (startPage < 1) {
          startPage = 1;
        }
        return startPage;
      }
      startPage = this.currentPage - 1;
      if (startPage < 1) {
        startPage = 1;
      }
      return startPage;
    },
    endPage() {
      return Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages);
    },
    pages() {
      const range = [];

      for (let i = this.startPage; i <= this.endPage; i += 1) {
        range.push({
          name: i,
          isDisabled: i === this.currentPage,
        });
      }

      return range;
    },
    isInFirstPage() {
      return this.currentPage === 1;
    },
    isInLastPage() {
      return this.currentPage === this.totalPages;
    },
  },
  methods: {
    onClickFirstPage() {
      this.$emit("pagechanged", 1);
    },
    onClickPreviousPage() {
      this.$emit("pagechanged", this.currentPage - 1);
    },
    onClickPage(page) {
      this.$emit("pagechanged", page);
    },
    onClickNextPage() {
      this.$emit("pagechanged", this.currentPage + 1);
    },
    onClickLastPage() {
      this.$emit("pagechanged", this.totalPages);
    },
    isPageActive(page) {
      return this.currentPage === page;
    },
  },
};
</script>
