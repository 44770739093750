<template>
  <overlay-loading v-model:isLoading="isLoading"></overlay-loading>
  <div>
    <!-- Filtri -->
    <div class="row m-0">
      <div
        class="align-items-center container-fluid d-flex flex-row justify-content-end mt-4 p-0"
      >
        <!-- Visualizza -->
        <div v-if="totalRecordsRisorseLibere > 0" class="dropdown me-4">
          <button
            class="btn button-outline-1 small dropdown-toggle"
            type="button"
            id="dropdown_visualizza"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span
              >Visualizza
              <span class="text-black">{{
                columnVisibility["skill"] ? "SKILLS" : "SOCIETÀ"
              }}</span></span
            >
          </button>
          <ul
            class="dropdown-menu dropdown-menu-end"
            aria-labelledby="dropdown_visualizza"
          >
            <li>
              <button
                class="dropdown-item"
                :class="{ active: columnVisibility['skill'] ? true : false }"
                type="button"
                @click="toggleColumnView('skill')"
              >
                SKILLS
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                :class="{ active: columnVisibility['skill'] ? false : true }"
                type="button"
                @click="toggleColumnView('societa')"
              >
                SOCIETÀ
              </button>
            </li>
          </ul>
        </div>
        <!-- Search -->
        <div
          v-if="totalRecordsRisorseLibere > 0"
          class="d-flex input-group"
          style="width: 250px"
        >
          <input
            class="form-control"
            placeholder="CERCA..."
            type="search"
            v-model.trim="model_searchText"
            spellcheck="false"
            autocomplete="off"
            autocapitalize="none"
            @input="handleClear($event)"
            @keydown.enter="submitSearch()"
          />
          <button
            @click.prevent="submitSearch()"
            type="button"
            class="btn button-outline-1 small px-3 m-0"
          >
            <i class="bi bi-search p-0 m-0"></i>
          </button>
        </div>
      </div>
    </div>
    <!-- Content -->
    <div class="container-fluid m-0 p-0">
      <!-- Intestazione -->
      <div class="row m-0 pt-4 pb-1">
        <div class="col-6 p-0 pe-2">
          <div class="bg-gradient-a p-2 text-white">
            RISORSE ASSEGNATE ( {{ localRisorseAssociate.length }} )
          </div>
        </div>
        <div class="col-6 p-0 ps-2">
          <div class="bg-gradient-a p-2 text-white">
            RISORSE LIBERE ( {{ totalRecordsRisorseLibere }} )
          </div>
        </div>
      </div>
      <!-- Riga contenitore 2 tabelle -->
      <div class="row m-0">
        <!-- Colonna Sinistra -->
        <div class="col-6 p-0 pe-2">
          <!-- No data -->
          <div
            v-if="localRisorseAssociate.length == 0 && !isLoading"
            class="align-items-end d-flex flex-row justify-content-start my-3"
          >
            <h6>Nessuna risorsa assegnata da visualizzare</h6>
          </div>
          <div v-if="localRisorseAssociate.length" class="scroll-x-container">
            <!-- Tabella Risorse assegnate -->
            <table class="table-bordered table-responsive-md table-setup">
              <thead class="table-header-font">
                <tr class="td-center">
                  <th>RISORSA</th>
                  <th style="min-width: 125px !important; width: 125px !important">
                    DAL
                  </th>
                  <th style="min-width: 125px !important; width: 125px !important">AL</th>
                  <th style="width: 50px !important">STR.</th>
                  <th style="width: 50px !important">REP.</th>
                  <th style="min-width: 50px !important; width: 50px !important">
                    AZIONI
                  </th>
                </tr>
              </thead>
              <!-- Table Records -->
              <tbody class="table-body-font td-vertical-center">
                <tr
                  v-for="(
                    risorsaAssociata, indexRisorsaAssociata
                  ) in paginatedRisorseAssociate"
                  :key="risorsaAssociata.idrisorsa"
                >
                  <!-- Nome risorsa assegnata -->
                  <td>{{ risorsaAssociata.fullname }}</td>
                  <!-- Dal -->
                  <td class="td-center">
                    {{
                      risorsaAssociata.datainizio
                        ? mixins_getLocalDate(risorsaAssociata.datainizio)
                        : "-"
                    }}
                  </td>
                  <!-- Al -->
                  <td class="td-center">
                    <VueDatePicker
                      v-model="risorsaAssociata.datafine"
                      dark
                      locale="it"
                      format="dd/MM/yyyy"
                      cancelText="Annulla"
                      selectText="Conferma"
                      :enable-time-picker="false"
                      @update:modelValue="onDatafineUpdate"
                      @cleared="onDatafineClear"
                      position="left"
                      :teleport="true"
                      placeholder="AL"
                      :disabled="isAuthenticatedConsultazione"
                    >
                      <template #input-icon>
                        <i class="input-slot-image2 zmdi zmdi-calendar"></i>
                      </template>
                      <template #clear-icon="{ clear }">
                        <i
                          @click="clear"
                          class="input-slot-image zmdi zmdi-close zmdi-hc-lg"
                        ></i>
                      </template>
                    </VueDatePicker>
                  </td>
                  <!-- Straordinario -->
                  <td class="td-center">
                    <div class="checkbox-custom">
                      <input
                        @change="updateParent"
                        type="checkbox"
                        :id="'str_' + risorsaAssociata.idrisorsa"
                        v-model="risorsaAssociata.straordinario"
                        :disabled="isAuthenticatedConsultazione"
                      />
                      <label :for="'str_' + risorsaAssociata.idrisorsa">
                        <div class="tick_mark"></div>
                      </label>
                    </div>
                  </td>
                  <!-- Reperibilità -->
                  <td class="td-center">
                    <div class="checkbox-custom">
                      <input
                        @change="updateParent"
                        type="checkbox"
                        :id="'rep_' + risorsaAssociata.idrisorsa"
                        v-model="risorsaAssociata.reperibilita"
                        :disabled="isAuthenticatedConsultazione"
                      />
                      <label :for="'rep_' + risorsaAssociata.idrisorsa">
                        <div class="tick_mark"></div>
                      </label>
                    </div>
                  </td>
                  <!-- Azioni -->
                  <td class="td-actions td-center">
                    <button
                      alt="RIMUOVI"
                      title="RIMUOVI"
                      @click="removeResource(indexRisorsaAssociata)"
                      :disabled="isAuthenticatedConsultazione"
                    >
                      <i class="zmdi zmdi-delete zmdi-hc-lg"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- Pagination tabella risorse assegnate -->
          <div v-if="localRisorseAssociate.length && totalPagesRisorseAssociate > 1">
            <div class="d-flex justify-content-end small mt-3">
              Pagina {{ pageRisorseAssociate }} / {{ totalPagesRisorseAssociate }}
            </div>
            <nav>
              <ul class="mt-3 pagination pagination-sm justify-content-end">
                <li class="page-item" :class="{ disabled: pageRisorseAssociate === 1 }">
                  <a
                    class="page-link"
                    href="#"
                    tabindex="-1"
                    @click.prevent="prevPageRisorseAssociate"
                    >Precedente</a
                  >
                </li>
                <li class="page-item" v-if="getVisiblePagesRisorseAssociate()[0] > 1">
                  <a
                    class="page-link"
                    href="#"
                    @click.prevent="goToPageRisorseAssociate(1)"
                    >1</a
                  >
                </li>
                <li class="page-item" v-if="getVisiblePagesRisorseAssociate()[0] > 2">
                  <span class="page-link">...</span>
                </li>

                <li
                  v-for="page in getVisiblePagesRisorseAssociate()"
                  :key="page"
                  class="page-item"
                  :class="{ active: page === pageRisorseAssociate }"
                >
                  <a
                    class="page-link"
                    href="#"
                    @click.prevent="goToPageRisorseAssociate(page)"
                    >{{ page }}</a
                  >
                </li>

                <li
                  class="page-item"
                  v-if="
                    getVisiblePagesRisorseAssociate().slice(-1)[0] <
                    totalPagesRisorseAssociate - 1
                  "
                >
                  <span class="page-link">...</span>
                </li>
                <li
                  class="page-item"
                  v-if="
                    getVisiblePagesRisorseAssociate().slice(-1)[0] <
                    totalPagesRisorseAssociate
                  "
                >
                  <a
                    class="page-link"
                    href="#"
                    @click.prevent="goToPageRisorseAssociate(totalPagesRisorseAssociate)"
                    >{{ totalPagesRisorseAssociate }}</a
                  >
                </li>

                <li
                  class="page-item"
                  :class="{
                    disabled: pageRisorseAssociate === totalPagesRisorseAssociate,
                  }"
                >
                  <a class="page-link" href="#" @click.prevent="nextPageRisorseAssociate"
                    >Successiva</a
                  >
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <!-- Colonna destra -->
        <div class="col-6 p-0 ps-2">
          <!-- No data -->
          <div
            v-if="localRisorseLibere.length == 0 && !isLoading"
            class="align-items-end d-flex flex-row justify-content-start my-3"
          >
            <h6>Nessuna risorsa libera da visualizzare</h6>
            <button
              v-if="totalRecordsRisorseLibere == 0 && model_searchText != ''"
              type="button"
              class="btn button-outline-1 x-small ms-2"
              @click.prevent="clearSearchResult()"
            >
              <i class="bi bi-x-circle-fill"></i>Cancella ricerca
            </button>
          </div>
          <div v-if="localRisorseLibere.length" class="scroll-x-container">
            <!-- Tabella Risorse libere -->
            <table class="table-bordered table-responsive-md table-setup">
              <thead class="table-header-font">
                <tr class="td-center">
                  <th>RISORSA</th>
                  <th
                    v-if="columnVisibility.societa"
                    style="min-width: 150px !important; width: 200px !important"
                  >
                    SOCIETÀ
                  </th>
                  <th
                    v-if="columnVisibility.skill"
                    style="min-width: 150px !important; width: 200px !important"
                  >
                    SKILLS
                  </th>
                  <th style="min-width: 125px !important; width: 125px !important">
                    COSTO MEDIO
                  </th>
                  <th style="min-width: 125px !important; width: 125px !important">
                    DAL
                  </th>
                  <th style="min-width: 50px !important; width: 50px !important">
                    AZIONI
                  </th>
                </tr>
              </thead>
              <!-- Table Records -->
              <tbody class="table-body-font td-vertical-center">
                <tr
                  v-for="(risorsaLibera, indexRisorsaLibera) in localRisorseLibere"
                  :key="risorsaLibera.idrisorsa"
                >
                  <!-- Nome risorsa libera -->
                  <td>
                    <div
                      class="align-items-center d-flex flex-row justify-content-between"
                    >
                      <span>{{ risorsaLibera.fullname }}</span>
                      <div
                        @click="
                          routeUserTeam(risorsaLibera.fullname, risorsaLibera.idrisorsa)
                        "
                        alt="APERTURA DETTAGLIO IN UN NUOVO TAB"
                        title="APERTURA DETTAGLIO IN UN NUOVO TAB"
                      >
                        <i class="bi bi-info-circle-fill ms-1 fs-5 isClickable"></i>
                      </div>
                    </div>
                  </td>
                  <!-- Società -->
                  <td v-if="columnVisibility.societa">
                    {{ risorsaLibera.societa }}
                  </td>
                  <!-- Skill -->
                  <td v-if="columnVisibility.skill" class="td-actions td-center">
                    <div
                      v-if="risorsaLibera.skills.length"
                      class="position-relative d-inline-block"
                    >
                      <button
                        @click="toggleDiv(indexRisorsaLibera)"
                        type="button"
                        class="toggle-skill-button"
                        alt="VISUALIZZA SKILLS"
                        title="VISUALIZZA SKILLS"
                      >
                        <i class="zmdi zmdi-eye zmdi-hc-lg"></i>
                      </button>
                      <div
                        v-if="visibleSkills[indexRisorsaLibera]"
                        class="toggle-skill-div"
                      >
                        <div class="toggle-skill-container">
                          <ul class="list-tags">
                            <li
                              v-for="(skill, index) in risorsaLibera.skills"
                              :key="index"
                              style="--type-display: flex; --justify: start"
                            >
                              <div class="mb-3">
                                <div
                                  class="truncate-ch ch-35 badge-colored orange"
                                  :title="skill.tiposkill"
                                  :alt="skill.tiposkill"
                                >
                                  {{ skill.tiposkill }}
                                </div>
                                <div class="small">Livello: {{ skill.livello }}</div>
                              </div>
                              <hr />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </td>
                  <!-- Costo medio -->
                  <td class="td-right">-</td>
                  <!-- Dal -->
                  <td class="td-center">
                    <VueDatePicker
                      v-model="risorsaLibera.datainizio"
                      dark
                      locale="it"
                      format="dd/MM/yyyy"
                      cancelText="Annulla"
                      selectText="Conferma"
                      :enable-time-picker="false"
                      @update:modelValue="onDatainizioUpdate"
                      @cleared="onDatainizioClear"
                      position="left"
                      :teleport="true"
                      placeholder="DAL"
                      :disabled="isAuthenticatedConsultazione"
                    >
                      <template #input-icon>
                        <i class="input-slot-image2 zmdi zmdi-calendar"></i>
                      </template>
                      <template #clear-icon="{ clear }">
                        <i
                          @click="clear"
                          class="input-slot-image zmdi zmdi-close zmdi-hc-lg"
                        ></i>
                      </template>
                    </VueDatePicker>
                  </td>
                  <!-- Azioni -->
                  <td class="td-actions td-center">
                    <button
                      alt="AGGIUNGI"
                      title="AGGIUNGI"
                      @click="addResource(risorsaLibera)"
                      :disabled="
                        risorsaLibera.datainizio == null || isAuthenticatedConsultazione
                      "
                    >
                      <i class="zmdi zmdi-plus zmdi-hc-lg"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Pagination tabella risorse libere -->
          <div v-if="localRisorseLibere.length && totalPagesRisorseLibere > 1">
            <div class="d-flex justify-content-end small mt-3">
              Pagina {{ pageRisorseLibere }} / {{ totalPagesRisorseLibere }}
            </div>
            <nav>
              <ul class="mt-3 pagination pagination-sm justify-content-end">
                <li class="page-item" :class="{ disabled: pageRisorseLibere === 1 }">
                  <a
                    class="page-link"
                    href="#"
                    tabindex="-1"
                    @click.prevent="prevPageRisorseLibere"
                    >Precedente</a
                  >
                </li>

                <li class="page-item" v-if="getVisiblePagesRisorseLibere()[0] > 1">
                  <a class="page-link" href="#" @click.prevent="goToPageRisorseLibere(1)"
                    >1</a
                  >
                </li>
                <li class="page-item" v-if="getVisiblePagesRisorseLibere()[0] > 2">
                  <span class="page-link">...</span>
                </li>

                <li
                  v-for="page in getVisiblePagesRisorseLibere()"
                  :key="page"
                  class="page-item"
                  :class="{ active: page === pageRisorseLibere }"
                >
                  <a
                    class="page-link"
                    href="#"
                    @click.prevent="goToPageRisorseLibere(page)"
                    >{{ page }}</a
                  >
                </li>

                <li
                  class="page-item"
                  v-if="
                    getVisiblePagesRisorseLibere().slice(-1)[0] <
                    totalPagesRisorseLibere - 1
                  "
                >
                  <span class="page-link">...</span>
                </li>
                <li
                  class="page-item"
                  v-if="
                    getVisiblePagesRisorseLibere().slice(-1)[0] < totalPagesRisorseLibere
                  "
                >
                  <a
                    class="page-link"
                    href="#"
                    @click.prevent="goToPageRisorseLibere(totalPagesRisorseLibere)"
                    >{{ totalPagesRisorseLibere }}</a
                  >
                </li>

                <li
                  class="page-item"
                  :class="{ disabled: pageRisorseLibere === totalPagesRisorseLibere }"
                >
                  <a class="page-link" href="#" @click.prevent="nextPageRisorseLibere"
                    >Successiva</a
                  >
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import OverlayLoading from "@/components/ui/OverlayLoading.vue";
import { UtilityMixins } from "@/mixins/UtilityMixins.js";
import { fetchCommessaById, fetchTeam, fetchCommessaRisorseLibere } from "@/services/api";
import { Commessa, CommessaSchema, RisorsaAssociata } from "@/models/CommessaJoiModel";
import { formatISO } from "date-fns";
import ProfiliPermissions from "@/mixins/ProfiliPermissions.js";

export default {
  mixins: [UtilityMixins, ProfiliPermissions],
  components: {
    OverlayLoading,
  },
  props: {
    isEditMode: {
      type: Boolean,
      default: false,
    },
    commessaDataRisorse: {
      type: Object,
      required: true,
    },
  },
  emits: [
    "section-modified",
    "section-verified",
    "fetch-data",
    "data-updated",
    "update:commessaDataRisorse",
  ],
  data() {
    return {
      isLoading: false, // Gestione del loader per indicare il caricamento
      localCommessa: new Commessa(), // Creo un oggetto per gestire i dati locali della commessa
      initialCommessa: null, // Memorizzo lo stato iniziale per il confronto
      localRisorseAssociate: [], // Creo un array per gestire i dati locali della risorsa associata
      initialRisorseAssociate: null, // Memorizzo lo stato iniziale per il confronto
      localRisorseLibere: [], // Array delle risorse libere
      columnVisibility: {
        skill: true,
        societa: false,
      },
      visibleSkills: {},
      // pagination risorse libere
      pageRisorseLibere: 1,
      limitRisorseLibere: 10,
      totalPagesRisorseLibere: 0,
      totalRecordsRisorseLibere: 0,
      // pagination risorse associate
      pageRisorseAssociate: 1,
      limitRisorseAssociate: 10,
      totalPagesRisorseAssociate: 0,
      totalRecordsRisorseAssociate: 0,
      // search
      model_searchText: "",
      userAuthenticated: null,
    };
  },
  computed: {
    // Computed property per ottenere solo le risorse associate della pagina corrente
    paginatedRisorseAssociate() {
      const start = (this.pageRisorseAssociate - 1) * this.limitRisorseAssociate;
      const end = start + this.limitRisorseAssociate;
      return this.localRisorseAssociate.slice(start, end);
    },
    //
    // Authenticated
    //
    isAuthenticatedSuperAdmin() {
      return this.permissions_isAuthenticatedSuperAdmin(this.userAuthenticated);
    },
    isAuthenticatedGestionePersonale() {
      return this.permissions_isAuthenticatedGestionePersonale(this.userAuthenticated);
    },
    isAuthenticatedConsultazione() {
      return this.permissions_isAuthenticatedConsultazione(this.userAuthenticated);
    },
  },
  watch: {},
  methods: {
    //
    // Search
    //
    handleClear(event) {
      if (!event.target.value.length) {
        console.log("** clearSearchResult");
        this.clearSearchResult();
      }
    },
    async clearSearchResult() {
      this.model_searchText = "";
      this.pageRisorseLibere = 1;
      this.totalPagesRisorseLibere = 0;
      this.totalRecordsRisorseLibere = 0;
      await this.loadRisorseLibere();
    },
    async submitSearch() {
      // rimuovo i caratteri *^? insieme ai tag HTML/XML per le query
      this.model_searchText = this.mixins_cleanTextForQuery(this.model_searchText);
      if (this.model_searchText.length < 2) {
        this.mixins_showMessage("Ricerca", "Digitare minimo 2 caratteri", "info");
        return;
      }
      this.pageRisorseLibere = 1;
      await this.loadRisorseLibere();
    },
    //
    // ** Fetch Data **
    //
    async fetchData() {
      if (!this.isEditMode) return; // Non faccio nulla se non sono in modalità modifica

      // Mostro il loader subito dopo il montaggio del componente
      this.$nextTick(() => {
        this.isLoading = true;
      });
      try {
        const response = await fetchCommessaById(this.$route.params.id);
        if (response) {
          // Se l'oggetto mandato dal server è vuoto,  non visualizzo la scheda
          if (this.mixins_isEmptyObject(response)) {
            this.$parent.errorMessage = "L'elemento non esiste";
            return;
          }
          console.warn("** response: ", response);
          // Memorizzo l'intera commessa
          this.localCommessa = new Commessa(response);
          this.initialCommessa = JSON.parse(JSON.stringify(this.localCommessa));
          console.log("** dati localCommessa", this.localCommessa);
          console.log("** dati initialCommessa", this.initialCommessa);

          // Memorizzo l'array di risorse associate utilizzando il costruttore RisorsaAssociata
          if (response.risorseassociate && Array.isArray(response.risorseassociate)) {
            this.localRisorseAssociate = response.risorseassociate.map(
              (risorsa) => new RisorsaAssociata(risorsa)
            );
          } else {
            this.localRisorseAssociate = [];
          }

          this.initialRisorseAssociate = JSON.parse(
            JSON.stringify(this.localRisorseAssociate)
          );
          console.log("** dati localRisorseAssociate", this.localRisorseAssociate);
          console.log("** dati initialRisorseAssociate", this.initialRisorseAssociate);

          // // --- PER AGGIUNGERE RISORSE ASSOCIATE ---
          // // Aggiungo la nuova risorsa all'array this.localRisorseAssociate
          // this.localRisorseAssociate.push(nuovaRisorsa);
          // // Ora aggiorno il campo risorseassociate di localCommessa
          // this.localCommessa.risorseassociate = this.localRisorseAssociate;

          // Emetto eventi per aggiornare il v-model e segnalare il fetch completato
          this.$emit("update:commessaDataRisorse", this.localCommessa);
          this.$emit("fetch-data", "risorse");
          this.$parent.errorMessage = "";
        } else {
          this.$parent.errorMessage = "L'elemento non esiste";
        }
      } catch (error) {
        // console.error("Errore durante il caricamento dei dati", error);
        this.$parent.errorMessage = "Errore durante il caricamento dei dati";
      } finally {
        // Nascondo il loader al termine del caricamento
        this.$nextTick(() => {
          this.isLoading = false;
        });
      }
    },
    async fetchDataAndUpdateFields() {
      if (this.isEditMode) {
        // Inizializzo gli array
        this.localRisorseAssociate = [];
        this.localRisorseLibere = [];
        // Inizializzo la paginazione
        this.pageRisorseLibere = 1;
        this.pageRisorseAssociate = 1;
        // Inizializzo i totali
        this.totalRecordsRisorseLibere = 0;
        this.totalRecordsRisorseAssociate = 0;

        // Eseguo il fetch dei dati della commessa
        await this.fetchData();

        this.updatePaginationRisorseAssociate();

        this.$nextTick(() => {
          this.isLoading = true;
        });

        await this.loadRisorseLibere();

        // // Sincronizzo i modelli locali
        // // await this.updateLocalModels();

        this.$nextTick(() => {
          this.isLoading = false;
        });
      }
    },
    // Metodo per caricare i dati in base alle risorse associate
    async loadRisorseLibere() {
      // Se l'array localRisorseAssociate non è vuoto allora vuol dire che sono state già assegnate delle risorse
      // In questo caso devo richiamare il metodo che restituisce le risorse libere
      // Altrimenti, richiamare il metodo che restituisce il team
      if (this.localRisorseAssociate.length) {
        // Esistono risorse associate, richiamo il metodo fetchRisorseLibereData()
        await this.fetchRisorseLibereData();
      } else {
        // Non esistono risorse associate, richiamo fetchTeamsData()
        await this.fetchTeamsData();
      }
    },
    async fetchTeamsData() {
      this.$nextTick(() => {
        this.isLoading = true;
      });

      this.error = null;
      this.totalRecordsRisorseLibere = 0;
      const sortType = 2;
      try {
        const response = await fetchTeam({
          page: this.pageRisorseLibere,
          limit: this.limitRisorseLibere,
          sortType: sortType,
          textsearch: this.model_searchText,
          filtersIds: { cessati: "N" },
        });
        // Mappatura dei dati dal response in localRisorseLibere
        this.localRisorseLibere = response.users.map((user) => ({
          idrisorsa: user.providerUserId,
          fullname: user.metadata?.fullname ?? "",
          skills: user.metadata?.skills ?? [],
          societa: user.metadata.rapporto.societa?.descrizione ?? "",
          costomedio: 0, // Valore fisso
          datainizio: null, // Valore fisso
        }));
        console.log("** localRisorseLibere", this.localRisorseLibere);

        this.totalPagesRisorseLibere = Math.ceil(
          response.totalCount / this.limitRisorseLibere
        );
        this.totalRecordsRisorseLibere = response.totalCount;
      } catch (err) {
        console.error("** fetchTeamsData: si è verificato un errore", err);
      } finally {
        this.$nextTick(() => {
          this.isLoading = false;
        });
      }
    },
    async fetchRisorseLibereData() {
      this.$nextTick(() => {
        this.isLoading = true;
      });

      this.error = null;
      this.totalRecordsRisorseLibere = 0;
      const idResources = this.getIdRisorseString(this.localRisorseAssociate);

      const sortType = 2;

      try {
        const response = await fetchCommessaRisorseLibere(
          this.pageRisorseLibere,
          this.limitRisorseLibere,
          idResources,
          sortType,
          this.model_searchText
        );

        // Mappatura dei dati dal response in localRisorseLibere
        this.localRisorseLibere = response.users.map((user) => ({
          idrisorsa: user.providerUserId,
          fullname: user.fullname,
          skills: user.skills,
          societa: user.societa.societa?.descrizione ?? "",
          costomedio: 0, // Valore fisso
          datainizio: null, // Valore fisso
        }));
        console.log("** localRisorseLibere", this.localRisorseLibere);

        this.totalPagesRisorseLibere = Math.ceil(
          response.totalCount / this.limitRisorseLibere
        );
        this.totalRecordsRisorseLibere = response.totalCount;
      } catch (err) {
        console.error("** fetchTeamsData: si è verificato un errore", err);
      } finally {
        this.$nextTick(() => {
          this.isLoading = false;
        });
      }
    },
    //
    // Gestione paginazioni
    //
    //
    // Paginazione risorse libere
    //
    prevPageRisorseLibere() {
      if (this.pageRisorseLibere > 1) {
        this.pageRisorseLibere--;
        this.loadRisorseLibere();
      }
    },
    nextPageRisorseLibere() {
      if (this.pageRisorseLibere < this.totalPagesRisorseLibere) {
        this.pageRisorseLibere++;
        this.loadRisorseLibere();
      }
    },
    goToPageRisorseLibere(page) {
      if (page >= 1 && page <= this.totalPagesRisorseLibere) {
        this.pageRisorseLibere = page;
        this.loadRisorseLibere();
      }
    },
    getVisiblePagesRisorseLibere() {
      const totalPages = this.totalPagesRisorseLibere;
      const currentPage = this.pageRisorseLibere;
      const maxPagesToShow = 6;
      let startPage, endPage;

      if (totalPages <= maxPagesToShow) {
        // Mostro tutte le pagine se sono 6 o meno
        startPage = 1;
        endPage = totalPages;
      } else {
        if (currentPage <= 3) {
          // Mostro le prime 6 pagine
          startPage = 1;
          endPage = maxPagesToShow;
        } else if (currentPage + 2 >= totalPages) {
          // Mostro le ultime 6 pagine
          startPage = totalPages - maxPagesToShow + 1;
          endPage = totalPages;
        } else {
          // Mostro la pagina corrente + le 2 precedenti e le 2 successive
          startPage = currentPage - 2;
          endPage = currentPage + 3;
        }
      }
      return [...Array(endPage + 1 - startPage).keys()].map((i) => startPage + i);
    },
    //
    // Paginazione risorse associate
    //
    // Metodo per aggiornare il numero totale di pagine e record
    updatePaginationRisorseAssociate() {
      this.totalRecordsRisorseAssociate = this.localRisorseAssociate.length;
      this.totalPagesRisorseAssociate = Math.ceil(
        this.totalRecordsRisorseAssociate / this.limitRisorseAssociate
      );
    },
    // Metodo per andare alla pagina precedente
    prevPageRisorseAssociate() {
      if (this.pageRisorseAssociate > 1) {
        this.pageRisorseAssociate--;
      }
    },
    // Metodo per andare alla pagina successiva
    nextPageRisorseAssociate() {
      if (this.pageRisorseAssociate < this.totalPagesRisorseAssociate) {
        this.pageRisorseAssociate++;
      }
    },
    // Metodo per andare a una pagina specifica
    goToPageRisorseAssociate(page) {
      if (page >= 1 && page <= this.totalPagesRisorseAssociate) {
        this.pageRisorseAssociate = page;
      }
    },
    // Metodo per calcolare le pagine visibili
    getVisiblePagesRisorseAssociate() {
      const totalPages = this.totalPagesRisorseAssociate;
      const currentPage = this.pageRisorseAssociate;
      const maxPagesToShow = 6;
      let startPage, endPage;

      if (totalPages <= maxPagesToShow) {
        startPage = 1;
        endPage = totalPages;
      } else {
        if (currentPage <= 3) {
          startPage = 1;
          endPage = maxPagesToShow;
        } else if (currentPage + 2 >= totalPages) {
          startPage = totalPages - maxPagesToShow + 1;
          endPage = totalPages;
        } else {
          startPage = currentPage - 2;
          endPage = currentPage + 3;
        }
      }
      return [...Array(endPage + 1 - startPage).keys()].map((i) => startPage + i);
    },
    //
    // ** Gestione delle date **
    //
    onDatainizioUpdate(newDate) {
      this.localRisorseLibere.datainizio = newDate ? formatISO(newDate) : null;
    },
    onDatainizioClear() {
      this.localRisorseLibere.datainizio = null;
    },
    onDatafineUpdate(newDate) {
      this.localRisorseLibere.datafine = newDate ? formatISO(newDate) : null;
      this.updateParent();
    },
    onDatafineClear() {
      this.localRisorseLibere.datafine = null;
      this.updateParent();
    },
    //
    // Gestione aggiornamenti e validazioni
    //
    updateParent() {
      // Creo copia dello stato corrente PRIMA dell'aggiornamento
      const statoPrec = JSON.parse(JSON.stringify(this.localRisorseAssociate));

      // Creo copia per il confronto generale PRIMA dell'aggiornamento
      const previousState = JSON.stringify(this.localRisorseAssociate);
      const initialState = JSON.stringify(this.initialRisorseAssociate);

      // Aggiorno localCommessa
      this.localCommessa.risorseassociate = this.localRisorseAssociate;

      // Verifico modifiche nei checkbox
      const modificheCheckbox = this.localRisorseAssociate.some((risorsa, index) => {
        const precedente = statoPrec[index];
        return (
          precedente &&
          (risorsa.straordinario !== precedente.straordinario ||
            risorsa.reperibilita !== precedente.reperibilita)
        );
      });

      // Confronto con stato precedente invece che quello corrente
      if (previousState !== initialState || modificheCheckbox) {
        this.markAsModified("risorse");

        if (this.validateImmediate()) {
          this.$emit("update:commessaDataRisorse", this.localCommessa);
          this.$emit("data-updated", this.localCommessa);
        } else {
          console.error("Errore nella validazione dei dati.");
        }
      }
    },
    markAsModified(section) {
      // Segnalo che una specifica sezione è stata modificata
      this.$emit("section-modified", section);
    },
    markAsVerified(section, value) {
      // Segnalo che una specifica sezione è stata verificata con successo o meno
      this.$emit("section-verified", section, value);
    },
    validateImmediate() {
      const result = CommessaSchema.validate(this.localCommessa, {
        abortEarly: false,
      });
      if (result.error) {
        const errObj = result.error.details.reduce((acc, curr) => {
          acc[curr.path[0]] = curr.message;
          console.error("** errore validazione", errObj);
        }, {});
        this.markAsVerified("risorse", false);
        return false;
      } else {
        this.markAsVerified("risorse", true);
        return true;
      }
    },
    //
    // Altri metodi
    //
    toggleColumnView(option) {
      for (let key in this.columnVisibility) {
        this.columnVisibility[key] = false;
      }
      this.columnVisibility[option] = true;
    },
    toggleDiv(index) {
      this.visibleSkills[index] = !this.visibleSkills[index];
    },
    getIdRisorseString(resources) {
      return resources.map((resource) => resource.idrisorsa).join(",");
    },
    routeUserTeam(userFullName, id) {
      if (id) {
        // Apro la pagina di dettaglio del team in un nuovo tab del browser
        const routeData = this.$router.resolve({
          name: "route-teams-edit",
          params: { id: id },
        });
        window.open(routeData.href, "_blank");
      } else {
        this.mixins_showMessage("Errore", `ID mancante per '${userFullName}'`, "error");
      }
    },
    async addResource(risorsaLibera) {
      // Creo una nuova istanza di RisorsaAssociata con i dati provenienti da risorsaLibera
      const nuovaRisorsa = new RisorsaAssociata({
        idrisorsa: risorsaLibera.idrisorsa,
        fullname: risorsaLibera.fullname,
        datainizio: formatISO(risorsaLibera.datainizio),
        datafine: null,
        straordinario: false,
        reperibilita: false,
      });

      // Aggiungo la nuova risorsa all'array localRisorseAssociate
      this.localRisorseAssociate.push(nuovaRisorsa);

      // Aggiorno la paginazione dopo l'aggiunta
      this.updatePaginationRisorseAssociate();

      // Aggiorno le risorse libere
      this.localRisorseLibere = [];
      this.pageRisorseLibere = 1;
      this.totalRecordsRisorseLibere = 0;
      await this.loadRisorseLibere();

      this.updateParent();
    },
    async removeResource(index) {
      // Rimuovo la risorsa dall'array localRisorseAssociate
      this.localRisorseAssociate.splice(index, 1);

      // Aggiorno la paginazione dopo la rimozione
      this.updatePaginationRisorseAssociate();

      // Aggiorno le risorse libere
      this.localRisorseLibere = [];
      this.pageRisorseLibere = 1;
      this.totalRecordsRisorseLibere = 0;
      await this.loadRisorseLibere();

      this.updateParent();
    },
  },
  mounted() {
    // Oggetto user da local storage
    this.userAuthenticated = this.mixin_getUserDataLS();
  },
};
</script>

<style scoped>
.page-item {
  color: var(--main-blue) !important;
  background-color: var(--main-white) !important;
}
.page-item.disabled .page-link {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.6;
}
.page-item.active .page-link,
.page-item .page-link:hover {
  z-index: 3;
  color: white !important;
  background-color: var(--main-blue);
  border-color: var(--main-blue);
}
</style>
