<template>
  <transition name="backdrop-modal">
    <div v-if="isVisible" class="backdrop-dialog"></div>
  </transition>

  <transition name="modal">
    <div class="dialog background-gray" :style="`width: ${dialogWidth}`" v-if="isVisible">
      <div class="container-fluid">
        <div class="row">
          <div class="header-text w-100">
            <h1>{{ dialogTitle }}</h1>
          </div>
        </div>
        <hr class="sepGray" />
        <div class="container-fluid p-0 m-0">
          <div class="container-fluid px-0 py-3">
            <!-- Festività -->
            <div class="row mb-3">
              <label
                class="col-form-label form-label col-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
                ><span>FESTIVITÀ</span></label
              >
              <div class="align-items-center col d-flex justify-content-center">
                <input
                  type="text"
                  v-input-textarea
                  class="form-control"
                  v-model.trim="localData.descrizione"
                  spellcheck="false"
                  autocomplete="off"
                  autocapitalize="none"
                  placeholder="FESTIVITÀ"
                  @keydown.enter.prevent
                />
              </div>
            </div>
            <!-- Data -->
            <div class="row mb-3">
              <label
                class="col-form-label form-label col-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
                ><span>DATA</span></label
              >
              <div class="align-items-center col d-flex justify-content-center">
                <VueDatePicker
                  v-model="localData.data"
                  dark
                  locale="it"
                  format="dd/MM/yyyy"
                  cancelText="Annulla"
                  selectText="Conferma"
                  :enable-time-picker="false"
                  @update:modelValue="onDataUpdate"
                  @cleared="onDataClear"
                  placeholder="DATA FESTIVITÀ"
                  :teleport="true"
                  style="font-size: 18px !important"
                >
                  <template #input-icon>
                    <i class="input-slot-image2 zmdi zmdi-calendar"></i>
                  </template>
                  <template #clear-icon="{ clear }">
                    <i
                      @click="clear"
                      class="input-slot-image zmdi zmdi-close zmdi-hc-lg"
                    ></i>
                  </template>
                </VueDatePicker>
                <button
                  class="timepicker-button"
                  alt="OGGI"
                  title="OGGI"
                  @click="setToday('data')"
                >
                  <i class="zmdi zmdi-calendar zmdi-hc-lg"></i>
                </button>
              </div>
            </div>
            <!-- Locale -->
            <div class="row mb-3">
              <label
                class="col-form-label form-label col-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
                ><span>LOCALE</span></label
              >
              <div class="align-items-center col d-flex justify-content-start">
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="localData.locale"
                    @change="toggleLocale"
                  />
                  <label class="content-form text-end label-yes-no">
                    {{ localData.locale === true ? "SI" : "NO" }}
                  </label>
                </div>
              </div>
            </div>
            <!-- Città (Comune) -->
            <div class="row mb-3">
              <label
                class="col-form-label form-label col-2 d-flex align-items-center justify-content-lg-end text-lg-end px-0 ps-3 ps-lg-0"
                ><span>COMUNE</span></label
              >
              <div class="align-items-center col d-flex justify-content-center">
                <MultiSelect
                  ref="comuniRef"
                  v-model="localData.citta"
                  :options="comuniOptions"
                  :searchable="true"
                  placeholder="- SELEZIONA -"
                  label="label"
                  track-by="value"
                  :show-no-results="true"
                  selectLabel="Seleziona"
                  deselectLabel="Rimuovi"
                  selectedLabel="Selezionato"
                  :maxHeight="310"
                  :multiple="false"
                  :taggable="false"
                  :showLabels="false"
                  @open="onOpenMultiselect"
                  @close="onCloseMultiselect"
                  @blur="onOpenMultiselect"
                  @search-change="(query) => scrollToFirstMatch(query, 'comuniRef')"
                  :optionsLimit="8000"
                  :disabled="!localData.locale"
                >
                  <template #noOptions>
                    <span>L'elenco è vuoto</span>
                  </template>
                  <template #noResult>
                    <span>Nessun elemento trovato</span>
                  </template>
                </MultiSelect>
              </div>
            </div>
          </div>
        </div>
        <hr class="sepGray" />
        <div class="row">
          <div class="container-fluid my-3">
            <button
              @click="closeModal"
              type="button"
              class="btn button-outline-1 float-start"
            >
              <span><i class="zmdi zmdi-close"></i>Annulla</span>
            </button>
            <button
              @click="saveData"
              type="button"
              class="btn button-outline-1 float-end"
              :disabled="isSaveDisabled"
            >
              <span
                ><i class="zmdi zmdi-floppy"></i
                >{{ isEditMode ? "Modifica festività" : "Salva festività" }}</span
              >
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { UtilityMixins } from "@/mixins/UtilityMixins.js";
import { format } from "date-fns";

export default {
  mixins: [UtilityMixins],
  components: {},
  props: {
    isVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
    dialogWidth: {
      type: String,
      default: "1200px",
    },
    dialogTitle: {
      type: String,
      default: "",
    },
    dataItem: {
      type: Object,
      default: () => ({}),
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
    selectedYear: {
      type: Number,
      required: true,
    },
  },
  emits: ["closeModal", "saveData"],
  data() {
    return {
      localData: {},
      originalData: null,
      provinceComuni: [],
      comuniOptions: [],
      selectedOption: null,
    };
  },
  computed: {
    isSaveDisabled() {
      // Controllo base: se localData non è definito
      if (!this.localData) return true;

      // Controllo descrizione (obbligatoria)
      if (!this.localData.descrizione) return true;

      // Controllo data (obbligatoria)
      if (!this.localData.data) return true;

      // Se locale è true, controllo che il comune sia selezionato
      if (this.localData.locale === true && !this.localData.citta) return true;

      // Confronto selettivo dei campi specifici
      const hasChanges =
        this.localData.descrizione !== this.originalData.descrizione ||
        this.localData.data !== this.originalData.data ||
        this.localData.locale !== this.originalData.locale ||
        (this.localData.locale &&
          (!this.originalData.citta ||
            this.localData.citta?.value !== this.originalData.citta));

      // Abilita il bottone se ci sono modifiche
      return !hasChanges;
    },
  },
  watch: {
    // Quando il modal diventa visibile, inizializzo i dati locali e le selezioni
    isVisible(newVal) {
      if (newVal) {
        // Inizializzo `localData`
        this.localData = { ...this.dataItem };
        this.originalData = { ...this.dataItem };
        console.log("** watch localData", this.localData);

        // Se `localData.data` non è impostato, usa l'anno selezionato
        if (!this.localData.data) {
          const defaultDate = new Date();
          defaultDate.setFullYear(this.selectedYear);
          defaultDate.setMonth(0); // Gennaio
          defaultDate.setDate(1); // Primo giorno dell'anno
          defaultDate.setHours(0, 0, 0, 0); // Mezzanotte
          this.localData.data = defaultDate;
        }

        // Inizializzo la selezione della provincia solo se esiste un valore in `localData.prov`
        if (this.localData.citta) {
          this.localData.citta = this.findOption(
            this.localData.citta,
            this.comuniOptions
          );
        }
      }
    },
  },
  methods: {
    closeModal() {
      this.localData = null;
      this.$emit("closeModal");
    },
    async saveData() {
      this.$emit("saveData", this.localData);
    },
    toggleLocale(event) {
      console.log("** toggle locale", event.target.checked);

      if (!event.target.checked) {
        // Se il checkbox è deselezionato, resetta i valori
        this.localData.citta = null; // Resetta il valore della MultiSelect
        this.selectedOption = null; // Resetta l'opzione selezionata
        console.log(
          "** multiselect comuni: valori resettati poiché locale è disabilitato"
        );
      } else {
        console.log("** multiselect comuni: locale abilitato, multiselect attiva");
      }
    },
    //
    // Gestion multiselect dei comuni
    //
    onOpenMultiselect() {
      const dialog = document.querySelector(".dialog");
      if (dialog) {
        dialog.classList.add("no-overflow");
      }

      // Controlla se localData.citta è valida prima di accedere alle sue proprietà
      if (
        this.localData.citta &&
        this.localData.citta.value &&
        this.localData.citta.label
      ) {
        this.selectedOption = {
          value: this.localData.citta.value,
          label: this.localData.citta.label,
        };
        this.scrollToSelected("comuniRef");
      } else {
        console.log(
          "** multiselect comuni: nessun elemento selezionato o dati non validi"
        );
        this.selectedOption = null; // Resetta selectedOption se non valida
      }
    },
    onCloseMultiselect() {
      const dialog = document.querySelector(".dialog");
      if (dialog) {
        dialog.classList.remove("no-overflow");
      }
    },
    findOption(value, options) {
      // Trovo l'opzione corrispondente in `options`, o converto `value` in un oggetto `{ value, label }`
      const option = options.find((option) => option.value === value);
      if (!option) {
        console.log(`** multiselect comuni: opzione non trovata per il valore: ${value}`);
      }
      return option || this.convertToObject(value);
    },

    convertToObject(value) {
      // Converto un valore stringa in un oggetto `{ value, label }`
      return value ? { value, label: value } : null;
    },
    scrollToSelected(idRefs) {
      this.$nextTick(() => {
        if (!this.selectedOption || !this.selectedOption.label) {
          console.log(
            "** multiselect comuni: opzione selezionata non valida:",
            this.selectedOption
          );
          return;
        }

        const multiselectEl = this.$refs[idRefs].$el;
        const selectedEl = Array.from(
          multiselectEl.querySelectorAll(".multiselect__option")
        ).find((el) => el.textContent.trim() === this.selectedOption.label);

        if (selectedEl) {
          // Usare block: "nearest" per scrollare e posizionarlo all'ultimo elemento della finestra options
          // selectedEl.scrollIntoView({ behavior: "smooth", block: "nearest" });

          // Usare block: "start" per posizionarlo al primo elemento
          selectedEl.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      });
    },
    scrollToFirstMatch(searchQuery, idRefs) {
      if (!searchQuery) {
        console.log("** multiselect comuni: ricerca vuota, nessun elemento da scorrere.");
        return;
      }

      this.searchQuery = searchQuery; // Aggiorna la ricerca corrente
      this.$nextTick(() => {
        const multiselectEl = this.$refs[idRefs]?.$el;
        if (!multiselectEl) {
          console.log(
            `** multiselect comuni: elemento multiselect non trovato: ${idRefs}`
          );
          return;
        }

        const firstMatchEl = multiselectEl.querySelector(
          ".multiselect__option:not(.multiselect__option--disabled)"
        );

        if (firstMatchEl) {
          firstMatchEl.scrollIntoView({ behavior: "smooth", block: "start" });
          console.log("** multiselect comuni: scorrimento al primo match:", firstMatchEl);
        } else {
          console.log(
            "** multiselect comuni: nessun elemento corrispondente alla ricerca"
          );
        }
      });
    },
    //
    // Gestione della data
    //
    setToday(fieldName) {
      // Imposta la data odierna in formato ISO con timezone Italia
      const now = new Date();
      this.localData[fieldName] = this.formatDateToISO(now);
      console.log("** festività data impostata a oggi:", this.localData[fieldName]);
    },
    onDataUpdate(newDate) {
      // Verifica che la data sia valida
      if (newDate) {
        // Converti direttamente in formato ISO
        this.localData.data = this.formatDateToISO(newDate);
        console.log("** festività data aggiornata:", this.localData.data);
      } else {
        console.warn("** festività data non valida:", newDate);
      }
    },
    onDataClear() {
      // Resetta la data
      this.localData.data = null;
      console.log("** festività data resettata");
    },
    formatDateToISO(date) {
      // Crea una nuova data impostando l'ora a mezzanotte (00:00:00)
      const midnightDate = new Date(date);
      midnightDate.setHours(0, 0, 0, 0);

      // Formatta la data in ISO con timezone UTC
      return format(midnightDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", { timeZone: "UTC" });
    },
  },
  async mounted() {
    // Popolo l'array generale di tutte le province con i relativi comuni
    this.provinceComuni = await this.mixins_fetchProvinceComuni();
    // Carico le options per la multiselect dei comuni, comuniOptions e ordino per comune in modo alfabetico crescente
    this.comuniOptions = this.provinceComuni
      .flatMap((provincia) =>
        provincia.comuni.map((comune) => ({
          value: comune,
          label: comune,
        }))
      )
      .sort((a, b) => a.value.localeCompare(b.value));
    console.log("** comuni", this.comuniOptions);
  },
};
</script>
