<template>
  <main>
    <div v-if="isLoading" class="backdrop"></div>
    <div class="container content-container">
      <div class="align-items-center d-flex justify-content-center container p-0 m-0">
        <div class="header-text">
          <h1>Login</h1>
        </div>
      </div>
      <div class="align-items-center d-flex justify-content-center row mx-1">
        <div class="login-wrap col-md-7 col-lg-6">
          <form
            novalidate
            class="m-3"
            @submit.prevent="handleLogin"
            v-if="!$store.getters.isAuthenticated"
          >
            <!-- UserName -->
            <div
              class="form-group mb-3"
              :class="{ error: v$.form.username.$errors.length }"
            >
              <label class="form-label">USERNAME</label>
              <input
                id="username"
                ref="username"
                v-model.trim="v$.form.username.$model"
                type="text"
                v-input-textarea
                class="form-control"
                placeholder="USERNAME IN FORMATO EMAIL"
                required
                spellcheck="false"
                autocomplete="off"
                autocapitalize="none"
                @click.right.prevent
                @keydown.space.prevent
              />
            </div>
            <div
              class="input-errors"
              v-for="(error, index) of v$.form.username.$errors"
              :key="index"
            >
              <div class="text-danger">{{ error.$message }}</div>
            </div>
            <!-- Password -->
            <div
              class="form-group mb-3"
              :class="{ error: v$.form.password.$errors.length }"
            >
              <label class="form-label">PASSWORD</label>
              <div class="input-group">
                <input
                  id="password"
                  ref="password"
                  v-model.trim="v$.form.password.$model"
                  type="password"
                  class="form-control"
                  placeholder="PASSWORD"
                  required
                  spellcheck="false"
                  autocomplete="off"
                  autocapitalize="none"
                  @click.right.prevent
                  @keydown.space.prevent
                />
                <button
                  type="button"
                  class="input-group-button"
                  :alt="eye_psw ? 'NASCONDI PASSWORD' : 'MOSTRA PASSWORD'"
                  :title="eye_psw ? 'NASCONDI PASSWORD' : 'MOSTRA PASSWORD'"
                  @keydown.enter.prevent
                  @click.prevent="eye_psw = toggleVisibility('password')"
                >
                  <i
                    :class="
                      eye_psw
                        ? 'zmdi zmdi-eye-off zmdi-hc-lg'
                        : 'zmdi zmdi-eye zmdi-hc-lg'
                    "
                  ></i>
                </button>
              </div>
            </div>
            <div
              class="input-errors"
              v-for="(error, index) of v$.form.password.$errors"
              :key="index"
            >
              <div class="text-danger">{{ error.$message }}</div>
            </div>
            <!-- Submit Login -->
            <div class="row mt-4">
              <div class="col-md-12">
                <!-- Login Button -->
                <base-icon-button
                  id="loginBtn"
                  ref="loginBtn"
                  :alignClass="'float-end'"
                  :typeButton="'submit'"
                  v-html="isLoading ? buttonCaption.wait : buttonCaption.login"
                  :disabled="v$.form.$invalid || isDisabled"
                ></base-icon-button>
              </div>
            </div>
            <!-- Forgot your password -->
            <hr class="sepGray mt-4 mb-4" />
            <div
              class="align-items-lg-center align-items-start d-flex flex-column flex-lg-row flex-md-row form-group justify-content-center justify-content-lg-between"
            >
              <div class="text-end">
                <router-link
                  class="link-base"
                  :to="{ name: 'route-forgotPassword' }"
                  exact
                  ><span class="small">Hai dimenticato la password?</span></router-link
                >
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import BaseIconButton from "@/components/ui/BaseIconButton.vue";
import useVuelidate from "@vuelidate/core";
import { required, helpers, minLength } from "@vuelidate/validators";
import { login } from "@/services/auth";
// import { UtilityMixins } from "@/mixins/UtilityMixins.js";
import EventBus from "@/eventBus"; // Importa l'EventBus

export default {
  name: "LoginView",
  // mixins: [UtilityMixins],
  components: { BaseIconButton },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      eye_psw: false,
      form: {
        username: "",
        password: "",
      },
      buttonCaption: {
        wait:
          '<span class="spinner-border spinner-border-sm" role="status"></span>&nbsp;Attendere',
        login: '<i class="bi bi-box-arrow-in-right me-2"></i>Login',
      },
      isDisabled: false,
      isLoading: false,
    };
  },
  validations() {
    return {
      form: {
        username: {
          required: helpers.withMessage("Questo campo non può essere vuoto", required),
        },
        password: {
          required: helpers.withMessage("Questo campo non può essere vuoto", required),
          minLength: helpers.withMessage(
            ({ $params }) => `La lunghezza minima è ${$params.min} caratteri`,
            minLength(8)
          ),
        },
      },
    };
  },
  methods: {
    toggleVisibility(refId) {
      const input = this.$refs[refId];
      input.type = input.type === "password" ? "text" : "password";
      return input.type === "text";
    },
    async handleLogin() {
      this.v$.form.$touch();
      if (this.v$.form.$invalid) return false;

      this.isDisabled = true;
      this.isLoading = true;

      try {
        const userData = await login(
          this.v$.form.username.$model,
          this.v$.form.password.$model
        );
        this.$store.dispatch("login", userData);

        // Emette l'evento changeUser per cambiare lo user nell'header
        EventBus.emit("changeUser", userData);

        this.$router.push({ name: "route-dashboard" });
      } catch (err) {
        console.error("Login error:", err);
      } finally {
        this.isLoading = false;
        this.isDisabled = false;
      }
    },
    focusInput() {
      this.$nextTick(() => {
        this.$refs?.username?.focus();
      });
    },
  },
  mounted() {
    if (this.$store.getters.isAuthenticated) {
      this.$router.push({ name: "route-dashboard" });
    } else {
      this.focusInput();
    }
  },
};
</script>
