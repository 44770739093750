import { createApp } from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import EventBus from "@/eventBus";

// Bootstrap 5
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "bootstrap-icons/font/bootstrap-icons.css"

// Material Design Font Icon
import "@/assets/css/material-design-iconic-font.min.css";

// Snackbar3
import { SnackbarService, Vue3Snackbar } from "vue3-snackbar";
import "vue3-snackbar/styles";

// Vue Sweetalert2
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

// Vue Loading
import VueLoading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

// Vue Datepicker
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

// Multiselect
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";
import "@/assets/css/vue-multiselect.css";

// Custom CSS
import "@/assets/css/styles.css";
import "@/assets/css/sweetalert2.css";
import "@/assets/css/full-calendar.css";

import { inputTextareaDirective } from '@/inputTextareaDirective'

//disabilita la console in production 
if (process.env.NODE_ENV !== "development") {
    console.log = () => { };
    console.debug = () => { };
    console.info = () => { };
    console.warn = () => { };
}

const app = createApp(App);

app.config.globalProperties.$EventBus = EventBus; // Aggiungo l'EventBus alle proprietà globali

async function firstLoad() {
    setTimeout(() => {
        app.use(store);
        app.use(router);
        app.use(SnackbarService);
        app.use(VueSweetalert2);
        app.component('VueDatePicker', VueDatePicker);
        app.component('MultiSelect', Multiselect);
        app.use(VueLoading);
        app.component("vue3-snackbar", Vue3Snackbar);
        app.directive('input-textarea', inputTextareaDirective);
        app.mount("#app");
    }, 1000);
}

firstLoad();