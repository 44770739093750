<template>
  <overlay-loading v-model:isLoading="isLoading"></overlay-loading>
  <div class="col-sm-12 main-col">
    <div class="row p-0">
      <div class="align-items-center d-flex flex-row justify-content-between m-0 p-0 row">
        <div class="col-sm-10 p-0">
          <div
            class="align-items-center bg-light d-flex header-text justify-content-start p-0 w-100"
          >
            <h1>
              GESTIONE SKILLS
              <span class="text-black small ms-2">
                <i class="bi bi-award-fill me-2"></i>{{ selectedSkillType.label }}
                <span class="small ms-2">
                  ({{ totalElements }} {{ elementsLabel }})
                </span>
              </span>
            </h1>
          </div>
        </div>
        <div class="col-sm-2 p-0">
          <!-- Search -->
          <search-button
            :searchText="searchQuery"
            @txtToSearch="filterTextToSearch"
            @clearTxtToSearch="filterClearTextToSearch"
            v-if="!loading"
          />
        </div>
      </div>
      <!-- Content -->
      <div
        class="container-fluid m-0 p-2 background-gray overflow-auto container-contents"
      >
        <!-- Linear Loader -->
        <div v-if="loading" class="linear-loader">
          <span></span>
        </div>
        <!-- Aggiungi nuovo skill -->
        <div
          class="w-100 mt-2 padding-x-4px align-items-center d-flex justify-content-end"
        >
          <button @click="addSkill()" type="button" class="btn button-outline-1 small">
            <i class="zmdi zmdi-plus zmdi-hc-lg"></i> Aggiungi Nuovo
          </button>
        </div>
        <hr class="sepGray mt-3" />
        <!-- No data -->
        <div v-if="filteredSkills.length == 0 && !loading">
          <h5 class="mt-3 padding-x-4px">Nessun dato da visualizzare</h5>
        </div>
        <!-- Data -->
        <div v-if="filteredSkills.length > 0 && !loading">
          <!-- Table Data -->
          <div class="mt-3 mb-5">
            <div class="row m-0 padding-x-4px">
              <table class="table-bordered table-responsive-md table-setup mb-2">
                <thead class="table-header-font">
                  <tr class="td-center">
                    <th>DESCRIZIONE</th>
                    <th style="min-width: 200px !important; width: 200px !important">
                      TIPOLOGIA
                    </th>
                    <th style="min-width: 100px !important; width: 100px !important">
                      AZIONI
                    </th>
                  </tr>
                </thead>
                <tbody class="table-body-font td-vertical-center">
                  <tr v-for="skill in filteredSkills" :key="skill.id">
                    <td>{{ skill.descrizione }}</td>
                    <td class="td-center">{{ selectedSkillType.label }}</td>
                    <!-- Actions -->
                    <td class="td-actions td-center">
                      <button
                        alt="MODIFICA"
                        title="MODIFICA"
                        class="btn icon-button"
                        @click="editSkill(skill)"
                      >
                        <i class="zmdi zmdi-edit zmdi-hc-lg"></i>
                      </button>
                      <button
                        class="delete btn icon-button"
                        alt="ELIMINA"
                        title="ELIMINA"
                        @click="askDeleteSkill(skill)"
                      >
                        <i class="zmdi zmdi-delete zmdi-hc-lg"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <DialogAddEditSkill
    :isVisible="isDialogVisible"
    :dialogWidth="'780px'"
    :dialogTitle="getDialogTitle"
    :dataItem="dataSkill"
    @closeModal="closeModal"
    @saveData="saveData"
    :isEditMode="getIsEditMode"
  />
</template>

<script>
import SearchButton from "@/components/ui/SearchButton.vue";
import { UtilityMixins } from "@/mixins/UtilityMixins.js";
import MappingSkillType from "@/data/mapping_skills_type.json";
import DialogAddEditSkill from "@/components/dialogs/DialogAddEditSkill.vue";
import OverlayLoading from "@/components/ui/OverlayLoading.vue";
import { updateTabella } from "@/services/api";

class SkillObject {
  constructor(data = {}) {
    this.id = data.id || "";
    this.nome = data.nome || "";
    this.descrizione = data.descrizione || "";
    this.type = data.type || "";
  }
}

export default {
  mixins: [UtilityMixins],
  components: {
    OverlayLoading,
    SearchButton,
    DialogAddEditSkill,
  },
  props: {
    selectedType: String,
    skillsSottoclassi: Array,
    mappingSkillType: Array,
    loading: Boolean,
  },
  data() {
    return {
      MappingSkillType,
      isSearching: false,
      // skill table
      mainSkillTable: {
        _id: "66b2521483a48dbb6de17c8d",
        sottoclassi: [],
        classe: "SkillsData",
        canbeupdated: true,
      },
      searchQuery: "", // Memorizzo il testo di ricerca
      // dialog
      isDialogVisible: false,
      dialogTitle: "",
      dataSkill: {
        id: "",
        nome: "",
        descrizione: "",
        type: "",
      },
      isEditMode: false,
      isLoading: false,
      localSkillsSottoclassi: [], // Sarà la copia locale di skillsSottoclassi
    };
  },
  computed: {
    selectedSkillType() {
      const typeData = this.mappingSkillType.find(
        (item) => item.value === this.selectedType
      );
      return typeData
        ? { label: typeData.label, value: typeData.value }
        : { label: this.selectedType, value: this.selectedType };
    },
    filteredSkills() {
      // Filtra e ordina le skills in base a selectedType e searchQuery, con sort case-sensitive
      return this.localSkillsSottoclassi
        .filter((skill) => {
          return (
            skill.type === this.selectedType &&
            skill.descrizione.toLowerCase().includes(this.searchQuery.toLowerCase())
          );
        })
        .sort((a, b) =>
          a.descrizione.localeCompare(b.descrizione, undefined, { sensitivity: "case" })
        );
    },
    getDialogTitle() {
      return this.dialogTitle;
    },
    getIsEditMode() {
      return this.isEditMode;
    },
    totalElements() {
      return this.filteredSkills.length;
    },
    elementsLabel() {
      return this.totalElements === 1 ? "elemento" : "elementi";
    },
  },
  watch: {
    skillsSottoclassi: {
      immediate: true,
      handler(newSkills) {
        // Creo una copia profonda ogni volta che `skillsSottoclassi` cambia
        this.localSkillsSottoclassi = JSON.parse(JSON.stringify(newSkills));
      },
    },
  },
  methods: {
    filterTextToSearch(text) {
      this.searchQuery = text;
    },
    filterClearTextToSearch() {
      this.searchQuery = "";
    },
    generateUniqueId() {
      const generateId = () => {
        // Genera un ID casuale di 24 caratteri esadecimali, simile a "66b2521183a48dbb6de17a27"
        return [...Array(24)]
          .map(() => Math.floor(Math.random() * 16).toString(16))
          .join("");
      };

      let newId;

      do {
        newId = generateId();
      } while (
        // Controlla che l'ID non esista in localSkillsSottoclassi e mainSkillTable.sottoclassi
        this.localSkillsSottoclassi.some((skill) => skill.id === newId) ||
        this.mainSkillTable.sottoclassi.some((skill) => skill.id === newId)
      );

      return newId;
    },
    //
    // Gestione dialog
    //
    closeModal() {
      this.isDialogVisible = false;
    },
    addSkill() {
      this.isEditMode = false;
      this.dialogTitle = `NUOVO SKILL (${this.selectedSkillType.label})`;
      this.dataSkill = new SkillObject();
      this.isDialogVisible = true;
    },
    editSkill(skill) {
      console.log(
        "** edit skill",
        skill,
        this.localSkillsSottoclassi,
        this.filteredSkills
      );
      this.isEditMode = true;
      this.dialogTitle = `MODIFICA SKILL (${this.selectedSkillType.label})`;
      this.dataSkill = new SkillObject(skill);
      this.isDialogVisible = true;
    },
    async saveData(dataRecived) {
      console.log("** Dati aggiornati ricevuti da DialogAddEditSkill:", dataRecived);

      this.closeModal();

      // Salvo i dati
      this.isLoading = true;

      if (this.isEditMode) {
        this.updateSkill(dataRecived);
      } else {
        dataRecived.id = dataRecived.id === "" ? this.generateUniqueId() : dataRecived.id;
        dataRecived.type = this.selectedSkillType.value;
        this.localSkillsSottoclassi.push(dataRecived);
      }

      this.mainSkillTable.sottoclassi = [...this.localSkillsSottoclassi];

      try {
        const apiResponse = await updateTabella(
          this.mainSkillTable._id,
          this.mainSkillTable
        );
        // this.searchQuery = "";
        // this.filterClearTextToSearch();
        console.log("** updateTabella skills", apiResponse);
        let msg = "";
        if (this.isEditMode) {
          msg = "Skill modificato correttamente";
        } else {
          msg = "Skill aggiunto correttamente";
        }
        if (apiResponse != undefined) {
          this.mixins_showMessage("Gestione Skill", msg, "info");
        }
      } catch (error) {
        console.error("Errore durante il salvataggio dei dati", error);
      } finally {
        this.isLoading = false;
      }
    },
    updateSkill(dataRecived) {
      const updatedData = dataRecived;

      // Trovo l'indice dell'oggetto con lo stesso id
      const index = this.localSkillsSottoclassi.findIndex(
        (skill) => skill.id === updatedData.id
      );

      // Se l'oggetto è trovato, aggiorno solo i campi esistenti in `updatedData`
      if (index !== -1) {
        this.localSkillsSottoclassi[index] = {
          ...this.localSkillsSottoclassi[index], // Mantengo i campi esistenti
          ...updatedData, // Sovrascrivo solo i campi presenti in updatedData
        };
      } else {
        console.warn("ID non trovato in localSkillsSottoclassi");
      }
    },
    removeSkillById(idToRemove) {
      // Trova l'indice dell'elemento con l'ID specifico
      const index = this.localSkillsSottoclassi.findIndex(
        (skill) => skill.id === idToRemove
      );

      // Se l'elemento esiste, rimuovilo usando splice
      if (index !== -1) {
        this.localSkillsSottoclassi.splice(index, 1);
        return true;
      } else {
        console.warn("Elemento non trovato con ID:", idToRemove);
        return false;
      }
    },
    async askDeleteSkill(skill) {
      if (!skill.id) return;

      this.$swal
        .fire({
          title: "Gestione Skill",
          html: "Sei sicuro di voler cancellare lo skill?",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: '<i class="fa fa-check"></i> Conferma',
          cancelButtonText: '<i class="fa fa-xmark"></i> Annulla',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await this.removeSkill(skill.id);
          }
        });
    },
    async removeSkill(id) {
      if (!this.removeSkillById(id)) return;

      this.mainSkillTable.sottoclassi = [...this.localSkillsSottoclassi];

      this.isLoading = true;
      try {
        const apiResponse = await updateTabella(
          this.mainSkillTable._id,
          this.mainSkillTable
        );
        // this.searchQuery = "";
        // this.filterClearTextToSearch();
        console.log("** updateTabella skills", apiResponse);
        if (apiResponse != undefined) {
          this.mixins_showMessage(
            "Gestione Skill",
            "Skill cancellato correttamente",
            "info"
          );
        }
      } catch (error) {
        console.error("Errore durante il salvataggio dei dati", error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
